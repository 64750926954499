<template>
  <div class="character-dataset1">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" enctype="multipart/form-data" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="form-group required" :class="errorFields['name'] ? 'error' : ''">
          <label for="name">{{ $t('character.name') }}</label>
          <input
            v-model="name"
            aria-describedby="name"
            class="form-control"
            id="name"
            name="name"
            required
            type="text"
            :placeholder="$t('character.name')"
          />
        </div>
        <div class="form-group" :class="errorFields['image'] ? 'error' : ''">
          <label for="image">{{ $t('global.picture') }}</label>
          <input
            accept="image/*"
            aria-describedby="image"
            class="form-control"
            id="image"
            name="image"
            type="file"
            @change="onChangeCharacterImageFile($event)"
          />
        </div>
        <FormGroupSelect
          class="form-group"
          :ariaDescribedby="game.ariaDescribedby"
          :class="errorFields['game'] ? 'error' : ''"
          :id="game.id"
          :label="game.label"
          :name="game.name"
          :optionLabel="game.optionLabel"
          :options="game.data"
          :optionValue="game.optionValue"
          :placeholder="game.placeholder"
          :required="game.required"
          :selectValue="game.selectedValue"
        />
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_1_REQUEST, CHARACTER_REGENERATION } from '@/store/actions/character';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { GAMES_USER_CHARACTER_REQUEST } from '@/store/actions/game';
import FormGroupSelect from '@/components/Form/FormGroupSelect';

export default {
  name: 'CharacterDataset1',
  components: {
    FormGroupSelect
  },
  mixins: [formMixin, prhMixin],
  props: {
    type: String
  },
  data() {
    return {
      errorFields: [],
      game: {
        ariaDescribedby: 'game',
        data: [],
        id: 'game',
        label: 'global.game',
        name: 'game',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      isContentReady: false,
      name: '',
      targetFile: '',
      targetName: ''
    };
  },
  created() {
    this.populateGames().then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    // EVENT HANDLERS
    onChangeCharacterImageFile($event) {
      this.targetName = $event.target.name;
      this.targetFile = $event.target.files[0];
    },
    onSubmit() {
      const { name, type, game } = this;
      const formData = new FormData();
      formData.append(this.targetName, this.targetFile);
      formData.append('name', name);
      formData.append('type', type);
      formData.append('game', game.selectedValue);

      this.form_resetResponse();
      this.prh_dispatch(CHARACTER_CREATION_STEP_1_REQUEST, CHARACTER_REGENERATION, formData, '/character/create/step2');
    },

    // FUNCTIONS
    populateGames() {
      return this.$store
        .dispatch(GAMES_USER_CHARACTER_REQUEST)
        .then(data => {
          this.game.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
