<template>
  <div id="stats-form" class>
    <table class="table table-stats">
      <thead>
        <tr>
          <th class="left">{{ $t('stats.stat') }}</th>
          <th class="center">{{ $t('stats.temporary') }}</th>
          <th class="center">{{ $t('stats.potential') }}</th>
          <th class="center">{{ $t('stats.race_bonus') }}</th>
          <th class="center">{{ $t('stats.score_calculated') }}</th>
          <th class="center">{{ $t('stats.bonus_calculated') }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="(category, catIndex) in statCategories">
          <tr class="stats-category" :key="`category-${catIndex}`">
            <td colspan="6">{{ category.name }}</td>
          </tr>
          <tr
            v-for="(stat, statIndex) in groupedStats(category)"
            :key="`stat-${catIndex}-${statIndex}`"
            class="stats-entry"
            :class="{
              even: statIndex % 2 === 0,
              odd: statIndex % 2 !== 0,
              error:
                stat.statusTypedStat === 'error' ||
                errorFieldsInherited['temporary_' + stat.id] ||
                errorFieldsInherited['potential_' + stat.id]
            }"
          >
            <td class="stat left">{{ stat.stat }}</td>
            <td class="center">
              <input
                required="required"
                :type="getTemporaryInputType(stat.id)"
                :disabled="disableTemporaryInput(stat.id)"
                class="form-control"
                :value="setTemporaryInputValue(stat)"
                :id="'temporary_' + stat.id"
                :name="'temporary_' + stat.id"
                :ref="'temporary_' + stat.id"
                :aria-describedby="'temporary_' + stat.id"
                @change="setStatData($event, 'temporary', stat.id)"
                @input="setStatData($event, 'temporary', stat.id)"
                @blur="checkStatData($event, 'temporary', stat.id)"
              />
              <template v-if="system.stat_size_id === stat.id">{{ $t('stats.not_applicable') }}</template>
              <template v-else> / {{ system.stat_max_roll_score }}</template>
            </td>
            <td class="center">
              <input
                required="required"
                :type="getPotentialInputType(stat.id, stat.tag)"
                class="form-control"
                :value="setPotentialInputValue(stat)"
                :disabled="disablePotentialInput(stat)"
                :id="'potential_' + stat.id"
                :name="'potential_' + stat.id"
                :ref="'potential_' + stat.id"
                :aria-describedby="'potential_' + stat.id"
                @change="setStatData($event, 'potential', stat.id)"
                @input="setStatData($event, 'potential', stat.id)"
                @blur="checkStatData($event, 'potential', stat.id)"
              />
              <template v-if="getPotentialInputType(stat.id, stat.tag) === 'hidden'">
                {{ $t('stats.not_applicable') }}</template
              >
              <template v-else> / {{ system.stat_max_roll_score }}</template>
            </td>
            <td class="right">
              {{ $helpers.formatSignedNumber(getRaceStatModifer(stat.id)) }}
            </td>
            <td class="right">
              {{ calculateTemporaryScore(stat.id) }}
              ({{ calculatePotentialScore(stat.id) }})
            </td>
            <td class="right">
              {{ $helpers.formatSignedNumber(calculateTemporaryBonus(stat.id)) }}
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import {
  STATS_RACE_REQUEST,
  STATS_REQUEST,
  STATS_STATUS_RESET,
  STAT_SET,
  STAT_INPUT_ERROR
} from '@/store/actions/stats';
import { FEATURE_ENTITY_STAT_REQUEST } from '@/store/actions/feature';
import { mapState } from 'vuex';

export default {
  name: 'StatsForm',
  mixins: [formMixin],
  props: {
    errorFieldsInherited: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      stats: [],
      raceStats: [],
      efIds: [],
      statEntityFeatures: [],
      isStatsPopulated: false
    };
  },
  computed: {
    ...mapState(['character', 'system']),
    statCategories() {
      let categories = [];
      let currCatId = null;

      for (let s = 0; s < this.stats.length; s++) {
        if (currCatId !== this.stats[s].category_id) {
          categories.push({
            category_id: this.stats[s].category_id,
            name: this.stats[s].category
          });
          currCatId = this.stats[s].category_id;
        }
      }
      return categories;
    }
  },
  created() {
    /* this part is useful only when reloading the page, otherwise the necessary character is already available */
    this.unsubscribe = this.$store.subscribe(mutation => {
      if (mutation.type === 'CHARACTER_SUCCESS') {
        this.populateStats();
        this.unsubscribe();
      }
    });
  },
  mounted() {
    this.populateStats();
    this.getRaceStats();
  },
  methods: {
    calculatePotentialScore(id) {
      const stat = this.stats.find(function(stat) {
        return stat.id === id;
      });

      let potentialScore;
      if (stat.potential_rolled) {
        potentialScore = stat.potential_rolled * 1 + this.getRaceStatModifer(id);
        potentialScore = potentialScore < this.system.stat_min_score ? this.system.stat_min_score : potentialScore;
      } else {
        potentialScore = '-';
      }

      return potentialScore;
    },
    calculateTemporaryBonus(id) {
      const stat = this.stats.find(function(stat) {
        return stat.id === id;
      });
      return stat.temporary_modifier;
    },
    calculateTemporaryScore(id) {
      const stat = this.stats.find(function(stat) {
        return stat.id === id;
      });

      let temporaryScore;
      if (stat.temporary_rolled) {
        temporaryScore = stat.temporary_rolled * 1 + this.getRaceStatModifer(id);
        temporaryScore = temporaryScore < this.system.stat_min_score ? this.system.stat_min_score : temporaryScore;
      } else {
        temporaryScore = '-';
      }

      return temporaryScore;
    },
    checkStatData($event, type, id) {
      let score = $event.target.value;
      const stat = this.stats.find(function(stat) {
        return stat.id === id;
      });
      stat[type + '_rolled'] = score;

      const data = [];
      const isInt = /^\d+$/.test(score);

      let modifier = '';
      let action = '';

      this.form_resetResponseComponent(id);

      if (isInt) {
        score *= 1;
        if (type === 'temporary' && /^\d+$/.test(stat['potential_rolled']) && score > stat['potential_rolled']) {
          action = STAT_SET;
          score = stat['potential_rolled'];
          stat[type + '_rolled'] = score;
          this.$store.commit(MESSAGE_ERROR_SET, 'temporary_stat_higher_than_potential');
        } else if (type === 'potential' && /^\d+$/.test(stat['temporary_rolled']) && stat['temporary_rolled'] > score) {
          action = STAT_SET;
          score = stat['temporary_rolled'];
          stat[type + '_rolled'] = score;
          this.$store.commit(MESSAGE_ERROR_SET, 'potential_stat_lower_than_temporary');
        }

        if (action === STAT_SET) {
          data.push({
            property: type + '_rolled',
            value: score
          });

          if (type === 'temporary') {
            // modifier = score - this.system.stat_average_score
            modifier = this.calculateTemporaryScore(id) - this.system.stat_average_score;
            data.push({
              property: type + '_modifier',
              value: modifier
            });
          }

          this.$store.commit(STAT_SET, {
            id: id,
            data: data
          });

          this.$refs[type + '_' + id][0].focus();
        }
      }
    },
    getEntityFeatureStatIds() {
      if (!this.character.feature) {
        return false;
      }

      if (this.character.feature.length > 0) {
        return this.$helpers.array_column(this.character.feature, 'ef_id');
      }
      return [];
    },
    getPotentialInputType(statId, statTag) {
      return this.system.stats_without_potential.includes(statTag) ? 'hidden' : 'text';
    },
    getTemporaryInputType(statId) {
      return statId === this.system.stat_size_id ? 'hidden' : 'text';
    },
    disablePotentialInput(stat) {
      let disableInput =
        !!stat.temporary_rolled === true || !!stat.potential_rolled === true || this.system.stat_size_id === stat.id
          ? false
          : 'disabled';

      const entityFeatureStat = this.statEntityFeatures.find(function(statEntityFeature) {
        return statEntityFeature.stat === stat.id;
      });

      if (entityFeatureStat && parseInt(entityFeatureStat.multiplier) === 0) {
        disableInput = 'disabled';
      }

      return disableInput;
    },
    disableTemporaryInput(statId) {
      let disableInput = false;

      const entityFeatureStat = this.statEntityFeatures.find(function(statEntityFeature) {
        return statEntityFeature.stat === statId;
      });

      if (entityFeatureStat && parseInt(entityFeatureStat.multiplier) === 0) {
        disableInput = 'disabled';
      }

      return disableInput;
    },
    getRaceStats() {
      this.$store
        .dispatch(STATS_RACE_REQUEST)
        .then(data => {
          this.raceStats = data ? data : [];
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    getRaceStatModifer(id) {
      let modifier = '';
      if (id || id === 0 || id === '0') {
        const raceStat = this.raceStats.filter(function(stat) {
          return stat.id === id;
        });
        modifier = raceStat.length === 1 ? raceStat[0].modifier : '';
      }
      return modifier * 1;
    },
    getStatModifier(modifier) {
      if (!modifier) {
        return 0;
      }
      return modifier * 1;
    },
    groupedStats(currCat) {
      return this.stats.filter(function(stat) {
        return stat.category_id === currCat.category_id;
      });
    },
    async populateStats() {
      this.efIds = this.getEntityFeatureStatIds();
      if (this.efIds && !this.isStatsPopulated) {
        this.isStatsPopulated = true;
        this.unsubscribe();
        let PROMISE_FEATURE_ENTITY_STAT_REQUEST = FEATURE_ENTITY_STAT_REQUEST;
        let promiseFeatureEntityStatData = this.efIds;

        try {
          if (this.efIds.length !== 0) {
            this.statEntityFeatures = await this.$store.dispatch(
              PROMISE_FEATURE_ENTITY_STAT_REQUEST,
              promiseFeatureEntityStatData
            );
          }

          this.stats = await this.$store.dispatch(STATS_REQUEST);
        } catch (err) {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        }
      }
    },
    setStatData($event, type, id) {
      let score = $event.target.value;
      const stat = this.stats.find(function(stat) {
        return stat.id === id;
      });
      stat[type + '_rolled'] = score;

      const data = [];
      const isInt = /^\d+$/.test(score);

      const maxScore =
        this.system.stat_max_roll_score ||
        this.system.stat_max_roll_score === '0' ||
        this.system.stat_max_roll_score === 0
          ? this.system.stat_max_roll_score * 1
          : undefined;
      const minScore =
        this.system.stat_min_roll_score ||
        this.system.stat_min_roll_score === '0' ||
        this.system.stat_min_roll_score === 0
          ? this.system.stat_min_roll_score * 1
          : undefined;
      let modifier = '';
      let action = STAT_SET;

      this.form_resetResponseComponent(id);

      if (isInt) {
        score *= 1;

        if (typeof minScore !== 'undefined' && score < minScore) {
          score = minScore;
          stat[type + '_rolled'] = score;
          this.$store.commit(MESSAGE_ERROR_SET, 'min_value_exceeded');
        }
        if (typeof maxScore !== 'undefined' && score > maxScore) {
          score = maxScore;
          stat[type + '_rolled'] = score;
          this.$store.commit(MESSAGE_ERROR_SET, 'max_value_exceeded');
        }

        modifier = this.calculateTemporaryScore(id) - this.system.stat_average_score;
      } else if (score) {
        action = STAT_INPUT_ERROR;
        this.$emit('error-fields-set', 'stats', 'error');
        this.$store.commit(MESSAGE_ERROR_SET, 'wrong_format');
      }

      data.push({
        property: type + '_rolled',
        value: score
      });

      if (type === 'temporary') {
        data.push({
          property: type + '_modifier',
          value: modifier
        });
      }

      this.$store.commit(action, {
        id: id,
        data: data
      });
    },
    setPotentialInputValue(potentialStat) {
      let potentialInputValue = this.system.stats_without_potential.includes(potentialStat)
        ? '0'
        : potentialStat.potential_rolled;

      const entityFeatureStat = this.statEntityFeatures.find(function(statEntityFeature) {
        return statEntityFeature.stat === potentialStat.id;
      });

      if (entityFeatureStat && parseInt(entityFeatureStat.multiplier) === 0) {
        potentialInputValue = '0';
      }

      potentialStat.potential_value = potentialInputValue;
      return potentialInputValue;
    },
    setTemporaryInputValue(temporaryStat) {
      let temporaryInputValue = this.system.stat_size_id === temporaryStat.id ? '0' : temporaryStat.temporary_rolled;

      const entityFeatureStat = this.statEntityFeatures.find(function(statEntityFeature) {
        return statEntityFeature.stat === temporaryStat.id;
      });

      if (entityFeatureStat && parseInt(entityFeatureStat.multiplier) === 0) {
        temporaryInputValue = '0';
      }

      temporaryStat.temporary_value = temporaryInputValue;
      return temporaryInputValue;
    },
    form_resetResponseComponent(id = null) {
      this.form_resetResponse();
      this.$emit('error-fields-set', null);
      if (id) {
        this.$store.commit(STATS_STATUS_RESET, { id: id });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.table-stats {
  border: 1px solid #606167;

  th,
  td {
    border: 0;
    padding: 3px 5px;
  }

  thead {
    th {
      background-color: #ccd3ff;
      border-bottom: 1px solid #606167;
      font-size: 1.2rem;
    }
  }

  tr {
    &.odd {
      background-color: transparent;
    }

    &.even {
      background-color: #f1f3ff;
    }

    &.stats-category {
      background-color: #e7ddff;
      color: #787878;
      font-size: 1rem;
      text-transform: uppercase;
    }

    &.stats-entry {
      &.error {
        .stat,
        input[type='text'] {
          color: $error-color;
        }
      }

      td {
        vertical-align: middle;

        &.stat::after {
          content: ':';
          display: inline-block;
        }

        .form-control {
          display: inline-block;
          width: 50px;
        }
      }
    }
  }
}
</style>
