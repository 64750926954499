<template>
  <div class="modifiers modifiers--general" :id="entityNature + 'List'">
    <AnimationLoading v-if="!this.isContentReady" />

    <template v-if="this.isContentReady">
      <table class="table table-modifier table-modifier--general">
        <tr>
          <td colspan="2" class="modifiers-maneuver-type">
            {{ $t(`action.maneuver_type.${this.maneuverType ? this.maneuverType : 'skill'}`) }}
          </td>
        </tr>
        <template v-if="modifierCategories.length > 0">
          <template v-for="(category, categoryIndex) in modifierCategories">
            <tr v-if="category.id" :key="`category-modifier-${categoryIndex}`">
              <td colspan="2" class="category-modifier-title">{{ category.name }}</td>
            </tr>
            <template v-for="(modifier, modifierIndex) in categorizedModifiers(category.id)">
              <tr
                :key="`modifier-${categoryIndex}-${modifierIndex}`"
                class="modifier"
                :class="isModifierSelected(modifier.id, modifier.isSelected)"
                @click="setManeuverModifier(modifier)"
              >
                <td class="condition">{{ modifier.condition }}</td>
                <td class="value right">{{ $helpers.formatSignedNumber(modifier.value) }}</td>
              </tr>
            </template>
          </template>
        </template>
        <template v-else>
          <tr>
            <td colspan="2" class="modifier-none">{{ $t(`global.none`) }}</td>
          </tr>
        </template>
      </table>
    </template>
  </div>
</template>

<script>
const entityNature = 'modifier',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { MANEUVER_SKILL_HIER_MODIFIERS_REQUEST, MANEUVER_TYPE_MODIFIERS_REQUEST } from '@/store/actions/modifier';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { mapGetters } from 'vuex';
import { EventBus } from '@/utils/eventBus';

export default {
  name: entityNatureCapitalized + 'List',
  props: ['type'],
  data() {
    return {
      characterSkillId: undefined,
      entityNature,
      entityNatureCapitalized,
      gameSkillId: undefined,
      isContentReady: false,
      maneuverSubtype: undefined,
      maneuverType: undefined,
      modifiers: [],
      selectedModifiers: [],
      skillId: undefined
    };
  },
  computed: {
    ...mapGetters(['isMaster']),
    modifierCategories() {
      const categories = [];

      if (typeof this.modifiers.length !== 'undefined') {
        const uniqueModifierCategories = this.$helpers.removeDuplicatesFromArrayOfObjects(this.modifiers, 'category');

        for (const category of uniqueModifierCategories) {
          categories.push({
            id: category.category,
            name: category.category_name
          });
        }
      }

      return categories;
    }
  },
  created() {
    EventBus.$on('load-maneuver-modifiers', this.loadSelectedActionModifers);
    EventBus.$on('action-reset-modifiers', this.selectedActionResetModifers);

    if (this.type === 'type') {
      this.$watch('maneuverType', () => {
        this.populateTypeModifiers();
      });
      this.$watch('maneuverSubtype', () => {
        this.populateTypeModifiers();
      });
    } else if (this.type === 'skill') {
      this.$watch('skillId', () => {
        this.populateSkillHierModifiers();
      });
    }
  },
  methods: {
    categorizedModifiers(currCategoryId) {
      return this.modifiers.filter(function(modifier) {
        return modifier.category === currCategoryId;
      });
    },
    isModifierSelected(id, isSelected) {
      return isSelected || this.selectedModifiers.includes(id) ? 'modifier--selected' : '';
    },
    loadSelectedActionModifers(action) {
      const { id, skillId, characterSkillId, gameSkillId, maneuverSubtype, maneuverType, selectedModifiers } = action;
      this.actionId = id;

      for (const modifier of this.modifiers) {
        modifier.isSelected = false;
      }

      if (this.actionId) {
        if (this.type === 'skill') {
          this.skillId = skillId;
          this.gameSkillId = gameSkillId;
          this.characterSkillId = characterSkillId;
        }
        if (this.type === 'type') {
          this.maneuverType = maneuverType;
          this.maneuverSubtype = maneuverSubtype;
        }
        this.selectedModifiers = selectedModifiers;
      } else {
        if (this.type === 'skill') {
          this.skillId = undefined;
          this.gameSkillId = undefined;
          this.characterSkillId = undefined;
        }
        if (this.type === 'type') {
          this.maneuverType = undefined;
          this.maneuverSubtype = undefined;
        }
        this.selectedModifiers = [];
      }
    },
    populateTypeModifiers() {
      if (!this.maneuverType) {
        this.isContentReady = true;
        return [];
      }

      const data = {
        maneuver_type: this.maneuverType,
        maneuver_subtype: this.maneuverSubtype
      };

      this.$store
        .dispatch(MANEUVER_TYPE_MODIFIERS_REQUEST, data)
        .then(data => {
          for (const modifier of data) {
            modifier.isSelected = false;
          }

          this.modifiers = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateSkillHierModifiers() {
      if (!this.characterSkillId && !this.gameSkillId && !this.skillId) {
        this.isContentReady = true;
        return [];
      }

      const data = {
        characterSkillId: this.characterSkillId,
        gameSkillId: this.gameSkillId,
        skillId: this.skillId
      };

      this.$store
        .dispatch(MANEUVER_SKILL_HIER_MODIFIERS_REQUEST, data)
        .then(data => {
          for (const modifier of data) {
            modifier.isSelected = false;
          }

          this.modifiers = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    selectedActionResetModifers() {
      for (const modifier of this.modifiers) {
        modifier.isSelected = false;
      }

      this.selectedModifiers = [];
    },
    setManeuverModifier(modifier) {
      if (modifier.category) {
        if (!modifier.isSelected) {
          for (const m of this.modifiers) {
            if (m.category === modifier.category) {
              m.isSelected = false;
            }
          }

          modifier.isSelected = true;
        } else {
          modifier.isSelected = false;
        }
      } else {
        modifier.isSelected = !modifier.isSelected;
      }

      EventBus.$emit('set-maneuver-modifier', {
        id: modifier.id,
        category: modifier.category,
        value: modifier.isSelected ? modifier.value : false
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.table-modifier {
  border-bottom: 1px solid $table-modifier-border-color;
  max-width: 100%;

  tr {
    &.modifier {
      cursor: pointer;

      &:hover {
        background-color: $table-modifier-hover-background-color;
      }

      &--selected {
        background-color: $table-modifier-active-background-color;

        &:hover {
          background-color: $table-modifier-active-background-color;
        }
      }
    }
  }

  td,
  th {
    &:first-of-type {
      border-left: 1px solid $table-modifier-border-color;
    }

    &:last-of-type {
      border-right: 1px solid $table-modifier-border-color;
    }

    padding-bottom: 0.3rem;
    padding-top: 0.3rem;
  }

  td {
    &.modifiers-type {
      background-color: $table-modifier-maneuver-type-background-color;
    }

    &.category-modifier-title {
      background-color: $table-modifier-category-background-color;
      font-size: 1.2rem;
      font-weight: bold;
      text-transform: uppercase;
    }

    &.modifier-none {
      font-style: italic;
    }
  }

  .modifiers-maneuver-type {
    border-bottom: 1px solid $table-modifier-border-color;
    border-top: 1px solid $table-modifier-border-color;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>
