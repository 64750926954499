<template>
  <main v-if="restrictedAccess_grantMaster" id="skills" class="body-view container-fluid skills">
    <div class="row">
      <div class="area_title col-12 center">
        <h1>
          {{ $t('skill.edit_skill') }}
        </h1>
      </div>
      <div class="page-action page-action--right">
        <button type="button" class="btn btn-primary" @click="goBack()">
          {{ $t('button.back') }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <SkillEdit :id="id" ref="skillEdit" v-on="$listeners" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>

<script>
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SYSTEM_REQUEST } from '@/store/actions/system';
import SkillEdit from '@/components/Skill/Edit';
import Message from '@/components/Message';

export default {
  name: 'SkillDetailEditView',
  components: {
    SkillEdit,
    Message
  },
  mixins: [restrictedAccessMixin],
  props: ['id'],
  data() {
    return {
      errorFields: []
    };
  },
  created() {
    this.$store
      .dispatch(SYSTEM_REQUEST)
      .then(() => {})
      .catch(err => {
        this.$store.commit(MESSAGE_ERROR_SET, err);
        this.$helpers.errorManager(err);
        this.$router.push('/system/list');
      });

    if (!this.id) {
      const err = 'no_id';
      this.$store.commit(MESSAGE_ERROR_SET, err);
      this.$helpers.errorManager(err);
      this.$router.push('/skill/list');
    }
  },
  methods: {
    goBack() {
      this.$router.push('/skill/list');
    }
  }
};
</script>
