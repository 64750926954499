<template>
  <CounterPoints :className="className" :currentPoints="currentPoints" :label="label" :totalPoints="totalPoints" />
</template>

<script>
import { mapState } from 'vuex';
import CounterPoints from '@/components/Counters/Points';

export default {
  name: 'CharacterCounterReputationPoints',
  data() {
    return {
      className: 'character-reputation-points',
      label: 'reputation_points'
    };
  },
  components: {
    CounterPoints
  },
  computed: {
    ...mapState(['character', 'scores']),
    currentPoints() {
      return this.scores.play.reputation_points;
    },
    totalPoints() {
      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.counter {
  @include round-counter(
    $character-reputation-points-counter-bg-color,
    $character-reputation-points-counter-border-color,
    $character-reputation-points-counter-font-color,
    $point-counter-size
  );
}
</style>
