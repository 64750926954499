<template>
  <div class="form-group-text-list" :class="required === 'required' ? 'required' : ''">
    <label class="form-group-text-list-label" for="id">{{ $t(label, labelParams) }}</label>

    <img
      v-if="true"
      class="icon icon-add icon-add-entry"
      :src="config.mediaPath.application.icons + config.assets.icons.add"
      :alt="$t(addEntryLabel)"
      @click="addEntry()"
    />

    <div v-for="(element, index) in list" :key="`${id}-${element.id}`" class="form-group-text-list-row">
      <div class="form-group-text-list-key">
        <input
          v-model="element[listKeyProperty]"
          type="text"
          class="form-control"
          :class="{
            error: errorFieldsInherited[id + '-' + listKeyProperty + '-' + element.id]
          }"
          :id="id + '-' + listKeyProperty + '-' + element.id"
          :name="id + '-' + listKeyProperty"
          :aria-describedby="id + '-' + listKeyProperty + '-' + element.id"
        />
      </div>
      <div class="form-group-text-list-value">
        <input
          v-model="element[listValueProperty]"
          type="text"
          class="form-control"
          :class="{
            error: errorFieldsInherited[id + '-' + listValueProperty + '-' + element.id]
          }"
          :id="id + '-' + listValueProperty + '-' + element.id"
          :name="id + '-' + listValueProperty"
          :aria-describedby="id + '-' + listValueProperty + '-' + element.id"
        />
      </div>
      <div class="form-group-text-list-remove">
        <img
          v-if="true"
          class="icon icon-remove icon-remove-entry"
          :src="config.mediaPath.application.icons + config.assets.icons.remove"
          :alt="$t(addEntryLabel)"
          @click="confirmRemoveEntry(index)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { EventBus } from '@/utils/eventBus';
import { config } from '@/setup/config';

export default {
  name: 'FormGroupTextList',
  props: {
    addEntryLabel: {
      type: String,
      default: null
    },
    ariaDescribedby: {
      type: String,
      default: null
    },
    disabled: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    errorFieldsInherited: {
      type: Array,
      default() {
        return [];
      }
    },
    label: {
      type: String,
      default: null
    },
    labelParams: {
      type: Object,
      default: null
    },
    list: {
      type: Array,
      default: null
    },
    listKeyProperty: {
      type: String,
      default: null
    },
    listValueProperty: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'form.type_new_entry'
    },
    required: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      config,
      newEntryIndex: 0
    };
  },
  created() {
    this.unwatchList = this.$watch('list', () => {
      this.addEntry();
      this.unwatchList();
    });

    EventBus.$on('modal-ok', () => {
      this.removeEntry(this.entryIndexToDelete);
      this.entryIndexToDelete = undefined;
    });
    EventBus.$on('modal-cancel', () => {
      this.entryIndexToDelete = undefined;
    });
  },
  beforeDestroy() {
    EventBus.$off('modal-ok');
    EventBus.$off('modal-cancel');
  },
  methods: {
    addEntry() {
      const newIndex = this.list.length * 1 + this.newEntryIndex * 1 + 1;
      const newEntry = {
        id: 'temp-' + this.newEntryIndex,
        description: '',
        modifier: '',
        isNew: true
      };

      this.list.splice(newIndex, 0, newEntry);
      this.newEntryIndex++;
    },
    confirmRemoveEntry(index) {
      this.entryIndexToDelete = index;
      this.$emit('modal', 'ok-cancel', {
        headerText: 'modal.questions.sure_to_delete_entry',
        bodyText: 'message.not_lost_until_save'
      });
    },
    removeEntry(index) {
      this.list.splice(index, 1);
      this.entryIndexToDelete = undefined;
    }
  }
};
</script>

<style lang="scss" scoped>
.form-group-text-list {
  &-row {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
  }

  &-key {
    flex: 1;
  }

  &-value {
    flex-basis: 50px;

    input {
      text-align: right;
    }
  }

  &-remove {
    display: flex;
    align-items: center;
  }

  .icon-add-entry,
  .icon-remove-entry {
    cursor: pointer;
  }

  .icon-add-entry {
    height: $skill-maneuver-modifier-icon-entry-add-size;
    margin-left: 5px;
    width: $skill-maneuver-modifier-icon-entry-add-size;
  }

  .icon-remove-entry {
    height: $skill-maneuver-modifier-icon-entry-remove-size;
    margin-left: 5px;
    width: $skill-maneuver-modifier-icon-entry-remove-size;
  }
}
</style>
