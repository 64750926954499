import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

import _class from './modules/class';
import action from './modules/action';
import auth from './modules/auth';
import campaign from './modules/campaign';
import character from './modules/character';
import feature from './modules/feature';
import game from './modules/game';
import instance from './modules/instance';
import master from './modules/master';
import materialSource from './modules/materialSource';
import message from './modules/message';
import modifier from './modules/modifier';
import party from './modules/party';
import play from './modules/play';
import preferences from './modules/preferences';
import race from './modules/race';
import realm from './modules/realm';
import role from './modules/role';
import scores from './modules/scores';
import setting from './modules/setting';
import skill from './modules/skill';
import stats from './modules/stats';
import system from './modules/system';
import trainingPackage from './modules/trainingPackage';
import user from './modules/user';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  state: {},
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    _class,
    action,
    auth,
    campaign,
    character,
    feature,
    game,
    instance,
    master,
    materialSource,
    modifier,
    message,
    party,
    play,
    preferences,
    race,
    realm,
    role,
    scores,
    setting,
    skill,
    stats,
    system,
    trainingPackage,
    user
  },
  strict: debug
});
