<template>
  <main v-if="restrictedAccess_grantMaster" id="games" class="body-view container games">
    <div class="row">
      <div class="col 12">
        <Message />
      </div>
    </div>
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <template v-if="games.length > 0">
        <template v-for="game in games">
          <div :key="game.id" class="game col-4">
            <div class="tile tile-selectable game-frame" @click="selectGame(game.id)">
              <ul class="game-text">
                <li>
                  <span class="game-text-label">{{ $t('game.name') }}</span
                  >{{ game.name }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.master') }}</span
                  >{{ game.master_name }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.starting_datetime') }}</span
                  >{{ game.starting_datetime }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.ending_datetime') }}</span
                  >{{ game.ending_datetime ? game.ending_datetime : $t('global.on_going') }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.current_datetime') }}</span
                  >{{ game.current_datetime }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.setting') }}</span
                  >{{ game.setting_name }}
                </li>
                <li>
                  <span class="game-text-label">{{ $t('game.system') }}</span
                  >{{ game.system_name }}
                </li>
              </ul>
              <div class="center">
                <button type="button" class="btn btn-primary btn-invite-users">
                  {{ $t('button.game.invite_users') }}
                </button>
              </div>
            </div>
          </div>
        </template>
      </template>

      <div class="game game-new col-4">
        <div class="tile tile-selectable tile-with-background game-frame" @click="createGame()">
          <div class="tile-image-frame game-image-frame ">
            <img class="game-image" border="0" :alt="$t('game.new_game')" :src="imageUrl" />
          </div>
          <div class="tile-selectable-text game-text">
            {{ $t('game.new_game') }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import formMixin from '@/mixins/form';
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { GAMES_REQUEST } from '@/store/actions/game';
import Message from '@/components/Message';
import styles from '@/styles/_variables.scss';
import { mapGetters } from 'vuex';

export default {
  name: 'GameListView',
  components: {
    Message
  },
  mixins: [formMixin, restrictedAccessMixin],
  data() {
    return {
      isContentReady: false,
      games: []
    };
  },
  computed: {
    ...mapGetters(['getGameStatus']),
    isLoading() {
      return this.getGameStatus('games') === 'loading';
    },
    imageUrl() {
      return styles['game-image-src'].replace(/"/g, '');
    }
  },
  mounted() {
    this.populateGames();
  },
  methods: {
    createGame() {
      this.$router.push('/game/create');
    },
    populateGames() {
      this.$store
        .dispatch(GAMES_REQUEST)
        .then(data => {
          this.games = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    selectGame(id) {
      if (!id) {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.games {
  max-width: 100%;

  .game {
    margin-bottom: 25px;

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .btn-invite-users {
      margin-top: 30px;
    }
  }
}
</style>
