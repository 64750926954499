import { AUTH_LOGOUT } from '../actions/auth';
import {
  CHARACTER_CREATION_ERROR,
  CHARACTER_CREATION_REQUEST,
  CHARACTER_CREATION_STEP_1_REQUEST,
  CHARACTER_CREATION_STEP_2_REQUEST,
  CHARACTER_CREATION_STEP_3_REQUEST,
  CHARACTER_CREATION_STEP_4_REQUEST,
  CHARACTER_CREATION_STEP_5_REQUEST,
  CHARACTER_CREATION_STEP_6_REQUEST,
  CHARACTER_CREATION_STEP_7_REQUEST,
  CHARACTER_CREATION_STEP_8_REQUEST,
  CHARACTER_CREATION_STEP_REQUEST,
  CHARACTER_CREATION_SUCCESS,
  CHARACTER_DELETE,
  CHARACTER_ERROR,
  CHARACTER_OPERATION_SUCCESS,
  CHARACTER_REFRESH,
  CHARACTER_REQUEST,
  CHARACTER_RESET,
  CHARACTER_REGENERATION,
  CHARACTER_SKILLS_ERROR,
  CHARACTER_SKILLS_RANK_UP,
  CHARACTER_SKILLS_SUCCESS,
  CHARACTER_STAT_PROPERTY_SET,
  CHARACTER_SUCCESS,
  CHARACTERS_ERROR,
  CHARACTERS_REQUEST,
  CHARACTERS_SUCCESS
} from '../actions/character';
import { INSTANCE_REGENERATION } from '../actions/instance';
import { PARTY_CHARACTER_SUCCESS } from '../actions/party';
import { ROLE_SET } from '../actions/role';
import { SCORES_PLAY_REGENERATE } from '../actions/scores';
import { SYSTEM_CHARACTER_REQUEST } from '../actions/system';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  creation_step: '',
  id: '',
  name: '',
  scores: [],
  stats: [],
  status: '',
  statusVuex: {}
};

const getters = {
  areStatsLoaded: state => state.stats && state.stats.length > 0,
  getCharacterStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  //isCharacter: rootState => rootState,
  isCharacter: (state, rootGetters, rootState) => rootState.role.type === 'character',
  isCharacterLoaded: state => !!state.id
};

const actions = {
  /*
  This has nothing to do with the actual character creation. 
  It retrieves the current creation step number.
  */
  [CHARACTER_CREATION_STEP_REQUEST]: () => {
    return new Promise(resolve => {
      resolve(state.creation_step);
    });
  },

  [CHARACTER_CREATION_STEP_1_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep1, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_2_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep2, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_3_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep3, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_4_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep4, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_5_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep5, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_6_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep6, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_7_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep7, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_CREATION_STEP_8_REQUEST]: ({ commit, rootState }, formData = null) => {
    return new Promise((resolve, reject) => {
      commit(CHARACTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCharacterStep8, formData)
        .then(resp => {
          commit(CHARACTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_DELETE]: ({ commit, rootState }, id) => {
    if (!id) {
      return false;
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_DELETE);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.deleteCharacter + '/' + id, params)
        .then(resp => {
          commit(CHARACTER_OPERATION_SUCCESS);
          // if id is the current character commit reset
          if (state.id === id) {
            commit(CHARACTER_RESET);
          }
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_ERROR);
          reject(err);
        });
    });
  },
  [CHARACTER_REFRESH]: ({ commit, dispatch, rootState }, data) => {
    let id, type;

    if (Vue.helpers.isPrimitive(data)) {
      id = data;
    } else {
      ({ id, type } = data);
    }

    if (!Number.isInteger(id)) {
      id = rootState.role.id;
    }

    if (!type) {
      type = rootState.role.type;
    }

    commit(CHARACTER_REFRESH);
    dispatch(ROLE_SET, { id, type }); // synchronous action

    return dispatch(SCORES_PLAY_REGENERATE).then(() => {
      return dispatch(CHARACTER_REQUEST, id);
    });
  },
  [CHARACTER_REGENERATION]: ({ commit, dispatch, rootState }, data) => {
    let { id, type } = data;

    if (!type) {
      type = rootState.role.type;
    }

    commit(CHARACTER_RESET);

    if (id) {
      dispatch(ROLE_SET, { id, type }); // synchronous action
    }

    return dispatch(SYSTEM_CHARACTER_REQUEST, id).then(() => {
      return dispatch(CHARACTER_REQUEST, id).then(() => {
        return dispatch(INSTANCE_REGENERATION, data);
      });
    });
  },
  [CHARACTER_REQUEST]: ({ commit, dispatch, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(CHARACTER_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacter + (id ? '/' + id : ''), params)
        .then(resp => {
          const data = resp.data,
            party = data.party;

          data.party = null;
          delete data.party;
          commit(CHARACTER_SUCCESS, data);
          commit(PARTY_CHARACTER_SUCCESS, party);
          resolve(data);
        })
        .catch(err => {
          commit(CHARACTER_RESET);
          commit(CHARACTER_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },

  [CHARACTER_SKILLS_RANK_UP]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CHARACTER_SKILLS_RANK_UP);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.rankupCharacterSkills, formData)
        .then(resp => {
          commit(CHARACTER_SKILLS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [CHARACTERS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(CHARACTERS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacterList, params)
        .then(resp => {
          commit(CHARACTERS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTERS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },
  [CHARACTER_CREATION_ERROR]: state => {
    Vue.set(state.statusVuex, 'characterCreation', 'error');
  },
  [CHARACTER_CREATION_REQUEST]: state => {
    Vue.set(state.statusVuex, 'characterCreation', 'loading');
  },
  [CHARACTER_CREATION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'characterCreation', 'success');
  },
  [CHARACTER_DELETE]: state => {
    Vue.set(state.statusVuex, 'character', 'loading');
  },
  [CHARACTER_ERROR]: state => {
    Vue.set(state.statusVuex, 'character', 'error');
  },
  [CHARACTER_OPERATION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'character', 'success');
  },
  [CHARACTER_REFRESH]: state => {
    Vue.helpers.resetState(state);
  },
  [CHARACTER_REQUEST]: state => {
    Vue.set(state.statusVuex, 'character', 'loading');
  },
  [CHARACTER_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [CHARACTER_REGENERATION]: state => {
    Vue.set(state.statusVuex, 'character', 'loading');
  },
  [CHARACTER_SKILLS_ERROR]: state => {
    Vue.set(state.statusVuex, 'skillsUpdate', 'error');
  },
  [CHARACTER_SKILLS_RANK_UP]: state => {
    Vue.set(state.statusVuex, 'skillsUpdate', 'loading');
  },
  [CHARACTER_SKILLS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'skillsUpdate', 'success');
  },
  [CHARACTER_STAT_PROPERTY_SET]: (state, resp) => {
    Vue.set(state.statusVuex, 'statPropertySet', 'success');
    const targetStat = state.stats.find(function(stat) {
      return stat.id === resp.id;
    });
    resp.data.forEach(function(statData) {
      targetStat[statData.key] = statData.value;
    });
  },
  [CHARACTER_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'character', 'success');
    state = Object.assign(state, resp);
  },

  [CHARACTERS_ERROR]: state => {
    Vue.set(state.statusVuex, 'characters', 'error');
  },
  [CHARACTERS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'characters', 'loading');
  },
  [CHARACTERS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'characters', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
