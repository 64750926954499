var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body-view profile container-fluid" }, [
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-12 points-counters" },
        [
          _c("CharacterCounterHitPoints"),
          _c("CharacterCounterExhaustionPoints"),
          _c("CharacterCounterPowerPoints"),
          _c("CharacterCounterFatePoints"),
          _c("CharacterCounterSanityPoints"),
          _c("CharacterCounterReputationPoints"),
          _c("CharacterCounterGracePoints")
        ],
        1
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c(
        "div",
        { staticClass: "col-5" },
        [
          _c("StatsBox", {
            ref: "statsBox",
            attrs: { mode: "play" },
            on: { "error-fields-set": _vm.form_onErrorFields }
          })
        ],
        1
      ),
      _c("div", { staticClass: "col-4" }, [
        _c("table", { staticClass: "profile-table" }, [
          _vm.isCharacterLoaded
            ? _c(
                "tbody",
                [
                  _c("tr", [
                    _c("td", { staticClass: "profile-table-label" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("profile.user_name")))
                      ])
                    ]),
                    _c("td", { staticClass: "profile-table-data" }, [
                      _vm._v(_vm._s(_vm.user.name))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "profile-table-label" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("profile.character_name")))
                      ])
                    ]),
                    _c("td", { staticClass: "profile-table-data" }, [
                      _vm._v(_vm._s(_vm.character.name))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "profile-table-label" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("profile.race")))
                      ])
                    ]),
                    _c("td", { staticClass: "profile-table-data" }, [
                      _vm._v(_vm._s(_vm.race))
                    ])
                  ]),
                  _vm.character.race && _vm.character.race.origin1_sex
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "profile.race_" +
                                    _vm.character.race.origin1_sex
                                )
                              )
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(_vm._s(_vm.raceOrigin(1)))
                        ])
                      ])
                    : _vm._e(),
                  _vm.character.race && _vm.character.race.origin2_sex
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "profile.race_" +
                                    _vm.character.race.origin2_sex
                                )
                              )
                            )
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(_vm._s(_vm.raceOrigin(2)))
                        ])
                      ])
                    : _vm._e(),
                  _vm.character.race && _vm.character.race.isCrossbreed
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("profile.predominant_race")))
                          ])
                        ]),
                        _vm.character.race && _vm.character.race.predominant
                          ? _c("td", { staticClass: "profile-table-data" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.predominantRace(
                                      _vm.character.race.predominant
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _c("td", { staticClass: "profile-table-data" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("profile.no_predominant_race")
                                  ) +
                                  " "
                              )
                            ])
                      ])
                    : _vm._e(),
                  _vm.character.metrics
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("profile.age")))
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.character.metrics.age) +
                              " (" +
                              _vm._s(
                                _vm.getBirthdate(
                                  _vm.character.metrics.birth_day,
                                  _vm.character.metrics.birth_month,
                                  _vm.character.metrics.birth_year
                                )
                              ) +
                              ") "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.character.metrics
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("profile.physique")))
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(
                            " " + _vm._s(_vm.character.metrics.physique) + " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.character.metrics
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("profile.height")))
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.character.metrics.height) +
                              " " +
                              _vm._s(_vm.character.metrics.height_unit) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _vm.character.metrics
                    ? _c("tr", [
                        _c("td", { staticClass: "profile-table-label" }, [
                          _c("span", { staticClass: "text" }, [
                            _vm._v(_vm._s(_vm.$t("profile.weight")))
                          ])
                        ]),
                        _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.character.metrics.weight) +
                              " " +
                              _vm._s(_vm.character.metrics.weight_unit) +
                              " "
                          )
                        ])
                      ])
                    : _vm._e(),
                  _c("tr", [
                    _c("td", { staticClass: "profile-table-label" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("profile.class")))
                      ])
                    ]),
                    _c("td", { staticClass: "profile-table-data" }, [
                      _vm._v(_vm._s(_vm.character.class.name))
                    ])
                  ]),
                  _c("tr", [
                    _c("td", { staticClass: "profile-table-label" }, [
                      _c("span", { staticClass: "text" }, [
                        _vm._v(_vm._s(_vm.$t("profile.realm(s)")))
                      ])
                    ]),
                    _vm.character.realms
                      ? _c("td", { staticClass: "profile-table-data" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.statsText(_vm.character.realms.components)
                              ) +
                              " (" +
                              _vm._s(
                                _vm.statsText(
                                  _vm.character.realms.components_magic
                                )
                              ) +
                              ") "
                          )
                        ])
                      : _vm._e()
                  ]),
                  _vm._l(_vm.factorCategories, function(category, catIndex) {
                    return _c("tr", { key: catIndex }, [
                      _c("td", { staticClass: "profile-table-label" }, [
                        _c("span", { staticClass: "text" }, [
                          _vm._v(_vm._s(category.name))
                        ])
                      ]),
                      _c("td", { staticClass: "profile-table-data" }, [
                        _c(
                          "ul",
                          { staticClass: "feature" },
                          _vm._l(_vm.groupedFactors(category), function(
                            factor,
                            factorIndex
                          ) {
                            return _c(
                              "li",
                              {
                                key: "stat-" + catIndex + "-" + factorIndex,
                                class: {
                                  advantage: category.isAdvantage,
                                  disadvantage: !category.isAdvantage
                                }
                              },
                              [_vm._v(" " + _vm._s(factor.name) + " ")]
                            )
                          }),
                          0
                        )
                      ])
                    ])
                  })
                ],
                2
              )
            : _vm._e()
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }