<template>
  <main v-if="restrictedAccess_grantMaster" id="campaigns" class="body-view container campaigns">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <template v-if="campaigns.length > 0">
        <template v-for="campaign in campaigns">
          <div :key="campaign.id" class="campaign col-4">
            <div class="tile tile-selectable campaign-frame" @click="selectCampaign(campaign.id)">
              <div class="campaign-image-frame">
                <img
                  class="campaign-image image-fill-frame"
                  :class="campaign.cover ? '' : 'fade-image'"
                  border="0"
                  :alt="campaign.name"
                  :src="campaign.cover ? coverImageUrl + campaign.id + '/' + campaign.cover : missingCampaignCoverImage"
                />
              </div>
              <ul class="campaign-text">
                <li>
                  <span class="campaign-text-label">{{ $t('campaign.name') }}</span
                  >{{ campaign.name }}
                </li>
                <li>
                  <span class="campaign-text-label">{{ $t('campaign.owner') }}</span
                  >{{ campaign.owner_name ? campaign.owner_name : $t('global.public') }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>

      <div class="campaign campaign-new col-4">
        <div class="tile tile-selectable tile-with-background campaign-frame" @click="createCampaign()">
          <div class="tile-image-frame campaign-image-frame">
            <img
              class="campaign-image image-fill-frame"
              border="0"
              :alt="$t('campaign.new_campaign_game')"
              :src="newCampaignImageUrl"
            />
          </div>
          <div class="tile-selectable-text campaign-text">
            {{ $t('campaign.new_campaign_game') }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { CAMPAIGN_TEMPLATES_REQUEST } from '@/store/actions/campaign';
import styles from '@/styles/_variables.scss';
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: 'CampaignTemplateListView',
  mixins: [restrictedAccessMixin],
  data() {
    return {
      isContentReady: false,
      campaigns: []
    };
  },
  computed: {
    ...mapGetters(['getCampaignStatus']),
    coverImageUrl() {
      return config.mediaPath.campaign + '/';
    },
    isLoading() {
      return this.getCampaignStatus('campaigns') === 'loading';
    },
    missingCampaignCoverImage() {
      return config.mediaPath.application.placeholders + 'missing_campaign_picture.png';
    },
    newCampaignImageUrl() {
      return styles['campaign-game-image-src'].replace(/"/g, '');
    }
  },
  created() {
    this.populateCampaigns().then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    createCampaign() {
      this.$router.push('/campaign/template/create');
    },
    populateCampaigns() {
      return this.$store
        .dispatch(CAMPAIGN_TEMPLATES_REQUEST)
        .then(data => {
          this.campaigns = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    selectCampaign(id) {
      if (!id) {
        return false;
      }

      console.log('select campaign: ', id);
    }
  }
};
</script>

<style lang="scss" scoped>
.campaigns {
  max-width: 100%;

  .campaign {
    margin-bottom: 25px;

    &-frame {
      border-color: $campaign-game-border-color;

      &:hover {
        border-color: $campaign-game-border-color-hover;
      }
    }

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .campaign-new {
    .campaign {
      &-frame {
        border-color: $campaign-game-new-border-color;

        &:hover {
          border-color: $campaign-game-new-border-color-hover;
        }
      }
    }
  }
}
</style>
