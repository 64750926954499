<template>
  <main class="body-view container-fluid center">
    <div class="row">
      <div class="area_title col-12">
        <h1>
          {{ $t(`role.character.create_character_dataset` + step) }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row main-content">
      <div class="dataset col-12 col-xxl-9">
        <Dataset ref="dataset" />
      </div>
      <div id="summary" class="summary col-12 col-xxl-3 sticky">
        <div id="sticky-counters" class="sticky sticky-counters">
          <CharacterCounterCategoryRanks />
          <CharacterCounterWhiteLabelRanks />
          <CharacterCounterFreeRanks />
          <CharacterCounterHobbyRanks />
          <CharacterCounterDevelopmentPoints />
        </div>
        <CharacterSummary />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
@include sticky-counters-on-full-width-page();
</style>

<script>
import characterCreationStepCheckMixin from '@/mixins/characterCreationStepCheck';
import { SCORES_CONSUMED_RESET } from '@/store/actions/scores';
import CharacterCounterCategoryRanks from '@/components/Character/Counters/CategoryRanks';
import CharacterCounterDevelopmentPoints from '@/components/Character/Counters/DevelopmentPoints';
import CharacterCounterFreeRanks from '@/components/Character/Counters/FreeRanks';
import CharacterCounterHobbyRanks from '@/components/Character/Counters/HobbyRanks';
import CharacterCounterWhiteLabelRanks from '@/components/Character/Counters/WhiteLabelRanks';
import Dataset from '@/components/Character/Dataset7';
import CharacterSummary from '@/components/Character/Summary';
import Message from '@/components/Message';
import { EventBus } from '@/utils/eventBus';

const step = '7';

export default {
  name: 'CharacterCreateStep' + step + 'View',
  components: {
    Message,
    Dataset,
    CharacterSummary,
    CharacterCounterFreeRanks,
    CharacterCounterHobbyRanks,
    CharacterCounterCategoryRanks,
    CharacterCounterWhiteLabelRanks,
    CharacterCounterDevelopmentPoints
  },
  mixins: [characterCreationStepCheckMixin],
  data() {
    return {
      step: step,
      nextRoute: '',
      leavePageConfirmed: false
    };
  },
  computed: {
    somethingHasChanged() {
      return (
        (this.$store.state.scores.consumed.category_ranks && this.$store.state.scores.consumed.category_ranks > 0) ||
        (this.$store.state.scores.consumed.free_ranks && this.$store.state.scores.consumed.free_ranks > 0) ||
        (this.$store.state.scores.consumed.hobby_ranks && this.$store.state.scores.consumed.hobby_ranks > 0) ||
        (this.$store.state.scores.consumed.white_label_ranks &&
          this.$store.state.scores.consumed.white_label_ranks > 0) ||
        (this.$store.state.scores.consumed.development_points &&
          this.$store.state.scores.consumed.development_points > 0)
      );
    }
  },
  created() {
    EventBus.$on('modal-leave-confirm', () => {
      this.leavePageConfirmed = true;
      this.$router.push(this.nextRoute);
    });
    EventBus.$on('modal-leave-stay', () => {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.somethingHasChanged && !this.leavePageConfirmed) {
      this.$emit('modal', 'stay-leave', {
        headerText: 'modal.questions.sure_to_leave',
        bodyText: 'message.unsaved_changes'
      });
      this.nextRoute = to.path;
    } else {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
      EventBus.$off('modal-leave-confirm');
      EventBus.$off('modal-leave-stay');
      this.$store.commit(SCORES_CONSUMED_RESET);
      next();
    }
  }
};
</script>
