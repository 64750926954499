import { AUTH_LOGOUT } from '../actions/auth';
import {
  FEATURE_ENTITY_STAT_ERROR,
  FEATURE_ENTITY_STAT_REQUEST,
  FEATURE_ENTITY_STAT_SUCCESS,
  FEATURE_RESET
} from '../actions/feature';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getFeatureStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [FEATURE_ENTITY_STAT_REQUEST]: ({ commit, rootState }, ids) => {
    return new Promise((resolve, reject) => {
      if (!ids) {
        commit(FEATURE_ENTITY_STAT_ERROR, 'no_id');
        reject('no_id');
        return false;
      }

      commit(FEATURE_ENTITY_STAT_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('entityFeatureIds', JSON.stringify(ids));
      return axios
        .post(config.sitePath.api.getFeatureEntityStatList, params)
        .then(resp => {
          commit(FEATURE_ENTITY_STAT_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(FEATURE_ENTITY_STAT_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [FEATURE_ENTITY_STAT_REQUEST]: state => {
    Vue.set(state.statusVuex, 'entityFeatureStat', 'loading');
  },
  [FEATURE_ENTITY_STAT_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'entityFeatureStat', 'success');
  },
  [FEATURE_ENTITY_STAT_ERROR]: state => {
    Vue.set(state.statusVuex, 'entityFeatureStat', 'error');
  },

  [FEATURE_RESET]: state => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
