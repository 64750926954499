<template>
  <div class="form-group-select" :class="required === 'required' ? 'required' : ''">
    <label class="form-group-select-label" for="id">{{ $t(label, labelParams) }}</label>
    <select
      v-model="selectedValue"
      :required="required"
      :disabled="disabled"
      :id="id"
      :data-select="JSON.stringify(data)"
      :name="name"
      :aria-describedby="ariaDescribedby"
      class="form-group-select form-control"
      @change="onChange"
    >
      <option v-if="!required || !selectedValue" value="">
        {{ $t(!placeholder && placeholder !== '' ? 'form.select_placeholder' : placeholder) }}
      </option>
      <template v-for="option in options">
        <optgroup
          v-if="optionGroup && option[optionGroup]"
          :key="`select-${id}-optgroup-${option[optionValue]}`"
          :label="option[optionLabel]"
        ></optgroup>
        <option v-else :key="`select-${id}-option-${option[optionValue]}`" :value="option[optionValue]">{{
          option[optionLabel]
        }}</option>
      </template>
      >
    </select>
  </div>
</template>

<style lang="scss" scoped>
optgroup.nesting-2,
option.nesting-2 {
  padding-left: 30px;
}
</style>

<script>
export default {
  name: 'FormGroupSelect',
  props: {
    ariaDescribedby: {
      type: String,
      default: null
    },
    collectionName: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: null
    },
    disabled: {
      type: [Boolean, String],
      default: null
    },
    id: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelParams: {
      type: Object,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    nesting: {
      type: Boolean,
      default: false
    },
    onChangeCallback: {
      type: Function,
      default: null
    },
    options: {
      type: Array,
      default() {
        return [];
      }
    },
    optionGroup: {
      type: String,
      default: null
    },
    optionLabel: {
      type: String,
      default: null
    },
    optionValue: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'form.select_placeholder'
    },
    required: {
      type: [Boolean, String],
      default: null
    },
    selectValue: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      selectedValue: this.selectValue
    };
  },
  watch: {
    selectValue(val) {
      this.selectedValue = val;
    }
  },
  methods: {
    onChange($event) {
      let parent;

      if (this.collectionName) {
        parent = this.$parent[this.collectionName][this.data.index];
      } else {
        parent = this.$parent[this.name];
      }

      parent.selectedValue = this.selectedValue.toString();

      if (typeof this.onChangeCallback === 'function') {
        this.onChangeCallback(this.selectedValue, $event);
      }
    }
  }
};
</script>
