<template>
  <div class="character-dataset7">
    <form class="character-form left" @submit.prevent="onSubmit">
      <SkillList
        v-on="$listeners"
        mode="creation"
        ref="skillList"
        :errorFieldsInherited="errorFields"
        @error-fields-set="form_onErrorFields"
      />

      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_7_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SCORES_CONSUMED_RESET } from '@/store/actions/scores';
import SkillList from '@/components/Skill/List';
import { mapState } from 'vuex';

export default {
  name: 'Characterdataset7',
  components: {
    SkillList
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      errorFields: [],
      formValidationMessage: ''
    };
  },
  computed: {
    ...mapState(['character', 'scores'])
  },
  methods: {
    // EVENT HANDLERS
    onSubmit() {
      if (!this.checkRanksAllocation() || !this.checkDevelopmentPointsAllocation()) {
        this.$store.commit(MESSAGE_ERROR_SET, this.formValidationMessage);
        this.form_scrollToMessage();
        this.formValidationMessage = '';
      } else {
        const skillsData = [];

        for (let s = 0; s < this.$refs.skillList.skills.length; s++) {
          const skillObj = {
            base_modifier: this.$refs.skillList.skills[s].base_modifier,
            base_rank_available: this.$refs.skillList.skills[s].base_rank_available,
            base_rank: this.$refs.skillList.skills[s].base_rank,
            development_effort: this.$refs.skillList.skills[s].development_effort,
            development_points: this.$refs.skillList.skills[s].development_points,
            development_points_allocated: this.$refs.skillList.skills[s].development_points_allocated,
            id: this.$refs.skillList.skills[s].id,
            isSkill: this.$refs.skillList.skills[s].isSkill,
            no_rank_penalty: this.$refs.skillList.skills[s].no_rank_penalty,
            parent: this.$refs.skillList.skills[s].parent,
            rank: this.$refs.skillList.skills[s].rank * 1 + this.$refs.skillList.skills[s].base_rank * 1,
            rank_progression: this.$refs.skillList.skills[s].rank_progression,
            stat1: this.$refs.skillList.skills[s].stat1,
            stat2: this.$refs.skillList.skills[s].stat2,
            stat3: this.$refs.skillList.skills[s].stat3,
            tag: this.$refs.skillList.skills[s].tag,
            template: this.$refs.skillList.skills[s].template,
            type: this.$refs.skillList.skills[s].type
          };
          skillsData.push(skillObj);
        }

        const formData = new FormData();
        formData.append('skills', JSON.stringify(skillsData));
        formData.append('rankUpRanks', JSON.stringify(this.$refs.skillList.rankUpRanks));
        this.form_resetResponse();
        this.prh_dispatch(
          CHARACTER_CREATION_STEP_7_REQUEST,
          CHARACTER_REFRESH,
          formData,
          '/character/create/step8',
          this.$store.commit.bind(null, SCORES_CONSUMED_RESET)
        );
      }
    },

    // FUNCTIONS
    checkRanksAllocation() {
      if (
        (this.character.scores.consumable.free_ranks != null && !this.scores.consumed.free_ranks) ||
        this.scores.consumed.free_ranks < this.character.scores.consumable.free_ranks ||
        (this.character.scores.consumable.category_ranks != null && !this.scores.consumed.category_ranks) ||
        this.scores.consumed.category_ranks < this.character.scores.consumable.category_ranks ||
        (this.character.scores.consumable.hobby_ranks != null && !this.scores.consumed.hobby_ranks) ||
        this.scores.consumed.hobby_ranks < this.character.scores.consumable.hobby_ranks ||
        (this.character.scores.consumable.white_label_ranks != null && !this.scores.consumed.white_label_ranks) ||
        this.scores.consumed.white_label_ranks < this.character.scores.consumable.white_label_ranks
      ) {
        this.formValidationMessage = 'too_few_points_allocated';
        console.log('this.scores.consumed.free_ranks', this.scores.consumed.free_ranks);
        console.log('this.character.scores.consumable.free_ranks', this.character.scores.consumable.free_ranks);
        console.log('this.scores.consumed.category_ranks', this.scores.consumed.category_ranks);
        console.log('this.character.scores.consumable.category_ranks', this.character.scores.consumable.category_ranks);
        console.log('this.scores.consumed.hobby_ranks', this.scores.consumed.hobby_ranks);
        console.log('this.scores.consumed.white_label_ranks', this.scores.consumed.white_label_ranks);
        const modifiedSkills = this.$refs.skillList.skills.filter(
          skill =>
            this.$helpers.sanitizeInteger(skill.rank) !== 0 ||
            this.$helpers.sanitizeInteger(skill.rank_allocated) !== 0 ||
            this.$helpers.sanitizeInteger(skill.development_points) !== 0 ||
            this.$helpers.sanitizeInteger(skill.experience_points) !== 0
        );
        console.log(modifiedSkills);
        return false;
      }

      if (
        (this.scores.consumed.free_ranks &&
          this.scores.consumed.free_ranks > this.character.scores.consumable.free_ranks) ||
        (this.scores.consumed.category_ranks &&
          this.scores.consumed.category_ranks > this.character.scores.consumable.category_ranks) ||
        (this.scores.consumed.hobby_ranks &&
          this.scores.consumed.hobby_ranks > this.character.scores.consumable.hobby_ranks) ||
        (this.scores.consumed.white_label_ranks &&
          this.scores.consumed.white_label_ranks > this.character.scores.consumable.white_label_ranks)
      ) {
        this.formValidationMessage = 'too_many_points_allocated';
        console.log('this.scores.consumed.free_ranks', this.scores.consumed.free_ranks);
        console.log('this.character.scores.consumable.free_ranks', this.character.scores.consumable.free_ranks);
        console.log('this.scores.consumed.category_ranks', this.scores.consumed.category_ranks);
        console.log('this.character.scores.consumable.category_ranks', this.character.scores.consumable.category_ranks);
        console.log('this.scores.consumed.hobby_ranks', this.scores.consumed.hobby_ranks);
        console.log('this.scores.consumed.white_label_ranks', this.scores.consumed.white_label_ranks);
        return false;
      }

      return true;
    },
    checkDevelopmentPointsAllocation() {
      if (
        !this.scores.consumed.development_points ||
        this.scores.consumed.development_points < this.character.scores.consumable.development_points
      ) {
        this.formValidationMessage = 'too_few_points_allocated';
        console.log('this.scores.consumed.development_points', this.scores.consumed.development_points);
        console.log(
          'this.character.scores.consumable.development_points',
          this.character.scores.consumable.development_points
        );
        return false;
      }

      if (
        this.scores.consumed.development_points &&
        this.scores.consumed.development_points > this.character.scores.consumable.development_points
      ) {
        this.formValidationMessage = 'too_many_points_allocated';
        console.log('this.scores.consumed.development_points', this.scores.consumed.development_points);
        console.log(
          'this.character.scores.consumable.development_points',
          this.character.scores.consumable.development_points
        );
        return false;
      }
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
  /deep/ .skill-table.table-fixed thead th {
    top: 108px;

    @media (min-width: $xxl-min-break-point) {
      top: 10px;
    }
  }
}
</style>
