<template>
  <div class="container roles" id="roles" :class="`roles--${type}`">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <!-- ROLE LIST -->
      <template v-if="this.isContentReady && isRoleType">
        <template v-if="roles.length > 0">
          <template v-for="role in roles">
            <div class="role col-4" :key="role.id">
              <Role
                :ref="role.type + '_' + role.id"
                :role="role"
                @delete-role="confirmDeleteRole"
                @click.native="
                  $refs[role.type + '_' + role.id][0].selectRole(role.id, role.type, role.status, role.creation_step)
                "
              />
            </div>
          </template>
        </template>

        <div v-if="canCreateRole" class="role role-new col-4">
          <RoleActionSelector
            :ref="type + 'Ref'"
            :title="'role.' + type + '.new_' + type"
            :alt="'role.' + type + '.new_' + type"
            :type="type"
            @click.native="$refs[type + 'Ref'].createRole()"
          />
        </div>
      </template>

      <template v-if="this.isContentReady && !isRoleType && getRoleListKeys.length > 0">
        <div v-for="role in getRoleListKeys" :key="role" class="role col-4">
          <RoleActionSelector
            :ref="role + 'Ref'"
            :title="'role.' + role + '.name'"
            :alt="'role.' + role + '.name'"
            :type="role"
            @click.native="$refs[role + 'Ref'][0].selectRoleType(role)"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { GAMES_USER_CHARACTER_REQUEST } from '@/store/actions/game';
import { MASTERS_REQUEST } from '@/store/actions/master';
import { ROLES_REQUEST } from '@/store/actions/role';
import Role from '@/components/Role';
import RoleActionSelector from '@/components/Role/ActionSelector';
import { mapGetters } from 'vuex';
import { EventBus } from '@/utils/eventBus';

export default {
  name: 'RoleList',
  components: {
    Role,
    RoleActionSelector
  },
  props: {
    type: {
      type: String,
      default: '',
      isRequired: true
    }
  },
  data() {
    return {
      canCreateRole: false,
      isContentReady: false,
      roles: []
    };
  },
  computed: {
    ...mapGetters(['getRoleListKeys']),
    isRoleType() {
      return this.getRoleListKeys.includes(this.type);
    }
  },
  created() {
    if (this.getRoleListKeys.length === 0) {
      this.populateRoleTypes();
    } else if (!this.type) {
      this.isContentReady = true;
    }

    if (this.type) {
      EventBus.$on('modal-ok', () => {
        this.deleteRole(this.roleToDelete);
        this.roleToDelete = undefined;
      });
      EventBus.$on('modal-cancel', () => {
        this.roleToDelete = undefined;
      });

      this.populateRoles();
    }

    // chech if creation is allowed
    /* for a character: check if there at least one game where the user is not master
        for a master: check if the user has not already one master account 
        WHY: charcters are created after the game; master is created mefore the game (=> he/she creates the game) */
    if (this.type === 'character') {
      this.$store
        .dispatch(GAMES_USER_CHARACTER_REQUEST)
        .then(data => {
          this.canCreateRole = data.length === 0 ? false : true;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
          return false;
        });
    } else if (this.type === 'master') {
      this.$store
        .dispatch(MASTERS_REQUEST)
        .then(data => {
          this.canCreateRole = data.length === 0 ? true : false;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  },
  beforeDestroy() {
    EventBus.$off('modal-ok');
    EventBus.$off('modal-cancel');
  },
  methods: {
    confirmDeleteRole(id) {
      this.roleToDelete = id;
      EventBus.$emit('modal', 'ok-cancel', {
        headerText: `modal.questions.sure_to_delete_${this.type}`,
        bodyText: 'message.all_data_will_be_lost'
      });
    },
    deleteRole(id) {
      if (!id) {
        return false;
      }

      this.$store
        .dispatch(this.type.toUpperCase() + '_DELETE', id)
        .then(() => {
          this.roleToDelete = undefined;
          if (this.$router.currentRoute.name === this.type.capitalize() + 'ListView') {
            this.populateRoles();
          } else {
            this.$router.push(`/${this.type}/list`);
          }
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateRoles() {
      this.isContentReady = false;
      this.$store
        .dispatch(this.type.toUpperCase() + 'S_REQUEST')
        .then(data => {
          const self = this;
          this.roles = data;
          this.roles.map(function(role) {
            role.type = self.type.toLowerCase();
            return role;
          });
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateRoleTypes() {
      this.$store
        .dispatch(ROLES_REQUEST)
        .then(() => {
          this.$nextTick(() => {
            if (!this.type) {
              this.isContentReady = true;
            }
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.roles {
  max-width: 100%;

  .role {
    margin-bottom: 25px;
  }
}
</style>
