import { MANEUVER_SKILL_HIER_MODIFIERS_REQUEST, MANEUVER_TYPE_MODIFIERS_REQUEST } from '../actions/modifier';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getModifierStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [MANEUVER_SKILL_HIER_MODIFIERS_REQUEST]: ({ rootState }, data = {}) => {
    let { characterSkillId, gameSkillId, skillId } = data;
    if (!characterSkillId && !gameSkillId && !skillId) {
      return false;
    }

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('skillId', skillId);
      params.append('gameSkillId', gameSkillId);
      params.append('characterSkillId', characterSkillId);

      axios
        .post(config.sitePath.api.getManeuverSkillHierModifierList, params)
        .then(resp => {
          resolve(resp.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  [MANEUVER_TYPE_MODIFIERS_REQUEST]: ({ rootState }, data = {}) => {
    let { maneuver_type, maneuver_subtype, skillId } = data;

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);

      if (maneuver_type) {
        params.append('maneuver_type', maneuver_type);
      }

      if (maneuver_subtype) {
        params.append('maneuver_subtype', maneuver_subtype);
      }

      if (skillId) {
        params.append('skillId', skillId);
      }

      axios
        .post(config.sitePath.api.getManeuverTypeModifierList, params)
        .then(resp => {
          resolve(resp.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
};

const mutations = {};

export default {
  state,
  getters,
  actions,
  mutations
};
