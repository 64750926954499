var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("AppHeader"),
      _vm.modal.show
        ? _c("Modal", {
            attrs: {
              bodyText: _vm.modal.bodyText,
              buttonCancel: _vm.modal.buttonCancel,
              buttonConfirm: _vm.modal.buttonConfirm,
              footerText: _vm.modal.footerText,
              headerText: _vm.modal.headerText,
              type: _vm.modal.type
            },
            on: {
              confirm: function($event) {
                _vm.modal.show = false
              },
              cancel: function($event) {
                _vm.modal.show = false
              },
              close: function($event) {
                _vm.modal.show = false
              }
            }
          })
        : _vm._e(),
      _vm.isAuthenticated ? _c("Navigation") : _vm._e(),
      _vm.isReadyForRender
        ? _c("RouterView", {
            key: _vm.$route.path,
            on: { modal: _vm.modal_handle }
          })
        : _vm._e(),
      _c("AppFooter")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }