export const PARTIES_ERROR = 'PARTIES_ERROR';
export const PARTIES_REQUEST = 'PARTIES_REQUEST';
export const PARTIES_SUCCESS = 'PARTIES_SUCCESS';

export const PARTY_CHARACTER_ERROR = 'PARTY_CHARACTER_ERROR';
export const PARTY_CHARACTER_REQUEST = 'PARTY_CHARACTER_REQUEST';
export const PARTY_CHARACTER_RESET = 'PARTY_CHARACTER_RESET';
export const PARTY_CHARACTER_SUCCESS = 'PARTY_CHARACTER_SUCCESS';
export const PARTY_MASTER_SUCCESS = 'PARTY_MASTER_SUCCESS';
export const PARTY_DELETE = 'PARTY_DELETE';
export const PARTY_ERROR = 'PARTY_ERROR';
export const PARTY_REQUEST = 'PARTY_REQUEST';
export const PARTY_SELECT = 'PARTY_SELECT';
export const PARTY_SUCCESS = 'PARTY_SUCCESS';
