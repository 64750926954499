<template>
  <div class="body-view container">
    <AnimationLoading v-if="isLoading" />
    <main class="center">
      <h1 class="welcome-message">{{ $t('login.welcome_message') }}</h1>
      <LoginBox />
    </main>
  </div>
</template>

<style lang="scss" scoped>
.welcome-message {
  margin: 30px auto;
  flex-direction: column;
}
</style>

<script>
import { mapGetters, mapState } from 'vuex';
import fakeFeed from '@/components/Home/fakeFeed';
import LoginBox from '@/components/Login/LoginBox';

export default {
  components: {
    LoginBox
  },
  data() {
    return {
      message: {
        code: '',
        errorList: [],
        operationResult: ''
      },
      errorFields: [],
      fakeFeed
    };
  },
  name: 'LoginView',
  computed: {
    ...mapGetters(['isAuthenticated', 'isUserLoaded', 'isRoleLoaded', 'getCharacterStatus', 'getUserStatus']),
    ...mapState(['auth']),
    isLoading() {
      return (
        (this.auth.status === 'loading' && !this.isAuthenticated) ||
        (this.getUserStatus('user') === 'loading' && !this.isUserLoaded) ||
        (this.getCharacterStatus('character') === 'loading' && !this.isRoleLoaded())
      );
    }
  }
};
</script>
