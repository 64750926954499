var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "stats-box", attrs: { id: "stats-box" } },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      !_vm.isGettingReady
        ? _c("table", { staticClass: "table table-stats" }, [
            _c("thead", [
              _c("tr", [
                _c("th", { staticClass: "left view-base" }, [
                  _vm._v(_vm._s(_vm.$t("stats.stat")))
                ]),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isExtendedView && _vm.isPlayMode,
                        expression: "isExtendedView && isPlayMode"
                      }
                    ],
                    staticClass: "center view-extended"
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("stats.code")) + " ")]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isExtendedView && _vm.isPlayMode,
                        expression: "isExtendedView && isPlayMode"
                      }
                    ],
                    staticClass: "center view-extended"
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("stats.race_modifier")) + " ")]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isExtendedView && _vm.isPlayMode,
                        expression: "isExtendedView && isPlayMode"
                      }
                    ],
                    staticClass: "center view-extended"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("stats.temporary_rolled_to_display")) +
                        " "
                    )
                  ]
                ),
                _c(
                  "th",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isExtendedView && _vm.isPlayMode,
                        expression: "isExtendedView && isPlayMode"
                      }
                    ],
                    staticClass: "center view-extended"
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("stats.potential_rolled_to_display")) +
                        " "
                    )
                  ]
                ),
                _c("th", { staticClass: "center view-base" }, [
                  _vm._v(" " + _vm._s(_vm.temporaryHeaderTitle) + " ")
                ]),
                _c("th", { staticClass: "center view-base" }, [
                  _vm._v(" " + _vm._s(_vm.potentialHeaderTitle) + " ")
                ]),
                _c("th", { staticClass: "center view-base" }, [
                  _vm._v(_vm._s(_vm.$t("stats.bonus")))
                ]),
                _vm.isRankUpMode
                  ? _c("th", { staticClass: "center view-rankup" }, [
                      _vm._v(" " + _vm._s(_vm.$t("stats.DE")) + " ")
                    ])
                  : _vm._e(),
                _vm.isRankUpMode
                  ? _c("th", { staticClass: "center view-rankup" }, [
                      _vm._v(" " + _vm._s(_vm.$t("stats.DPs_to_rank_up")) + " ")
                    ])
                  : _vm._e(),
                _vm.isRankUpMode
                  ? _c("th", { staticClass: "center view-rankup" }, [
                      _vm._v(" " + _vm._s(_vm.$t("stats.XPs_allocated")) + " ")
                    ])
                  : _vm._e()
              ])
            ]),
            _vm.isCharacterLoaded
              ? _c(
                  "tbody",
                  [
                    _vm._l(_vm.statCategories, function(category, catIndex) {
                      return [
                        _c(
                          "tr",
                          {
                            key: "category-" + catIndex,
                            staticClass: "stats-category"
                          },
                          [
                            _c("td", { attrs: { colspan: _vm.nVisibleCols } }, [
                              _vm._v(" " + _vm._s(category.name) + " ")
                            ])
                          ]
                        ),
                        _vm._l(_vm.groupedStats(category), function(
                          stat,
                          statIndex
                        ) {
                          return [
                            _vm.isRankUpableStat(stat)
                              ? _c(
                                  "tr",
                                  {
                                    key: "stat-" + catIndex + "-" + statIndex,
                                    class: {
                                      even: statIndex % 2 === 0,
                                      odd: statIndex % 2 !== 0
                                    }
                                  },
                                  [
                                    _c(
                                      "td",
                                      { staticClass: "left view-base" },
                                      [_vm._v(_vm._s(stat.stat))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isExtendedView &&
                                              _vm.isPlayMode,
                                            expression:
                                              "isExtendedView && isPlayMode"
                                          }
                                        ],
                                        staticClass: "center view-extended"
                                      },
                                      [_vm._v(" " + _vm._s(stat.code) + " ")]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isExtendedView &&
                                              _vm.isPlayMode,
                                            expression:
                                              "isExtendedView && isPlayMode"
                                          }
                                        ],
                                        staticClass:
                                          "race_modifier center view-extended"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatRaceModifier(
                                                stat.race_modifier
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isExtendedView &&
                                              _vm.isPlayMode,
                                            expression:
                                              "isExtendedView && isPlayMode"
                                          }
                                        ],
                                        staticClass:
                                          "temporary-rolled center view-extended"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(stat.temporary_rolled) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              _vm.isExtendedView &&
                                              _vm.isPlayMode,
                                            expression:
                                              "isExtendedView && isPlayMode"
                                          }
                                        ],
                                        staticClass:
                                          "potential_rolled center view-extended"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getPotential(
                                                stat,
                                                "potential_rolled"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "temporary center view-base"
                                      },
                                      [_vm._v(_vm._s(stat.temporary))]
                                    ),
                                    _c(
                                      "td",
                                      {
                                        staticClass:
                                          "potential center view-base"
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.getPotential(
                                                stat,
                                                "potential"
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _c(
                                      "td",
                                      { staticClass: "center view-base" },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$helpers.formatSignedNumber(
                                                stat.bonus
                                              )
                                            ) +
                                            " "
                                        )
                                      ]
                                    ),
                                    _vm.isRankUpMode
                                      ? _c(
                                          "td",
                                          { staticClass: "center view-rankup" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  stat.development_effort
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isRankUpMode
                                      ? _c(
                                          "td",
                                          { staticClass: "center view-rankup" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  stat.temporary * 1 <
                                                  stat.potential * 1
                                                    ? ""
                                                    : "disabled"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$helpers.formatNumber(
                                                      _vm.developmentPointsToRankUp(
                                                        stat.temporary,
                                                        stat.development_effort
                                                      )
                                                    )
                                                  ) + " "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.isRankUpMode
                                      ? _c(
                                          "td",
                                          { staticClass: "center view-rankup" },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "development_points_to_rank_up_wrapper"
                                              },
                                              [
                                                _c("img", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.canRankDown(stat) &&
                                                        _vm.isRankUpMode,
                                                      expression:
                                                        "canRankDown(stat) && isRankUpMode"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "icon icon-rank-down",
                                                  attrs: {
                                                    src:
                                                      _vm.config.mediaPath
                                                        .application.icons +
                                                      _vm.config.assets.icons
                                                        .rank_down,
                                                    alt: _vm.$t(
                                                      "stats.cancel_rankup"
                                                    )
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.rankDown(stat)
                                                    }
                                                  }
                                                }),
                                                _c("input", {
                                                  staticClass:
                                                    "form-control development_points",
                                                  class:
                                                    stat.temporary * 1 <
                                                    stat.potential * 1
                                                      ? ""
                                                      : "disabled",
                                                  attrs: {
                                                    type: "number",
                                                    step: "1",
                                                    min: "0",
                                                    id:
                                                      "development-points-" +
                                                      stat.id,
                                                    name: "development-points",
                                                    "aria-describedby":
                                                      "development-points-" +
                                                      stat.id
                                                  },
                                                  domProps: {
                                                    value: _vm.isPlayMode
                                                      ? stat.development_points_allocated
                                                      : stat.development_points
                                                  },
                                                  on: {
                                                    input: function($event) {
                                                      return _vm.updateDevelopmentPoints(
                                                        $event,
                                                        stat
                                                      )
                                                    }
                                                  }
                                                }),
                                                _c("img", {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        _vm.canRankUp(stat) &&
                                                        _vm.isRankUpMode,
                                                      expression:
                                                        "canRankUp(stat) && isRankUpMode"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "icon icon-rank-up",
                                                  attrs: {
                                                    src:
                                                      _vm.config.mediaPath
                                                        .application.icons +
                                                      _vm.config.assets.icons
                                                        .rank_up,
                                                    alt: _vm.$t("stats.rankup")
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.rankUp(stat)
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ]
                        })
                      ]
                    })
                  ],
                  2
                )
              : _vm._e()
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }