export const MASTER_CREATION_ERROR = 'MASTER_CREATION_ERROR';
export const MASTER_CREATION_REQUEST = 'MASTER_CREATION_REQUEST';
export const MASTER_CREATION_SUCCESS = 'MASTER_CREATION_SUCCESS';
export const MASTER_ERROR = 'MASTER_ERROR';
export const MASTER_REQUEST = 'MASTER_REQUEST';
export const MASTER_RESET = 'MASTER_RESET';
export const MASTER_REGENERATION = 'MASTER_REGENERATION';
export const MASTER_SUCCESS = 'MASTER_SUCCESS';

export const MASTERS_ERROR = 'MASTERS_ERROR';
export const MASTERS_REQUEST = 'MASTERS_REQUEST';
export const MASTERS_SUCCESS = 'MASTERS_SUCCESS';
