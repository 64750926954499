import { AUTH_LOGOUT } from '../actions/auth';
import {
  ROLE_SELECT_RESET,
  ROLE_SELECT,
  ROLE_SET,
  ROLE_SELECT_ERROR,
  ROLE_SELECT_REQUEST,
  ROLE_SELECT_SUCCESS,
  ROLES_ERROR,
  ROLES_REQUEST,
  ROLES_SUCCESS
} from '../actions/role';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { config } from '@/setup/config';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  id: '',
  list: [],
  type: '',
  statusVuex: {}
};

const getters = {
  getRoleListKeys: state => state.list.map(role => role.id),
  getRoleStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  getStoredRoleId: () => localStorage.getItem(config.storage.roleId) || undefined,
  getStoredRoleType: () => localStorage.getItem(config.storage.roleType) || undefined,
  isRoleLoaded: (state, getters) => () => {
    return state.type && getters['is' + state.type.capitalize() + 'Loaded'];
  }
};

const actions = {
  [ROLE_SELECT_RESET]: ({ commit, getters }) => {
    return new Promise(resolve => {
      commit(ROLE_SELECT_RESET); // this is for other stores

      for (let role of getters.getRoleListKeys) {
        commit(role.toUpperCase() + '_RESET');
      }

      resolve();
    });
  },
  [ROLE_SELECT]: async ({ commit, dispatch, rootState }, data) => {
    const { id, type } = data;

    if (!id) {
      return Promise.reject('no_id');
    }

    if (!type) {
      return Promise.reject('no_role_type');
    }

    const actionRoleType = type.toUpperCase();
    await dispatch(ROLE_SELECT_RESET);

    return new Promise((resolve, reject) => {
      commit(ROLE_SELECT_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('id', id);
      params.append('type', type);
      return axios
        .post(config.sitePath.api.updateRole, params)
        .then(resp => {
          let data = resp.data;
          data.rememberMe = rootState.auth.rememberMe;
          commit(ROLE_SELECT_SUCCESS, data);
          return dispatch(`${actionRoleType}_REGENERATION`, data);
        })
        .then(() => {
          resolve();
        })
        .catch(err => {
          commit(ROLE_SELECT_ERROR, err.response);
          reject(err);
        });
    });
  },
  [ROLE_SET]: ({ commit, rootState }, data) => {
    data.rememberMe = rootState.auth.rememberMe;
    commit(ROLE_SELECT_SUCCESS, data);
  },

  [ROLES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(ROLES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getRoleList, params)
        .then(resp => {
          commit(ROLES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(ROLES_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [ROLE_SELECT_ERROR]: state => {
    localStorage.removeItem(config.storage.roleId);
    localStorage.removeItem(config.storage.roleType);
    Vue.set(state.statusVuex, 'role', 'error');
  },
  [ROLE_SELECT_REQUEST]: state => {
    Vue.set(state.statusVuex, 'role', 'loading');
  },
  [ROLE_SELECT_RESET]: state => {
    localStorage.removeItem(config.storage.roleId);
    localStorage.removeItem(config.storage.roleType);
    Vue.helpers.resetState(state);
  },
  [ROLE_SELECT_SUCCESS]: (state, resp) => {
    const { id, type, rememberMe } = resp;
    state.id = id;
    state.type = type;

    if (rememberMe === true) {
      localStorage.setItem(config.storage.roleId, id);
      localStorage.setItem(config.storage.roleType, type);
    }

    Vue.set(state.statusVuex, 'role', 'success');
  },

  [ROLES_ERROR]: state => {
    Vue.set(state.statusVuex, 'role', 'error');
  },
  [ROLES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'role', 'loading');
  },
  [ROLES_SUCCESS]: (state, resp) => {
    state.list = resp;
    Vue.set(state.statusVuex, 'role', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
