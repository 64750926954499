<template>
  <div class="character-score-summary-frame">
    <ul class="character-score-summary-text" v-if="isCharacterLoaded">
      <li v-if="character.scores && character.scores.game">
        <span class="character-score-summary-text-label">{{ $t('character.game_scores') }}</span>
        <ul class="character-score-summary-text-list">
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.hit_points') }}</span
            >{{ character.scores.game.hit_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.power_points') }}</span
            >{{ character.scores.game.power_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.exhaustion_points') }}</span
            >{{ character.scores.game.exhaustion_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.sanity_points') }}</span
            >{{ character.scores.game.sanity_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.fate_points') }}</span
            >{{ character.scores.game.fate_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.reputation_points') }}</span
            >{{ scores.play.reputation_points }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.grace_points') }}</span
            >{{ scores.play.grace_points }}
          </li>
        </ul>
      </li>

      <li v-if="character.scores && character.scores.permanent_modifiers">
        <span class="character-score-summary-text-label">{{ $t('character.game_rates') }}</span>
        <ul class="character-score-summary-text-list">
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.learning_rate') }}</span
            >{{ character.scores.permanent_modifiers.learning_rate }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.soul_departure_rounds') }}</span
            >{{ character.scores.permanent_modifiers.soul_departure_rounds }}
          </li>
          <li class="bullet">
            <span class="character-score-summary-text-label">{{ $t('character.healing_rate') }}</span
            >{{ character.scores.permanent_modifiers.healing_rate }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterSummaryScores',
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'scores'])
  }
};
</script>

<style lang="scss" scoped>
.character-score-summary {
  &-frame {
    border: 1px solid $character-score-summary-border-color;
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 6px;
    text-align: left;

    &-label {
      font-size: 1rem;
      text-transform: uppercase;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &-list {
      list-style-type: none;
      margin: 0 0 0 6px;
      padding: 0 1.2rem;

      li.minus:before,
      li.plus:before,
      li.bullet:before {
        position: absolute;
      }

      li.minus:before,
      li.plus:before {
        left: 2.5rem;
      }

      li.bullet:before {
        left: 3rem;
        margin-top: 0.1rem;
      }

      li.minus:before {
        content: $disadvantage-list-symbol;
      }

      li.plus:before {
        content: $advantage-list-symbol;
      }

      li.bullet:before {
        content: '\2022';
      }
    }
  }
}
</style>
