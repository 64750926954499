<template>
  <header class="container-fluid header-menu" v-if="isAuthenticated">
    <ul class="header-menu-left col-3">
      <li v-if="isUserLoaded && date">{{ $t('global.date') }}: {{ date }}</li>
      <li v-if="isUserLoaded">
        <RouterLink to="/account">{{ $t('global.user') }}: {{ userName }}</RouterLink>
      </li>
      <template v-if="isRoleLoaded">
        <template v-if="isCharacterLoaded && isCharacter">
          <li>
            <RouterLink to="/account">{{ $t('global.character') }}: {{ characterName }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/account"
              >{{ $t('global.party') }}:
              <span class="campaign">{{ partyName }}</span>
            </RouterLink>
          </li>
        </template>
        <template v-else-if="isMasterLoaded && isMaster">
          <li>
            <RouterLink to="/account">{{ $t('global.master') }}: {{ masterName }}</RouterLink>
          </li>
        </template>
        <li v-if="isSettingLoaded">
          <RouterLink to="/account">{{ $t('global.world') }}: {{ settingName }}</RouterLink>
        </li>
        <li v-if="isGameLoaded">
          <RouterLink to="/account">{{ $t('global.game') }}: {{ gameName }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/account"
            >{{ $t('global.current_campaign') }}:
            <span class="campaign">{{ campaignName }}</span>
          </RouterLink>
        </li>
        <li>
          <RouterLink to="/account">{{ $t('global.system') }}: {{ systemName }}</RouterLink>
        </li>
      </template>
      <!--
      <li>
        <RouterLink to="/character/profile">/character/profile</RouterLink>
      </li>
      <li>
        <RouterLink to="/master/create">/master/create</RouterLink>
      </li>
      <li>
        <RouterLink to="/character/create">/character/create</RouterLink>
      </li>
      <li>
        <RouterLink to="/login">/login</RouterLink>
      </li>
      -->
    </ul>

    <ul class="header-menu-right is-authenticated col-9" v-if="isAuthenticated">
      <li>
        <transition name="expand">
          <Preferences v-if="arePreferencesLoaded" v-show="showPreferences" />
        </transition>
      </li>
      <li>
        <RouterLink to="/account">{{ $t('global.account') }}</RouterLink>
      </li>
      <li>
        <b-dropdown id="role-select-menu" class="header-submenu" type="light" variant="light" :text="$t('role.select')">
          <b-dropdown-item class="header-submenu-option">
            <RouterLink to="/character/list">Character</RouterLink>
          </b-dropdown-item>
          <b-dropdown-item class="header-submenu-option">
            <RouterLink to="/master/list">Master</RouterLink>
          </b-dropdown-item>
        </b-dropdown>
      </li>
      <li @click="logout">
        <span class="tab-link">{{ $t('global.logout') }}</span>
      </li>
      <li class="preferences" @click="togglePreferences">
        <img
          class="icon-config"
          :src="config.mediaPath.application.icons + config.assets.icons.config"
          :alt="$t('global.settings')"
        />
      </li>
    </ul>

    <ul class="header-menu-right is-not-authenticated col-10" v-if="!isAuthenticated">
      <li>
        <RouterLink to="/">{{ $t('global.login') }}</RouterLink>
      </li>
    </ul>

    <div id="counters" class="counters" style="display:none">
      <PartyCounterExperiencePoints />
      <CharacterCounterExperiencePoints />
    </div>
  </header>
</template>

<script>
import Preferences from '@/components/Preferences';
import PartyCounterExperiencePoints from '@/components/Party/Counters/ExperiencePoints';
import CharacterCounterExperiencePoints from '@/components/Character/Counters/ExperiencePoints';
import { AUTH_LOGOUT } from '@/store/actions/auth';
import { mapGetters, mapState } from 'vuex';
import { config } from '@/setup/config';

export default {
  name: 'Header',
  data() {
    return {
      config,
      showPreferences: false
    };
  },
  components: {
    PartyCounterExperiencePoints,
    Preferences,
    CharacterCounterExperiencePoints
  },
  methods: {
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
    togglePreferences() {
      this.showPreferences = !this.showPreferences;
    }
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
    ...mapState(['auth', 'character']),

    arePreferencesLoaded() {
      return this.$store.getters.arePreferencesLoaded;
    },
    isCharacter() {
      return this.$store.getters.isCharacter;
    },
    isCharacterLoaded() {
      return this.$store.getters.isCharacterLoaded;
    },
    isGameLoaded() {
      return this.$store.getters.isGameLoaded;
    },
    isMaster() {
      return this.$store.getters.isMaster;
    },
    isMasterLoaded() {
      return this.$store.getters.isMasterLoaded;
    },
    isRoleLoaded() {
      return this.$store.getters.isRoleLoaded();
    },
    isSettingLoaded() {
      return this.$store.getters.isSettingLoaded;
    },
    isUserLoaded() {
      return this.$store.getters.isUserLoaded;
    },

    date() {
      return this.$store.state.game.current_datetime == null || this.$store.state.game.current_datetime === ''
        ? ''
        : this.$helpers.getCustomFormattedDate(
            this.$store.state.game.current_datetime,
            this.$store.state.user.language
          );
    },
    campaignName() {
      return this.$store.state.campaign.name
        ? this.$store.state.campaign.name
        : this.$t('campaign.no_current_campaign');
    },
    characterName() {
      return this.$store.state.character.name ? this.$store.state.character.name : '';
    },
    gameName() {
      return this.$store.state.game.name ? this.$store.state.game.name : this.$t('game.no_current_game');
    },
    masterName() {
      return this.$store.state.master.name ? this.$store.state.master.name : '';
    },
    settingName() {
      return this.$store.state.setting.name ? this.$store.state.setting.name : this.$t('setting.no_current_setting');
    },
    partyName() {
      return this.$store.state.party.name ? this.$store.state.party.name : this.$t('party.no_current_party');
    },
    systemName() {
      return this.$store.state.system.name ? this.$store.state.system.name : this.$t('system.no_current_system');
    },
    userName() {
      return this.$store.state.user.name ? this.$store.state.user.name : '';
    }
  }
};
</script>

<style lang="scss">
.header-menu {
  font-size: $header-menu-font-size;

  &-left {
    float: left;
  }

  &-right {
    display: flex;
    float: right;
    justify-content: flex-end;
    list-style: none;

    > li {
      display: block;
      padding: 10px 20px;
    }
  }
}

.header-submenu {
  > ul {
    padding: 0;
  }

  &-option {
    padding: 0;
    font-size: $header-submenu-font-size;
  }

  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
}
</style>

<style lang="scss" scoped>
.container-fluid {
  display: inline-block;
}

.preferences {
  cursor: pointer;
}

.icon-config {
  width: 25px;
}

.expand-enter-active,
.expand-leave-active {
  transition: all 0.3s;
}
.expand-enter,
.expand-leave-to {
  visibility: hidden;
  height: 0;
  margin: 0;
  padding: 0;
  opacity: 0;
}
</style>
