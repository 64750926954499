var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "actions",
      class: _vm.mode === "compact" ? "actions--compact" : "",
      attrs: { id: _vm.entityNature + "List" }
    },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      this.isContentReady
        ? [
            _c(
              "table",
              { staticClass: "table table-action table-action-committed" },
              [
                _c("thead", [
                  _c("tr", [
                    _vm.mode === "compact"
                      ? _c(
                          "th",
                          { staticClass: "center", attrs: { scope: "col" } },
                          [_vm._v(_vm._s(_vm.$t("action.skill")))]
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [
                        _c("span", { staticClass: "th-initiative" }, [
                          _vm._v(_vm._s(_vm.$t("action.initiative")))
                        ]),
                        _c(
                          "span",
                          { staticClass: "small th-initiative-components" },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("action.bonus")) +
                                " / " +
                                _vm._s(_vm.$t("action.roll")) +
                                " / " +
                                _vm._s(_vm.$t("action.modifier"))
                            )
                          ]
                        )
                      ]
                    ),
                    _vm.mode !== "compact"
                      ? _c(
                          "th",
                          { staticClass: "center", attrs: { scope: "col" } },
                          [_vm._v(_vm._s(_vm.$t("action.character")))]
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("action.dice_rolls")))
                        ])
                      ]
                    ),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.modifier")))]
                    ),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.bonus")))]
                    ),
                    _vm.mode !== "compact"
                      ? _c(
                          "th",
                          { staticClass: "center", attrs: { scope: "col" } },
                          [_c("span", [_vm._v(_vm._s(_vm.$t("action.total")))])]
                        )
                      : _vm._e(),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.status")))]
                    ),
                    _c("th", { staticClass: "center", attrs: { scope: "col" } })
                  ])
                ]),
                _vm._l(_vm.committedActions, function(
                  committedAction,
                  committedActionIndex
                ) {
                  return [
                    _c(
                      "tbody",
                      {
                        key: "committed-action-r1-" + committedActionIndex,
                        staticClass: "committed-action",
                        class:
                          _vm.selectedScheduledActionId ===
                            committedAction.scheduled_action ||
                          committedAction.id === _vm.selectedCommittedActionId
                            ? "selected-committed-action"
                            : "",
                        on: {
                          click: function($event) {
                            return _vm.selectCommittedAction(committedAction)
                          }
                        }
                      },
                      [
                        _vm.mode !== "compact"
                          ? _c("tr", { staticClass: "initiative-components" }, [
                              _c(
                                "td",
                                {
                                  staticClass: "small",
                                  class:
                                    _vm.mode === "compact" ? "left" : "center"
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$helpers.formatSignedNumber(
                                          committedAction.initiative_bonus
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.formatDiceRoll(
                                          committedAction.initiative_roll
                                        )
                                      ) +
                                      " / " +
                                      _vm._s(
                                        _vm.getInitiativeModifier(
                                          committedAction
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              ),
                              _c("td", {
                                staticClass: "center",
                                attrs: { colspan: "7" }
                              })
                            ])
                          : _vm._e(),
                        _c(
                          "tr",
                          {
                            key: "committed-action-r2-" + committedActionIndex,
                            staticClass: "action-data",
                            class:
                              _vm.selectedScheduledActionId ===
                              committedAction.scheduled_action
                                ? "selected-scheduled-action"
                                : ""
                          },
                          [
                            _vm.mode === "compact"
                              ? _c(
                                  "td",
                                  { staticClass: "skill-name-compact left" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.getSkillName(committedAction)
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c("td", { staticClass: "initiative center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.calculateInitiativeTotal(
                                      committedAction
                                    )
                                  ) +
                                  " "
                              ),
                              _vm.mode === "compact"
                                ? _c(
                                    "span",
                                    { staticClass: "initiative-components" },
                                    [
                                      _vm._v(
                                        " ( = " +
                                          _vm._s(
                                            _vm.$helpers.formatSignedNumber(
                                              committedAction.initiative_bonus
                                            )
                                          ) +
                                          " / " +
                                          _vm._s(
                                            _vm.formatDiceRoll(
                                              committedAction.initiative_roll
                                            )
                                          ) +
                                          " / " +
                                          _vm._s(
                                            _vm.getInitiativeModifier(
                                              committedAction
                                            )
                                          ) +
                                          ") "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.mode !== "compact" &&
                              _vm.canDeleteCommittedAction(committedAction)
                                ? _c("img", {
                                    staticClass: "icon icon-remove-circle",
                                    attrs: {
                                      src:
                                        _vm.config.mediaPath.application.icons +
                                        _vm.config.assets.icons.remove_circle,
                                      alt: _vm.$t("action.delete_action")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmDeleteCommittedAction(
                                          committedAction.id,
                                          committedAction.character_id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _vm.mode !== "compact"
                              ? _c("td", { staticClass: "center" }, [
                                  _vm._v(_vm._s(committedAction.character_name))
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "center" }, [
                              _vm.showDiceRoll
                                ? _c("span", [
                                    committedAction.skill_roll
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDiceRoll(
                                                committedAction.skill_roll
                                              )
                                            ) +
                                              " = " +
                                              _vm._s(
                                                _vm.rollResolver_calculateRollScore(
                                                  committedAction.skill_roll
                                                )
                                              )
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      _vm._s(_vm.$t("action.hidden_number"))
                                    )
                                  ])
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$helpers.formatSignedNumber(
                                      _vm.getCommittedActionModifier(
                                        committedAction
                                      )
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.$helpers.formatSignedNumber(
                                    committedAction.skill_bonus
                                  )
                                )
                              )
                            ]),
                            _vm.mode !== "compact"
                              ? _c("td", { staticClass: "center total" }, [
                                  _vm.isCommittedActionResolved(
                                    committedAction.committed_action_status
                                  )
                                    ? _c(
                                        "span",
                                        {
                                          class:
                                            _vm.calculateTotal(
                                              committedAction
                                            ) < _vm.systemSuccessThreshold
                                              ? "play-outcome--failure"
                                              : "play-outcome--success"
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.calculateTotal(
                                                committedAction
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _c("span", [
                                        _vm._v(
                                          _vm._s(_vm.$t("action.hidden_number"))
                                        )
                                      ])
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "center status" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "action.textStatus." +
                                        committedAction.committed_action_status
                                    )
                                  ) +
                                  " "
                              )
                            ]),
                            _c("td", { staticClass: "center" }, [
                              _vm.mode === "compact" &&
                              _vm.canDeleteCommittedAction(committedAction)
                                ? _c("img", {
                                    staticClass: "icon icon-remove-circle",
                                    attrs: {
                                      src:
                                        _vm.config.mediaPath.application.icons +
                                        _vm.config.assets.icons.remove_circle,
                                      alt: _vm.$t("action.delete_action")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmDeleteCommittedAction(
                                          committedAction.id,
                                          committedAction.character_id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]
                        ),
                        _vm.mode !== "compact"
                          ? _c(
                              "tr",
                              {
                                key:
                                  "committed-action-r3-" + committedActionIndex,
                                class:
                                  _vm.selectedScheduledActionId ===
                                  committedAction.scheduled_action
                                    ? "selected-scheduled-action"
                                    : ""
                              },
                              [
                                _c(
                                  "td",
                                  {
                                    staticClass: "skill-name",
                                    attrs: { colspan: "8" }
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(_vm.getSkillName(committedAction))
                                    )
                                  ]
                                )
                              ]
                            )
                          : _vm._e()
                      ]
                    )
                  ]
                })
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }