var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "skill-list skill-list-mode-" + _vm.mode,
      attrs: { id: "skill-list" }
    },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c("table", { staticClass: "table table-fixed skill-table" }, [
        _c("thead", [
          _c("tr", { attrs: { id: "skill-table-header-row" } }, [
            _c("th", { staticClass: "left index", attrs: { scope: "col" } }),
            _c("th", { staticClass: "left", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.skill")))
            ]),
            _vm.isPlayMode ? _c("th", { attrs: { scope: "col" } }) : _vm._e(),
            _vm.isPlayMode && _vm.isGameScope
              ? _c("th", { attrs: { scope: "col" } })
              : _vm._e(),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.type")))
            ]),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.maneuver_type")))
            ]),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.stats")))
            ]),
            _vm.isEditMode || _vm.isCreationMode
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.base_rank")) + " ")
                ])
              : _vm._e(),
            _vm.isCreationMode
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.add_ranks")) + " ")
                ])
              : _vm._e(),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isCreationMode
                          ? _vm.$t("skill.total_ranks")
                          : _vm.$t("skill.ranks")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(" " + _vm._s(_vm.$t("skill.rank_progression")) + " ")
            ]),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.no_rank_malus")))
            ]),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.stats_bonus")) + " ")
                ])
              : _vm._e(),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.rank_bonus")) + " ")
                ])
              : _vm._e(),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("skill.base_modifier")))
            ]),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.total_bonus")) + " ")
                ])
              : _vm._e(),
            _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(_vm._s(_vm.$t("skill.initiative_bonus")))
                ])
              : _vm._e(),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isEditMode || _vm.isCreationMode
                      ? _vm.$t("skill.development_effort")
                      : _vm.$t("skill.DE")
                  ) +
                  " "
              )
            ]),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.isCreationMode
                          ? _vm.$t("skill.development_points_to_rank_up")
                          : _vm.$t("skill.DPs_to_rank_up")
                      ) +
                      " "
                  )
                ])
              : _vm._e(),
            !_vm.isEditMode && _vm.isPlayMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.$t("skill.DPs_allocated")) + " ")
                ])
              : _vm._e(),
            !_vm.isEditMode && _vm.isCharacterScope
              ? _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
                  _vm._v(" " + _vm._s(_vm.developmentPointsInputTitle) + " ")
                ])
              : _vm._e()
          ])
        ]),
        this.isContentReady
          ? _c(
              "tbody",
              [
                _vm._l(_vm.skills, function(skill, skillIndex) {
                  return [
                    _vm.filterSkills(skill)
                      ? _c(
                          "tr",
                          {
                            key: "skill-" + skillIndex,
                            ref: skill.isSkill ? "skills" : "categories",
                            refInFor: true,
                            class: [
                              skillIndex % 2 === 0 ? "even" : "odd",
                              skill.isSkill
                                ? "skills-entry " +
                                  skill.type_label.toLowerCase()
                                : "skills-category",
                              "nesting-" + _vm.handleNesting(skill),
                              skill.statusTypedSkill === "error" ||
                              _vm.errorFieldsInherited["skill_" + skill.id]
                                ? "error"
                                : "",
                              skill.open ? "open" : "",
                              skill.collapsed ? "collapsed" : ""
                            ],
                            attrs: {
                              id: skill.isSkill
                                ? "skill-" + skill.id
                                : "category-" + skill.id,
                              "data-id": skill.id,
                              "data-skill": skill.skill,
                              "data-skill-type": skill.isSkill
                                ? skill.type
                                : "",
                              "data-base-rank-available":
                                skill.base_rank_available
                            }
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass: "skill-index left",
                                attrs: {
                                  scope: "row",
                                  id:
                                    (skill.isSkill ? "skill" : "category") +
                                    "-index-" +
                                    skill.id
                                }
                              },
                              [_vm._v(" " + _vm._s(skillIndex + 1) + " ")]
                            ),
                            _c(
                              "td",
                              { staticClass: "name left" },
                              [
                                _vm.isTemporarySkill(skill)
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "name-wrapper",
                                        class: [
                                          skill.type ===
                                          _vm.system.skill_types.whitelabel
                                            ? "skill-white-label"
                                            : ""
                                        ],
                                        attrs: { id: "skill-name-" + skill.id }
                                      },
                                      [
                                        (_vm.isEditMode ||
                                          _vm.isCreationMode) &&
                                        skill.isSkill &&
                                        !skill.skill &&
                                        skill.rank * 1 === 0 &&
                                        skill.development_points_allocated *
                                          1 ===
                                          0
                                          ? _c("img", {
                                              staticClass: "icon icon-remove",
                                              attrs: {
                                                src:
                                                  _vm.config.mediaPath
                                                    .application.icons +
                                                  _vm.config.assets.icons
                                                    .remove,
                                                alt: _vm.$t(
                                                  "skill.delete_skill"
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deleteSkill(
                                                    skillIndex
                                                  )
                                                }
                                              }
                                            })
                                          : _vm._e(),
                                        !_vm.isEditMode &&
                                        _vm.isUsableSkill(
                                          skill.isSkill,
                                          skill.type
                                        )
                                          ? _c(
                                              "a",
                                              {
                                                staticClass: "skill-link",
                                                attrs: {
                                                  href: "/skill/" + skill.skill,
                                                  target: "_blank"
                                                }
                                              },
                                              [_vm._v(_vm._s(skill.name))]
                                            )
                                          : _vm.isEditMode
                                          ? _c(
                                              "RouterLink",
                                              {
                                                attrs: {
                                                  to: "/skill/edit/" + skill.id
                                                }
                                              },
                                              [_vm._v(_vm._s(skill.name))]
                                            )
                                          : [_vm._v(_vm._s(skill.name))]
                                      ],
                                      2
                                    )
                                  : _vm._e(),
                                skill.tempIndex !== "" &&
                                typeof skill.tempIndex !== "undefined"
                                  ? _c("div", { staticClass: "new-skill" }, [
                                      _c("img", {
                                        staticClass: "icon icon-remove",
                                        attrs: {
                                          src:
                                            _vm.config.mediaPath.application
                                              .icons +
                                            _vm.config.assets.icons.remove,
                                          alt: _vm.$t("skill.remove_skill")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.cancelSkill(skillIndex)
                                          }
                                        }
                                      }),
                                      _c("input", {
                                        staticClass: "form-control",
                                        attrs: {
                                          type: "text",
                                          id: "skill-name-" + skill.tempIndex,
                                          name: "skill-name",
                                          "aria-describedby":
                                            "rank-" + skill.tempIndex
                                        },
                                        on: {
                                          input: function($event) {
                                            return _vm.updateSkillName(
                                              $event,
                                              skill
                                            )
                                          }
                                        }
                                      }),
                                      _c("img", {
                                        staticClass: "icon icon-save",
                                        attrs: {
                                          src:
                                            _vm.config.mediaPath.application
                                              .icons +
                                            _vm.config.assets.icons.save,
                                          alt: _vm.$t("skill.save_skill")
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.saveSkill(
                                              skillIndex,
                                              skill
                                            )
                                          }
                                        }
                                      })
                                    ])
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      skill.isSkill && skill.medium
                                        ? "(" + skill.medium + ")"
                                        : ""
                                    ) +
                                    " "
                                ),
                                !skill.isSkill
                                  ? _c("img", {
                                      staticClass: "icon icon-expand",
                                      attrs: {
                                        src:
                                          _vm.config.mediaPath.application
                                            .icons +
                                          (skill.open
                                            ? _vm.config.assets.icons.expand
                                            : _vm.config.assets.icons.collapse),
                                        alt: _vm.$t("global.expand_collapse")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleCollapse(
                                            skillIndex,
                                            skill.nesting
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                skill.type ===
                                  _vm.system.skill_types.whitelabel &&
                                (_vm.isCreationMode || _vm.isRankUpMode)
                                  ? _c("img", {
                                      staticClass:
                                        "icon icon-add icon-add-skill",
                                      attrs: {
                                        src:
                                          _vm.config.mediaPath.application
                                            .icons +
                                          _vm.config.assets.icons.add,
                                        alt: _vm.$t("skill.add_skill")
                                      },
                                      on: {
                                        click: function($event) {
                                          _vm.isCreationMode || _vm.isRankUpMode
                                            ? _vm.addLabelGameSkill(
                                                skillIndex,
                                                skill
                                              )
                                            : false
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                skill.synopsis
                                  ? _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "skill-index-" + skill.id,
                                          placement: "topright",
                                          triggers: "hover",
                                          "custom-class": "skill-tooltip"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(skill.synopsis) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _vm.isPlayMode
                              ? _c("td", { staticClass: "commit center" }, [
                                  skill.isSkill
                                    ? _c("img", {
                                        staticClass: "icon icon-die",
                                        attrs: {
                                          src:
                                            _vm.config.mediaPath.application
                                              .icons +
                                            _vm.config.assets.icons.die6,
                                          alt: _vm.$t(
                                            _vm.isGameScope
                                              ? "skill.schedule_action"
                                              : "skill.commit_action"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.queueAction(skill)
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              : _vm._e(),
                            _vm.isPlayMode && _vm.isGameScope
                              ? _c(
                                  "td",
                                  { staticClass: "play-for-all center" },
                                  [
                                    skill.isSkill
                                      ? _c("img", {
                                          staticClass: "icon icon-party-play",
                                          attrs: {
                                            src:
                                              _vm.config.mediaPath.application
                                                .icons +
                                              _vm.config.assets.icons.play,
                                            alt: _vm.$t(
                                              "skill.play_party_action"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.playPartyAction(skill)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            _c("td", { staticClass: "type center" }, [
                              skill.isSkill
                                ? _c(
                                    "span",
                                    { attrs: { title: skill.type_label } },
                                    [_vm._v(" " + _vm._s(skill.type) + " ")]
                                  )
                                : _vm._e()
                            ]),
                            _c(
                              "td",
                              { staticClass: "maneuver_type_subtype center" },
                              [
                                skill.maneuver_type
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "maneuver_type",
                                          attrs: {
                                            title:
                                              _vm.$t(
                                                "action.maneuver_type." +
                                                  skill.maneuver_type
                                              ) +
                                              (skill.maneuver_subtype
                                                ? " - " +
                                                  _vm.$t(
                                                    "action.maneuver_subtype." +
                                                      skill.maneuver_subtype
                                                  )
                                                : "")
                                          }
                                        },
                                        [_vm._v(_vm._s(skill.maneuver_type))]
                                      ),
                                      skill.maneuver_subtype
                                        ? [
                                            _vm._v("/"),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "maneuver_subtype"
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(skill.maneuver_subtype)
                                                )
                                              ]
                                            )
                                          ]
                                        : _vm._e()
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _c(
                              "td",
                              { staticClass: "stats center" },
                              [
                                skill.stat1_code
                                  ? [
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            title: _vm.$helpers.formatSignedNumber(
                                              skill.stat1_bonus
                                            )
                                          }
                                        },
                                        [_vm._v(_vm._s(skill.stat1_code))]
                                      ),
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            title: _vm.$helpers.formatSignedNumber(
                                              skill.stat2_bonus
                                            )
                                          }
                                        },
                                        [_vm._v(_vm._s(skill.stat2_code))]
                                      ),
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        {
                                          attrs: {
                                            title: _vm.$helpers.formatSignedNumber(
                                              _vm.formulas_stat3Bonus(skill)
                                            )
                                          }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              skill.stat3_code
                                                ? skill.stat3_code
                                                : "*"
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            ),
                            _vm.isEditMode || _vm.isCreationMode
                              ? _c("td", { staticClass: "base_rank center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.displayBaseRank(skill)) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            !_vm.isEditMode &&
                            _vm.isCreationMode &&
                            _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  { staticClass: "rank center" },
                                  [
                                    skill.tag !== "arcane_resistance_skill"
                                      ? [
                                          (_vm.isRankable(
                                            skill.development_effort
                                          )
                                            ? "number"
                                            : "hidden") === "checkbox" &&
                                          _vm.isRankUpableSkillType(skill)
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: skill.rank,
                                                    expression: "skill.rank"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  step: "1",
                                                  min: "0",
                                                  max:
                                                    _vm.system
                                                      .stat_max_roll_score -
                                                    skill.base_rank,
                                                  disabled: _vm.isTemporarySkill(
                                                    skill
                                                  )
                                                    ? false
                                                    : "disabled",
                                                  id: "rank-" + skill.id,
                                                  name: "rank",
                                                  "aria-describedby":
                                                    "rank-" + skill.id,
                                                  type: "checkbox"
                                                },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    skill.rank
                                                  )
                                                    ? _vm._i(skill.rank, null) >
                                                      -1
                                                    : skill.rank
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateRanks(
                                                      $event,
                                                      skill
                                                    )
                                                  },
                                                  change: function($event) {
                                                    var $$a = skill.rank,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            skill,
                                                            "rank",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            skill,
                                                            "rank",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        skill,
                                                        "rank",
                                                        $$c
                                                      )
                                                    }
                                                  }
                                                }
                                              })
                                            : (_vm.isRankable(
                                                skill.development_effort
                                              )
                                                ? "number"
                                                : "hidden") === "radio" &&
                                              _vm.isRankUpableSkillType(skill)
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: skill.rank,
                                                    expression: "skill.rank"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  step: "1",
                                                  min: "0",
                                                  max:
                                                    _vm.system
                                                      .stat_max_roll_score -
                                                    skill.base_rank,
                                                  disabled: _vm.isTemporarySkill(
                                                    skill
                                                  )
                                                    ? false
                                                    : "disabled",
                                                  id: "rank-" + skill.id,
                                                  name: "rank",
                                                  "aria-describedby":
                                                    "rank-" + skill.id,
                                                  type: "radio"
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    skill.rank,
                                                    null
                                                  )
                                                },
                                                on: {
                                                  input: function($event) {
                                                    return _vm.updateRanks(
                                                      $event,
                                                      skill
                                                    )
                                                  },
                                                  change: function($event) {
                                                    return _vm.$set(
                                                      skill,
                                                      "rank",
                                                      null
                                                    )
                                                  }
                                                }
                                              })
                                            : _vm.isRankUpableSkillType(skill)
                                            ? _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: skill.rank,
                                                    expression: "skill.rank"
                                                  }
                                                ],
                                                staticClass: "form-control",
                                                attrs: {
                                                  step: "1",
                                                  min: "0",
                                                  max:
                                                    _vm.system
                                                      .stat_max_roll_score -
                                                    skill.base_rank,
                                                  disabled: _vm.isTemporarySkill(
                                                    skill
                                                  )
                                                    ? false
                                                    : "disabled",
                                                  id: "rank-" + skill.id,
                                                  name: "rank",
                                                  "aria-describedby":
                                                    "rank-" + skill.id,
                                                  type: _vm.isRankable(
                                                    skill.development_effort
                                                  )
                                                    ? "number"
                                                    : "hidden"
                                                },
                                                domProps: { value: skill.rank },
                                                on: {
                                                  input: [
                                                    function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        skill,
                                                        "rank",
                                                        $event.target.value
                                                      )
                                                    },
                                                    function($event) {
                                                      return _vm.updateRanks(
                                                        $event,
                                                        skill
                                                      )
                                                    }
                                                  ]
                                                }
                                              })
                                            : _vm._e(),
                                          !skill.isSkill
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "quantity_buttons"
                                                },
                                                [
                                                  _c("input", {
                                                    staticClass: "minus",
                                                    attrs: {
                                                      type: "button",
                                                      value: "-"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.transferBaseRanksWithParent(
                                                          $event,
                                                          skill,
                                                          "-"
                                                        )
                                                      }
                                                    }
                                                  }),
                                                  _c("input", {
                                                    staticClass: "plus",
                                                    attrs: {
                                                      type: "button",
                                                      value: "+"
                                                    },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.transferBaseRanksWithParent(
                                                          $event,
                                                          skill,
                                                          "+"
                                                        )
                                                      }
                                                    }
                                                  })
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      : _vm._e()
                                  ],
                                  2
                                )
                              : _vm._e(),
                            !_vm.isEditMode
                              ? [
                                  skill.tag === "arcane_resistance_skill"
                                    ? _c(
                                        "td",
                                        { staticClass: "total_ranks center" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "rank_display" },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.getArcaneResistanceSkillRank(
                                                      skill
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    : _vm.isCharacterScope
                                    ? _c(
                                        "td",
                                        {
                                          staticClass: "total_ranks center",
                                          on: {
                                            click: function($event) {
                                              _vm.isPlayMode
                                                ? _vm.openEditRankMode(
                                                    skillIndex
                                                  )
                                                : false
                                            }
                                          }
                                        },
                                        [
                                          skill.editRankMode
                                            ? [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: skill.rank,
                                                      expression: "skill.rank"
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control rank",
                                                  attrs: {
                                                    type: "number",
                                                    step: "1",
                                                    min: "0",
                                                    max:
                                                      _vm.system
                                                        .stat_max_roll_score,
                                                    id: "rank-" + skill.id,
                                                    name: "rank",
                                                    "aria-describedby":
                                                      "rank-" + skill.id
                                                  },
                                                  domProps: {
                                                    value: skill.rank
                                                  },
                                                  on: {
                                                    blur: function($event) {
                                                      return _vm.closeEditRankMode(
                                                        skill
                                                      )
                                                    },
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        skill,
                                                        "rank",
                                                        $event.target.value
                                                      )
                                                    }
                                                  }
                                                })
                                              ]
                                            : _c(
                                                "span",
                                                { staticClass: "rank_display" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        skill.isSkill &&
                                                          skill.type !==
                                                            _vm.system
                                                              .skill_types
                                                              .whitelabel
                                                          ? _vm.formulas_totalRanks(
                                                              skill.rank,
                                                              skill.base_rank
                                                            )
                                                          : ""
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              )
                                        ],
                                        2
                                      )
                                    : _vm._e()
                                ]
                              : _vm._e(),
                            _c(
                              "td",
                              { staticClass: "rank_progression center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isEditMode ||
                                        (skill.isSkill &&
                                          skill.type !==
                                            _vm.system.skill_types.whitelabel)
                                        ? skill.rank_progression
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "no_rank_penalty center" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.isEditMode ||
                                        (skill.isSkill &&
                                          skill.type !==
                                            _vm.system.skill_types.whitelabel)
                                        ? skill.no_rank_penalty
                                        : ""
                                    ) +
                                    " "
                                )
                              ]
                            ),
                            !_vm.isEditMode && _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  { staticClass: "stats_bonus center" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          skill.isSkill &&
                                            skill.type !==
                                              _vm.system.skill_types.whitelabel
                                            ? _vm.$helpers.formatSignedNumber(
                                                _vm.formulas_statsBonus(
                                                  skill.stat1_bonus,
                                                  skill.stat2_bonus,
                                                  skill.stat3_bonus
                                                )
                                              )
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isEditMode && _vm.isCharacterScope
                              ? _c("td", { staticClass: "rank_bonus center" }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        skill.isSkill &&
                                          skill.type !==
                                            _vm.system.skill_types.whitelabel
                                          ? _vm.$helpers.formatSignedNumber(
                                              _vm.formulas_rankBonus(
                                                skill.rank_progression,
                                                skill.rank,
                                                skill.base_rank
                                              )
                                            )
                                          : ""
                                      ) +
                                      " "
                                  )
                                ])
                              : _vm._e(),
                            _c("td", { staticClass: "base_modifier center" }, [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.isEditMode ||
                                      (skill.isSkill &&
                                        skill.type !==
                                          _vm.system.skill_types.whitelabel)
                                      ? _vm.$helpers.formatSignedNumber(
                                          skill.base_modifier
                                        )
                                      : ""
                                  ) +
                                  " "
                              )
                            ]),
                            !_vm.isEditMode && _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  { staticClass: "total_bonus center" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          skill.isSkill &&
                                            skill.type !==
                                              _vm.system.skill_types.whitelabel
                                            ? _vm.$helpers.formatSignedNumber(
                                                _vm.formulas_totalBonus(
                                                  skill.rank,
                                                  skill.base_rank,
                                                  skill.rank_progression,
                                                  skill.no_rank_penalty,
                                                  skill.stat1_bonus,
                                                  skill.stat2_bonus,
                                                  skill.stat3_bonus,
                                                  skill.base_modifier
                                                )
                                              )
                                            : ""
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  { staticClass: "initiative_bonus center" },
                                  [
                                    _c(
                                      "span",
                                      {
                                        attrs: {
                                          title: skill.stat_initiative_code
                                        }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            skill.isSkill &&
                                              skill.type !==
                                                _vm.system.skill_types
                                                  .whitelabel
                                              ? _vm.$helpers.formatSignedNumber(
                                                  skill.stat_initiative_bonus
                                                )
                                              : ""
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _c(
                              "td",
                              { staticClass: "development_effort center" },
                              [
                                skill.tag !== "arcane_resistance_skill"
                                  ? _c("span", [
                                      _vm._v(_vm._s(skill.development_effort))
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            !_vm.isEditMode && _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "development_points_to_rank_up center"
                                  },
                                  [
                                    _vm.isRankUpableSkillType(skill) &&
                                    skill.tag !== "arcane_resistance_skill"
                                      ? _c(
                                          "span",
                                          {
                                            class:
                                              skill.rank * 1 <
                                              _vm.system.skill_max_rank_number *
                                                1
                                                ? ""
                                                : "disabled"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.developmentPointsToRankUp(
                                                  skill.rank,
                                                  skill.base_rank,
                                                  skill.development_effort
                                                )
                                              ) + " "
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isEditMode &&
                            _vm.isPlayMode &&
                            _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  {
                                    staticClass:
                                      "development_points_allocated center"
                                  },
                                  [
                                    skill.tag !== "arcane_resistance_skill"
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(skill.development_points)
                                          )
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e(),
                            !_vm.isEditMode && _vm.isCharacterScope
                              ? _c(
                                  "td",
                                  { staticClass: "development_points center" },
                                  [
                                    _vm.isRankUpableSkillType(skill) &&
                                    skill.tag !== "arcane_resistance_skill"
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "development_points_to_rank_up_wrapper"
                                          },
                                          [
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.canRankDown(skill) &&
                                                    (_vm.isCreationMode ||
                                                      _vm.isRankUpMode),
                                                  expression:
                                                    "canRankDown(skill) && (isCreationMode || isRankUpMode)"
                                                }
                                              ],
                                              staticClass:
                                                "icon icon-rank-down",
                                              attrs: {
                                                src:
                                                  _vm.config.mediaPath
                                                    .application.icons +
                                                  _vm.config.assets.icons
                                                    .rank_down,
                                                alt: _vm.$t(
                                                  "skill.cancel_rankup"
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.rankDown(skill)
                                                }
                                              }
                                            }),
                                            _c("input", {
                                              staticClass:
                                                "form-control development_points",
                                              class:
                                                skill.rank * 1 <
                                                _vm.system
                                                  .skill_max_rank_number *
                                                  1
                                                  ? ""
                                                  : "disabled",
                                              attrs: {
                                                type: _vm.isRankable(
                                                  skill.development_effort
                                                )
                                                  ? "number"
                                                  : "hidden",
                                                step: "1",
                                                min: "0",
                                                disabled: _vm.isTemporarySkill(
                                                  skill
                                                )
                                                  ? false
                                                  : "disabled",
                                                id:
                                                  "development-points-" +
                                                  skill.id,
                                                name: "development-points",
                                                "aria-describedby":
                                                  "development-points-" +
                                                  skill.id
                                              },
                                              domProps: {
                                                value: _vm.isPlayMode
                                                  ? skill.development_points_allocated
                                                  : skill.development_points
                                              },
                                              on: {
                                                input: function($event) {
                                                  return _vm.updateDevelopmentPoints(
                                                    $event,
                                                    skill
                                                  )
                                                }
                                              }
                                            }),
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.canRankUp(skill) &&
                                                    (_vm.isCreationMode ||
                                                      _vm.isRankUpMode),
                                                  expression:
                                                    "canRankUp(skill) && (isCreationMode || isRankUpMode)"
                                                }
                                              ],
                                              staticClass: "icon icon-rank-up",
                                              attrs: {
                                                src:
                                                  _vm.config.mediaPath
                                                    .application.icons +
                                                  _vm.config.assets.icons
                                                    .rank_up,
                                                alt: _vm.$t(
                                                  "skill.rankup_skill"
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.rankUp(skill)
                                                }
                                              }
                                            }),
                                            _c("img", {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    skill.development_points_allocated >
                                                      0 && _vm.isPlayMode,
                                                  expression:
                                                    "skill.development_points_allocated > 0 && isPlayMode"
                                                }
                                              ],
                                              staticClass:
                                                "icon icon-add icon-add-experience-points",
                                              attrs: {
                                                src:
                                                  _vm.config.mediaPath
                                                    .application.icons +
                                                  _vm.config.assets.icons.add,
                                                alt: _vm.$t(
                                                  "skill.add_experience_points"
                                                )
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.addExperiencePoints(
                                                    skill
                                                  )
                                                }
                                              }
                                            })
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              : _vm._e()
                          ],
                          2
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }