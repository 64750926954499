import { AUTH_LOGOUT } from '../actions/auth';
import { CHARACTER_REFRESH } from '../actions/character';
import {
  SCORES_CONSUME,
  SCORES_CONSUMED_RESET,
  SCORES_CONSUMED_SET,
  SCORES_ERROR,
  SCORES_PLAY_REGENERATE,
  SCORES_RESET,
  SCORES_SUCCESS
} from '../actions/scores';
import { ROLE_SELECT_RESET } from '../actions/role';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  consumed: {},
  play: {},
  statusVuex: {}
};

const getters = {
  getScoresStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [SCORES_PLAY_REGENERATE]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(SCORES_PLAY_REGENERATE);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getScoresCharacterGame, params)
        .then(resp => {
          let data = {
            play: resp.data
          };
          commit(SCORES_SUCCESS, data);
          resolve(data);
        })
        .catch(err => {
          commit(SCORES_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [CHARACTER_REFRESH]: () => {
    Vue.helpers.resetState(state);
  },

  [ROLE_SELECT_RESET]: () => {
    Vue.helpers.resetState(state);
  },

  [SCORES_CONSUME]: (state, resp) => {
    Vue.set(state.statusVuex, 'scoreConsume', 'success');
    const property = Object.keys(resp)[0];
    const value = (state.consumed[property] ? state.consumed[property] : 0) + resp[property];
    Vue.set(state.consumed, property, value);
  },

  [SCORES_CONSUMED_RESET]: state => {
    console.log('SCORES_CONSUMED_RESET');
    state.consumed = {};
  },
  [SCORES_CONSUMED_SET]: (state, resp) => {
    Vue.set(state.statusVuex, 'scoresConsumed', 'success');
    const property = Object.keys(resp)[0];
    Vue.set(state.consumed, property, resp[property]);
  },
  [SCORES_ERROR]: state => {
    Vue.set(state.statusVuex, 'set', 'error');
  },
  [SCORES_PLAY_REGENERATE]: state => {
    Vue.set(state.statusVuex, 'set', 'loading');
  },
  [SCORES_RESET]: state => {
    Vue.set(state.statusVuex, 'set', 'success');
    Vue.helpers.resetState(state);
  },
  [SCORES_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'set', 'success');
    state = Object.assign(state, resp);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
