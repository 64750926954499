<template>
  <main v-if="restrictedAccess_grantMaster" id="settings" class="body-view container settings">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <template v-if="settings.length > 0">
        <template v-for="setting in settings">
          <div :key="setting.id" class="setting col-4">
            <div class="tile tile-selectable setting-frame" @click="selectSetting(setting.id)">
              <div class="setting-image-frame">
                <img
                  class="setting-image image-fill-frame"
                  :class="setting.cover ? '' : 'fade-image'"
                  border="0"
                  :alt="setting.name"
                  :src="setting.cover ? coverImageUrl + setting.id + '/' + setting.cover : missingSettingCoverImage"
                />
              </div>
              <ul class="setting-text">
                <li>
                  <span class="setting-text-label">{{ $t('setting.name') }}</span
                  >{{ setting.name }}
                </li>
                <li>
                  <span class="setting-text-label">{{ $t('setting.owner') }}</span
                  >{{ setting.owner_name ? setting.owner_name : $t('global.public') }}
                </li>
                <li>
                  <span class="setting-text-label">{{ $t('setting.description') }}</span
                  >{{ setting.synopsis }}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </template>

      <div class="setting setting-new col-4">
        <div class="tile tile-selectable tile-with-background setting-frame" @click="createSetting()">
          <div class="tile-image-frame setting-image-frame">
            <img class="setting-image" border="0" :alt="$t('setting.new_setting')" :src="newSettingImageUrl" />
          </div>
          <div class="tile-selectable-text setting-text">
            {{ $t('setting.new_setting') }}
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SETTINGS_REQUEST } from '@/store/actions/setting';
import styles from '@/styles/_variables.scss';
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: 'SettingListView',
  mixins: [restrictedAccessMixin],
  data() {
    return {
      isContentReady: false,
      settings: []
    };
  },
  computed: {
    ...mapGetters(['getSettingStatus']),
    coverImageUrl() {
      return config.mediaPath.setting + '/';
    },
    isLoading() {
      return this.getSettingStatus('settings') === 'loading';
    },
    missingSettingCoverImage() {
      return config.mediaPath.application.placeholders + 'missing_setting_picture.png';
    },
    newSettingImageUrl() {
      return styles['setting-image-src'].replace(/"/g, '');
    }
  },
  mounted() {
    this.populateSettings();
  },
  methods: {
    createSetting() {
      console.log('create setting: ');
    },
    populateSettings() {
      this.$store
        .dispatch(SETTINGS_REQUEST)
        .then(data => {
          this.settings = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    selectSetting(id) {
      if (!id) {
        return false;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.settings {
  max-width: 100%;

  .setting {
    margin-bottom: 25px;

    &-frame {
      border-color: $setting-border-color;

      &:hover {
        border-color: $setting-border-color-hover;
      }
    }

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .setting-new {
    .setting {
      &-frame {
        border-color: $setting-new-border-color;

        &:hover {
          border-color: $setting-new-border-color-hover;
        }
      }
    }
  }
}
</style>
