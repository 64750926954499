<template>
  <CounterPoints
    :label="label"
    :class="'counter-' + counterShape"
    :className="className"
    :currentPoints="currentPoints"
    :totalPoints="totalPoints"
  />
</template>

<script>
import CounterPoints from '@/components/Counters/Points';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'PartyCounterExperiencePoints',
  props: {
    counterShape: {
      type: String,
      default: 'rectangular'
    },
    partyPoints: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      className: 'party-experience-points'
    };
  },
  components: {
    CounterPoints
  },
  computed: {
    ...mapGetters(['isPartyLoaded']),
    ...mapState(['scores', 'party']),
    isPartyView() {
      return this.$route.path.includes('/party');
    },
    isRankUpView() {
      return this.$route.path.includes('/rankup');
    },
    label() {
      if (this.isPartyView) {
        return 'experience_points';
      }

      return 'experience_points_party';
    },
    currentPoints() {
      /* If the user is on the ranking-up page the current points are the total available points 
         to the sole character, which means 1/n of the total points, where n is the member number */
      if (this.isRankUpView) {
        return this.isPartyLoaded ? Math.floor(this.totalPoints / this.party.characters.length) : 0;
      }

      /* This is the typical case of a master user (but also available to a character user).
         If the user is not on the party page and points data are passed by props, then those points
         (which are the total party points) get displayed */
      if (this.partyPoints) {
        return this.partyPoints;
      }

      /* This is the typical case of a character user (but also available to a master user).
         If the user is not on the party page and points data are not passed by props, then the store points
         (which are the total party points) get displayed */
      return this.isPartyLoaded ? this.totalPoints : 0;
    },
    totalPoints() {
      if (this.isRankUpView) {
        return this.isPartyLoaded ? this.party.experience_points * 1 : 0;
      }

      return null;
    }
  }
};
</script>

<style lang="scss" scoped>
.counter-rectangular {
  @include rectangular-counter(
    $party-experience-points-counter-bg-color,
    $party-experience-points-counter-border-color,
    $party-experience-points-counter-font-color
  );
}

.counter-round {
  @include round-counter(
    $party-experience-points-counter-bg-color,
    $party-experience-points-counter-border-color,
    $party-experience-points-counter-font-color,
    $point-counter-size
  );
}
</style>
