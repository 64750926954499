<template>
  <div class="container item-detail" :id="entityNature + '-detail'">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <!-- ITEM DETAIL -->
      <template v-if="this.isContentReady && item">
        <div class="col-4" :key="item.id">
          <Item :ref="entityNature + '_' + item.id" :item="item" />
        </div>

        <div class="col-4"></div>
        <div class="col-4"></div>
      </template>
    </div>
  </div>
</template>

<script>
const entityNature = 'gameCampaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import Item from '@/components/Campaign/Game';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized + 'Detail',
  components: {
    Item
  },
  data() {
    return {
      entityNature,
      entityNatureCapitalized,
      isContentReady: false,
      item: null
    };
  },
  computed: {
    ...mapGetters(['getCampaignStatus', 'isCampaignLoaded'])
  },
  created() {
    if (!this.isCampaignLoaded) {
      this.unwatchGameCampaign = this.$watch('isCampaignLoaded', () => {
        this.unwatchGameCampaign();
        this.item = this.$store.state.campaign;
        this.isContentReady = true;
      });
    } else {
      this.item = this.$store.state.campaign;
      this.isContentReady = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
