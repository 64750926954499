<template>
  <CounterPoints :className="className" :currentPoints="currentPoints" :label="label" :totalPoints="totalPoints" />
</template>

<script>
import CounterPoints from '@/components/Counters/Points';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterCounterBaseSpellList',
  components: {
    CounterPoints
  },
  data() {
    return {
      className: 'character-base-spell-lists',
      label: 'base_spell_lists'
    };
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'scores']),
    currentPoints() {
      return this.totalPoints * 1 - this.$helpers.sanitizeInteger(this.scores.consumed.base_spell_list_selected);
    },
    totalPoints() {
      return this.isCharacterLoaded ? this.character.scores.permanent_modifiers.base_spell_list_number : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.counter {
  @include rectangular-counter(
    $character-base-spell-lists-counter-bg-color,
    $character-base-spell-lists-counter-border-color,
    $character-base-spell-lists-counter-font-color
  );
}
</style>
