import { AUTH_LOGOUT } from '../actions/auth';
import {
  PARTIES_ERROR,
  PARTIES_REQUEST,
  PARTIES_SUCCESS,
  PARTY_CHARACTER_ERROR,
  PARTY_CHARACTER_REQUEST,
  PARTY_CHARACTER_RESET,
  PARTY_CHARACTER_SUCCESS,
  PARTY_MASTER_SUCCESS,
  PARTY_ERROR,
  PARTY_REQUEST,
  PARTY_SUCCESS
} from '../actions/party';
import { ROLE_SELECT_RESET } from '../actions/role';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  experience_points: '',
  id: '',
  name: '',
  statusVuex: {}
};

const getters = {
  getPartyStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isPartyLoaded: state => !!state.id
};

const actions = {
  [PARTIES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(PARTIES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getPartyList, params)
        .then(resp => {
          commit(PARTIES_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PARTIES_ERROR, err.response);
          reject(err);
        });
    });
  },

  [PARTY_CHARACTER_REQUEST]: ({ commit, dispatch, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(PARTY_CHARACTER_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      axios
        .post(config.sitePath.api.getCharacterParty + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(PARTY_CHARACTER_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PARTY_CHARACTER_RESET);
          commit(PARTY_CHARACTER_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },
  [PARTY_REQUEST]: ({ commit, rootState }, id) => {
    return new Promise((resolve, reject) => {
      commit(PARTIES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getParty + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(PARTY_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PARTY_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },
  [PARTY_CHARACTER_REQUEST]: state => {
    Vue.set(state.statusVuex, 'partyCharacter', 'loading');
  },
  [PARTY_CHARACTER_ERROR]: state => {
    Vue.set(state.statusVuex, 'partyCharacter', 'error');
  },
  [PARTY_CHARACTER_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [PARTY_CHARACTER_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'partyCharacter', 'success');
    state = Object.assign(state, resp);
  },
  [PARTY_MASTER_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'partyMaster', 'success');
    state = Object.assign(state, resp);
  },

  [PARTY_ERROR]: state => {
    Vue.set(state.statusVuex, 'party', 'error');
  },
  [PARTY_REQUEST]: state => {
    Vue.set(state.statusVuex, 'party', 'loading');
  },
  [PARTY_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'party', 'success');
  },

  [PARTIES_ERROR]: state => {
    Vue.set(state.statusVuex, 'parties', 'error');
  },
  [PARTIES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'parties', 'loading');
  },
  [PARTIES_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'parties', 'success');
  },

  [ROLE_SELECT_RESET]: () => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
