// SKILL CATEGORY
export const CATEGORIES_ERROR = 'CATEGORIES_ERROR';
export const CATEGORIES_REQUEST = 'CATEGORIES_REQUEST';
export const CATEGORIES_SUCCESS = 'CATEGORIES_SUCCESS';

// SKILL
export const SKILL_ERROR = 'SKILL_ERROR';
export const SKILL_REQUEST = 'SKILL_REQUEST';
export const SKILL_SAVE = 'SKILL_SAVE';
export const SKILL_SUCCESS = 'SKILL_SUCCESS';
export const SKILLS_ERROR = 'SKILLS_ERROR';
export const SKILLS_REQUEST = 'SKILLS_REQUEST';
export const SKILLS_SUCCESS = 'SKILLS_SUCCESS';

// CHARACTER SKILL
export const CHARACTER_GAME_SKILL_ADD = 'CHARACTER_GAME_SKILL_ADD';
export const CHARACTER_GAME_SKILL_ADD_ERROR = 'CHARACTER_GAME_SKILL_ADD_ERROR';
export const CHARACTER_GAME_SKILL_ADD_SUCCESS = 'CHARACTER_GAME_SKILL_ADD_SUCCESS';

export const CHARACTER_GAME_SKILL_ERROR = 'CHARACTER_GAME_SKILL_REQUEST';
export const CHARACTER_GAME_SKILL_REQUEST = 'CHARACTER_GAME_SKILL_REQUEST';
export const CHARACTER_GAME_SKILL_SUCCESS = 'CHARACTER_GAME_SKILL_REQUEST';

export const CHARACTER_SKILL_EXPERIENCE_ADD = 'CHARACTER_SKILL_EXPERIENCE_ADD';
export const CHARACTER_SKILL_EXPERIENCE_ADD_ERROR = 'CHARACTER_SKILL_EXPERIENCE_ADD_ERROR';
export const CHARACTER_SKILL_EXPERIENCE_ADD_SUCCESS = 'CHARACTER_SKILL_EXPERIENCE_ADD_SUCCESS';

export const CHARACTER_SKILL_ERROR = 'CHARACTER_SKILL_REQUEST';
export const CHARACTER_SKILL_REQUEST = 'CHARACTER_SKILL_REQUEST';
export const CHARACTER_SKILL_SUCCESS = 'CHARACTER_SKILL_REQUEST';

export const CHARACTER_SKILLS_ERROR = 'CHARACTER_SKILLS_REQUEST';
export const CHARACTER_SKILLS_REQUEST = 'CHARACTER_SKILLS_REQUEST';
export const CHARACTER_SKILLS_SUCCESS = 'CHARACTER_SKILLS_REQUEST';

// CLASS SKILL
export const CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_ERROR = 'CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_ERROR';
export const CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST = 'CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST';
export const CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_SUCCESS = 'CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_SUCCESS';

// GAME SKILL
export const GAME_SKILL_ERROR = 'GAME_SKILL_ERROR';
export const GAME_SKILL_REQUEST = 'GAME_SKILL_REQUEST';
export const GAME_SKILL_SUCCESS = 'GAME_SKILL_SUCCESS';

export const GAME_SKILLS_ERROR = 'GAME_SKILLS_ERROR';
export const GAME_SKILLS_REQUEST = 'GAME_SKILLS_REQUEST';
export const GAME_SKILLS_SUCCESS = 'GAME_SKILLS_SUCCESS';

// SKILL MANEUVER TYPES AND SUBTYPES
export const MANEUVER_SUBTYPES_REQUEST = 'MANEUVER_SUBTYPES_REQUEST';
export const MANEUVER_TYPES_ERROR = 'MANEUVER_TYPES_ERROR';
export const MANEUVER_TYPES_REQUEST = 'MANEUVER_TYPES_REQUEST';
export const MANEUVER_TYPES_SUCCESS = 'MANEUVER_TYPES_SUCCESS';

// SKILL MEDIA
export const MEDIA_ERROR = 'MEDIA_ERROR';
export const MEDIA_REQUEST = 'MEDIA_REQUEST';
export const MEDIA_SUCCESS = 'MEDIA_SUCCESS';

// SPELL SKILL
export const SPELL_LISTS_ERROR = 'SPELL_LISTS_ERROR';
export const SPELL_LISTS_REQUEST = 'SPELL_LISTS_REQUEST';
export const SPELL_LISTS_SUCCESS = 'SPELL_LISTS_SUCCESS';

// SKILL STATUS
export const STATUS_ERROR = 'STATUS_ERROR';
export const STATUS_REQUEST = 'STATUS_REQUEST';
export const STATUS_SUCCESS = 'STATUS_SUCCESS';

// SKILL TYPES
export const TYPES_ERROR = 'TYPES_ERROR';
export const TYPES_REQUEST = 'TYPES_REQUEST';
export const TYPES_SUCCESS = 'TYPES_SUCCESS';

// SYSTEM SKILL
export const SYSTEM_SKILLS_ERROR = 'SYSTEM_SKILLS_ERROR';
export const SYSTEM_SKILLS_REQUEST = 'SYSTEM_SKILLS_REQUEST';
export const SYSTEM_SKILLS_SUCCESS = 'SYSTEM_SKILLS_SUCCESS';
