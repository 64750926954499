import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueI18n from 'vue-i18n';
import App from './App.vue';
import { config } from '@/setup/config';
import helpers from '@/utils/helpers';
import defaultDictionary from '@/translations/en.json';
import router from './router';
import store from './store/index';
import axios from 'axios';
import VueAxios from 'vue-axios';
import AnimationLoading from './components/Lib/Loading';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import '@/styles/global.scss';

Vue.config.productionTip = false;
const plugin = {
  install() {
    Vue.helpers = helpers;
    Vue.prototype.$helpers = helpers;
  }
};
const defaultLocale = config.language.defaultLanguage;
const messages = {};
axios.defaults.withCredentials = true;
messages[config.language.defaultLanguage] = defaultDictionary;

Vue.use(BootstrapVue);
Vue.use(VueAxios, axios);
Vue.use(VueI18n);
Vue.use(plugin);

Vue.component('AnimationLoading', AnimationLoading);
// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: defaultLocale, // set locale
  fallbackLocale: config.language.defaultLanguage,
  messages
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app');
