<template>
  <div
    id="message"
    class="message alert"
    :class="[alignment, operationResultClass(message.operationResult)]"
    v-if="message.code"
  >
    {{ $t(`message.${message.code}`) }}

    <ul class="error_list" v-if="message.errorList.length > 0">
      <li v-for="(error, index) in message.errorList" :key="`error-${index}`">
        {{ $t(`message.${error}`) }}
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Message',
  props: {
    alignment: {
      type: String,
      default: 'left'
    }
  },
  computed: {
    ...mapState(['message'])
  },
  methods: {
    operationResultClass(operationResult) {
      let resultClass;
      switch (operationResult) {
        case 'success':
          resultClass = 'alert-success';
          break;
        case 'partial_success':
          resultClass = 'alert-warning';
          break;
        case 'error':
          resultClass = 'alert-danger';
          break;
        default:
          resultClass = null;
      }
      return resultClass;
    }
  }
};
</script>
