import Vue from 'vue';
import VueI18n from 'vue-i18n';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { config } from '@/setup/config';

Vue.use(VueAxios, axios);
Vue.use(VueI18n);

const loadedLanguages = [config.language.defaultLanguage];

function setI18nLanguage(i18n, lang) {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
}

export function loadLanguageAsync(i18n, lang) {
  // If the same language - not necessary (it's from the original implementation where en was always loaded as default language
  /*
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(i18n, lang))
  }
  */

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(i18n, lang));
  }

  // If the language hasn't been loaded yet
  return import(/* webpackChunkName: "lang-[request]" */ `@/translations/${lang}.json`).then(messages => {
    i18n.setLocaleMessage(lang, messages.default);
    loadedLanguages.push(lang);
    return setI18nLanguage(i18n, lang);
  });
}
