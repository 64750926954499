export const STATS_ERROR = 'STATS_ERROR';
export const STATS_REQUEST = 'STATS_REQUEST';
export const STATS_RESET = 'STATS_RESET';
export const STATS_SUCCESS = 'STATS_SUCCESS';

export const STAT_INPUT_ERROR = 'STAT_INPUT_ERROR';
export const STAT_SET = 'STAT_SET';
export const STATS_STATUS_RESET = 'STATS_STATUS_RESET';

export const STATS_RACE_ERROR = 'STATS_RACE_ERROR';
export const STATS_RACE_REQUEST = 'STATS_RACE_REQUEST';
export const STATS_RACE_SUCCESS = 'STATS_RACE_SUCCESS';
