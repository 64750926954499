var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: "skill-list skill-list-mode-" + _vm.mode,
      attrs: { id: "skill-list" }
    },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c("table", { staticClass: "table table-fixed skill-table" }, [
        _c("thead", [
          _c("tr", { attrs: { id: "skill-table-header-row" } }, [
            _c("th", { staticClass: "left index", attrs: { scope: "col" } }),
            _c("th", { staticClass: "left", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("play.skills")))
            ]),
            _c("th", { staticClass: "center", attrs: { scope: "col" } }, [
              _vm._v(_vm._s(_vm.$t("play.roll_for_party")))
            ])
          ])
        ]),
        this.isContentReady
          ? _c(
              "tbody",
              [
                _vm._l(_vm.skills, function(skill, skillIndex) {
                  return [
                    _vm.filterSkills(skill)
                      ? _c(
                          "tr",
                          {
                            key: "skill-" + skillIndex,
                            ref: skill.isSkill ? "skills" : "categories",
                            refInFor: true,
                            class: [
                              skillIndex % 2 === 0 ? "even" : "odd",
                              skill.isSkill
                                ? "skills-entry " +
                                  skill.type_label.toLowerCase()
                                : "skills-category",
                              "nesting-" + _vm.handleNesting(skill),
                              skill.statusTypedSkill === "error" ||
                              _vm.errorFieldsInherited["skill_" + skill.id]
                                ? "error"
                                : "",
                              skill.open ? "open" : "",
                              skill.collapsed ? "collapsed" : ""
                            ],
                            attrs: {
                              id: skill.isSkill
                                ? "skill-" + skill.id
                                : "category-" + skill.id,
                              "data-id": skill.id,
                              "data-template": skill.template,
                              "data-skill-type": skill.isSkill
                                ? skill.type
                                : "",
                              "data-base-rank-available":
                                skill.base_rank_available
                            }
                          },
                          [
                            _c(
                              "th",
                              {
                                staticClass: "skill-index left",
                                attrs: {
                                  scope: "row",
                                  id:
                                    (skill.isSkill ? "skill" : "category") +
                                    "-index-" +
                                    skill.id
                                }
                              },
                              [_vm._v(" " + _vm._s(skillIndex + 1) + " ")]
                            ),
                            _c(
                              "td",
                              { staticClass: "name left" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "name-wrapper",
                                    class: [
                                      skill.type ===
                                      _vm.system.skill_types.whitelabel
                                        ? "skill-white-label"
                                        : ""
                                    ],
                                    attrs: { id: "skill-name-" + skill.id }
                                  },
                                  [
                                    _vm.isUsableSkill(skill.isSkill, skill.type)
                                      ? _c(
                                          "a",
                                          {
                                            staticClass: "skill-link",
                                            attrs: {
                                              href: "/skill/" + skill.id,
                                              target: "_blank"
                                            }
                                          },
                                          [_vm._v(_vm._s(skill.name))]
                                        )
                                      : [_vm._v(_vm._s(skill.name))]
                                  ],
                                  2
                                ),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      skill.isSkill && skill.medium
                                        ? "(" + skill.medium + ")"
                                        : ""
                                    ) +
                                    " "
                                ),
                                !skill.isSkill
                                  ? _c("img", {
                                      staticClass: "icon icon-expand",
                                      attrs: {
                                        src:
                                          _vm.config.mediaPath.application
                                            .icons +
                                          (skill.open
                                            ? _vm.config.assets.icons.expand
                                            : _vm.config.assets.icons.collapse),
                                        alt: _vm.$t("global.expand_collapse")
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.toggleCollapse(
                                            skillIndex,
                                            skill.nesting
                                          )
                                        }
                                      }
                                    })
                                  : _vm._e(),
                                skill.synopsis
                                  ? _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          target: "skill-index-" + skill.id,
                                          placement: "topright",
                                          triggers: "hover",
                                          "custom-class": "skill-tooltip"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " + _vm._s(skill.synopsis) + " "
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ],
                              1
                            ),
                            _c("td", { staticClass: "commit center" }, [
                              skill.isSkill
                                ? _c("img", {
                                    staticClass: "icon icon-die",
                                    attrs: {
                                      src:
                                        _vm.config.mediaPath.application.icons +
                                        _vm.config.assets.icons.die6,
                                      alt: _vm.$t("skill.commit_action")
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.commitAction(skill)
                                      }
                                    }
                                  })
                                : _vm._e()
                            ])
                          ]
                        )
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }