var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        {
          staticClass: "body-view container settings",
          attrs: { id: "settings" }
        },
        [
          _c(
            "div",
            { staticClass: "row relative" },
            [
              !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
              _vm.settings.length > 0
                ? [
                    _vm._l(_vm.settings, function(setting) {
                      return [
                        _c(
                          "div",
                          { key: setting.id, staticClass: "setting col-4" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "tile tile-selectable setting-frame",
                                on: {
                                  click: function($event) {
                                    return _vm.selectSetting(setting.id)
                                  }
                                }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "setting-image-frame" },
                                  [
                                    _c("img", {
                                      staticClass:
                                        "setting-image image-fill-frame",
                                      class: setting.cover ? "" : "fade-image",
                                      attrs: {
                                        border: "0",
                                        alt: setting.name,
                                        src: setting.cover
                                          ? _vm.coverImageUrl +
                                            setting.id +
                                            "/" +
                                            setting.cover
                                          : _vm.missingSettingCoverImage
                                      }
                                    })
                                  ]
                                ),
                                _c("ul", { staticClass: "setting-text" }, [
                                  _c("li", [
                                    _c(
                                      "span",
                                      { staticClass: "setting-text-label" },
                                      [_vm._v(_vm._s(_vm.$t("setting.name")))]
                                    ),
                                    _vm._v(_vm._s(setting.name) + " ")
                                  ]),
                                  _c("li", [
                                    _c(
                                      "span",
                                      { staticClass: "setting-text-label" },
                                      [_vm._v(_vm._s(_vm.$t("setting.owner")))]
                                    ),
                                    _vm._v(
                                      _vm._s(
                                        setting.owner_name
                                          ? setting.owner_name
                                          : _vm.$t("global.public")
                                      ) + " "
                                    )
                                  ]),
                                  _c("li", [
                                    _c(
                                      "span",
                                      { staticClass: "setting-text-label" },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.$t("setting.description"))
                                        )
                                      ]
                                    ),
                                    _vm._v(_vm._s(setting.synopsis) + " ")
                                  ])
                                ])
                              ]
                            )
                          ]
                        )
                      ]
                    })
                  ]
                : _vm._e(),
              _c("div", { staticClass: "setting setting-new col-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "tile tile-selectable tile-with-background setting-frame",
                    on: {
                      click: function($event) {
                        return _vm.createSetting()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tile-image-frame setting-image-frame" },
                      [
                        _c("img", {
                          staticClass: "setting-image",
                          attrs: {
                            border: "0",
                            alt: _vm.$t("setting.new_setting"),
                            src: _vm.newSettingImageUrl
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "tile-selectable-text setting-text" },
                      [
                        _vm._v(
                          " " + _vm._s(_vm.$t("setting.new_setting")) + " "
                        )
                      ]
                    )
                  ]
                )
              ])
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }