import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      restrictedAccess_grantMaster: false
    };
  },
  computed: {
    ...mapGetters(['isRoleLoaded']),
    restrictedAccess_isRoleLoaded() {
      return this.$store.getters.isRoleLoaded();
    }
  },
  created() {
    if (!this.$store.getters.isRoleLoaded()) {
      this.unwatchRestrictedAccessRole = this.$watch('restrictedAccess_isRoleLoaded', () => {
        this.unwatchRestrictedAccessRole();
        this.restrictedAccess_master();
      });
    } else {
      this.restrictedAccess_master();
    }
  },
  methods: {
    restrictedAccess_master() {
      if (!this.$store.getters.isMaster) {
        this.$store.commit(MESSAGE_ERROR_SET, 'non_master_forbidden');
        this.$router.push('/' + this.$store.state.role.type + '/profile');
      } else {
        this.restrictedAccess_grantMaster = true;
      }
    }
  }
};
