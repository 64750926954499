<template>
  <div class="col-4 offset-4 center">
    <Message :code="message.code" :errorList="message.errorList" :operationResult="message.operationResult" />
    <form class="form-login left" @submit.prevent="login">
      <div class="form-frame">
        <div class="form-group">
          <label for="email">{{ $t('login.email') }}</label>
          <input
            required
            v-model="email"
            type="text"
            class="form-control"
            id="email"
            name="email"
            aria-describedby="email"
            :placeholder="$t('login.email')"
            autocomplete="on"
          />
        </div>
        <div class="form-group">
          <label for="password">{{ $t('login.password') }}</label>
          <input
            required
            v-model="password"
            type="password"
            class="form-control"
            id="password"
            name="password"
            aria-describedby="password"
            :placeholder="$t('login.password')"
            autocomplete="on"
          />
        </div>
        <!--div class="form-group">
          <label for="password">{{ $t('login.password') }}</label>
          <input required v-model="password" type="password" class="form-control" id="password" aria-describedby="password" :placeholder="$t('login.password')">
        </div-->
        <FormGroupSelect
          :selectValue="language.selectedValue"
          :required="language.required"
          :id="language.id"
          :name="language.name"
          :optionValue="language.optionValue"
          :optionLabel="language.optionLabel"
          class="form-group"
          :class="errorFields['language'] ? 'error' : ''"
          :ariaDescribedby="language.ariaDescribedby"
          :label="language.label"
          :placeholder="language.placeholder"
          :options="language.data"
        />
        <div class="form-check">
          <input
            type="checkbox"
            v-model="rememberMe"
            class="form-check-input"
            id="remember_me"
            name="remember_me"
            value="1"
            checked
          />
          <label class="form-check-label" for="remember_me">{{ $t('login.remember_me') }}</label>
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('login.login') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { AUTH_REQUEST } from '@/store/actions/auth';
import { MESSAGE_ERROR_SET, MESSAGE_ERROR_LIST_SET } from '@/store/actions/message';
import { PREFERENCES_REQUEST } from '@/store/actions/preferences';
import { ROLES_REQUEST } from '@/store/actions/role';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import Message from '@/components/Message';
import { config } from '@/setup/config';
import { loadLanguageAsync } from '@/setup/i18n-setup';
import { mapGetters } from 'vuex';
import formMixin from '@/mixins/form';

export default {
  name: 'LoginBox',
  data() {
    return {
      message: {
        code: '',
        errorList: [],
        operationResult: ''
      },
      errorFields: [],
      language: {
        id: 'language',
        name: 'language',
        optionValue: 'id',
        optionLabel: 'name',
        required: 'required',
        ariaDescribedby: 'language',
        label: 'login.language',
        placeholder: null,
        data: [],
        selectedValue: config.language.defaultLanguage
      },
      email: '',
      password: null,
      rememberMe: true
    };
  },
  components: {
    Message,
    FormGroupSelect
  },
  mixins: [formMixin],
  computed: {
    ...mapGetters(['isCharacterLoaded'])
  },
  mounted() {
    this.populateLanguages();
  },
  methods: {
    login() {
      const { email, password, language, rememberMe } = this;
      const formData = new FormData();
      formData.append('email', email);
      formData.append('password', password);
      formData.append('language', language.selectedValue);
      formData.append('rememberMe', rememberMe);

      this.$store
        .dispatch(AUTH_REQUEST, formData)
        .then(() => {
          Promise.all([this.$store.dispatch(PREFERENCES_REQUEST), this.$store.dispatch(ROLES_REQUEST)]).then(() => {
            this.message.code = 'login_success';
            this.message.operationResult = 'success';
            if (this.isCharacterLoaded) {
              this.$router.push('/character/profile');
            } else {
              this.$router.push('/role/list');
            }
          });
        })
        .catch(err => {
          let responseMessage = err;
          if (err.response && err.response.data && err.response.data.message) {
            responseMessage = err.response.data.message;
          }

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            const errorList = this.$helpers.getErrorList(responseMessage);
            this.form_setFieldErrors(responseMessage['fields']);
            this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
          } else {
            this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
            this.$helpers.errorManager(responseMessage);
          }
        });
    },
    onChangeLanguage(value) {
      let language = value;
      if (typeof language === 'object') {
        language = value.value;
      }
      loadLanguageAsync(this.$i18n, language);
    },
    populateLanguages() {
      this.language.data = [
        {
          id: 'en',
          name: 'English'
        },
        {
          id: 'ja',
          name: '日本語'
        }
      ];
    }
  }
};
</script>

<style lang="scss" scoped>
.form-login {
  margin-bottom: 60px;
}
</style>
