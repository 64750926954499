<template>
  <div class="container item-detail" :id="entityNature + '-detail'">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <!-- ITEM DETAIL -->
      <template v-if="this.isContentReady && item">
        <div class="col-4" :key="item.id">
          <Item :ref="entityNature + '_' + item.id" :item="item" />
        </div>

        <div class="col-4 item-detail-characters">
          <h4 class="item-detail-characters-title">{{ $t('party.members') }}</h4>
          <ul class="item-detail-characters-list">
            <li v-for="member in item.characters" :key="member.id" class="item-detail-characters-list-item">
              {{ member.name }}
            </li>
          </ul>
        </div>
        <div class="col-4 col-counters">
          <PartyCounterExperiencePoints counterShape="rectangular" :partyPoints="item.experience_points" />
          <PartyCounterReputationPoints counterShape="round" :partyPoints="item.reputation_points" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const entityNature = 'party',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { PARTY_REQUEST } from '@/store/actions/party';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import Item from '@/components/Party';
import PartyCounterExperiencePoints from '@/components/Party/Counters/ExperiencePoints';
import PartyCounterReputationPoints from '@/components/Party/Counters/ReputationPoints';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized + 'Detail',
  components: {
    Item,
    PartyCounterExperiencePoints,
    PartyCounterReputationPoints
  },
  props: ['id'],
  data() {
    return {
      entityNature,
      entityNatureCapitalized,
      isContentReady: false,
      item: null
    };
  },
  computed: {
    ...mapGetters([
      'get' + entityNatureCapitalized + 'Status',
      'isCharacter',
      'isMaster',
      'is' + entityNatureCapitalized + 'Loaded'
    ])
  },
  created() {
    if (this.id && this.isMaster) {
      this.getItem();
    } else {
      if (!this.isPartyLoaded) {
        this.unwatchParty = this.$watch('isPartyLoaded', () => {
          this.unwatchParty();
          this.item = this.$store.state.party;
          this.isContentReady = true;
        });
      } else {
        this.item = this.$store.state.party;
        this.isContentReady = true;
      }
    }
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(PARTY_REQUEST, this.id)
        .then(data => {
          console.log(data);
          this.item = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.col-counters {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.item-detail-characters {
  &-title {
    font-weight: bold;
    text-transform: uppercase;
  }

  &-list {
    padding-left: 17px;
  }
}
</style>
