var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tile tile-selectable item-frame",
      on: {
        click: function($event) {
          return _vm.selectItem()
        }
      }
    },
    [
      _c("div", { staticClass: "item-image-frame" }, [
        _c("img", {
          staticClass: "item-image image-fill-frame",
          class: _vm.item.picture ? "" : "fade-image",
          attrs: {
            border: "0",
            alt: _vm.item.name,
            src: _vm.item.picture
              ? _vm.imageUrl + _vm.item.id + "/" + _vm.item.picture
              : _vm.missingImage
          }
        })
      ]),
      _c("ul", { staticClass: "item-text" }, [
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".name")))
          ]),
          _vm._v(_vm._s(_vm.item.name) + " ")
        ]),
        _vm.isCharacter
          ? _c("li", [
              _c("span", { staticClass: "item-text-label" }, [
                _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".master")))
              ]),
              _vm._v(_vm._s(_vm.masterName) + " ")
            ])
          : _vm._e(),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".game")))
          ]),
          _vm._v(_vm._s(_vm.gameName) + " ")
        ]),
        _c("li", { staticClass: "party-characters" }, [
          _c("span", { staticClass: "party-text-label" }, [
            _vm._v(
              _vm._s(_vm.$t(_vm.entityNature + ".members")) +
                " (" +
                _vm._s(_vm.item.characters.length) +
                ")"
            )
          ]),
          _c(
            "ul",
            { staticClass: "party-member-block" },
            [
              _vm._l(_vm.item.characters, function(character) {
                return [
                  _c("li", { key: character.id, staticClass: "party-member" }, [
                    _vm._v(_vm._s(character.name))
                  ])
                ]
              })
            ],
            2
          )
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".reputation_points")))
          ]),
          _vm._v(_vm._s(_vm.item.reputation_points) + " ")
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".experience_points")))
          ]),
          _vm._v(_vm._s(_vm.item.experience_points) + " ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }