var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isMasterLoaded
    ? _c("div", { staticClass: "body-view profile container-fluid" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-3 left" }, [
            _c("img", {
              staticClass: "master-image image-fill-frame",
              attrs: {
                border: "0",
                alt: _vm.master.name,
                src: _vm.master.picture
                  ? _vm.imageUrl + _vm.master.id + "/" + _vm.master.picture
                  : _vm.missingRoleImage
              }
            })
          ]),
          _c("div", { staticClass: "col-9 left" }, [
            _c("table", { staticClass: "profile-table" }, [
              _c("tbody", [
                _c("tr", [
                  _c("td", { staticClass: "profile-table-label" }, [
                    _vm._v(" " + _vm._s(_vm.$t("profile.user_name")) + " ")
                  ]),
                  _c("td", { staticClass: "profile-table-data" }, [
                    _vm._v(_vm._s(_vm.user.name))
                  ])
                ]),
                _c("tr", [
                  _c("td", { staticClass: "profile-table-label" }, [
                    _vm._v(" " + _vm._s(_vm.$t("profile.master_name")) + " ")
                  ]),
                  _c("td", { staticClass: "profile-table-data" }, [
                    _vm._v(_vm._s(_vm.master.name))
                  ])
                ])
              ])
            ])
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }