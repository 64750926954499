<template>
  <div id="stats-box" class="stats-box">
    <AnimationLoading v-if="!this.isContentReady" />
    <table class="table table-stats" v-if="!isGettingReady">
      <thead>
        <tr>
          <th class="left view-base">{{ $t('stats.stat') }}</th>
          <th class="center view-extended" v-show="isExtendedView && isPlayMode">
            {{ $t('stats.code') }}
          </th>
          <th class="center view-extended" v-show="isExtendedView && isPlayMode">
            {{ $t('stats.race_modifier') }}
          </th>
          <th class="center view-extended" v-show="isExtendedView && isPlayMode">
            {{ $t('stats.temporary_rolled_to_display') }}
          </th>
          <th class="center view-extended" v-show="isExtendedView && isPlayMode">
            {{ $t('stats.potential_rolled_to_display') }}
          </th>
          <th class="center view-base">
            {{ temporaryHeaderTitle }}
          </th>
          <th class="center view-base">
            {{ potentialHeaderTitle }}
          </th>
          <th class="center view-base">{{ $t('stats.bonus') }}</th>
          <th class="center view-rankup" v-if="isRankUpMode">
            {{ $t('stats.DE') }}
          </th>
          <th class="center view-rankup" v-if="isRankUpMode">
            {{ $t('stats.DPs_to_rank_up') }}
          </th>
          <th class="center view-rankup" v-if="isRankUpMode">
            {{ $t('stats.XPs_allocated') }}
          </th>
        </tr>
      </thead>
      <tbody v-if="isCharacterLoaded">
        <template v-for="(category, catIndex) in statCategories">
          <tr class="stats-category" :key="`category-${catIndex}`">
            <td :colspan="nVisibleCols">
              {{ category.name }}
            </td>
          </tr>
          <template v-for="(stat, statIndex) in groupedStats(category)">
            <tr
              v-if="isRankUpableStat(stat)"
              :key="`stat-${catIndex}-${statIndex}`"
              :class="{ even: statIndex % 2 === 0, odd: statIndex % 2 !== 0 }"
            >
              <td class="left view-base">{{ stat.stat }}</td>
              <td class="center view-extended" v-show="isExtendedView && isPlayMode">
                {{ stat.code }}
              </td>
              <td class="race_modifier center view-extended" v-show="isExtendedView && isPlayMode">
                {{ formatRaceModifier(stat.race_modifier) }}
              </td>
              <td class="temporary-rolled center view-extended" v-show="isExtendedView && isPlayMode">
                {{ stat.temporary_rolled }}
              </td>
              <td class="potential_rolled center view-extended" v-show="isExtendedView && isPlayMode">
                {{ getPotential(stat, 'potential_rolled') }}
              </td>
              <td class="temporary center view-base">{{ stat.temporary }}</td>
              <td class="potential center view-base">
                {{ getPotential(stat, 'potential') }}
              </td>
              <td class="center view-base">
                {{ $helpers.formatSignedNumber(stat.bonus) }}
              </td>
              <td class="center view-rankup" v-if="isRankUpMode">
                {{ stat.development_effort }}
              </td>
              <td class="center view-rankup" v-if="isRankUpMode">
                <span :class="stat.temporary * 1 < stat.potential * 1 ? '' : 'disabled'"
                  >{{ $helpers.formatNumber(developmentPointsToRankUp(stat.temporary, stat.development_effort)) }}
                </span>
              </td>
              <td class="center view-rankup" v-if="isRankUpMode">
                <span class="development_points_to_rank_up_wrapper">
                  <img
                    v-show="canRankDown(stat) && isRankUpMode"
                    class="icon icon-rank-down"
                    :src="config.mediaPath.application.icons + config.assets.icons.rank_down"
                    :alt="$t('stats.cancel_rankup')"
                    @click="rankDown(stat)"
                  />
                  <input
                    :value="isPlayMode ? stat.development_points_allocated : stat.development_points"
                    type="number"
                    step="1"
                    min="0"
                    class="form-control development_points"
                    :class="stat.temporary * 1 < stat.potential * 1 ? '' : 'disabled'"
                    :id="'development-points-' + stat.id"
                    name="development-points"
                    :aria-describedby="'development-points-' + stat.id"
                    @input="updateDevelopmentPoints($event, stat)"
                  />
                  <img
                    v-show="canRankUp(stat) && isRankUpMode"
                    class="icon icon-rank-up"
                    :src="config.mediaPath.application.icons + config.assets.icons.rank_up"
                    :alt="$t('stats.rankup')"
                    @click="rankUp(stat)"
                  />
                </span>
              </td>
            </tr>
          </template>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SCORES_CONSUME } from '@/store/actions/scores';
import { CHARACTER_STAT_PROPERTY_SET } from '@/store/actions/character';
import { config } from '@/setup/config';

export default {
  name: 'StatsBox',
  props: {
    mode: {
      type: String,
      default: null
    },
    errorFieldsInherited: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      config,
      isContentReady: false,
      nVisibleCols: 0,
      stats: []
    };
  },
  computed: {
    ...mapGetters([
      'getInstanceStatus',
      'getSystemStatus',
      'areStatsLoaded',
      'isExtendedView',
      'isMasterView',
      'isCharacterLoaded'
    ]),
    ...mapState(['character', 'system', 'scores']),
    isGettingReady() {
      return this.getSystemStatus('system') !== 'success' || !this.areStatsLoaded;
    },
    isCreationMode() {
      return this.mode === 'creation';
    },
    isPlayMode() {
      return this.mode === 'play';
    },
    isRankUpMode() {
      return this.mode === 'rankup';
    },
    potentialHeaderTitle() {
      return (this.isExtendedView || this.isMasterView) && this.isPlayMode
        ? this.$t('stats.potential_final')
        : this.$t('stats.potential');
    },
    statCategories() {
      let categories = [];
      let currCatId = null;
      if (this.stats) {
        for (let s = 0; s < this.stats.length; s++) {
          if (currCatId !== this.stats[s].category_id) {
            categories.push({
              category_id: this.stats[s].category_id,
              name: this.stats[s].category
            });
            currCatId = this.stats[s].category_id;
          }
        }
      }
      return categories;
    },
    temporaryHeaderTitle() {
      return (this.isExtendedView || this.isMasterView) && this.isPlayMode
        ? this.$t('stats.temporary_final')
        : this.$t('stats.temporary');
    }
  },
  watch: {
    isExtendedView(newValue) {
      if (this.isPlayMode) {
        if (newValue) {
          this.nVisibleCols += this.nExtendedViewCols;
        } else {
          this.nVisibleCols -= this.nExtendedViewCols;
        }
      }
    },
    isMasterView(newValue) {
      if (this.isPlayMode) {
        if (newValue) {
          this.nVisibleCols += this.nMasterViewCols;
        } else {
          this.nVisibleCols -= this.nMasterViewCols;
        }
      }
    }
  },
  mounted() {
    // populateStats could have been run in created, but in this way I can rely on one single watcher
    if (this.character.stats.length > 0) {
      this.populateStats();
      this.setTableData();
    } else {
      this.unwatchCharacterStats = this.$watch('isGettingReady', () => {
        this.unwatchCharacterStats();
        this.populateStats();
        this.setTableData();
      });
    }
  },
  methods: {
    canRankDown(stat) {
      return stat.development_points_allocated > 0;
    },
    canRankUp(stat) {
      if (stat.temporary < stat.potential) {
        const developmentPointsToRankUp = this.developmentPointsToRankUp(stat.temporary, stat.development_effort);
        return stat.development_points * 1 >= developmentPointsToRankUp * 1;
      }
      return false;
    },
    developmentPointsToRankDown(temporary, development_effort) {
      const nextRank = temporary ? temporary * 1 : 0;
      return this.developmentPointsToNextRank(nextRank, development_effort);
    },
    developmentPointsToRankUp(temporary, development_effort) {
      const nextRank = (temporary ? temporary * 1 : 0) + 1;
      return this.developmentPointsToNextRank(nextRank, development_effort);
    },
    developmentPointsToNextRank(nextRank, development_effort) {
      if (this.isCreationMode) {
        return Math.ceil(nextRank * development_effort * this.system.stat_development_cost_unit);
      } else {
        const series = [];
        for (let i = 1; i <= nextRank; i++) {
          series[i] = Math.ceil(i * development_effort * this.system.stat_development_cost_unit);
        }
        return series.reduce((a, b) => a + b) / 10;
      }
    },
    isRankUpableStat(stat) {
      return !this.isRankUpMode || !this.system.stats_no_rank_up.includes(stat.tag);
    },
    rankDown(stat) {
      if (stat.development_points_allocated > 0) {
        let developmentPointsToRankDown = this.developmentPointsToRankDown(stat.temporary, stat.development_effort) * 1;
        const consumeDevelopmentPoints = stat.development_points * 1 + developmentPointsToRankDown;

        const statsData = {
          id: stat.id,
          data: [
            {
              key: 'development_points',
              value: consumeDevelopmentPoints
            },
            {
              key: 'development_points_prechange',
              value: consumeDevelopmentPoints
            },
            {
              key: 'development_points_allocated',
              value:
                (stat.development_points_allocated ? stat.development_points_allocated * 1 : 0) -
                developmentPointsToRankDown
            },
            {
              key: 'temporary',
              value: stat.temporary * 1 - 1
            }
          ]
        };
        this.$store.commit(CHARACTER_STAT_PROPERTY_SET, statsData);
      }
      return false;
    },
    rankUp(stat) {
      if (stat.temporary < stat.potential) {
        let developmentPointsToRankUp = this.developmentPointsToRankUp(stat.temporary, stat.development_effort) * 1;
        const consumeDevelopmentPoints = stat.development_points * 1 - developmentPointsToRankUp;

        if (consumeDevelopmentPoints < 0) {
          this.$emit('error-fields-set', 'stat_' + stat.id, 'error');
          this.$store.commit(MESSAGE_ERROR_SET, 'stats_not_enough_development_points');
          return false;
        }

        const statsData = {
          id: stat.id,
          data: [
            {
              key: 'development_points',
              value: consumeDevelopmentPoints
            },
            {
              key: 'development_points_prechange',
              value: consumeDevelopmentPoints
            },
            {
              key: 'development_points_allocated',
              value:
                (stat.development_points_allocated ? stat.development_points_allocated * 1 : 0) +
                developmentPointsToRankUp
            },
            {
              key: 'temporary',
              value: stat.temporary * 1 + 1
            }
          ]
        };
        this.$store.commit(CHARACTER_STAT_PROPERTY_SET, statsData);
      }
      return false;
    },
    updateDevelopmentPoints($event, stat) {
      // NB: development_points input has no model, must be handled manually
      if (this.isRankUpMode) {
        this.updateDevelopmentPointsForRankUp($event, stat);
      }
    },
    updateDevelopmentPointsForRankUp($event, stat) {
      // NB: development_points input has no model, must be handled manually

      let developmentPointsValue = $event.target.value * 1;
      if (stat.development_points_allocated * 1 === 0 && developmentPointsValue < stat.development_points_base) {
        developmentPointsValue = stat.development_points_base;
        $event.target.value = stat.development_points_base;
        this.$store.commit(CHARACTER_STAT_PROPERTY_SET, {
          id: stat.id,
          data: [
            {
              key: 'development_points',
              value: stat.development_points_base
            }
          ]
        });
      }

      let consumeDevelopmentPoints =
        developmentPointsValue - (stat.development_points_prechange ? stat.development_points_prechange * 1 : 0);

      const scoresConsumedDevelopmentPoints = this.scores.consumed.experience_points
        ? this.scores.consumed.experience_points * 1
        : 0;

      const remainingDevelopmentPoints = this.scores.play.experience_points * 1 - scoresConsumedDevelopmentPoints;

      if (consumeDevelopmentPoints < 0) {
        this.$store.commit(CHARACTER_STAT_PROPERTY_SET, {
          id: stat.id,
          data: [
            {
              key: 'development_points',
              value: developmentPointsValue
            }
          ]
        });
      } else {
        if (remainingDevelopmentPoints === 0) {
          $event.target.value = stat.development_points_prechange;
          consumeDevelopmentPoints = 0;
        } else if (consumeDevelopmentPoints > remainingDevelopmentPoints) {
          consumeDevelopmentPoints += remainingDevelopmentPoints - consumeDevelopmentPoints;
          developmentPointsValue = stat.development_points * 1 + consumeDevelopmentPoints;
          this.$store.commit(CHARACTER_STAT_PROPERTY_SET, {
            id: stat.id,
            data: [
              {
                key: 'development_points',
                value: developmentPointsValue
              }
            ]
          });
          $event.target.value = developmentPointsValue;
        } else {
          this.$store.commit(CHARACTER_STAT_PROPERTY_SET, {
            id: stat.id,
            data: [
              {
                key: 'development_points',
                value: developmentPointsValue
              }
            ]
          });
        }
      }

      if (consumeDevelopmentPoints !== 0) {
        this.$store.commit(CHARACTER_STAT_PROPERTY_SET, {
          id: stat.id,
          data: [
            {
              key: 'development_points_prechange',
              value: stat.development_points
            }
          ]
        });

        const scores = {
          experience_points: consumeDevelopmentPoints
        };

        this.$store.commit(SCORES_CONSUME, scores);
      }
    },

    formatRaceModifier(raceModifier) {
      raceModifier = this.$helpers.formatSignedNumber(raceModifier);
      return raceModifier ? raceModifier : '';
    },
    getPotential(stat, potentialProperty) {
      return this.system.stats_without_potential.includes(stat.tag)
        ? this.$t('stats.not_applicable')
        : stat[potentialProperty];
    },
    groupedStats(currCat) {
      return this.stats.filter(function(stat) {
        return stat.category_id === currCat.category_id;
      });
    },
    // this function needs the DOM ready
    setTableData() {
      this.nBaseViewCols = this.$el.querySelectorAll('.table-stats thead .view-base').length;

      this.nExtendedViewCols = this.isPlayMode
        ? this.$el.querySelectorAll('.table-stats thead .view-extended').length
        : 0;

      this.nMasterViewCols =
        this.isMasterView && this.isPlayMode ? this.$el.querySelectorAll('.table-stats thead .view-master').length : 0;

      this.nRankUpViewCols = this.isRankUpMode
        ? this.$el.querySelectorAll('.table-stats thead .view-rankup').length
        : 0;
      this.nVisibleCols =
        this.nBaseViewCols +
        (this.isExtendedView ? this.nExtendedViewCols : 0) +
        this.nMasterViewCols +
        this.nRankUpViewCols;
    },
    populateStats() {
      this.stats = this.character.stats;
      this.stats.forEach(function(stat) {
        stat.development_points_prechange = stat.development_points * 1;
        stat.development_points_base = stat.development_points * 1;
        stat.development_points_allocated = 0;
      });
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.stats-box {
  position: relative;

  .table-stats {
    border: 1px solid $stats-table-border-color;
    margin-bottom: 50px;

    th,
    td {
      border: 0;
      padding: 3px 5px;
      vertical-align: middle;
    }

    td {
      .development_points_to_rank_up_wrapper {
        position: relative;

        input {
          font-size: 1.4rem;
        }

        .development_points {
          width: 75px;
        }
      }

      .form-control {
        display: inline-block;
      }

      .disabled {
        color: $disabled-color;
      }
    }

    thead {
      th {
        background-color: $stats-table-header-background-color;
        border-bottom: 1px solid $stats-table-border-color;
        font-size: 1.2rem;
      }
    }

    tr {
      &.even,
      &.odd {
        line-height: 3rem;
      }

      &.even {
        background-color: $stats-table-row-even-background-color;
      }

      &.stats-category {
        background-color: $stats-table-row-category-background-color;
        color: $stats-table-row-category-color;
        font-size: 1rem;
        text-transform: uppercase;
      }
    }
  }
}

.icon {
  cursor: pointer;
  display: inline-block;

  &-add {
    height: 15px;
    margin-left: 5px;
    margin-bottom: 3px;
    vertical-align: bottom;
  }

  &-add-experience-points {
    height: 21px;
    margin-bottom: 4px;
  }

  &-rank-down,
  &-rank-up {
    height: 24px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }

  &-rank-down {
    left: -32px;
    margin-right: 7px;
  }

  &-rank-up {
    margin-left: 7px;
  }
}
</style>
