import { render, staticRenderFns } from "./Scores.vue?vue&type=template&id=06185280&scoped=true&"
import script from "./Scores.vue?vue&type=script&lang=js&"
export * from "./Scores.vue?vue&type=script&lang=js&"
import style0 from "./Scores.vue?vue&type=style&index=0&id=06185280&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06185280",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("c:\\xampp\\htdocs\\syntropy-rpg\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('06185280')) {
      api.createRecord('06185280', component.options)
    } else {
      api.reload('06185280', component.options)
    }
    module.hot.accept("./Scores.vue?vue&type=template&id=06185280&scoped=true&", function () {
      api.rerender('06185280', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Character/Summary/Scores.vue"
export default component.exports