var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset5" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-frame" },
            _vm._l(_vm.categoriesToPrioritize, function(category, index) {
              return _c(
                "div",
                { key: "category-to-prioritize-" + index, staticClass: "row" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group col-12",
                      class: _vm.errorFields["age"] ? "error" : ""
                    },
                    [
                      _c(
                        "label",
                        { attrs: { for: "category_" + category.id } },
                        [_vm._v(_vm._s(category.name))]
                      ),
                      _c(
                        "table",
                        {
                          staticClass:
                            "table table-striped table-category-to-prioritize"
                        },
                        [
                          _c("thead", [
                            _c("tr", [
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("skill.subcategory")))
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(_vm._s(_vm.$t("skill.priority")))
                              ]),
                              _c("th", { attrs: { scope: "col" } }, [
                                _vm._v(
                                  _vm._s(_vm.$t("skill.development_effort"))
                                )
                              ])
                            ])
                          ]),
                          _c(
                            "draggable",
                            {
                              attrs: { tag: "tbody" },
                              on: {
                                change: function($event) {
                                  return _vm.onChangePosition(
                                    $event,
                                    category.id
                                  )
                                }
                              },
                              model: {
                                value: category.entries,
                                callback: function($$v) {
                                  _vm.$set(category, "entries", $$v)
                                },
                                expression: "category.entries"
                              }
                            },
                            _vm._l(category.entries, function(subcategory) {
                              return _c("tr", { key: subcategory.name }, [
                                _c("td", [_vm._v(_vm._s(subcategory.name))]),
                                _c("td", { attrs: { scope: "row" } }, [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        category.prioritizationData[
                                          subcategory.prioritization
                                        ].priority
                                      ) +
                                      " "
                                  )
                                ]),
                                _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.getDevelopmentEffort(
                                          category.development_effort,
                                          category.prioritizationData[
                                            subcategory.prioritization
                                          ].priority_development_effort,
                                          subcategory.development_effort
                                        )
                                      ) +
                                      " "
                                  )
                                ])
                              ])
                            }),
                            0
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            }),
            0
          ),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }