<template>
  <footer class="footer container-fluid right">
    {{ $t('footer.text', { date }) }}
  </footer>
</template>

<style lang="scss" scoped>
.footer {
  background-color: $indigoLight;
  color: $footer-color;
  margin-top: $footer-margin-top;
}
</style>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      date: ''
    };
  },
  created() {
    this.date = this.$helpers.getCurrentYear();
  }
};
</script>
