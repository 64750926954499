import { AUTH_LOGOUT } from '../actions/auth';
import { CAMPAIGN_SUCCESS } from '../actions/campaign';
import { GAME_SELECT_SUCCESS } from '../actions/game';
import {
  INSTANCE_ERROR,
  INSTANCE_REGENERATION,
  INSTANCE_CHARACTER_REQUEST,
  INSTANCE_MASTER_REQUEST,
  INSTANCE_REQUEST,
  INSTANCE_RESET,
  INSTANCE_SUCCESS
} from '../actions/instance';
import { PARTY_MASTER_SUCCESS } from '../actions/party';
import { ROLE_SELECT_RESET } from '../actions/role';
import { SCORES_SUCCESS } from '../actions/scores';
import { SETTING_SUCCESS } from '../actions/setting';
import { SYSTEM_SUCCESS } from '../actions/system';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getInstanceStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [INSTANCE_REGENERATION]: ({ dispatch }, data) => {
    if (!data.type) {
      return Promise.reject('no_role_type');
    }

    if (data.type === 'character') {
      return dispatch(INSTANCE_CHARACTER_REQUEST, data);
    }

    if (data.type === 'master') {
      return dispatch(INSTANCE_MASTER_REQUEST, data);
    }
  },
  [INSTANCE_CHARACTER_REQUEST]: ({ commit, dispatch, rootState }, data = {}) => {
    let { id, forced } = data;

    return new Promise((resolve, reject) => {
      commit(INSTANCE_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('forced', forced);

      return axios
        .post(config.sitePath.api.getInstanceCharacter + (id ? '/' + id : ''), params)
        .then(resp => {
          let data = resp.data;
          commit(GAME_SELECT_SUCCESS, data.game);
          commit(SYSTEM_SUCCESS, data.system);
          commit(SETTING_SUCCESS, data.setting);
          commit(CAMPAIGN_SUCCESS, data.campaign);
          commit(SCORES_SUCCESS, data.scores);
          commit(INSTANCE_SUCCESS);
          resolve(data);
        })
        .catch(err => {
          commit(INSTANCE_ERROR, err.response);
          dispatch(AUTH_LOGOUT);
          reject(err.response.data);
        });
    });
  },
  [INSTANCE_MASTER_REQUEST]: ({ commit, dispatch, rootState }, data = {}) => {
    // id is game id
    let { id, forced } = data;

    return new Promise((resolve, reject) => {
      commit(INSTANCE_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('forced', forced);
      return axios
        .post(config.sitePath.api.getInstanceMaster + (id ? '/' + id : ''), params)
        .then(resp => {
          let data = resp.data;
          commit(SYSTEM_SUCCESS, data.system);
          commit(SETTING_SUCCESS, data.setting);
          commit(CAMPAIGN_SUCCESS, data.campaign);
          commit(PARTY_MASTER_SUCCESS, data.party);
          commit(INSTANCE_SUCCESS);
          resolve(data.game);
        })
        .catch(err => {
          commit(INSTANCE_ERROR, err.response);
          dispatch(AUTH_LOGOUT);
          reject(err.response.data);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [INSTANCE_ERROR]: state => {
    Vue.set(state.statusVuex, 'instance', 'error');
  },
  [INSTANCE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'instance', 'loading');
  },
  [INSTANCE_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [INSTANCE_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'instance', 'success');
  },

  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
