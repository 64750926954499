<template>
  <form class="form-preferences">
    <ul class="preferences">
      <li v-for="preference in preferences.checkbox" :key="`preference-${preference.id}`">
        <input
          type="checkbox"
          class="form-check-input"
          data-type="checkbox"
          :id="'preferences_' + preference.id"
          :name="'preferences_' + preference.tag"
          :value="preference.id"
          :checked="preference.value"
          :disabled="preference.disabled"
          @change="savePreference($event)"
        />
        <label class="form-check-label" :for="'preferences_' + preference.id">{{
          $t(`preferences.${preference.tag}`)
        }}</label>
      </li>
    </ul>
  </form>
</template>

<style lang="scss" scoped>
.form-preferences {
  margin-bottom: 0;

  ul.preferences {
    display: flex;
    flex-flow: row wrap;
    list-style: none;
    margin-left: 0;
    padding-left: 1em;

    li {
      display: block;
      flex: 1 0 33.333%;
      max-width: 33.333%;
      padding: 0 15px 5px 0;
    }
  }

  .form-check-input {
    position: relative;
    margin-left: 0;
    margin-top: 0.4rem;
    vertical-align: top;
  }
}
</style>

<script>
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { PREFERENCE_UPDATE_REQUEST } from '@/store/actions/preferences';
import { mapState } from 'vuex';

export default {
  name: 'Preferences',
  data() {
    return {
      disabled: false
    };
  },
  methods: {
    savePreference($event) {
      this.disabled = true;
      this.$store
        .dispatch(PREFERENCE_UPDATE_REQUEST, {
          key: $event.target.value,
          value: $event.target.checked ? 1 : 0,
          type: $event.target.dataset.type
        })
        .then(() => {})
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        })
        .finally(() => {
          this.disabled = false;
        });
    }
  },
  computed: {
    ...mapState({
      preferences: state => {
        return state.preferences.options;
      }
    })
  }
};
</script>
