<template>
  <main class="body-view col-12 center">
    <h1 class="area_title">
      {{ $t(`role.character.create_character_dataset` + step) }}
    </h1>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row main-content">
      <div class="col-9">
        <div class="row">
          <div class="col-6">
            <CharacterSummary :showModifiers="false" />
          </div>
          <div class="col-6">
            <CharacterSummaryScores />
          </div>
        </div>
        <div class="row">
          <div class="dataset col-12">
            <Dataset ref="dataset" />
          </div>
        </div>
      </div>
      <div id="summary" class="summary col-3 sticky">
        <div id="sticky-counters" class="sticky sticky-counters">
          <CharacterCounterCategoryRanks />
          <CharacterCounterWhiteLabelRanks />
          <CharacterCounterFreeRanks />
          <CharacterCounterHobbyRanks />
          <CharacterCounterDevelopmentPoints />
        </div>
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped></style>

<script>
import characterCreationStepCheckMixin from '@/mixins/characterCreationStepCheck';
import CharacterCounterCategoryRanks from '@/components/Character/Counters/CategoryRanks';
import CharacterCounterDevelopmentPoints from '@/components/Character/Counters/DevelopmentPoints';
import CharacterCounterFreeRanks from '@/components/Character/Counters/FreeRanks';
import CharacterCounterHobbyRanks from '@/components/Character/Counters/HobbyRanks';
import CharacterCounterWhiteLabelRanks from '@/components/Character/Counters/WhiteLabelRanks';
import CharacterSummary from '@/components/Character/Summary';
import CharacterSummaryScores from '@/components/Character/Summary/Scores';
import Dataset from '@/components/Character/Dataset8';
import Message from '@/components/Message';

const step = '8';

export default {
  name: 'CharacterCreateStep' + step + 'View',
  components: {
    Message,
    Dataset,
    CharacterSummary,
    CharacterSummaryScores,
    CharacterCounterFreeRanks,
    CharacterCounterHobbyRanks,
    CharacterCounterCategoryRanks,
    CharacterCounterWhiteLabelRanks,
    CharacterCounterDevelopmentPoints
  },
  mixins: [characterCreationStepCheckMixin],
  data() {
    return {
      step: step
    };
  }
};
</script>
