import Vue from 'vue';
import { MESSAGE_RESET } from '@/store/actions/message';

export default {
  methods: {
    form_onErrorFields(field, value) {
      if (!field) {
        this.errorFields = [];
      } else if (!value) {
        delete this.errorFields[field];
      } else {
        this.errorFields[field] = value;
      }
    },
    form_setFieldErrors(fields) {
      if (fields) {
        for (let m = 0; m < fields.length; m++) {
          Vue.set(this.errorFields, fields[m], true);
        }
      }
    },
    form_resetResponse() {
      this.$store.commit(MESSAGE_RESET);
      this.errorFields = [];
    },
    form_scrollToMessage() {
      setTimeout(function() {
        const $message = document.getElementById('message');
        if ($message) {
          const top = window.scrollY + $message.getBoundingClientRect().top - 10;
          window.scrollTo(0, top);
        }
      }, 0);
    }
  }
};
