var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset4" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-frame" },
            _vm._l(_vm.trainingPackages, function(trainingPackage, index) {
              return _c(
                "div",
                { key: "training-package-" + index, staticClass: "row" },
                [
                  _c("FormGroupSelect", {
                    staticClass: "form-group col-12",
                    class: _vm.errorFields["trainingPackages"] ? "error" : "",
                    attrs: {
                      ariaDescribedby: "training_packages_" + index,
                      collectionName:
                        _vm.trainingPackages[index].collectionName,
                      data: { index: index },
                      id: "training_packages_" + index,
                      label: _vm.trainingPackages[index].label,
                      labelParams: _vm.trainingPackages[index].labelParams,
                      name: _vm.trainingPackages[index].name,
                      onChangeCallback: _vm.onChangeTrainingPackage,
                      optionLabel: _vm.trainingPackages[index].optionLabel,
                      options: _vm.trainingPackages[index].data,
                      optionValue: _vm.trainingPackages[index].optionValue,
                      placeholder: _vm.trainingPackages[index].placeholder,
                      required: _vm.trainingPackages[index].required,
                      selectValue: _vm.trainingPackages[index].selectedValue
                    }
                  })
                ],
                1
              )
            }),
            0
          ),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }