/************************************************************************
This mixin is meant only to contain the shared methods by the committed
and scheduled action lists
/***********************************************************************/

import { ACTIONS_REQUEST } from '@/store/actions/action';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';

export default {
  computed: {
    committedActions() {
      const self = this;
      if (this.$store.state.action.commitmentList) {
        return this.$store.state.action.commitmentList.filter(function(action) {
          if (self.modeCharacter) {
            return !!action.id && !!action.character_id && action.character_id === self.modeCharacter;
          }

          return !!action.id;
        });
      }

      return [];
    },
    scheduledActions() {
      if (this.$store.state.action.scheduleList) {
        return this.$store.state.action.scheduleList;
      }

      return [];
    }
  },
  methods: {
    getScheduledActionModifier(action, forceZero = false) {
      if (action.modifiers.length === 0) {
        if (action.modifier == null || action.modifier === '') {
          if (action.customModifier == null || action.customModifier === '') {
            return forceZero ? 0 : '-';
          }

          //return action.customModifier * 1;
          return action.customModifier * 1;
        }

        return action.modifier;
      }

      let totalModifier = 0;

      for (const modifier of action.modifiers) {
        totalModifier += modifier.value * 1;
      }

      totalModifier += action.customModifier * 1;

      return totalModifier;
    },
    getSkillName(action) {
      if (action.hier && action.hier.length > 0) {
        const nameChainToken = ' -> ';
        let skillName = '';

        for (let i = action.hier.length - 1; i >= 0; i--) {
          const level = action.hier[i];

          if (level.name) {
            skillName += (i < action.hier.length - 1 ? nameChainToken : '') + level.name;
          }
        }

        return skillName;
      }

      return action.name || action.skill_name;
    },
    populateActions(forced = false) {
      console.log('populateActions');
      if (!this.$store.state.action.statusVuex.actions || forced) {
        console.log('called populateActions');
        this.$store
          .dispatch(ACTIONS_REQUEST)
          .then(() => {
            this.$nextTick(() => {
              this.isContentReady = true;
            });
          })
          .catch(err => {
            this.$store.commit(MESSAGE_ERROR_SET, err);
            this.$helpers.errorManager(err);
          });
      } else {
        this.isContentReady = true;
      }
    }
  }
};
