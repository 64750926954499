<template>
  <CounterPoints :className="className" :currentPoints="currentPoints" :label="label" :totalPoints="totalPoints" />
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import CounterPoints from '@/components/Counters/Points';

export default {
  name: 'CharacterCounterHitPoints',
  data() {
    return {
      className: 'character-hit-points',
      label: 'hit_points'
    };
  },
  components: {
    CounterPoints
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'scores']),
    currentPoints() {
      return this.scores.play.hit_points;
    },
    totalPoints() {
      return this.isCharacterLoaded ? this.character.scores.game.hit_points : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.counter {
  @include round-counter(
    $character-hit-points-counter-bg-color,
    $character-hit-points-counter-border-color,
    $character-hit-points-counter-font-color,
    $point-counter-size
  );
}
</style>
