/************************************************************************
This mixin is meant to handle all the calculation usefull to calculate
the final score of a skill roll.
The functionalities are not provided as plugin because not necessary 
globally
/***********************************************************************/

import formulasMixin from '@/mixins/formulas';
import { CHARACTER_SKILL_REQUEST, CHARACTER_GAME_SKILL_REQUEST, GAME_SKILL_REQUEST } from '@/store/actions/skill';
import { ACTION_COMMIT, ACTION_SCHEDULE, ACTION_PARTY_EXECUTE } from '@/store/actions/action';
//import { EventBus } from '@/utils/eventBus';

export default {
  mixins: [formulasMixin],
  methods: {
    /*        
    commit: do not roll neither initiative not skill (just add action to the queue)
    execute: roll initiative and skill  
    schedule: roll only initiative
    */
    async rollResolver_execute(character, skill, type, subtype, bonus) {
      /*
      In some cases the skill bonus can be already available, there is no need to recalculate it.
      "skillId" is used only when the skill data is not available and must be retrieved

      1) get skill bonus if not provided
      2) get equipment bonus (this part can't be implemented yet)
      3) roll dice for initiative
      4) roll dice for skill
      */
      bonus = await this.rollResolver_getCharacterSkillBonus(character, skill, bonus);
      //console.log('bonus:', bonus);
      const rolledDice = this.formulas_rollOpenDice(100, 1);
      //console.log('roll dice:', rolledDice);
      let scored = 0;
      for (let roll of rolledDice) {
        scored += roll;
      }
      //console.log('scored:', scored);

      const data = {
        character,
        skill,
        initiative: 13, // da calcolare!!!
        rolledDice: rolledDice.join('|'),
        scored,
        bonus,
        game: this.$store.state.game.id,
        campaign: this.$store.state.campaign.id,
        status: 'pending'
      };

      return this.$store.dispatch(ACTION_COMMIT, data).then(resp => {
        console.log('rollResolver_execute', resp);
        return resp;
      });
    },
    async rollResolver_commit(characterId, skill, characterSkillId = null) {
      if (!characterId || (!skill && !characterSkillId)) {
        return false;
      }

      /*
      In some cases the skill bonus can be already available, there is no need to recalculate it
      1) get skill bonus if not provided
      2) get equipment bonus (this part can't be implemented yet)
      3) roll dice for initiative
      */

      if (!skill && characterSkillId) {
        skill = await this.$store.dispatch(CHARACTER_SKILL_REQUEST, (characterSkillId = null));
      }

      const initiativeBonus = skill.stat_initiative_bonus;
      const initiativeRoll = this.formulas_rollInitiative();
      const skillBonus = this.formulas_totalBonus(skill);
      const data = {
        characterId,
        characterSkill: skill.id,
        gameSkill: skill.game_skill,
        initiativeBonus,
        initiativeRoll,
        skillBonus,
        game: this.$store.state.game.id,
        campaign: this.$store.state.campaign.id,
        status: 'pending'
      };

      return this.$store.dispatch(ACTION_COMMIT, data).then(resp => {
        return resp;
      });
    },
    async rollResolver_schedule(gameId, skill, gameSkillId = null) {
      if (!gameId || (!skill && !gameSkillId)) {
        return false;
      }

      if (!skill && gameSkillId) {
        skill = await this.$store.dispatch(GAME_SKILL_REQUEST, gameSkillId);
      }

      const data = {
        game: gameId,
        gameSkill: skill.id,
        campaign: this.$store.state.campaign.id,
        status: 'pending'
      };

      return this.$store.dispatch(ACTION_SCHEDULE, data).then(resp => {
        return resp;
      });
    },
    async rollResolver_partyExecute(gameId, skill, partyCharacterIds, gameSkillId = null, partyId = null) {
      if (!gameId || (!skill && !gameSkillId) || (!partyCharacterIds && !partyId)) {
        return false;
      }

      if (!skill && gameSkillId) {
        skill = await this.$store.dispatch(GAME_SKILL_REQUEST, gameSkillId);
      }

      if (!partyCharacterIds && partyId) {
        // get party character ids
        //partyCharacterIds = await this.$store.dispatch(XXXX, (characterSkillId = null));
      }

      const data = {
        game: gameId,
        gameSkill: skill.id,
        campaign: this.$store.state.campaign.id,
        status: 'resolved'
      };

      const characterActions = [];

      for (let characterId of partyCharacterIds) {
        const characterData = {
          character: characterId,
          gameSkill: skill.id
        };
        const characterSkill = await this.$store.dispatch(CHARACTER_GAME_SKILL_REQUEST, characterData);
        const characterAction = {
          character: characterId,
          initiativeBonus: characterSkill.stat_initiative_bonus,
          initiativeRoll: this.formulas_rollInitiative(),
          skillBonus: this.formulas_totalBonus(characterSkill)
        };

        characterActions.push(characterAction);
      }

      data.actions = characterActions;

      console.log(data);

      return this.$store.dispatch(ACTION_PARTY_EXECUTE, data).then(resp => {
        return resp;
      });
    },
    rollResolver_calculateRollScore(rolledDice) {
      const rolledDiceArray = Array.isArray(rolledDice) ? rolledDice : rolledDice.split('|');
      return rolledDiceArray.reduce((a, b) => a * 1 + b * 1, 0);
    }
    /*
    rollResolver_getCharacterSkill(skillId) {
      if (!skillId) {
        return false;
      }
      
      if (skill) {
        return this.formulas_totalBonus(skill);
      }

      return this.$store
        .dispatch(CHARACTER_SKILL_REQUEST, skillId)
        .then(skill => {
          return skill;
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    },
    */
  }
};
