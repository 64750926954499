// eslint-disable-promise/param-names */
import {
  AUTH_ERROR,
  AUTH_ERROR_TOKEN,
  AUTH_LOGOUT,
  AUTH_REQUEST,
  AUTH_RESET,
  AUTH_SUCCESS,
  AUTH_REMEMBERME_LOGIN,
  AUTH_TOKEN_REQUEST
} from '../actions/auth';
import { PREFERENCE_UPDATE_REQUEST } from '../actions/preferences';
import { GAME_SELECT_ERROR, GAME_SELECT_RESET } from '../actions/game';
import { ROLE_SELECT_ERROR, ROLE_SELECT_RESET } from '../actions/role';
import { USER_AUTH_SUCCESS } from '../actions/user';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import router from '@/router';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  isAuthenticated: false,
  language: localStorage.getItem(config.storage.language) || undefined,
  rememberMe:
    localStorage.getItem(config.storage.rememberMe) === true ||
    localStorage.getItem(config.storage.rememberMe) === 'true',
  status: '',
  token: localStorage.getItem(config.storage.token) || undefined
};

const getters = {
  getAuthenticationStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  // exception: state would be enough but this keeps simmetry and it's a good shorthand
  isAuthenticated: state => state.isAuthenticated
};

const actions = {
  [AUTH_LOGOUT]: ({ commit, dispatch }) => {
    return new Promise((resolve, reject) => {
      return axios
        .post(config.sitePath.api.logout)
        .catch(err => {
          commit(AUTH_ERROR, err.response);
          commit(ROLE_SELECT_ERROR);
          commit(GAME_SELECT_ERROR);
          reject(err);
        })
        .finally(resp => {
          commit(AUTH_LOGOUT);
          dispatch(ROLE_SELECT_RESET);
          commit(GAME_SELECT_RESET);
          resolve(resp);
          router.push('/login').catch(err => {
            // eslint-disable-next-line no-console
            console.log('router push ignored error (auth)', err);
          });
        });
    });
  },
  [AUTH_REQUEST]: ({ commit, dispatch }, formData) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      return axios
        .post(config.sitePath.api.login, formData)
        .then(resp => {
          let data = resp.data;
          data.rememberMe = !!data.rememberMe;
          dispatch(PREFERENCE_UPDATE_REQUEST, {
            tag: 'language',
            value: data.language
          });
          commit(AUTH_RESET);
          commit(AUTH_SUCCESS, data);
          commit(USER_AUTH_SUCCESS, data);
          resolve(data);
        })
        .catch(err => {
          commit(AUTH_ERROR, err.response);
          reject(err);
        });
    });
  },
  [AUTH_REMEMBERME_LOGIN]: ({ dispatch, state, rootGetters }) => {
    return new Promise((resolve, reject) => {
      const token = state.token;
      // Only if the remember me and the token are present the account can be refetched
      if (state.rememberMe !== true || !token) {
        console.log('no stored credentials');
        reject('no stored credentials');
      }

      if (rootGetters.isUserLoaded) {
        console.log('User already loaded...strange...logout');
        return dispatch(AUTH_LOGOUT);
      }

      // 1) Get user from token
      return dispatch(AUTH_TOKEN_REQUEST, {
        token,
        language: state.language
      }).then(() => {
        resolve();
      });
    });
  },
  [AUTH_TOKEN_REQUEST]: ({ commit }, storedData) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      let params = new URLSearchParams();
      params.append('token', storedData.token);
      params.append('language', storedData.language);
      return axios
        .post(config.sitePath.api.autologin, params)
        .then(resp => {
          let data = resp.data;
          data.rememberMe = !!data.rememberMe;

          commit(AUTH_RESET);
          commit(AUTH_SUCCESS, data);
          commit(USER_AUTH_SUCCESS, data);
          resolve(data);
        })
        .catch(err => {
          commit(AUTH_ERROR_TOKEN, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_ERROR]: state => {
    localStorage.clear();

    Object.keys(state).forEach(function(key) {
      state[key] = '';
    });

    state.status = 'error';
    state.isAuthenticated = false;
  },
  [AUTH_ERROR_TOKEN]: state => {
    localStorage.removeItem(config.storage.token);
    state.status = 'error';
    state.isAuthenticated = false;
  },
  [AUTH_LOGOUT]: state => {
    localStorage.clear();
    Object.keys(state).forEach(function(key) {
      state[key] = '';
    });
    state.isAuthenticated = false;
  },
  [AUTH_REQUEST]: state => {
    state.status = 'loading';
  },
  [AUTH_RESET]: state => {
    Object.keys(state).forEach(function(key) {
      state[key] = '';
    });
    state.isAuthenticated = false;
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = 'success';
    state.isAuthenticated = true;
    state.token = resp.token;
    state.rememberMe = !!resp.rememberMe;

    if (state.rememberMe === true) {
      localStorage.setItem(config.storage.token, resp.token);
      localStorage.setItem(config.storage.rememberMe, !!resp.rememberMe);
    }
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
