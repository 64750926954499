// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/******************/\n/*** COMPONENTS ***/\n/******************/\n/******************/\n/*** COMPONENTS ***/\n/******************/\n.header-menu {\n  font-size: 1.4rem;\n}\n.header-menu-left {\n    float: left;\n}\n.header-menu-right {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    float: right;\n    -webkit-box-pack: end;\n        -ms-flex-pack: end;\n            justify-content: flex-end;\n    list-style: none;\n}\n.header-menu-right > li {\n      display: block;\n      padding: 10px 20px;\n}\n.header-submenu > ul {\n  padding: 0;\n}\n.header-submenu-option {\n  padding: 0;\n  font-size: 1.4rem;\n}\n.header-submenu a {\n  text-decoration: none;\n}\n.header-submenu a:hover {\n    text-decoration: none;\n}\n", ""]);
// Exports
exports.locals = {
	"role-character-image-src": "\"/application/assets/images/placeholders/character.jpg\"",
	"role-master-image-src": "\"/application/assets/images/placeholders/master.jpg\"",
	"game-image-src": "\"/application/assets/images/placeholders/game.jpg\"",
	"system-image-src": "\"/application/assets/images/placeholders/system.jpg\"",
	"setting-image-src": "\"/application/assets/images/placeholders/setting.jpg\"",
	"campaign-image-src": "\"/application/assets/images/placeholders/campaign.jpg\"",
	"campaign-game-image-src": "\"/application/assets/images/placeholders/campaign_game.png\"",
	"party-image-src": "\"/application/assets/images/placeholders/party.jpg\"",
	"xl-max-break-point": "1599px",
	"xxl-min-break-point": "1600px"
};
module.exports = exports;
