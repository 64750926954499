var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "character-dataset7" }, [
    _c(
      "form",
      {
        staticClass: "character-form left",
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.onSubmit($event)
          }
        }
      },
      [
        _c(
          "SkillList",
          _vm._g(
            {
              ref: "skillList",
              attrs: {
                mode: "creation",
                errorFieldsInherited: _vm.errorFields
              },
              on: { "error-fields-set": _vm.form_onErrorFields }
            },
            _vm.$listeners
          )
        ),
        _c("div", { staticClass: "center" }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", attrs: { type: "submit" } },
            [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
          )
        ])
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }