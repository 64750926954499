var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "actions", attrs: { id: _vm.entityNature + "List" } },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      this.isContentReady
        ? [
            _c(
              "table",
              { staticClass: "table table-action table-action-scheduled" },
              [
                _c("thead", [
                  _c("tr", [
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.action")))]
                    ),
                    _c(
                      "th",
                      {
                        staticClass: "center table-cell-small",
                        attrs: { scope: "col" }
                      },
                      [_vm._v(_vm._s(_vm.$t("action.maneuver_type_subtype")))]
                    ),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.modifier")))]
                    ),
                    _c(
                      "th",
                      { staticClass: "center", attrs: { scope: "col" } },
                      [_vm._v(_vm._s(_vm.$t("action.status")))]
                    ),
                    _vm.isMaster
                      ? _c("th", {
                          staticClass: "center",
                          attrs: { scope: "col" }
                        })
                      : _vm._e()
                  ])
                ]),
                _c(
                  "tbody",
                  [
                    _vm._l(_vm.scheduledActions, function(
                      scheduledAction,
                      scheduledActionIndex
                    ) {
                      return [
                        _c(
                          "tr",
                          {
                            key: "scheduled-action-" + scheduledActionIndex,
                            staticClass: "scheduled-action",
                            class: {
                              "selected-scheduled-action":
                                scheduledAction.id ===
                                _vm.selectedScheduledActionId
                            },
                            on: {
                              click: function($event) {
                                return _vm.selectScheduledAction(
                                  scheduledAction
                                )
                              }
                            }
                          },
                          [
                            _c("td", { staticClass: "skill-name" }, [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.getSkillName(scheduledAction)) +
                                  " "
                              ),
                              _vm.isMaster &&
                              !_vm.isScheduledActionResolved(scheduledAction)
                                ? _c("img", {
                                    staticClass: "icon icon-remove-circle",
                                    attrs: {
                                      src:
                                        _vm.config.mediaPath.application.icons +
                                        _vm.config.assets.icons.remove_circle,
                                      alt: _vm.$t("action.delete_action")
                                    },
                                    on: {
                                      click: function($event) {
                                        $event.stopPropagation()
                                        return _vm.confirmDeleteScheduledAction(
                                          scheduledAction.id
                                        )
                                      }
                                    }
                                  })
                                : _vm._e()
                            ]),
                            _c(
                              "td",
                              { staticClass: "maneuver-type-subtype center" },
                              [
                                _vm._v(
                                  " " + _vm._s(scheduledAction.maneuverType)
                                ),
                                scheduledAction.maneuverSubtype
                                  ? _c("span", [
                                      _vm._v("/"),
                                      _c(
                                        "span",
                                        { staticClass: "maneuver-subtype" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              scheduledAction.maneuverSubtype
                                            )
                                          )
                                        ]
                                      )
                                    ])
                                  : _vm._e()
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "maneuver-modifier center" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "maneuver-modifier-value" },
                                  [
                                    _vm.isMaster &&
                                    !_vm.isScheduledActionResolved(
                                      scheduledAction
                                    ) &&
                                    scheduledAction.id ===
                                      _vm.selectedScheduledActionId
                                      ? _c("img", {
                                          staticClass: "icon icon-reset",
                                          attrs: {
                                            src:
                                              _vm.config.mediaPath.application
                                                .icons +
                                              _vm.config.assets.icons.reset,
                                            alt: _vm.$t(
                                              "action.reset_modifiers"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.resetScheduledActionSkillModifiers(
                                                scheduledAction
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.isMaster &&
                                    !_vm.isScheduledActionResolved(
                                      scheduledAction
                                    ) &&
                                    scheduledAction.id ===
                                      _vm.selectedScheduledActionId
                                      ? _c("img", {
                                          staticClass: "icon icon-minus",
                                          attrs: {
                                            src:
                                              _vm.config.mediaPath.application
                                                .icons +
                                              _vm.config.assets.icons.minus,
                                            alt: _vm.$t(
                                              "action.decrease_modifier"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.modifyScheduledActionSkillModifiers(
                                                scheduledAction,
                                                -5
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$helpers.formatSignedNumber(
                                            _vm.getScheduledActionModifier(
                                              scheduledAction
                                            ),
                                            true
                                          )
                                        ) +
                                        " "
                                    ),
                                    _vm.isMaster &&
                                    !_vm.isScheduledActionResolved(
                                      scheduledAction
                                    ) &&
                                    scheduledAction.id ===
                                      _vm.selectedScheduledActionId
                                      ? _c("img", {
                                          staticClass: "icon icon-plus",
                                          attrs: {
                                            src:
                                              _vm.config.mediaPath.application
                                                .icons +
                                              _vm.config.assets.icons.plus,
                                            alt: _vm.$t(
                                              "action.increase_modifier"
                                            )
                                          },
                                          on: {
                                            click: function($event) {
                                              $event.stopPropagation()
                                              return _vm.modifyScheduledActionSkillModifiers(
                                                scheduledAction,
                                                5
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _c("td", { staticClass: "center status" }, [
                              _vm._v(
                                _vm._s(
                                  _vm.getScheduledActionStatus(scheduledAction)
                                )
                              )
                            ]),
                            _vm.isMaster
                              ? _c("td", { staticClass: "center" }, [
                                  !_vm.isScheduledActionResolved(
                                    scheduledAction,
                                    true
                                  )
                                    ? _c("img", {
                                        staticClass: "icon icon-play",
                                        attrs: {
                                          src:
                                            _vm.config.mediaPath.application
                                              .icons +
                                            _vm.config.assets.icons.play,
                                          alt: _vm.$t("action.play_action"),
                                          title: _vm.$t("action.play_action")
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.playSkillAction(
                                              scheduledAction
                                            )
                                          }
                                        }
                                      })
                                    : !_vm.isActionConcluded(
                                        scheduledAction.status
                                      )
                                    ? _c("img", {
                                        staticClass: "icon icon-conclude",
                                        attrs: {
                                          src:
                                            _vm.config.mediaPath.application
                                              .icons +
                                            _vm.config.assets.icons.archive,
                                          alt: _vm.$t("action.conclude_action"),
                                          title: _vm.$t(
                                            "action.conclude_action"
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.concludeAction(
                                              scheduledAction.id
                                            )
                                          }
                                        }
                                      })
                                    : _vm._e()
                                ])
                              : _vm._e()
                          ]
                        )
                      ]
                    })
                  ],
                  2
                )
              ]
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }