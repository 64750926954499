<template>
  <div class="counter" :class="className + '-counter'" v-if="isRoleLoaded()">
    <span class="counter-label">{{ $t('counters.' + label) }}</span>
    <span class="counter-actual" :class="currentPoints < 0 ? 'negative' : ''">{{
      $helpers.formatNumber(currentPoints)
    }}</span>
    <span v-if="totalPoints != null && totalPoints !== ''" class="counter-total"
      >/{{ $helpers.formatNumber(totalPoints) }}</span
    >
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'CounterPoints',
  props: ['label', 'className', 'currentPoints', 'totalPoints'],
  computed: {
    ...mapGetters(['isRoleLoaded'])
  }
};
</script>
