var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CounterPoints", {
    attrs: {
      className: _vm.className,
      currentPoints: _vm.currentPoints,
      label: _vm.label,
      totalPoints: _vm.totalPoints
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }