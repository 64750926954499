<template>
  <div v-if="restrictedAccess_grantMaster" class="body-view col-12 center">
    <h1 class="area_title">
      {{ $t(`game.create_game`) }}
    </h1>
    <Message />
    <form class="form-game-create left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="form-group required" :class="errorFields['name'] ? 'error' : ''">
          <label for="name">{{ $t('game.name') }}</label>
          <input
            required
            v-model="name"
            type="text"
            class="form-control"
            id="name"
            name="name"
            aria-describedby="name"
            :placeholder="$t('game.name')"
          />
        </div>
        <FormGroupSelect
          :selectValue="system.selectedValue"
          :required="system.required"
          :id="system.id"
          :name="system.name"
          :optionValue="system.optionValue"
          :optionLabel="system.optionLabel"
          class="form-group"
          :class="errorFields['system'] ? 'error' : ''"
          :ariaDescribedby="system.ariaDescribedby"
          :label="system.label"
          :placeholder="system.placeholder"
          :options="system.data"
        />
        <FormGroupSelect
          :selectValue="setting.selectedValue"
          :required="setting.required"
          :id="setting.id"
          :name="setting.name"
          :optionValue="setting.optionValue"
          :optionLabel="setting.optionLabel"
          class="form-group"
          :class="errorFields['setting'] ? 'error' : ''"
          :ariaDescribedby="setting.ariaDescribedby"
          :label="setting.label"
          :placeholder="setting.placeholder"
          :options="setting.data"
        />

        <div class="row">
          <div class="form-group col-6 required" :class="errorFields['starting_datetime'] ? 'error' : ''">
            <label for="starting_datetime"
              >{{ $t('game.starting_datetime') }} (<span class="label-data-format">{{
                $t('game.datetime_format')
              }}</span
              >)</label
            >
            <input
              required
              v-model="starting_datetime"
              type="text"
              class="form-control"
              id="starting_datetime"
              name="starting_datetime"
              aria-describedby="starting_datetime"
            />
          </div>
          <div class="form-group col-6" :class="errorFields['ending_datetime'] ? 'error' : ''">
            <label for="ending_datetime"
              >{{ $t('game.ending_datetime') }} (<span class="label-note">{{ $t('game.datetime_format') }}</span
              >)</label
            >
            <input
              v-model="ending_datetime"
              type="text"
              class="form-control"
              id="ending_datetime"
              name="ending_datetime"
              aria-describedby="ending_datetime"
            />
          </div>
        </div>

        <div class="row">
          <div class="form-group col-6" :class="errorFields['starting_grace_points'] ? 'error' : ''">
            <label for="starting_grace_points">{{ $t('game.starting_grace_points') }}</label>
            <input
              v-model="starting_grace_points"
              type="text"
              class="form-control"
              id="starting_grace_points"
              name="starting_grace_points"
              aria-describedby="starting_grace_points"
              :placeholder="$t('game.starting_grace_points')"
            />
          </div>
          <div class="form-group col-6" :class="errorFields['starting_reputation_points'] ? 'error' : ''">
            <label for="starting_reputation_points">{{ $t('game.starting_reputation_points') }}</label>
            <input
              v-model="starting_reputation_points"
              type="text"
              class="form-control"
              id="starting_reputation_points"
              name="starting_reputation_points"
              aria-describedby="starting_reputation_points"
              :placeholder="$t('game.starting_reputation_points')"
            />
          </div>
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.create') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET, MESSAGE_ERROR_LIST_SET, MESSAGE_SUCCESS_SET } from '@/store/actions/message';
import { GAME_CREATION_REQUEST } from '@/store/actions/game';
import { SYSTEMS_REQUEST } from '@/store/actions/system';
import { SETTINGS_REQUEST } from '@/store/actions/setting';
import Message from '@/components/Message';
import FormGroupSelect from '@/components/Form/FormGroupSelect';

export default {
  name: 'GameCreateView',
  components: {
    Message,
    FormGroupSelect
  },
  mixins: [formMixin, restrictedAccessMixin],
  data() {
    return {
      errorFields: [],
      name: '',
      starting_datetime: '',
      ending_datetime: '',
      starting_grace_points: 0,
      starting_reputation_points: 0,
      system: {
        id: 'system',
        name: 'system',
        optionValue: 'id',
        optionLabel: 'name',
        required: 'required',
        ariaDescribedby: 'system',
        label: 'global.system',
        placeholder: null,
        data: [],
        selectedValue: ''
      },
      setting: {
        id: 'setting',
        name: 'setting',
        optionValue: 'id',
        optionLabel: 'name',
        required: 'required',
        ariaDescribedby: 'setting',
        label: 'global.setting',
        placeholder: null,
        data: [],
        selectedValue: ''
      }
    };
  },
  mounted() {
    this.populateSystems();
    this.populateSettings();
  },
  methods: {
    populateSettings() {
      this.$store
        .dispatch(SETTINGS_REQUEST)
        .then(data => {
          this.setting.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateSystems() {
      this.$store
        .dispatch(SYSTEMS_REQUEST)
        .then(data => {
          this.system.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    onSubmit() {
      const {
        name,
        system,
        setting,
        starting_datetime,
        ending_datetime,
        starting_grace_points,
        starting_reputation_points
      } = this;
      const formData = new FormData();
      formData.append('name', name);
      formData.append('system', system.selectedValue);
      formData.append('setting', setting.selectedValue);
      formData.append('starting_datetime', starting_datetime);
      formData.append('ending_datetime', ending_datetime);
      formData.append('starting_grace_points', starting_grace_points);
      formData.append('starting_reputation_points', starting_reputation_points);

      this.form_resetResponse();

      this.$store
        .dispatch(GAME_CREATION_REQUEST, formData)
        .then(data => {
          // const id = data.response // not need at the moment
          const responseMessage = data.responseMessage;

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            this.$store.commit(MESSAGE_ERROR_LIST_SET, responseMessage);
          } else {
            this.$router.push('/game/list');
            this.$store.commit(MESSAGE_SUCCESS_SET);
          }
        })
        .catch(err => {
          let responseMessage = err;
          if (err.response && err.response.data && err.response.data.message) {
            responseMessage = err.response.data.message;
          }

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            const errorList = this.$helpers.getErrorList(responseMessage);
            this.form_setFieldErrors(responseMessage['fields']);
            this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
          } else {
            this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
            this.$helpers.errorManager(responseMessage);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-game-create {
  margin-top: auto;
}
</style>
