import { AUTH_LOGOUT } from '../actions/auth';
import {
  PHYSIQUE_ERROR,
  PHYSIQUE_REQUEST,
  PHYSIQUE_SUCCESS,
  RACE_RESET,
  RACES_ERROR,
  RACES_REQUEST,
  RACES_SUCCESS,
  SEX_ERROR,
  SEX_REQUEST,
  SEX_SUCCESS
} from '../actions/race';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getRaceStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [PHYSIQUE_REQUEST]: ({ commit, rootState }, id) => {
    return new Promise((resolve, reject) => {
      commit(PHYSIQUE_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getRacePhysiqueList + '/' + id, params)
        .then(resp => {
          commit(PHYSIQUE_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PHYSIQUE_ERROR, err.response);
          reject(err);
        });
    });
  },

  [RACES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(RACES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getRaceLastTierList, params)
        .then(resp => {
          commit(RACES_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(RACES_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SEX_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(SEX_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getRaceSexList, params)
        .then(resp => {
          commit(SEX_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SEX_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [PHYSIQUE_ERROR]: state => {
    Vue.set(state.statusVuex, 'physique', 'error');
  },
  [PHYSIQUE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'physique', 'loading');
  },
  [PHYSIQUE_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'physique', 'success');
  },

  [RACE_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [RACES_ERROR]: state => {
    Vue.set(state.statusVuex, 'races', 'error');
  },
  [RACES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'races', 'loading');
  },
  [RACES_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'races', 'success');
  },

  [SEX_ERROR]: state => {
    Vue.set(state.statusVuex, 'sex', 'error');
  },
  [SEX_REQUEST]: state => {
    Vue.set(state.statusVuex, 'sex', 'loading');
  },
  [SEX_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'sex', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
