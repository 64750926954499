<template>
  <CounterPoints
    :className="className"
    :currentPoints="currentPoints"
    :label="label"
    :totalPoints="totalPoints"
    @click.native="scrollToScrollableElement()"
  />
</template>

<script>
import CounterPoints from '@/components/Counters/Points';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterCounterCategoryRanks',
  components: {
    CounterPoints
  },
  data() {
    return {
      className: 'character-category-ranks',
      label: 'category_ranks',
      scrollToIndex: 0
    };
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'scores']),
    currentPoints() {
      return this.totalPoints * 1 - this.$helpers.sanitizeInteger(this.scores.consumed.category_ranks);
    },
    totalPoints() {
      return this.isCharacterLoaded ? this.character.scores.consumable.category_ranks : '';
    }
  },
  methods: {
    scrollToScrollableElement() {
      const scrollableElements = [];
      const $elements = this.$parent.$refs.dataset.$refs.skillList.$refs.categories;
      $elements.forEach($element => {
        if (this.$helpers.sanitizeInteger($element.dataset.baseRankAvailable) > 0) {
          scrollableElements.push($element.id);
        }
      });

      if (scrollableElements.length > 0) {
        if (this.scrollToIndex > scrollableElements.length - 1) {
          this.scrollToIndex = 0;
        }
        const elementId = scrollableElements[this.scrollToIndex];
        this.$helpers.scrollToElement(elementId, this.$helpers.getCreateCharacterSkillTableHeaderYOffset());
        this.scrollToIndex++;
      } else {
        this.scrollToIndex = 0;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.counter {
  @include rectangular-counter(
    $character-category-ranks-counter-bg-color,
    $character-category-ranks-counter-border-color,
    $character-category-ranks-counter-font-color
  );
}

.character-category-ranks-counter {
  cursor: pointer;
}
</style>
