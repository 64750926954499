import { AUTH_LOGOUT } from '../actions/auth';
import { USER_AUTH_SUCCESS, USER_ERROR, USER_REQUEST, USER_RESET, USER_SUCCESS } from '../actions/user';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  email: '',
  id: '',
  name: '',
  status: '',
  statusVuex: {}
};

const getters = {
  getUserStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isUserLoaded: state => !!state.id
};

const actions = {
  // Only for masters, to get a user information
  [USER_REQUEST]: ({ commit, rootState }, id) => {
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(USER_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getUser + '/' + id, params)
        .then(resp => {
          commit(USER_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(USER_ERROR);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.set(state.statusVuex, 'user', 'error');
  },

  [USER_AUTH_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'user', 'success');
    state.id = resp.id;
    state.email = resp.email;
    state.name = resp.name;
    state.language = resp.language;
    state.status = resp.status;
    localStorage.setItem(config.storage.language, resp.language);
  },
  [USER_ERROR]: state => {
    Vue.set(state.statusVuex, 'user', 'error');
  },
  [USER_REQUEST]: state => {
    Vue.set(state.statusVuex, 'user', 'loading');
  },
  [USER_RESET]: state => {
    Vue.set(state.statusVuex, 'system', 'error');
  },
  [USER_SUCCESS]: state => {
    // at the moment simple id-based user request is never used, so no data store
    Vue.set(state.statusVuex, 'user', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
