<template>
  <div class="character-dataset3">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="row">
          <FormGroupSelect
            class="form-group col-9"
            :ariaDescribedby="characterClass.ariaDescribedby"
            :class="errorFields['characterClass'] ? 'error' : ''"
            :id="characterClass.id"
            :label="characterClass.label"
            :name="characterClass.name"
            :onChangeCallback="characterClass.onChange"
            :optionLabel="characterClass.optionLabel"
            :options="characterClass.data"
            :optionValue="characterClass.optionValue"
            :placeholder="characterClass.placeholder"
            :required="characterClass.required"
            :selectValue="characterClass.selectedValue"
          />
          <FormGroupSelect
            v-if="characterClass.selectedValue && !isClassMagic"
            class="form-group col-3"
            :ariaDescribedby="selectableRealm.ariaDescribedby"
            :class="errorFields['selectableRealm'] ? 'error' : ''"
            :id="selectableRealm.id"
            :label="selectableRealm.label"
            :name="selectableRealm.name"
            :optionLabel="selectableRealm.optionLabel"
            :options="selectableRealm.data"
            :optionValue="selectableRealm.optionValue"
            :placeholder="selectableRealm.placeholder"
            :required="characterClass.selectedValue && !isClassMagic ? 'required' : false"
            :selectValue="selectableRealm.selectedValue"
          />
        </div>

        <div class="form-group required" :class="errorFields['stats'] ? 'error' : ''">
          <label for="stats">{{ $t('character.stats') }}</label>
          <StatsForm ref="statsForm" :errorFieldsInherited="errorFields" @error-fields-set="form_onErrorFields" />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_3_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
import { CLASSES_REQUEST } from '@/store/actions/class';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { REALMS_REQUEST } from '@/store/actions/realm';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import StatsForm from '@/components/Stats/StatsForm';

export default {
  name: 'CharacterDataset3',
  components: {
    FormGroupSelect,
    StatsForm
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      characterClass: {
        ariaDescribedby: 'characterClass',
        data: [],
        id: 'characterClass',
        label: 'character.class',
        name: 'characterClass',
        onChange: this.onChangeClass,
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      errorFields: [],
      isContentReady: false,
      name: '',
      selectableRealm: {
        ariaDescribedby: 'selectableRealm',
        data: [],
        id: 'selectableRealm',
        label: 'character.magic_realm',
        name: 'selectableRealm',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        selectedValue: ''
      }
    };
  },
  computed: {
    isClassMagic() {
      if (this.characterClass.selectedValue) {
        const classOption = this.characterClass.data.find(
          theClass => theClass.id === this.characterClass.selectedValue
        );
        return !!+classOption.isMagic;
      }
      return false;
    }
  },
  created() {
    Promise.all([this.populateClass(), this.populateSelectableRealm()]).then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    // EVENT HANDLERS
    onChangeClass() {
      this.selectableRealm.selectedValue = '';
    },
    onSubmit() {
      const { characterClass, selectableRealm } = this;
      const statsData = [];

      for (let s = 0; s < this.$refs.statsForm.stats.length; s++) {
        const statObj = {
          id: this.$refs.statsForm.stats[s].id,
          tag: this.$refs.statsForm.stats[s].tag,
          temporary_rolled:
            this.$refs.statsForm.stats[s].temporary_rolled || this.$refs.statsForm.stats[s].temporary_value,
          potential_rolled:
            this.$refs.statsForm.stats[s].potential_rolled || this.$refs.statsForm.stats[s].potential_value
        };
        statsData.push(statObj);
      }

      const selectedClass = this.characterClass.data.find(theClass => theClass.id === characterClass.selectedValue);
      const realms = [];
      realms.push(selectedClass.realm1);

      if (selectedClass.realm2) {
        realms.push(selectedClass.realm2);
      }

      const formData = new FormData();
      formData.append('characterClass', characterClass.selectedValue);
      formData.append('realms', JSON.stringify(realms));
      formData.append('selectableRealm', selectableRealm.selectedValue);
      formData.append('stats', JSON.stringify(statsData));

      this.form_resetResponse();
      this.prh_dispatch(CHARACTER_CREATION_STEP_3_REQUEST, CHARACTER_REFRESH, formData, '/character/create/step4');
    },

    // FUNCTIONS
    populateClass() {
      return this.$store
        .dispatch(CLASSES_REQUEST)
        .then(data => {
          this.characterClass.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateSelectableRealm() {
      return this.$store
        .dispatch(REALMS_REQUEST)
        .then(data => {
          this.selectableRealm.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
