var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    { staticClass: "navigation container-fluid" },
    [
      _vm.isRoleLoaded() && _vm.isCharacter
        ? _c(
            "ul",
            { staticClass: "menu menu--player tabs" },
            [
              _c(
                "li",
                [
                  _c("RouterLink", { attrs: { to: "/character/profile" } }, [
                    _vm._v(_vm._s(_vm.$t("menu.character.profile")))
                  ])
                ],
                1
              ),
              _vm.isCharacterActive
                ? [
                    _c(
                      "li",
                      [
                        _c(
                          "RouterLink",
                          { attrs: { to: "/character/skills" } },
                          [_vm._v(_vm._s(_vm.$t("menu.character.skills")))]
                        )
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c("RouterLink", { attrs: { to: "/party" } }, [
                          _vm._v(_vm._s(_vm.$t("menu.character.party")))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "li",
                      [
                        _c(
                          "RouterLink",
                          { attrs: { to: "/play/action/monitor" } },
                          [
                            _vm._v(
                              _vm._s(
                                _vm.$t("menu.character.play.action.monitor")
                              )
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm.isRoleLoaded() && _vm.isMaster
        ? _c("ul", { staticClass: "menu menu--master tabs" }, [
            _c(
              "li",
              [
                _c("RouterLink", { attrs: { to: "/master/profile" } }, [
                  _vm._v(_vm._s(_vm.$t("menu.master.profile")))
                ])
              ],
              1
            )
          ])
        : _vm._e(),
      _vm.isMasterLoaded && _vm.isMaster
        ? [
            _c(
              "ul",
              { staticClass: "menu menu--master submenu submenu--all tabs" },
              [
                _c("li", [
                  _c("span", [_vm._v(_vm._s(_vm.$t("menu.master.all")))])
                ]),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/system/list" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.systems")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/game/list" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.games")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/setting/list" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.settings")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/campaign/list" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.campaigns")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/party/list" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.parties")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/master/system" } }, [
                      _vm._v(_vm._s(_vm.$t("menu.master.characters")))
                    ])
                  ],
                  1
                )
              ]
            ),
            _c(
              "ul",
              {
                staticClass:
                  "menu menu--master submenu submenu--selected-system tabs"
              },
              [
                _vm.isSystemLoaded
                  ? [
                      _c("li", [
                        _c("span", [
                          _vm._v(_vm._s(_vm.$t("menu.master.selected_system")))
                        ])
                      ]),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/system/x" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.system")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/skill/list" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.skills")))
                          ])
                        ],
                        1
                      )
                    ]
                  : _vm._e()
              ],
              2
            ),
            _c(
              "ul",
              { staticClass: "menu menu--master submenu submenu--game tabs" },
              [
                _c("li", [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("menu.master.selected_game")))
                  ])
                ]),
                _vm.isGameLoaded
                  ? [
                      _c(
                        "li",
                        [
                          _c(
                            "RouterLink",
                            { attrs: { to: "/play/action/monitor" } },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("menu.character.play.action.monitor")
                                )
                              )
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/play/action" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.action")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "RouterLink",
                            { attrs: { to: "/game/instance" } },
                            [_vm._v(_vm._s(_vm.$t("menu.master.game")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/game/skills" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.skills")))
                          ])
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "RouterLink",
                            { attrs: { to: "/game/campaign" } },
                            [_vm._v(_vm._s(_vm.$t("menu.master.campaign")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c(
                            "RouterLink",
                            { attrs: { to: "/game/characters" } },
                            [_vm._v(_vm._s(_vm.$t("menu.master.characters")))]
                          )
                        ],
                        1
                      ),
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/game/party" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.party")))
                          ])
                        ],
                        1
                      )
                    ]
                  : [
                      _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/game/select" } }, [
                            _vm._v(_vm._s(_vm.$t("menu.master.select_game")))
                          ])
                        ],
                        1
                      )
                    ]
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }