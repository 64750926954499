<template>
  <div class="character-dataset5">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div v-for="(category, index) in categoriesToPrioritize" class="row" :key="`category-to-prioritize-${index}`">
          <div class="form-group col-12" :class="errorFields['age'] ? 'error' : ''">
            <label :for="'category_' + category.id">{{ category.name }}</label>
            <table class="table table-striped table-category-to-prioritize">
              <thead>
                <tr>
                  <th scope="col">{{ $t('skill.subcategory') }}</th>
                  <th scope="col">{{ $t('skill.priority') }}</th>
                  <th scope="col">{{ $t('skill.development_effort') }}</th>
                </tr>
              </thead>
              <draggable v-model="category.entries" tag="tbody" @change="onChangePosition($event, category.id)">
                <tr v-for="subcategory in category.entries" :key="subcategory.name">
                  <td>{{ subcategory.name }}</td>
                  <td scope="row">
                    {{ category.prioritizationData[subcategory.prioritization].priority }}
                  </td>
                  <td>
                    {{
                      getDevelopmentEffort(
                        category.development_effort,
                        category.prioritizationData[subcategory.prioritization].priority_development_effort,
                        subcategory.development_effort
                      )
                    }}
                  </td>
                </tr>
              </draggable>
            </table>
          </div>
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_5_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST } from '@/store/actions/skill';
import { mapGetters, mapState } from 'vuex';
import draggable from 'vuedraggable';

export default {
  name: 'CharacterDataset5',
  components: {
    draggable
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      categoriesToPrioritize: [],
      errorFields: [],
      isContentReady: false
    };
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character'])
  },
  created() {
    this.populateCategoriesToPrioritize().then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    // EVENT HANDLERS
    onChangePosition($event, categoryId) {
      const subcategoryId = $event.moved.element.id;
      const category = this.categoriesToPrioritize.find(category => category.id === categoryId);
      const subcategory = category.entries.find(subcategory => subcategory.id === subcategoryId);

      for (let p = 0; p < category.entries.length; p++) {
        if ($event.moved.newIndex > $event.moved.oldIndex) {
          if (
            category.entries[p].prioritization > $event.moved.oldIndex &&
            category.entries[p].prioritization <= $event.moved.newIndex
          ) {
            category.entries[p].prioritization--;
          }
        } else if ($event.moved.newIndex < $event.moved.oldIndex) {
          if (
            category.entries[p].prioritization >= $event.moved.newIndex &&
            category.entries[p].prioritization < $event.moved.oldIndex
          ) {
            category.entries[p].prioritization++;
          }
        }
      }

      subcategory.prioritization = $event.moved.newIndex;
    },
    onSubmit() {
      const formData = new FormData();
      formData.append('prioritizedCategories', JSON.stringify(this.categoriesToPrioritize));

      this.form_resetResponse();
      this.prh_dispatch(CHARACTER_CREATION_STEP_5_REQUEST, CHARACTER_REFRESH, formData, '/character/create/step6');
    },

    // FUNCTIONS
    getDevelopmentEffort(categoryDE, categoryPrioritizationDE, subcategoryDE) {
      const developmentEffort = this.isCharacterLoaded
        ? categoryDE *
          1 *
          categoryPrioritizationDE *
          1 *
          subcategoryDE *
          1 *
          this.character.scores.permanent_modifiers.learning_rate *
          1
        : '';
      return this.$helpers.roundTo(developmentEffort, 3);
    },
    populateCategoriesToPrioritize() {
      return this.$store
        .dispatch(CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST)
        .then(data => {
          this.categoriesToPrioritize = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}

.table-category-to-prioritize {
  thead {
    background-color: #ccd3ff;

    th {
      border-bottom: 0;
    }
  }

  tbody {
    tr {
      cursor: pointer;

      &:active {
        cursor: move; /* fallback if grab cursor is unsupported */
        cursor: grab;
      }
    }
    tr:nth-of-type(odd) {
      background-color: #ccd3ff50;
    }
  }

  td,
  th {
    border-bottom: 1px solid #a8aee1;
    border-top: 1px solid #a8aee1;

    &:first-of-type {
      border-left: 1px solid #a8aee1;
    }

    &:last-of-type {
      border-right: 1px solid #a8aee1;
    }
  }
}

.drag-wrapper {
  display: flex;
  justify-content: center;
}

ul {
  display: flex;
  flex-direction: column;
  padding: 3px !important;
  min-height: 70vh;
  width: 100px;
  float: left;
  list-style-type: none;
  overflow-y: auto;
  border: 2px solid #888;
  border-radius: 0.2em;
  background: #8adccc;
  color: #555;
  margin-right: 5px;
}

/* drop target state */
ul[aria-dropeffect='move'] {
  border-color: #68b;
  background: #fff;
}

/* drop target focus and dragover state */
ul[aria-dropeffect='move']:focus,
ul[aria-dropeffect='move'].dragover {
  outline: none;
  box-shadow: 0 0 0 1px #fff, 0 0 0 3px #68b;
}

/* draggable items */
li {
  display: block;
  list-style-type: none;
  margin: 0 0 2px 0;
  padding: 0.2em 0.4em;
  border-radius: 0.2em;
  line-height: 1.3;
}

li:hover {
  box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
}

/* items focus state */
li:focus {
  outline: none;
  box-shadow: 0 0 0 2px #68b, inset 0 0 0 1px #ddd;
}

/* items grabbed state */
li[aria-grabbed='true'] {
  background: #5cc1a6;
  color: #fff;
}

@keyframes nodeInserted {
  from {
    opacity: 0.2;
  }
  to {
    opacity: 0.8;
  }
}

.item-dropzone-area {
  height: 2rem;
  background: #888;
  opacity: 0.8;
  animation-duration: 0.5s;
  animation-name: nodeInserted;
}
</style>
