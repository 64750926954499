<template>
  <div class="body-view profile container-fluid" v-if="isMasterLoaded">
    <div class="row">
      <div class="col-3 left">
        <img
          class="master-image image-fill-frame"
          border="0"
          :alt="master.name"
          :src="master.picture ? imageUrl + master.id + '/' + master.picture : missingRoleImage"
        />
      </div>
      <div class="col-9 left">
        <table class="profile-table">
          <tbody>
            <tr>
              <td class="profile-table-label">
                {{ $t('profile.user_name') }}
              </td>
              <td class="profile-table-data">{{ user.name }}</td>
            </tr>
            <tr>
              <td class="profile-table-label">
                {{ $t('profile.master_name') }}
              </td>
              <td class="profile-table-data">{{ master.name }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from '@/setup/config';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'MasterProfileView',
  methods: {},
  computed: {
    ...mapGetters(['isMasterLoaded', 'isRoleLoaded']),
    ...mapState(['auth', 'user', 'master']),
    imageUrl() {
      return config.mediaPath[this.$helpers.getRoleType()].picture + '/';
    },
    missingRoleImage() {
      return `${config.mediaPath.application.placeholders}missing_
        ${this.$helpers.getRoleType()}_picture.png`;
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  &-table {
    &-label {
      vertical-align: top;

      &::after {
        content: ':';
        display: inline-block;
      }
    }

    &-data {
      padding-left: 10px;
      vertical-align: top;
    }
  }

  .master-image {
    width: 100%;
  }
}
</style>
