var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container item-detail",
      attrs: { id: _vm.entityNature + "-detail" }
    },
    [
      _c(
        "div",
        { staticClass: "row relative" },
        [
          !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
          this.isContentReady && _vm.item
            ? [
                _c(
                  "div",
                  { key: _vm.item.id, staticClass: "col-4" },
                  [
                    _c("Item", {
                      ref: _vm.entityNature + "_" + _vm.item.id,
                      attrs: { item: _vm.item }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4 item-detail-characters" }, [
                  _c("h4", { staticClass: "item-detail-characters-title" }, [
                    _vm._v(_vm._s(_vm.$t("party.members")))
                  ]),
                  _c(
                    "ul",
                    { staticClass: "item-detail-characters-list" },
                    _vm._l(_vm.item.characters, function(member) {
                      return _c(
                        "li",
                        {
                          key: member.id,
                          staticClass: "item-detail-characters-list-item"
                        },
                        [_vm._v(" " + _vm._s(member.name) + " ")]
                      )
                    }),
                    0
                  )
                ]),
                _c(
                  "div",
                  { staticClass: "col-4 col-counters" },
                  [
                    _c("PartyCounterExperiencePoints", {
                      attrs: {
                        counterShape: "rectangular",
                        partyPoints: _vm.item.experience_points
                      }
                    }),
                    _c("PartyCounterReputationPoints", {
                      attrs: {
                        counterShape: "round",
                        partyPoints: _vm.item.reputation_points
                      }
                    })
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }