<template>
  <div class="tile tile-selectable item-frame" @click="selectItem()">
    <div class="item-image-frame">
      <img
        class="item-image image-fill-frame"
        :class="item.picture ? '' : 'fade-image'"
        border="0"
        :alt="item.name"
        :src="item.picture ? imageUrl + item.id + '/' + item.picture : missingImage"
      />
    </div>
    <ul class="item-text">
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.name') }}</span
        >{{ item.name }}
      </li>
      <li v-if="isCharacter">
        <span class="item-text-label">{{ $t(entityNature + '.master') }}</span
        >{{ masterName }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.game') }}</span
        >{{ gameName }}
      </li>
      <li class="party-characters">
        <span class="party-text-label">{{ $t(entityNature + '.members') }} ({{ item.characters.length }})</span>
        <ul class="party-member-block">
          <template v-for="character in item.characters">
            <li :key="character.id" class="party-member">{{ character.name }}</li>
          </template>
        </ul>
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.reputation_points') }}</span
        >{{ item.reputation_points }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.experience_points') }}</span
        >{{ item.experience_points }}
      </li>
    </ul>
  </div>
</template>

<script>
const entityNature = 'party',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized,
  props: ['item'],
  data() {
    return {
      itemToDelete: undefined,
      entityNature,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters([
      'get' + entityNatureCapitalized + 'Status',
      'isCharacter',
      'isMaster',
      'is' + entityNatureCapitalized + 'Loaded'
    ]),
    imageUrl() {
      return config.mediaPath[entityNature] + '/';
    },
    gameName() {
      return this.item.game_name || this.$store.state.game.name;
    },
    masterName() {
      return this.item.master_name || this.$store.state.master.name;
    },
    missingImage() {
      return config.mediaPath.application.placeholders + 'missing_' + entityNature + '_picture.png';
    }
  },
  methods: {
    deleteItem() {
      /* IMPORTANT: TO BE IMPLEMENTED 
        a party can't be delete if there is more than member */
      console.log('delete ' + entityNature, this.item.id);
      this.$emit('delete-' + entityNature, this.item.id);
    },
    selectItem() {
      console.log('select ' + entityNature + ': ', this.item.id);
      if (this.isMaster) {
        this.$router.push('/party/' + this.item.id);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.item-detail,
.items {
  max-width: 100%;

  .item {
    margin-bottom: 25px;

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }
  }

  .party {
    &-text-label {
      text-transform: uppercase;
      font-size: 1rem;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &-characters {
      display: flex;
    }

    &-member-block {
      padding-left: 18px;
    }

    &-member {
      list-style: square;
      text-indent: -5px;
    }
  }
}
</style>
