var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        {
          staticClass: "body-view container-fluid skills",
          attrs: { id: "skills" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "area_title col-12 center" }, [
              _c("h1", [_vm._v(" " + _vm._s(_vm.$t("skill.edit_skill")) + " ")])
            ]),
            _c("div", { staticClass: "page-action page-action--right" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      return _vm.goBack()
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("button.back")) + " ")]
              )
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [
                _c(
                  "SkillEdit",
                  _vm._g(
                    { ref: "skillEdit", attrs: { id: _vm.id } },
                    _vm.$listeners
                  )
                )
              ],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }