<template>
  <div class="tile tile-selectable tile-with-background item-frame" @click="createCampaign()">
    <div class="tile-image-frame item-image-frame">
      <img
        class="item-image image-fill-frame"
        border="0"
        :alt="$t(entityNature + '.create_' + entityNature)"
        :src="newCampaignImageUrl"
      />
    </div>
    <div class="tile-selectable-text item-text">
      {{ $t(entityNature + '.create_' + entityNature) }}
    </div>
  </div>
</template>

<script>
const entityNature = 'campaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import styles from '@/styles/_variables.scss';

export default {
  name: entityNatureCapitalized + 'Creation',
  props: ['title', 'type'],
  data() {
    return {
      entityNature
    };
  },
  computed: {
    newCampaignImageUrl() {
      return styles[entityNature + '-image-src'].replace(/"/g, '');
    }
  },
  methods: {
    createCampaign() {
      this.$router.push(`/${entityNature}/create`);
    }
  }
};
</script>

<style lang="scss" scoped>
.role {
  &-frame {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: $tile-z-index;
  }

  &-frame-new {
    &--character {
      border-color: $role-character-border-color;

      &:hover {
        border-color: $role-character-border-color-hover;
      }
    }

    &--master {
      border-color: $role-master-border-color;

      &:hover {
        border-color: $role-master-border-color-hover;
      }
    }
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 6px;
  }
}
</style>
