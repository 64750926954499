<template>
  <main :id="entityNature + '-view'" class="body-view container-fluid" :class="entityNature + '-view'">
    <div class="row">
      <div class="area_title col-12 center">
        <h1>
          {{ $t('action.action_monitor') }}
          <img
            class="icon icon-refresh"
            :src="config.mediaPath.application.icons + config.assets.icons.refresh"
            :alt="$t('action.refresh')"
            @click.stop="refresh()"
          />
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row relative">
      <div class="col-6 action-scheduled">
        <ScheduledActionList ref="scheduledActionList" />

        <div class="row relative action-committed">
          <div class="col-6">
            <ManeuverModifierList v-show="showModifiers" type="type" />
          </div>
          <div class="col-6">
            <ManeuverModifierList v-show="showModifiers" type="skill" />
          </div>
        </div>
      </div>
      <div class="col-6">
        <CommittedActionList ref="committedActionList" />
      </div>
    </div>
  </main>
</template>

<script>
const entityNature = 'actionMonitor',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import ScheduledActionList from '@/components/Action/ScheduleList';
import CommittedActionList from '@/components/Action/CommitmentList';
import Message from '@/components/Message';
import ManeuverModifierList from '@/components/Modifier/List';
import { EventBus } from '@/utils/eventBus';
import { config } from '@/setup/config';

export default {
  name: entityNatureCapitalized + 'View',
  components: {
    ScheduledActionList,
    CommittedActionList,
    Message,
    ManeuverModifierList
  },
  data() {
    return {
      config,
      entityNature,
      entityNatureCapitalized,
      showModifiers: false
    };
  },
  created() {
    EventBus.$on('load-maneuver-modifiers', skill => {
      const { id } = skill;

      if (id) {
        this.showModifiers = true;
      } else {
        this.showModifiers = false;
      }
    });
  },
  methods: {
    refresh() {
      this.$refs.scheduledActionList.populateActions(true);
    }
  }
};
</script>

<style lang="scss" scoped>
.action-scheduled {
  padding-right: 50px;
}

.icon {
  cursor: pointer;
  display: inline-block;

  &-refresh {
    height: 24px;
  }
}
</style>
