var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "tile tile-selectable tile-with-background role-frame role-frame-new",
      class: "tile-selectable-new--" + _vm.type
    },
    [
      _c("img", {
        staticClass: "tile-image role-image image-fill",
        attrs: { border: "0", alt: _vm.$t(_vm.alt), src: _vm.imageUrl }
      }),
      _c("div", { staticClass: "tile-selectable-text role-text" }, [
        _vm._v(" " + _vm._s(_vm.$t(_vm.title)) + " ")
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }