export default {
  methods: {
    modal_handle(type, options) {
      this.modal.type = type;
      this.modal.headerText = this.$t(options.headerText);
      this.modal.bodyText = this.$t(options.bodyText);
      if (type === 'ok-cancel') {
        this.modal.buttonConfirm = this.$t('button.ok');
        this.modal.buttonCancel = this.$t('button.cancel');
      } else if (type === 'stay-leave') {
        this.modal.buttonCancel = this.$t('button.stay');
        this.modal.buttonConfirm = this.$t('button.leave');
      } else if (type === 'close') {
        this.modal.buttonCancel = this.$t('button.close');
      }
      this.modal.show = true;
    }
  }
};
