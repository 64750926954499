<template>
  <div class="actions" :class="mode === 'compact' ? 'actions--compact' : ''" :id="entityNature + 'List'">
    <AnimationLoading v-if="!this.isContentReady" />

    <template v-if="this.isContentReady">
      <!-- COMMITTED ACTIONS -->
      <table class="table table-action table-action-committed">
        <thead>
          <tr>
            <th v-if="mode === 'compact'" scope="col" class="center">{{ $t('action.skill') }}</th>
            <th scope="col" class="center">
              <span class="th-initiative">{{ $t('action.initiative') }}</span>
              <span class="small th-initiative-components"
                >{{ $t('action.bonus') }} / {{ $t('action.roll') }} / {{ $t('action.modifier') }}</span
              >
            </th>
            <th v-if="mode !== 'compact'" scope="col" class="center">{{ $t('action.character') }}</th>
            <th scope="col" class="center">
              <span>{{ $t('action.dice_rolls') }}</span>
            </th>
            <th scope="col" class="center">{{ $t('action.modifier') }}</th>
            <th scope="col" class="center">{{ $t('action.bonus') }}</th>
            <th v-if="mode !== 'compact'" scope="col" class="center">
              <span>{{ $t('action.total') }}</span>
            </th>
            <th scope="col" class="center">{{ $t('action.status') }}</th>
            <th scope="col" class="center"></th>
          </tr>
        </thead>
        <template v-for="(committedAction, committedActionIndex) in committedActions">
          <tbody
            class="committed-action"
            :class="
              selectedScheduledActionId === committedAction.scheduled_action ||
              committedAction.id === selectedCommittedActionId
                ? 'selected-committed-action'
                : ''
            "
            :key="`committed-action-r1-${committedActionIndex}`"
            @click="selectCommittedAction(committedAction)"
          >
            <tr v-if="mode !== 'compact'" class="initiative-components">
              <td class="small" :class="mode === 'compact' ? 'left' : 'center'">
                {{ $helpers.formatSignedNumber(committedAction.initiative_bonus) }} /
                {{ formatDiceRoll(committedAction.initiative_roll) }} / {{ getInitiativeModifier(committedAction) }}
              </td>
              <td colspan="7" class="center"></td>
            </tr>
            <tr
              class="action-data"
              :key="`committed-action-r2-${committedActionIndex}`"
              :class="selectedScheduledActionId === committedAction.scheduled_action ? 'selected-scheduled-action' : ''"
            >
              <td v-if="mode === 'compact'" class="skill-name-compact left">
                {{ getSkillName(committedAction) }}
              </td>
              <td class="initiative center">
                {{ calculateInitiativeTotal(committedAction) }}

                <span v-if="mode === 'compact'" class="initiative-components">
                  ( = {{ $helpers.formatSignedNumber(committedAction.initiative_bonus) }} /
                  {{ formatDiceRoll(committedAction.initiative_roll) }} / {{ getInitiativeModifier(committedAction) }})
                </span>
                <img
                  v-if="mode !== 'compact' && canDeleteCommittedAction(committedAction)"
                  class="icon icon-remove-circle"
                  :src="config.mediaPath.application.icons + config.assets.icons.remove_circle"
                  :alt="$t('action.delete_action')"
                  @click.stop="confirmDeleteCommittedAction(committedAction.id, committedAction.character_id)"
                />
              </td>
              <td v-if="mode !== 'compact'" class="center">{{ committedAction.character_name }}</td>
              <td class="center">
                <span v-if="showDiceRoll"
                  ><span v-if="committedAction.skill_roll"
                    >{{ formatDiceRoll(committedAction.skill_roll) }} =
                    {{ rollResolver_calculateRollScore(committedAction.skill_roll) }}</span
                  ></span
                >
                <span v-else>{{ $t('action.hidden_number') }}</span>
              </td>
              <td class="center">
                {{ $helpers.formatSignedNumber(getCommittedActionModifier(committedAction)) }}
              </td>
              <td class="center">{{ $helpers.formatSignedNumber(committedAction.skill_bonus) }}</td>
              <td v-if="mode !== 'compact'" class="center total">
                <span
                  v-if="isCommittedActionResolved(committedAction.committed_action_status)"
                  :class="
                    calculateTotal(committedAction) < systemSuccessThreshold
                      ? 'play-outcome--failure'
                      : 'play-outcome--success'
                  "
                  >{{ calculateTotal(committedAction) }}</span
                >
                <span v-else>{{ $t('action.hidden_number') }}</span>
              </td>
              <td class="center status">
                {{ $t(`action.textStatus.${committedAction.committed_action_status}`) }}
              </td>
              <td class="center">
                <img
                  v-if="mode === 'compact' && canDeleteCommittedAction(committedAction)"
                  class="icon icon-remove-circle"
                  :src="config.mediaPath.application.icons + config.assets.icons.remove_circle"
                  :alt="$t('action.delete_action')"
                  @click.stop="confirmDeleteCommittedAction(committedAction.id, committedAction.character_id)"
                />
              </td>
            </tr>
            <tr
              v-if="mode !== 'compact'"
              :key="`committed-action-r3-${committedActionIndex}`"
              :class="selectedScheduledActionId === committedAction.scheduled_action ? 'selected-scheduled-action' : ''"
            >
              <td colspan="8" class="skill-name">{{ getSkillName(committedAction) }}</td>
            </tr>
          </tbody>
        </template>
      </table>
    </template>
  </div>
</template>

<script>
const entityNature = 'committedAction',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import actionSharedMethods from '@/mixins/actionSharedMethods';
import rollResolver from '@/mixins/rollResolver';
import { COMMITTED_ACTION_DELETE } from '@/store/actions/action';
import { MESSAGE_ERROR_SET, MESSAGE_SUCCESS_SET } from '@/store/actions/message';
import { config } from '@/setup/config';
import { EventBus } from '@/utils/eventBus';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized + 'List',
  props: {
    mode: {
      type: String,
      default: null
    },
    modeCharacter: {
      type: String,
      default: null
    }
  },
  mixins: [actionSharedMethods, rollResolver],
  data() {
    return {
      actions: [],
      config,
      entityNature,
      entityNatureCapitalized,
      isContentReady: false,
      selectedCommittedActionId: undefined,
      selectedScheduledActionId: undefined
    };
  },
  computed: {
    // committedActions and scheduledActions are set in the mixin
    ...mapGetters(['isMaster']),
    showDiceRoll() {
      return this.isMaster;
    },
    systemSuccessThreshold() {
      return this.$store.state.system.skill_success_threshold;
    }
  },
  created() {
    EventBus.$on('modal-ok', () => {
      this.deleteAction(this.actionCommittedToDelete, this.actionCommittedToDeleteCharacterId);
      this.actionCommittedToDelete = undefined;
      this.actionCommittedToDeleteCharacterId = undefined;
    });

    EventBus.$on('modal-cancel', () => {
      this.actionCommittedToDelete = undefined;
      this.actionCommittedToDeleteCharacterId = undefined;
    });

    EventBus.$on('select-committed-actions', selectedScheduledActionId => {
      this.selectedCommittedActionId = undefined;
      this.selectedScheduledActionId = selectedScheduledActionId;
    });

    this.populateActions();
  },
  beforeDestroy() {
    EventBus.$off('modal-ok');
    EventBus.$off('modal-cancel');
  },
  methods: {
    calculateInitiativeTotal(action) {
      return (
        action.initiative_bonus * 1 +
        this.rollResolver_calculateRollScore(action.initiative_roll) +
        this.getInitiativeModifier(action, true)
      );
    },
    calculateTotal(action) {
      if (!action.skill_roll) {
        return this.$t('message.no_roll');
      }

      return (
        this.rollResolver_calculateRollScore(action.skill_roll) +
        action.skill_bonus * 1 +
        this.getCommittedActionModifier(action, true) * 1
      );
    },
    canDeleteCommittedAction(action) {
      return (
        (this.isMaster || this.$store.state.character.id === action.character_id) &&
        action.committed_action_status !== 'resolved'
      );
    },
    confirmDeleteCommittedAction(id, characterId) {
      this.actionScheduledToDelete = undefined;
      this.actionCommittedToDelete = id;
      this.actionCommittedToDeleteCharacterId = characterId;
      EventBus.$emit('modal', 'ok-cancel', {
        headerText: 'modal.questions.sure_to_delete_action_committed',
        bodyText: 'message.action_committed_reminder'
      });
    },
    deleteAction(actionCommittedId, actionCommittedCharacterId = null) {
      if (!actionCommittedId) {
        return false;
      }

      const data = {
        id: actionCommittedId,
        character: actionCommittedCharacterId
      };

      this.$store
        .dispatch(COMMITTED_ACTION_DELETE, data)
        .then(() => {
          this.$store.commit(MESSAGE_SUCCESS_SET);
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    formatDiceRoll(rolledDice) {
      return rolledDice.split('|').join(' -> ');
    },
    getCommittedActionModifier(action, forceZero = false) {
      if (action.skill_modifiers.length === 0) {
        if (action.skill_modifier == null || action.skill_modifier === '') {
          const scheduledAction = this.scheduledActions.find(sa => sa.id === action.scheduled_action);
          if (action.customModifier == null || action.customModifier === '') {
            return this.getScheduledActionModifier(scheduledAction);
          }

          return action.customModifier * 1 + this.getScheduledActionModifier(scheduledAction, true);
        }

        return forceZero ? 0 : '-';
      }

      let totalModifier = 0;

      for (const modifier of action.skill_modifiers) {
        totalModifier += modifier.value * 1;
      }

      totalModifier += action.customModifier * 1;
      return totalModifier;
    },
    getInitiativeModifier(action, forceZero = false) {
      if (action.initiative_modifiers.length === 0) {
        if (action.initiative_modifier == null || action.initiative_modifier === '') {
          return forceZero ? 0 : '-';
        }

        return action.initiative_modifier;
      }

      let totalModifier = 0;

      for (const modifier of action.initiative_modifiers) {
        totalModifier += modifier.value * 1;
      }

      totalModifier += action.initiative_customModifier * 1;
      return totalModifier;
    },
    isCommittedActionResolved(status) {
      return status === 'resolved';
    },
    selectCommittedAction(action) {
      this.selectedScheduledActionId = undefined;
      if (action.id === this.selectedCommittedActionId) {
        this.selectedCommittedActionId = undefined;
      } else {
        this.selectedCommittedActionId = action.id;
      }

      EventBus.$emit('select-scheduled-actions', action.scheduled_action);
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  padding-left: 25px;

  .table-action {
    td,
    th {
      vertical-align: middle;

      &.table-cell-small {
        font-size: 1rem;
        font-weight: normal;
      }

      .th-initiative,
      .th-initiative-components {
        display: block;
      }
    }

    .committed-action {
      cursor: pointer;

      &:not(.selected-committed-action) {
        &:hover {
          background-color: $action-committed-hover-background-color;
        }
      }

      &.selected-committed-action {
        background-color: $action-committed-selected-background-color;
      }
    }

    .status {
      font-size: 1.2rem;
    }

    .total {
      font-weight: bold;

      .play-outcome {
        &--failure {
          color: $table-modifier-play-outcome-failure;
        }

        &--success {
          color: $table-modifier-play-outcome-success;
        }
      }
    }

    .icon {
      cursor: pointer;
      display: inline-block;

      &-conclude,
      &-minus,
      &-play,
      &-plus,
      &-remove-circle,
      &-reset {
        height: 18px;
      }
    }

    &-committed {
      thead {
        background-color: $action-committed-table-header-background-color;
      }

      tr {
        &.initiative-components {
          td {
            padding-bottom: 0;
          }
        }
      }

      td {
        border-top: 0;

        &.skill-name {
          border-bottom: 1px solid $gray-300;
          font-size: 1.3rem;
          padding-top: 0;
        }

        &.skill-name-compact {
          font-size: 1.3rem;
        }

        &.initiative {
          position: relative;
        }
      }
    }
  }

  &:not(.actions--compact) {
    .icon {
      &-minus,
      &-plus,
      &-remove-circle,
      &-reset {
        position: absolute;
        top: 50%;
      }

      &-minus {
        left: -10px;
        transform: translate(-100%, -50%);
      }

      &-plus {
        right: -10px;
        transform: translate(100%, -50%);
      }

      &-reset {
        left: -37px;
        transform: translate(-100%, -50%);
      }

      &-remove-circle {
        left: -25px;
        transform: translateY(-50%);
      }
    }
  }

  &.actions--compact {
    padding-left: 0;

    .table-action {
      th {
        padding: 3px 5px;
      }

      &-committed {
        td {
          &.initiative {
            .initiative-components {
              font-size: 1.2rem;
            }
          }
        }
      }
    }
  }
}
</style>
