var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tile item-frame" }, [
    _c("div", { staticClass: "item-image-frame" }, [
      _c("img", {
        staticClass: "item-image image-fill-frame",
        class: _vm.item.cover ? "" : "fade-image",
        attrs: {
          border: "0",
          alt: _vm.item.name,
          src: _vm.item.cover
            ? _vm.imageUrl + _vm.item.id + "/" + _vm.item.cover
            : _vm.missingImage
        }
      })
    ]),
    _c("ul", { staticClass: "item-text" }, [
      _c("li", [
        _c("span", { staticClass: "item-text-label" }, [
          _vm._v(_vm._s(_vm.$t(_vm.referenceOrigin + ".name")))
        ]),
        _vm._v(_vm._s(_vm.item.name) + " ")
      ]),
      _vm.item.game
        ? _c("li", [
            _c("span", { staticClass: "item-text-label" }, [
              _vm._v(_vm._s(_vm.$t(_vm.referenceOrigin + ".game_name")))
            ]),
            _vm._v(_vm._s(_vm.item.game.name) + " ")
          ])
        : _vm._e(),
      _c("li", [
        _c("span", { staticClass: "item-text-label" }, [
          _vm._v(_vm._s(_vm.$t(_vm.referenceOrigin + ".starting_datetime")))
        ]),
        _vm._v(
          _vm._s(
            _vm.$helpers.getCustomFormattedDateTime(_vm.item.starting_datetime)
          ) + " "
        )
      ]),
      _c("li", [
        _c("span", { staticClass: "item-text-label" }, [
          _vm._v(_vm._s(_vm.$t(_vm.referenceOrigin + ".ending_datetime")))
        ]),
        _vm._v(
          _vm._s(
            _vm.item.ending_datetime
              ? _vm.$helpers.getCustomFormattedDateTime(
                  _vm.item.ending_datetime
                )
              : _vm.$t("global.on_going")
          ) + " "
        )
      ]),
      _c("li", [
        _c("span", { staticClass: "item-text-label" }, [
          _vm._v(_vm._s(_vm.$t("game.current_datetime")))
        ]),
        _vm._v(_vm._s(_vm.currentDatetime) + " ")
      ]),
      _c("li", [
        _c("span", { staticClass: "item-text-label" }, [
          _vm._v(_vm._s(_vm.$t(_vm.referenceOrigin + ".experience_points")))
        ]),
        _vm._v(
          _vm._s(_vm.$helpers.formatNumber(_vm.item.experience_points)) + " "
        )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }