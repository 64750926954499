import { AUTH_LOGOUT } from '../actions/auth';
import {
  MASTER_CREATION_ERROR,
  MASTER_CREATION_REQUEST,
  MASTER_CREATION_SUCCESS,
  MASTER_ERROR,
  MASTER_REGENERATION,
  MASTER_REQUEST,
  MASTER_RESET,
  MASTER_SUCCESS,
  MASTERS_ERROR,
  MASTERS_REQUEST,
  MASTERS_SUCCESS
} from '../actions/master';
import { ROLE_SET } from '../actions/role';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  id: '',
  name: '',
  status: '',
  statusVuex: {}
};

const getters = {
  getMasterStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isMaster: (state, rootGetters, rootState) => rootState.role.type === 'master',
  isMasterLoaded: state => !!state.id
};

const actions = {
  [MASTER_CREATION_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(MASTER_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createMaster, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          commit(MASTER_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MASTER_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [MASTER_REGENERATION]: ({ commit, dispatch, rootState }, data) => {
    let { id, type } = data;

    if (!type) {
      type = rootState.role.type;
    }

    commit(MASTER_RESET);

    if (id) {
      dispatch(ROLE_SET, { id, type }); // synchronous action
    }

    return dispatch(MASTER_REQUEST, id);
  },
  [MASTER_REQUEST]: ({ commit, dispatch, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(MASTER_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getMaster + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(MASTER_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MASTER_RESET);
          commit(MASTER_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },

  [MASTERS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(MASTERS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getMasterList, params)
        .then(resp => {
          commit(MASTERS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MASTERS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [MASTER_CREATION_REQUEST]: state => {
    Vue.set(state.statusVuex, 'masterCreation', 'loading');
  },
  [MASTER_CREATION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'masterCreation', 'success');
  },
  [MASTER_CREATION_ERROR]: state => {
    Vue.set(state.statusVuex, 'masterCreation', 'error');
  },

  [MASTER_REQUEST]: state => {
    Vue.set(state.statusVuex, 'master', 'loading');
  },
  [MASTER_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [MASTER_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'character', 'success');
    state = Object.assign(state, resp);
  },

  [MASTERS_ERROR]: state => {
    Vue.set(state.statusVuex, 'masters', 'error');
  },
  [MASTERS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'masters', 'loading');
  },
  [MASTERS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'masters', 'success');
  },

  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
