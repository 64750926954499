<template>
  <div class="character-dataset8">
    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.confirm') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_8_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';

export default {
  name: 'CharacterDataset8',
  mixins: [formMixin, prhMixin],
  data() {
    return {
      errorFields: []
    };
  },
  methods: {
    // EVENT HANDLERS
    onSubmit() {
      this.form_resetResponse();
      const formData = new FormData();
      this.prh_dispatch(CHARACTER_CREATION_STEP_8_REQUEST, CHARACTER_REFRESH, formData, '/character/profile');
    }
  }
};
</script>

<style lang="scss" scoped>
button[type='submit'] {
  margin-top: 30px;
}
</style>
