import { AUTH_LOGOUT } from '../actions/auth';
import { ROLE_SELECT_RESET } from '../actions/role';
import {
  SYSTEM_CHARACTER_REQUEST,
  SYSTEM_ERROR,
  SYSTEM_REQUEST,
  SYSTEM_RESET,
  SYSTEM_SELECT,
  SYSTEM_SUCCESS,
  SYSTEMS_ERROR,
  SYSTEMS_REQUEST,
  SYSTEMS_SUCCESS
} from '../actions/system';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  id: '',
  name: '',
  skill_success_threshold: '',
  statusVuex: {}
};

const getters = {
  getSystemStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isSystemLoaded: state => !!state.id
};

const actions = {
  [SYSTEM_CHARACTER_REQUEST]: ({ commit, rootState }, id = null) => {
    // id is the character id
    return new Promise((resolve, reject) => {
      commit(SYSTEM_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacterSystem + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(SYSTEM_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SYSTEM_RESET);
          commit(SYSTEM_ERROR);
          reject(err);
        });
    });
  },
  [SYSTEM_REQUEST]: ({ commit, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(SYSTEM_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSystem + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(SYSTEM_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SYSTEM_RESET);
          commit(SYSTEM_ERROR);
          reject(err);
        });
    });
  },
  [SYSTEM_SELECT]: ({ commit, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(SYSTEM_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.selectSystem + (id ? '/' + id : ''), params)
        .then(resp => {
          commit(SYSTEM_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SYSTEM_RESET);
          commit(SYSTEM_ERROR);
          reject(err);
        });
    });
  },

  [SYSTEMS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(SYSTEMS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSystemList, params)
        .then(resp => {
          commit(SYSTEMS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SYSTEMS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [SYSTEM_ERROR]: state => {
    Vue.set(state.statusVuex, 'system', 'error');
  },
  [SYSTEM_REQUEST]: state => {
    Vue.set(state.statusVuex, 'system', 'loading');
  },
  [SYSTEM_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [SYSTEM_SUCCESS]: (state, resp) => {
    state = Object.assign(state, resp);
    Vue.set(state.statusVuex, 'system', 'success');
  },

  [SYSTEMS_ERROR]: state => {
    Vue.set(state.statusVuex, 'systems', 'error');
  },
  [SYSTEMS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'systems', 'loading');
  },
  [SYSTEMS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'systems', 'error');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
