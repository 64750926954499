var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset2" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-frame" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("FormGroupSelect", {
                  staticClass: "form-group col-10",
                  class: _vm.errorFields["race"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.race.ariaDescribedby,
                    id: _vm.race.id,
                    label: _vm.race.label,
                    name: _vm.race.name,
                    onChangeCallback: _vm.race.onChange,
                    optionLabel: _vm.race.optionLabel,
                    options: _vm.race.data,
                    optionValue: _vm.race.optionValue,
                    placeholder: _vm.race.placeholder,
                    required: _vm.race.required,
                    selectValue: _vm.race.selectedValue
                  }
                }),
                _c("FormGroupSelect", {
                  staticClass: "form-group col-2",
                  class: _vm.errorFields["sex"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.sex.ariaDescribedby,
                    disabled: _vm.sex.disabled,
                    id: _vm.sex.id,
                    label: _vm.sex.label,
                    name: _vm.sex.name,
                    onChangeCallback: _vm.sex.onChange,
                    optionLabel: _vm.sex.optionLabel,
                    options: _vm.sex.data,
                    optionValue: _vm.sex.optionValue,
                    placeholder: _vm.sex.placeholder,
                    required: _vm.sex.required,
                    selectValue: _vm.sex.selectedValue
                  }
                })
              ],
              1
            ),
            _vm.feature && _vm.feature.length > 0
              ? _c("div", { staticClass: "row" }, [
                  _c("div", { staticClass: "form-group col-12" }, [
                    _c("label", { attrs: { for: "feature" } }, [
                      _vm._v(_vm._s(_vm.$t("character.feature")))
                    ]),
                    _c(
                      "ul",
                      {
                        staticClass: "display_data display_data--list",
                        attrs: { id: "feature" }
                      },
                      _vm._l(_vm.feature, function(factor, index) {
                        return _c(
                          "li",
                          {
                            key: "factor-" + index,
                            class: factor.isAdvantage ? "plus" : "minus"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(factor.name) +
                                ": " +
                                _vm._s(factor.description) +
                                " "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  ])
                ])
              : _vm._e(),
            _vm.race.isCrossbreed
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "form-group col-10" }, [
                      _c("label", { attrs: { for: "race_origin1" } }, [
                        _vm._v(_vm._s(_vm.$t("character.race_origin")) + " 1")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "display_data",
                          attrs: { id: "race_origin1" }
                        },
                        [_vm._v(_vm._s(_vm.race_origin1.name))]
                      )
                    ]),
                    _c("FormGroupSelect", {
                      staticClass: "form-group col-2",
                      class: _vm.errorFields["race_origin1_sex"] ? "error" : "",
                      attrs: {
                        ariaDescribedby: _vm.race_origin1_sex.ariaDescribedby,
                        id: _vm.race_origin1_sex.id,
                        label: _vm.race_origin1_sex.label,
                        name: _vm.race_origin1_sex.name,
                        optionLabel: _vm.race_origin1_sex.optionLabel,
                        options: _vm.race_origin1_sex.data,
                        optionValue: _vm.race_origin1_sex.optionValue,
                        placeholder: _vm.race_origin1_sex.placeholder,
                        required: _vm.race_origin1_sex.required,
                        selectValue: _vm.race_origin1_sex.selectedValue
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.race.isCrossbreed
              ? _c(
                  "div",
                  { staticClass: "row" },
                  [
                    _c("div", { staticClass: "form-group col-10" }, [
                      _c("label", { attrs: { for: "race_origin2" } }, [
                        _vm._v(_vm._s(_vm.$t("character.race_origin")) + " 2")
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "display_data",
                          attrs: { id: "race_origin2" }
                        },
                        [_vm._v(_vm._s(_vm.race_origin2.name))]
                      )
                    ]),
                    _c("FormGroupSelect", {
                      staticClass: "form-group col-2",
                      class: _vm.errorFields["race_origin2_sex"] ? "error" : "",
                      attrs: {
                        ariaDescribedby: _vm.race_origin2_sex.ariaDescribedby,
                        id: _vm.race_origin2_sex.id,
                        label: _vm.race_origin2_sex.label,
                        name: _vm.race_origin2_sex.name,
                        optionLabel: _vm.race_origin2_sex.optionLabel,
                        options: _vm.race_origin2_sex.data,
                        optionValue: _vm.race_origin2_sex.optionValue,
                        placeholder: _vm.race_origin2_sex.placeholder,
                        required: _vm.race_origin2_sex.required,
                        selectValue: _vm.race_origin2_sex.selectedValue
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("FormGroupSelect", {
                  staticClass: "form-group col-6",
                  class: _vm.errorFields["physique"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.physique.ariaDescribedby,
                    id: _vm.physique.id,
                    label: _vm.physique.label,
                    name: _vm.physique.name,
                    optionLabel: _vm.physique.optionLabel,
                    options: _vm.physique.data,
                    optionValue: _vm.physique.optionValue,
                    placeholder: _vm.physique.placeholder,
                    required: _vm.physique.required,
                    selectValue: _vm.physique.selectedValue
                  }
                }),
                _vm.race.isCrossbreed
                  ? _c("FormGroupSelect", {
                      staticClass: "form-group col-6",
                      class: _vm.errorFields["race_predominant"] ? "error" : "",
                      attrs: {
                        ariaDescribedby: _vm.race_predominant.ariaDescribedby,
                        id: _vm.race_predominant.id,
                        label: _vm.race_predominant.label,
                        name: _vm.race_predominant.name,
                        optionLabel: _vm.race_predominant.optionLabel,
                        options: _vm.race_predominant.data,
                        optionValue: _vm.race_predominant.optionValue,
                        placeholder: _vm.race_predominant.placeholder,
                        required: _vm.race_predominant.required,
                        selectValue: _vm.race_predominant.selectedValue
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "row" },
              [
                _c(
                  "div",
                  {
                    staticClass: "form-group required col-3",
                    class: _vm.errorFields["age"] ? "error" : ""
                  },
                  [
                    _c("label", { attrs: { for: "age" } }, [
                      _vm._v(_vm._s(_vm.$t("character.age")))
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.age.data,
                          expression: "age.data"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        required: "",
                        type: "number",
                        "aria-describedby": _vm.age.id,
                        disabled: _vm.age.isDisabled ? "disabled" : false,
                        id: _vm.age.id,
                        min: _vm.age.min,
                        name: _vm.age.name,
                        placeholder: _vm.$t("character.age")
                      },
                      domProps: { value: _vm.age.data },
                      on: {
                        change: function($event) {
                          return _vm.onChangeAge()
                        },
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(_vm.age, "data", $event.target.value)
                        }
                      }
                    })
                  ]
                ),
                _c("FormGroupSelect", {
                  staticClass: "form-group col-3 offset-3",
                  class: _vm.errorFields["birth_day"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.birth_day.ariaDescribedby,
                    id: _vm.birth_day.id,
                    label: _vm.birth_day.label,
                    name: _vm.birth_day.name,
                    optionLabel: _vm.birth_day.optionLabel,
                    options: _vm.birth_day.data,
                    optionValue: _vm.birth_day.optionValue,
                    placeholder: _vm.birth_day.placeholder,
                    required: _vm.birth_day.required,
                    selectValue: _vm.birth_day.selectedValue
                  }
                }),
                _c("FormGroupSelect", {
                  staticClass: "form-group col-3",
                  class: _vm.errorFields["birth_month"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.birth_month.ariaDescribedby,
                    id: _vm.birth_month.id,
                    label: _vm.birth_month.label,
                    name: _vm.birth_month.name,
                    optionLabel: _vm.birth_month.optionLabel,
                    options: _vm.birth_month.data,
                    optionValue: _vm.birth_month.optionValue,
                    placeholder: _vm.birth_month.placeholder,
                    required: _vm.birth_month.required,
                    selectValue: _vm.birth_month.selectedValue
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: _vm.errorFields["eyes"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "eyes" } }, [
                  _vm._v(_vm._s(_vm.$t("character.eyes")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.eyes,
                      expression: "eyes"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "aria-describedby": "eyes",
                    id: "eyes",
                    name: "eyes",
                    type: "text"
                  },
                  domProps: { value: _vm.eyes },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.eyes = $event.target.value
                    }
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: _vm.errorFields["hair"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "hair" } }, [
                  _vm._v(_vm._s(_vm.$t("character.hair")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.hair,
                      expression: "hair"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "aria-describedby": "hair",
                    id: "hair",
                    name: "hair",
                    type: "text"
                  },
                  domProps: { value: _vm.hair },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.hair = $event.target.value
                    }
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: _vm.errorFields["facial_hair"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "facial_hair" } }, [
                  _vm._v(_vm._s(_vm.$t("character.facial_hair")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.facial_hair,
                      expression: "facial_hair"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "aria-describedby": "facial_hair",
                    id: "facial_hair",
                    name: "facial_hair",
                    type: "text"
                  },
                  domProps: { value: _vm.facial_hair },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.facial_hair = $event.target.value
                    }
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: _vm.errorFields["skin"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "skin" } }, [
                  _vm._v(_vm._s(_vm.$t("character.skin")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.skin,
                      expression: "skin"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "aria-describedby": "skin",
                    id: "skin",
                    name: "skin",
                    type: "text"
                  },
                  domProps: { value: _vm.skin },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.skin = $event.target.value
                    }
                  }
                })
              ]
            ),
            _c(
              "div",
              {
                staticClass: "form-group",
                class: _vm.errorFields["distinguishing_marks"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "distinguishing_marks" } }, [
                  _vm._v(_vm._s(_vm.$t("character.distinguishing_marks")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.distinguishing_marks,
                      expression: "distinguishing_marks"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    "aria-describedby": "distinguishing_marks",
                    id: "distinguishing_marks",
                    name: "distinguishing_marks",
                    type: "text"
                  },
                  domProps: { value: _vm.distinguishing_marks },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.distinguishing_marks = $event.target.value
                    }
                  }
                })
              ]
            )
          ]),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }