<template>
  <main class="body-view container-fluid center">
    <div class="row">
      <div class="area_title col-12">
        <h1>
          {{ $t(`role.master.create_master`) }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Dataset :type="getRoleType()" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.master-form-create {
  margin-top: auto;
}
</style>

<script>
import Dataset from '@/components/Master/Dataset';
import Message from '@/components/Message';

export default {
  name: 'masterCreateStepView',
  components: {
    Message,
    Dataset
  },
  methods: {
    getRoleType() {
      return this.$helpers.getRoleType();
    }
  }
};
</script>
