<template>
  <div class="actions" :id="entityNature + 'List'">
    <AnimationLoading v-if="!this.isContentReady" />

    <template v-if="this.isContentReady">
      <!-- SCHEDULED ACTIONS -->
      <table class="table table-action table-action-scheduled">
        <thead>
          <tr>
            <th scope="col" class="center">{{ $t('action.action') }}</th>
            <th scope="col" class="center table-cell-small">{{ $t('action.maneuver_type_subtype') }}</th>
            <th scope="col" class="center">{{ $t('action.modifier') }}</th>
            <th scope="col" class="center">{{ $t('action.status') }}</th>
            <th scope="col" class="center" v-if="isMaster"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(scheduledAction, scheduledActionIndex) in scheduledActions">
            <tr
              class="scheduled-action"
              :class="{
                'selected-scheduled-action': scheduledAction.id === selectedScheduledActionId
              }"
              :key="`scheduled-action-${scheduledActionIndex}`"
              @click="selectScheduledAction(scheduledAction)"
            >
              <td class="skill-name">
                {{ getSkillName(scheduledAction) }}

                <img
                  v-if="isMaster && !isScheduledActionResolved(scheduledAction)"
                  class="icon icon-remove-circle"
                  :src="config.mediaPath.application.icons + config.assets.icons.remove_circle"
                  :alt="$t('action.delete_action')"
                  @click.stop="confirmDeleteScheduledAction(scheduledAction.id)"
                />
              </td>
              <td class="maneuver-type-subtype center">
                {{ scheduledAction.maneuverType
                }}<span v-if="scheduledAction.maneuverSubtype"
                  >/<span class="maneuver-subtype">{{ scheduledAction.maneuverSubtype }}</span></span
                >
              </td>
              <td class="maneuver-modifier center">
                <span class="maneuver-modifier-value">
                  <img
                    v-if="
                      isMaster &&
                        !isScheduledActionResolved(scheduledAction) &&
                        scheduledAction.id === selectedScheduledActionId
                    "
                    class="icon icon-reset"
                    :src="config.mediaPath.application.icons + config.assets.icons.reset"
                    :alt="$t('action.reset_modifiers')"
                    @click.stop="resetScheduledActionSkillModifiers(scheduledAction)"
                  />

                  <img
                    v-if="
                      isMaster &&
                        !isScheduledActionResolved(scheduledAction) &&
                        scheduledAction.id === selectedScheduledActionId
                    "
                    class="icon icon-minus"
                    :src="config.mediaPath.application.icons + config.assets.icons.minus"
                    :alt="$t('action.decrease_modifier')"
                    @click.stop="modifyScheduledActionSkillModifiers(scheduledAction, -5)"
                  />

                  {{ $helpers.formatSignedNumber(getScheduledActionModifier(scheduledAction), true) }}

                  <img
                    v-if="
                      isMaster &&
                        !isScheduledActionResolved(scheduledAction) &&
                        scheduledAction.id === selectedScheduledActionId
                    "
                    class="icon icon-plus"
                    :src="config.mediaPath.application.icons + config.assets.icons.plus"
                    :alt="$t('action.increase_modifier')"
                    @click.stop="modifyScheduledActionSkillModifiers(scheduledAction, 5)"
                  />
                </span>
              </td>
              <td class="center status">{{ getScheduledActionStatus(scheduledAction) }}</td>
              <td class="center" v-if="isMaster">
                <img
                  v-if="!isScheduledActionResolved(scheduledAction, true)"
                  class="icon icon-play"
                  :src="config.mediaPath.application.icons + config.assets.icons.play"
                  :alt="$t('action.play_action')"
                  :title="$t('action.play_action')"
                  @click.stop="playSkillAction(scheduledAction)"
                />
                <img
                  v-else-if="!isActionConcluded(scheduledAction.status)"
                  class="icon icon-conclude"
                  :src="config.mediaPath.application.icons + config.assets.icons.archive"
                  :alt="$t('action.conclude_action')"
                  :title="$t('action.conclude_action')"
                  @click.stop="concludeAction(scheduledAction.id)"
                />
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </template>
  </div>
</template>

<script>
const entityNature = 'scheduledAction',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import actionSharedMethods from '@/mixins/actionSharedMethods';
import rollResolver from '@/mixins/rollResolver';
import {
  ACTION_STATUS_SET,
  SCHEDULED_ACTION_DELETE,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_SET,
  SCHEDULED_ACTION_PLAY
} from '@/store/actions/action';
import { MESSAGE_ERROR_SET, MESSAGE_SUCCESS_SET } from '@/store/actions/message';
import { config } from '@/setup/config';
import { EventBus } from '@/utils/eventBus';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized + 'List',
  mixins: [actionSharedMethods, rollResolver],
  data() {
    return {
      actions: [],
      config,
      entityNature,
      entityNatureCapitalized,
      isContentReady: false,
      selectedScheduledActionId: undefined
    };
  },
  computed: {
    // committedActions and scheduledActions are set in the mixin
    ...mapGetters(['isMaster'])
  },
  created() {
    const self = this;
    EventBus.$on('set-maneuver-modifier', self.setManeuverModifier);
    EventBus.$on('select-scheduled-actions', selectedScheduledActionId => {
      this.selectedScheduledActionId = selectedScheduledActionId;
    });
    EventBus.$on('modal-ok', () => {
      this.deleteAction(this.actionScheduledToDelete);
      this.actionScheduledToDelete = undefined;
    });
    EventBus.$on('modal-cancel', () => {
      this.actionScheduledToDelete = undefined;
    });

    this.populateActions();
  },
  beforeDestroy() {
    EventBus.$off('modal-ok');
    EventBus.$off('modal-cancel');
  },
  methods: {
    concludeAction(id) {
      const data = {
        id,
        status: 'concluded'
      };

      this.$store
        .dispatch(ACTION_STATUS_SET, data)
        .then(() => {
          this.$store.commit(MESSAGE_SUCCESS_SET);
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    confirmDeleteScheduledAction(id) {
      this.actionScheduledToDelete = id;
      EventBus.$emit('modal', 'ok-cancel', {
        headerText: 'modal.questions.sure_to_delete_action_scheduled',
        bodyText: 'message.action_scheduled_reminder'
      });
    },
    deleteAction(actionScheduledId) {
      if (!actionScheduledId) {
        return false;
      }

      const data = {
        id: actionScheduledId
      };

      this.$store
        .dispatch(SCHEDULED_ACTION_DELETE, data)
        .then(() => {
          this.$store.commit(MESSAGE_SUCCESS_SET);
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    getScheduledActionStatus(action) {
      if (action.status === 'resolved') {
        // check if there are still pending committed action (it can happen if committed after resolving)
        const committedAction = this.committedActions.filter(
          ca => ca.scheduled_action === action.id && ca.committed_action_status === 'pending'
        );

        if (committedAction.length > 0) {
          return this.$t(`action.textStatus.pending*`);
        }
      }

      return this.$t(`action.textStatus.${action.status}`);
    },
    isActionConcluded(status) {
      return status === 'concluded';
    },
    isScheduledActionResolved(action, withCommittedActions = false) {
      if (withCommittedActions && action.status === 'resolved') {
        // check if there are still pending committed action (it can happen if committed after resolving)
        const committedAction = this.committedActions.filter(
          ca => ca.scheduled_action === action.id && ca.committed_action_status === 'pending'
        );

        return committedAction.length === 0;
      }

      return action.status === 'resolved' || action.status === 'concluded';
    },
    modifyScheduledActionSkillModifiers(action, variation) {
      if (this.isScheduledActionResolved(action)) {
        return;
      }

      const modifier = {
        scheduledActionId: action.id,
        variation: variation * 1
      };

      this.$store.commit(SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY, modifier);
      //action.customModifier += variation * 1;
    },
    playSkillAction(action) {
      if (this.isScheduledActionResolved(action, true)) {
        return false;
      }

      const playedCommittedActions = [];

      for (const committedAction of this.committedActions) {
        if (
          !!committedAction.id &&
          committedAction.scheduled_action === action.id &&
          committedAction.committed_action_status !== 'resolved'
        ) {
          playedCommittedActions.push({
            id: committedAction.id,
            skillBonus: committedAction.skill_bonus,
            skillModifier: committedAction.skill_modifier,
            rolledDice: this.formulas_rollSkill(),
            status: committedAction.committed_action_status
          });
        }
      }

      const data = {
        id: action.id,
        game: action.game,
        modifier: this.getScheduledActionModifier(action, true),
        committedActions: playedCommittedActions
      };

      this.$store
        .dispatch(SCHEDULED_ACTION_PLAY, data)
        .then(() => {
          this.$store.commit(MESSAGE_SUCCESS_SET);
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    resetScheduledActionSkillModifiers(action) {
      this.$store.commit(SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET, action.id);
      EventBus.$emit('action-reset-modifiers');
    },
    selectScheduledAction(action) {
      const selectedScheduledAction = {
        id: undefined,
        skillId: undefined,
        gameSkillId: undefined,
        characterSkillId: undefined,
        maneuverType: undefined,
        maneuverSubtype: undefined,
        selectedModifiers: []
      };

      if (action.id === this.selectedScheduledActionId) {
        this.selectedScheduledActionId = undefined;
      } else {
        this.selectedScheduledActionId = action.id;
        selectedScheduledAction.id = action.id;
        selectedScheduledAction.skillId = action.skillId;
        selectedScheduledAction.gameSkillId = action.gameSkillId;
        selectedScheduledAction.characterSkillId = action.characterSkillId;
        selectedScheduledAction.maneuverType = action.maneuverType;
        selectedScheduledAction.maneuverSubtype = action.maneuverSubtype;
        selectedScheduledAction.selectedModifiers = action.modifiers.map(m => m.id);
      }

      EventBus.$emit('select-committed-actions', this.selectedScheduledActionId);

      if (this.isScheduledActionResolved(action)) {
        return;
      }

      if (this.isMaster) {
        EventBus.$emit('load-maneuver-modifiers', selectedScheduledAction);
      }
    },
    setManeuverModifier(modifier) {
      const scheduledAction = this.scheduledActions.find(action => action.id === this.selectedScheduledActionId);

      if (this.isScheduledActionResolved(scheduledAction)) {
        return;
      }

      modifier.scheduledActionId = this.selectedScheduledActionId;
      this.$store.commit(SCHEDULED_ACTION_MANEUVER_MODIFIER_SET, modifier);
    }
  }
};
</script>

<style lang="scss" scoped>
.actions {
  padding-left: 25px;
}

.table-action {
  td,
  th {
    vertical-align: middle;

    &.table-cell-small {
      font-size: 1rem;
      font-weight: normal;
    }
  }

  .scheduled-action {
    background-color: $action-scheduled-background-color;
    cursor: pointer;

    &:not(.selected-scheduled-action) {
      &:hover {
        background-color: $action-scheduled-hover-background-color;
      }
    }

    &.selected-scheduled-action {
      background-color: $action-scheduled-selected-background-color;
    }

    .skill-name {
      position: relative;
    }

    .maneuver-modifier,
    .action-success-threshold {
      font-weight: bold;
    }

    .maneuver-modifier {
      font-size: 1.6rem;

      &-value {
        display: inline-block;
        position: relative;
        z-index: 10;
      }
    }

    .maneuver-modifier {
      color: $table-modifier-maneuver-modifier-color;
    }

    .action-success-threshold {
      color: $table-modifier-success-threshold-color;
    }

    .maneuver-subtype {
      font-size: 1.1rem;
    }
  }

  .status {
    font-size: 1.2rem;
  }

  .total {
    font-weight: bold;

    .play-outcome {
      &--failure {
        color: $table-modifier-play-outcome-failure;
      }

      &--success {
        color: $table-modifier-play-outcome-success;
      }
    }
  }

  .icon {
    cursor: pointer;
    display: inline-block;

    &-conclude,
    &-minus,
    &-play,
    &-plus,
    &-remove-circle,
    &-reset {
      height: 18px;
    }

    &-minus,
    &-plus,
    &-remove-circle,
    &-reset {
      position: absolute;
      top: 50%;
    }

    &-minus {
      left: -10px;
      transform: translate(-100%, -50%);
    }

    &-plus {
      right: -10px;
      transform: translate(100%, -50%);
    }

    &-reset {
      left: -37px;
      transform: translate(-100%, -50%);
    }

    &-remove-circle {
      left: -25px;
      transform: translateY(-50%);
    }
  }

  &-scheduled {
    margin-bottom: 50px;

    thead {
      background-color: $action-scheduled-table-header-background-color;
    }

    td {
      border-bottom: 1px solid $gray-300;
    }
  }
}
</style>
