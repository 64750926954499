import { ROLE_SELECT_RESET } from '../actions/role';
import {
  STAT_INPUT_ERROR,
  STAT_SET,
  STATS_ERROR,
  STATS_RACE_ERROR,
  STATS_RACE_REQUEST,
  STATS_RACE_SUCCESS,
  STATS_REQUEST,
  STATS_RESET,
  STATS_STATUS_RESET,
  STATS_SUCCESS
} from '../actions/stats';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  data: [],
  statusVuex: {}
};

const getters = {
  getStatsStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [STATS_RACE_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(STATS_RACE_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getStatsRaceList, params)
        .then(resp => {
          commit(STATS_RACE_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(STATS_RACE_ERROR, err.response);
          reject(err);
        });
    });
  },
  [STATS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getStats, params)
        .then(resp => {
          commit(STATS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(STATS_RESET);
          commit(STATS_ERROR);
          reject(err);
        });
    });
  }
};

const mutations = {
  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [STAT_INPUT_ERROR]: (state, resp) => {
    const stat = state.data.find(function(stat) {
      return stat.id === resp.id;
    });

    if (stat) {
      for (let i = 0; i < resp.data.length; i++) {
        let property = resp.data[i].property;
        if (!Object.prototype.hasOwnProperty.call(stat, property)) {
          Vue.set(stat, property, '');
        }
        stat[property] = resp.data[i].value;
      }

      if (!Object.prototype.hasOwnProperty.call(stat, 'statusTypedStat')) {
        Vue.set(stat, 'statusTypedStat', '');
      }
      stat['statusTypedStat'] = 'error';
    }
    Vue.set(state.statusVuex, 'stats', 'error');
  },
  [STAT_SET]: (state, resp) => {
    let stat = state.data.find(function(stat) {
      return stat.id === resp.id;
    });

    if (stat) {
      for (let i = 0; i < resp.data.length; i++) {
        let property = resp.data[i].property;
        if (!Object.prototype.hasOwnProperty.call(stat, property)) {
          Vue.set(stat, property, '');
        }
        stat[property] = resp.data[i].value;
      }

      if (!Object.prototype.hasOwnProperty.call(stat, 'statusTypedStat')) {
        Vue.set(stat, 'statusTypedStat', '');
      }
      stat['statusTypedStat'] = 'success';
      Vue.set(state.statusVuex, 'stats', 'success');
    } else {
      Vue.set(state.statusVuex, 'stats', 'error');
    }
  },

  [STATS_ERROR]: state => {
    Vue.set(state.statusVuex, 'stats', 'error');
  },
  [STATS_RACE_ERROR]: state => {
    Vue.set(state.statusVuex, 'raceStats', 'error');
  },
  [STATS_RACE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'raceStats', 'loading');
  },
  [STATS_RACE_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'raceStats', 'success');
  },
  [STATS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'stats', 'loading');
  },
  [STATS_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [STATS_STATUS_RESET]: (state, resp) => {
    let stat = state.data.find(function(stat) {
      return stat.id === resp.id;
    });

    if (stat) {
      if (!Object.prototype.hasOwnProperty.call(stat, 'statusTypedStat')) {
        Vue.set(stat, 'statusTypedStat', '');
      }
      stat['statusTypedStat'] = '';
    }
    Vue.set(state.statusVuex, 'stats', '');
  },
  [STATS_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'stats', 'success');
    state.data = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
