export const ROLE_SET = 'ROLE_SET';
export const ROLE_SELECT = 'ROLE_SELECT';
export const ROLE_SELECT_ERROR = 'ROLE_SELECT_ERROR';
export const ROLE_SELECT_REQUEST = 'ROLE_SELECT_REQUEST';
export const ROLE_SELECT_RESET = 'ROLE_SELECT_RESET';
export const ROLE_SELECT_SUCCESS = 'ROLE_SELECT_SUCCESS';

export const ROLES_ERROR = 'ROLES_ERROR';
export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
