import { AUTH_LOGOUT } from '../actions/auth';
import {
  MESSAGE_ERROR_LIST_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_PARTIAL_SUCCESS_SET,
  MESSAGE_RESET,
  MESSAGE_SUCCESS_SET
} from '../actions/message';
import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

const state = {
  code: '',
  errorList: [],
  operationResult: ''
};

const getters = {};

const actions = {};

const mutations = {
  [AUTH_LOGOUT]: () => {
    //Vue.helpers.resetState(state);
  },

  [MESSAGE_ERROR_LIST_SET]: (state, errorList) => {
    let message = {
      operationResult: 'error'
    };

    if (errorList.length === 1) {
      message.code = errorList[0];
    } else {
      message.code = 'error_list';
      message.errorList = typeof errorList === 'object' ? Vue.helpers.getErrorList(errorList) : errorList;
    }

    state.message = 'success';
    state = Object.assign(state, message);
  },
  [MESSAGE_ERROR_SET]: (state, error) => {
    const message = {
      code: Object.prototype.toString.call(error) === '[object Object]' ? error.message : error,
      operationResult: 'error'
    };

    state.message = 'success';
    state = Object.assign(state, message);
  },
  [MESSAGE_PARTIAL_SUCCESS_SET]: (state, resp) => {
    const message = {
      code: 'success_with_errors',
      operationResult: 'partial_success',
      errorList: Vue.helpers.getErrorList(resp)
    };

    state.message = 'success';
    state = Object.assign(state, message);
  },
  [MESSAGE_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [MESSAGE_SUCCESS_SET]: state => {
    const message = {
      code: 'success',
      operationResult: 'success'
    };

    state.message = 'success';
    state = Object.assign(state, message);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
