export const GAME_CREATION_ERROR = 'GAME_CREATION_ERROR';
export const GAME_CREATION_REQUEST = 'GAME_CREATION_REQUEST';
export const GAME_CREATION_SUCCESS = 'GAME_CREATION_SUCCESS';
export const GAME_DELETE = 'GAME_DELETE';
export const GAME_ERROR = 'GAME_ERROR';
export const GAME_LOAD = 'GAME_LOAD';
export const GAME_LOAD_SUCCESS = 'GAME_LOAD_SUCCESS';
export const GAME_REQUEST = 'GAME_REQUEST';
export const GAME_RESET = 'GAME_RESET';
export const GAME_SET = 'GAME_SET';
export const GAME_SELECT = 'GAME_SELECT';
export const GAME_SELECT_ERROR = 'GAME_SELECT_ERROR';
export const GAME_SELECT_REQUEST = 'GAME_SELECT_REQUEST';
export const GAME_SELECT_RESET = 'GAME_SELECT_RESET';
export const GAME_SELECT_SUCCESS = 'GAME_SELECT_SUCCESS';
export const GAME_SUCCESS = 'GAME_SUCCESS';

export const GAMES_USER_CHARACTER_REQUEST = 'GAMES_USER_CHARACTER_REQUEST';
export const GAMES_ERROR = 'GAMES_ERROR';
export const GAMES_REQUEST = 'GAMES_REQUEST';
export const GAMES_SUCCESS = 'GAMES_SUCCESS';
