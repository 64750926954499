<template>
  <div v-if="restrictedAccess_grantMaster" class="master-dataset1">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="master-form left" enctype="multipart/form-data" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="form-group required" :class="errorFields['name'] ? 'error' : ''">
          <label for="name">{{ $t('master.name') }}</label>
          <input
            v-model="name"
            aria-describedby="name"
            class="form-control"
            id="name"
            name="name"
            required
            type="text"
            :placeholder="$t('master.name')"
          />
        </div>
        <div class="form-group" :class="errorFields['image'] ? 'error' : ''">
          <label for="image">{{ $t('global.picture') }}</label>
          <input
            accept="image/*"
            aria-describedby="image"
            class="form-control"
            id="image"
            name="image"
            type="file"
            @change="onChangeMasterImageFile($event)"
          />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MASTER_CREATION_REQUEST, MASTER_REGENERATION, MASTERS_REQUEST } from '@/store/actions/master';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';

export default {
  name: 'MasterDataset1',
  mixins: [formMixin, prhMixin, restrictedAccessMixin],
  props: {
    type: String
  },
  data() {
    return {
      errorFields: [],
      isContentReady: false,
      name: '',
      targetFile: '',
      targetName: ''
    };
  },
  beforeCreate() {
    this.$store
      .dispatch(MASTERS_REQUEST)
      .then(() => {})
      .catch(err => {
        this.$store.commit(MESSAGE_ERROR_SET, err);
        this.$helpers.errorManager(err);
      });
  },
  created() {
    // no asynchronous calls going on, but I want to keep symmetry with character
    this.isContentReady = true;
  },
  methods: {
    // EVENT HANDLERS
    onChangeMasterImageFile($event) {
      this.targetName = $event.target.name;
      this.targetFile = $event.target.files[0];
    },
    onSubmit() {
      const { name, type } = this;
      const formData = new FormData();
      formData.append(this.targetName, this.targetFile);
      formData.append('name', name);
      formData.append('type', type);

      this.form_resetResponse();
      this.prh_dispatch(MASTER_CREATION_REQUEST, MASTER_REGENERATION, formData, '/master/profile');
    }
  }
};
</script>

<style lang="scss" scoped>
.master-form {
  margin-top: auto;
}
</style>
