// const domain = 'http://www.prometeo.toys'
const domain = '';
// const rootFolder = 'syntropy'
const rootFolder = '';
/* this folder must be public as Webpack can't load dynamically the image path: to keep it internal
   I would have to explicit the media path in every component -> no way! */
const assetsFolder = 'assets';
const applicationImagesAssetsFolderPath = '/application/' + assetsFolder + '/images/';
const uploadFolder = 'upload';
const rootPath = domain + '/' + (rootFolder.length > 0 ? rootFolder + '/' : '');
const rootPathApi = rootPath + 'api/';
const uploadPath = '/' + uploadFolder + '/';

export const config = {
  sitePath: {
    baseUrl: domain + '/',
    root: rootPath,
    api: {
      // CAMPAIGN
      createCampaign: rootPathApi + 'campaign/create',
      getCampaign: rootPathApi + 'campaign',
      getCampaignList: rootPathApi + 'campaign/list',
      //getCampaignTemplateList: rootPathApi + 'campaign/template/list',
      //createCampaignTemplate: rootPathApi + 'campaign/template/create',

      // CHARACTER
      createCharacterStep1: rootPathApi + 'character/step1/create',
      createCharacterStep2: rootPathApi + 'character/step2/create',
      createCharacterStep3: rootPathApi + 'character/step3/create',
      createCharacterStep4: rootPathApi + 'character/step4/create',
      createCharacterStep5: rootPathApi + 'character/step5/create',
      createCharacterStep6: rootPathApi + 'character/step6/create',
      createCharacterStep7: rootPathApi + 'character/step7/create',
      createCharacterStep8: rootPathApi + 'character/step8/create',
      deleteCharacter: rootPathApi + 'character/delete',
      getCharacter: rootPathApi + 'character',
      getCharacterList: rootPathApi + 'character/list',

      // CLASS
      getClassList: rootPathApi + 'class/list',
      getSpellcasterType: rootPathApi + 'class/spellcasterType',

      // FEATURE
      getFeatureEntityStatList: rootPathApi + 'feature/entity/stat/list',

      // GAME
      addGameSkill: rootPathApi + 'skill/game/add',
      createGame: rootPathApi + 'game/create',
      getCharacterUserGameList: rootPathApi + 'game/user/character/list',
      getGame: rootPathApi + 'game',
      getGameList: rootPathApi + 'game/list',
      getUserGames: rootPathApi + 'game/user/list',

      // INSTANCE
      getInstanceCharacter: rootPathApi + 'instance/character',
      getInstanceMaster: rootPathApi + 'instance/master',

      // LOGIN
      autologin: rootPathApi + 'log/autologin',
      getUser: rootPathApi + 'user',
      login: rootPathApi + 'log/login',
      logout: rootPathApi + 'log/logout',

      // MASTER
      getMaster: rootPathApi + 'master',
      createMaster: rootPathApi + 'master/create',
      getMasterList: rootPathApi + 'master/list',

      // MATERIAL SOURCE
      getMaterialSourceList: rootPathApi + 'materialSource/list',

      // MODIFIERS
      getManeuverTypeModifierList: rootPathApi + 'maneuver/modifier/type/list',
      getManeuverSkillHierModifierList: rootPathApi + 'maneuver/modifier/skill/hier/list',

      // PARTY
      getCharacterParty: rootPathApi + 'party/character',
      getParty: rootPathApi + 'party',
      getPartyList: rootPathApi + 'party/list',

      // PLAY - ACTION
      commitAction: rootPathApi + 'action/commit',
      deleteCommittedAction: rootPathApi + 'action/committed/delete',
      deleteScheduledAction: rootPathApi + 'action/scheduled/delete',
      executePartyAction: rootPathApi + 'action/party/execute',
      getActionList: rootPathApi + 'action/list',
      getScheduledAction: rootPathApi + 'action/scheduled',
      playScheduledAction: rootPathApi + 'action/scheduled/play',
      scheduleAction: rootPathApi + 'action/schedule',
      setScheduledActionStatus: rootPathApi + 'action/scheduled/status/set',
      //resolveCommittedCharactersAction: rootPathApi + 'action/committed/character/resolve',

      // PREFERENCES
      getPreferenceIdFromTag: rootPathApi + 'preferences/id/get',
      setPreference: rootPathApi + 'preferences/update',
      getPreferences: rootPathApi + 'preferences',

      // RACE
      getRaceHierarchyList: rootPathApi + 'race/list/hierarchy',
      getRaceLastTierList: rootPathApi + 'race/list/lastTier',
      getRaceList: rootPathApi + 'race/list',
      getRacePhysiqueList: rootPathApi + 'race/physique/list',
      getRaceSexList: rootPathApi + 'race/sex/list',

      // REALM
      getMagicRealmList: rootPathApi + 'realm/magic/list',

      // ROLE
      getRoleList: rootPathApi + 'role/list',
      getRoleType: rootPathApi + 'role/type',
      updateRole: rootPathApi + 'role/update',

      // SCORES
      getScoresCharacterGame: rootPathApi + 'scores/character/game',

      // SETTING
      getSetting: rootPathApi + 'setting',
      getSettingList: rootPathApi + 'setting/list',

      // SKILLS
      // --> Core
      getCategories: rootPathApi + 'skill/category/list',
      getSkill: rootPathApi + 'skill',
      getSkillManeuverSubtypes: rootPathApi + 'skill/maneuverSubtype/list',
      getSkillManeuverTypes: rootPathApi + 'skill/maneuverType/list',
      getSkillMedia: rootPathApi + 'skill/medium/list',
      getSkills: rootPathApi + 'skill/list',
      getSkillStatus: rootPathApi + 'skill/status/list',
      getSkillTypes: rootPathApi + 'skill/type/list',
      getSpellLists: rootPathApi + 'spell/lists',
      rankupCharacterSkills: rootPathApi + 'skill/character/rankup',
      saveSkill: rootPathApi + 'skill/save',
      // --> Character
      getCharacterGameSkill: rootPathApi + 'skill/game/character',
      getCharacterSkillClassCategoriesToPriotize: rootPathApi + 'skill/character/class/categoriesToPriotize',
      addCharacterSkillExperience: rootPathApi + 'skill/character/experience/add',
      getCharacterSkill: rootPathApi + 'skill/character', // never use for uncomplete-creation character (only finalized character)
      getCharacterSkills: rootPathApi + 'skill/character/list',
      // --> Game
      getGameSkill: rootPathApi + 'skill/game',
      getGameSkills: rootPathApi + 'skill/game/list',

      // STATS
      getStats: rootPathApi + 'stats',
      getStatsCharacterList: rootPathApi + 'stats/Character/list',
      getStatsRaceList: rootPathApi + 'stats/race/list',

      // SYSTEM
      getCharacterSystem: rootPathApi + 'system/character',
      getSystem: rootPathApi + 'system',
      getSystemList: rootPathApi + 'system/list',
      selectSystem: rootPathApi + 'system/select',

      // TRAINING PACKAGE
      getTrainingPackageCharacterList: rootPathApi + 'trainingPackage/character/list',
      getTrainingPackageClassList: rootPathApi + 'trainingPackage/class/list'
    }
  },
  mediaPath: {
    application: {
      icons: applicationImagesAssetsFolderPath + 'icons/',
      images: applicationImagesAssetsFolderPath,
      logos: applicationImagesAssetsFolderPath + 'logos/',
      placeholders: applicationImagesAssetsFolderPath + 'placeholders/'
    },
    campaign: uploadPath + 'images/campaigns',
    character: {
      picture: uploadPath + 'images/characters'
    },
    master: {
      picture: uploadPath + 'images/masters'
    },
    party: uploadPath + 'images/parties',
    setting: uploadPath + 'images/settings',
    system: uploadPath + 'images/systems'
  },
  assets: {
    icons: {
      add: 'icon_add.svg',
      archive: 'icon_archive.svg',
      config: 'icon_config.svg',
      expand: 'icon_expand.svg',
      collapse: 'icon_collapse.svg',
      die6: 'icon_d6.svg',
      minus: 'icon_minus.svg',
      play: 'icon_play.svg',
      plus: 'icon_plus.svg',
      rank_down: 'icon_rank_down.svg',
      rank_up: 'icon_rank_up.svg',
      refresh: 'icon_refresh.svg',
      remove: 'icon_remove.svg',
      remove_circle: 'icon_remove_circle.svg',
      reset: 'icon_reset.svg',
      save: 'icon_save.svg'
    }
  },
  language: {
    defaultLanguage: 'en' // da rivedere, deve venire da localStorage
  },
  storage: {
    language: 'syntropy-language',
    gameId: 'syntropy-game-id',
    rememberMe: 'syntropy-remember-me',
    roleId: 'syntropy-role-id',
    roleType: 'syntropy-role-type',
    token: 'syntropy-user-token'
  }
};
