<template>
  <div class="container item-detail" :id="entityNature + '-detail'">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <!-- ITEM DETAIL -->
      <template v-if="this.isContentReady && item">
        <div class="col-4" :key="item.id">
          <Item :ref="entityNature + '_' + item.id" :item="item" :action="action" />
        </div>
        <div class="col-4 item-detail-x"></div>
        <div class="col-4  item-detail-y"></div>
      </template>
    </div>
  </div>
</template>

<script>
const entityNature = 'game',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { GAME_REQUEST } from '@/store/actions/game';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import Item from '@/components/Game';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized + 'Detail',
  components: {
    Item
  },
  props: ['action', 'id'],
  data() {
    return {
      entityNature,
      entityNatureCapitalized,
      isContentReady: false,
      item: null
    };
  },
  computed: {
    ...mapGetters([
      'get' + entityNatureCapitalized + 'Status',
      'isCharacter',
      'isMaster',
      'is' + entityNatureCapitalized + 'Loaded'
    ])
  },
  created() {
    if (this.isCharacter) {
      if (!this.isGameLoaded) {
        this.unwatchGame = this.$watch('isGameLoaded', () => {
          this.unwatchGame();
          this.item = this.$store.state.game;
          console.log(1, this.item);
          this.isContentReady = true;
        });
      } else {
        this.item = this.$store.state.game;
        console.log(2, this.item);
        this.isContentReady = true;
      }
    } else if (this.isMaster) {
      if (this.id) {
        this.getItem();
      } else {
        this.setItem();
      }
    }
  },
  methods: {
    getItem() {
      this.$store
        .dispatch(GAME_REQUEST, this.id)
        .then(data => {
          this.item = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    setItem() {
      this.item = this.$store.state.game;
      this.item.master_name = this.$store.state.master.name;
      this.isContentReady = true;
    }
  }
};
</script>

<style lang="scss" scoped></style>
