<template>
  <div class="character-dataset2">
    <AnimationLoading v-if="!this.isContentReady" />

    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="row">
          <FormGroupSelect
            class="form-group col-10"
            :ariaDescribedby="race.ariaDescribedby"
            :class="errorFields['race'] ? 'error' : ''"
            :id="race.id"
            :label="race.label"
            :name="race.name"
            :onChangeCallback="race.onChange"
            :optionLabel="race.optionLabel"
            :options="race.data"
            :optionValue="race.optionValue"
            :placeholder="race.placeholder"
            :required="race.required"
            :selectValue="race.selectedValue"
          />
          <FormGroupSelect
            class="form-group col-2"
            :ariaDescribedby="sex.ariaDescribedby"
            :class="errorFields['sex'] ? 'error' : ''"
            :disabled="sex.disabled"
            :id="sex.id"
            :label="sex.label"
            :name="sex.name"
            :onChangeCallback="sex.onChange"
            :optionLabel="sex.optionLabel"
            :options="sex.data"
            :optionValue="sex.optionValue"
            :placeholder="sex.placeholder"
            :required="sex.required"
            :selectValue="sex.selectedValue"
          />
        </div>

        <div class="row" v-if="feature && feature.length > 0">
          <div class="form-group col-12">
            <label for="feature">{{ $t('character.feature') }}</label>
            <ul id="feature" class="display_data display_data--list">
              <li
                v-for="(factor, index) in feature"
                :class="factor.isAdvantage ? 'plus' : 'minus'"
                :key="`factor-${index}`"
              >
                {{ factor.name }}: {{ factor.description }}
              </li>
            </ul>
          </div>
        </div>

        <div class="row" v-if="race.isCrossbreed">
          <div class="form-group col-10">
            <label for="race_origin1">{{ $t('character.race_origin') }} 1</label>
            <span id="race_origin1" class="display_data">{{ race_origin1.name }}</span>
          </div>
          <FormGroupSelect
            class="form-group col-2"
            :ariaDescribedby="race_origin1_sex.ariaDescribedby"
            :class="errorFields['race_origin1_sex'] ? 'error' : ''"
            :id="race_origin1_sex.id"
            :label="race_origin1_sex.label"
            :name="race_origin1_sex.name"
            :optionLabel="race_origin1_sex.optionLabel"
            :options="race_origin1_sex.data"
            :optionValue="race_origin1_sex.optionValue"
            :placeholder="race_origin1_sex.placeholder"
            :required="race_origin1_sex.required"
            :selectValue="race_origin1_sex.selectedValue"
          />
        </div>

        <div class="row" v-if="race.isCrossbreed">
          <div class="form-group col-10">
            <label for="race_origin2">{{ $t('character.race_origin') }} 2</label>
            <span id="race_origin2" class="display_data">{{ race_origin2.name }}</span>
          </div>
          <FormGroupSelect
            class="form-group col-2"
            :ariaDescribedby="race_origin2_sex.ariaDescribedby"
            :class="errorFields['race_origin2_sex'] ? 'error' : ''"
            :id="race_origin2_sex.id"
            :label="race_origin2_sex.label"
            :name="race_origin2_sex.name"
            :optionLabel="race_origin2_sex.optionLabel"
            :options="race_origin2_sex.data"
            :optionValue="race_origin2_sex.optionValue"
            :placeholder="race_origin2_sex.placeholder"
            :required="race_origin2_sex.required"
            :selectValue="race_origin2_sex.selectedValue"
          />
        </div>

        <div class="row">
          <FormGroupSelect
            class="form-group col-6"
            :ariaDescribedby="physique.ariaDescribedby"
            :class="errorFields['physique'] ? 'error' : ''"
            :id="physique.id"
            :label="physique.label"
            :name="physique.name"
            :optionLabel="physique.optionLabel"
            :options="physique.data"
            :optionValue="physique.optionValue"
            :placeholder="physique.placeholder"
            :required="physique.required"
            :selectValue="physique.selectedValue"
          />

          <FormGroupSelect
            v-if="race.isCrossbreed"
            class="form-group col-6"
            :ariaDescribedby="race_predominant.ariaDescribedby"
            :class="errorFields['race_predominant'] ? 'error' : ''"
            :id="race_predominant.id"
            :label="race_predominant.label"
            :name="race_predominant.name"
            :optionLabel="race_predominant.optionLabel"
            :options="race_predominant.data"
            :optionValue="race_predominant.optionValue"
            :placeholder="race_predominant.placeholder"
            :required="race_predominant.required"
            :selectValue="race_predominant.selectedValue"
          />
        </div>

        <div class="row">
          <div class="form-group required col-3" :class="errorFields['age'] ? 'error' : ''">
            <label for="age">{{ $t('character.age') }}</label>
            <input
              v-model="age.data"
              class="form-control"
              required
              type="number"
              :aria-describedby="age.id"
              :disabled="age.isDisabled ? 'disabled' : false"
              :id="age.id"
              :min="age.min"
              :name="age.name"
              :placeholder="$t('character.age')"
              @change="onChangeAge()"
            />
          </div>

          <FormGroupSelect
            class="form-group col-3 offset-3"
            :ariaDescribedby="birth_day.ariaDescribedby"
            :class="errorFields['birth_day'] ? 'error' : ''"
            :id="birth_day.id"
            :label="birth_day.label"
            :name="birth_day.name"
            :optionLabel="birth_day.optionLabel"
            :options="birth_day.data"
            :optionValue="birth_day.optionValue"
            :placeholder="birth_day.placeholder"
            :required="birth_day.required"
            :selectValue="birth_day.selectedValue"
          />
          <FormGroupSelect
            class="form-group col-3"
            :ariaDescribedby="birth_month.ariaDescribedby"
            :class="errorFields['birth_month'] ? 'error' : ''"
            :id="birth_month.id"
            :label="birth_month.label"
            :name="birth_month.name"
            :optionLabel="birth_month.optionLabel"
            :options="birth_month.data"
            :optionValue="birth_month.optionValue"
            :placeholder="birth_month.placeholder"
            :required="birth_month.required"
            :selectValue="birth_month.selectedValue"
          />
        </div>

        <div class="form-group" :class="errorFields['eyes'] ? 'error' : ''">
          <label for="eyes">{{ $t('character.eyes') }}</label>
          <input v-model="eyes" aria-describedby="eyes" class="form-control" id="eyes" name="eyes" type="text" />
        </div>

        <div class="form-group" :class="errorFields['hair'] ? 'error' : ''">
          <label for="hair">{{ $t('character.hair') }}</label>
          <input v-model="hair" aria-describedby="hair" class="form-control" id="hair" name="hair" type="text" />
        </div>

        <div class="form-group" :class="errorFields['facial_hair'] ? 'error' : ''">
          <label for="facial_hair">{{ $t('character.facial_hair') }}</label>
          <input
            v-model="facial_hair"
            aria-describedby="facial_hair"
            class="form-control"
            id="facial_hair"
            name="facial_hair"
            type="text"
          />
        </div>

        <div class="form-group" :class="errorFields['skin'] ? 'error' : ''">
          <label for="skin">{{ $t('character.skin') }}</label>
          <input v-model="skin" aria-describedby="skin" class="form-control" id="skin" name="skin" type="text" />
        </div>

        <div class="form-group" :class="errorFields['distinguishing_marks'] ? 'error' : ''">
          <label for="distinguishing_marks">{{ $t('character.distinguishing_marks') }}</label>
          <input
            v-model="distinguishing_marks"
            aria-describedby="distinguishing_marks"
            class="form-control"
            id="distinguishing_marks"
            name="distinguishing_marks"
            type="text"
          />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_2_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
/*
import {
  MESSAGE_ERROR_LIST_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_PARTIAL_SUCCESS_SET,
  MESSAGE_SUCCESS_SET
} from '@/store/actions/message';
*/
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { PHYSIQUE_REQUEST, RACES_REQUEST, SEX_REQUEST } from '@/store/actions/race';
import FormGroupSelect from '@/components/Form/FormGroupSelect';

export default {
  name: 'CharacterDataset2',
  components: {
    FormGroupSelect
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      age: {
        data: '',
        id: 'age',
        isDisabled: true,
        min: 1,
        name: 'age'
      },
      birth_day: {
        ariaDescribedby: 'birth_day',
        data: this.$helpers.getDaysInMonth('global.datetime.monthList'),
        id: 'birth_day',
        label: 'character.birth_day',
        name: 'birth_day',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      birth_month: {
        ariaDescribedby: 'birth_month',
        data: this.$helpers.getMonthsInYear(this.$i18n.t('global.datetime.monthList')),
        id: 'birth_month',
        label: 'character.birth_month',
        name: 'birth_month',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      distinguishing_marks: '',
      errorFields: [],
      eyes: '',
      facial_hair: '',
      feature: [],
      hair: '',
      isContentReady: false,
      race: {
        ariaDescribedby: 'race',
        data: [],
        id: 'race',
        isCrossbreed: false,
        label: 'character.race',
        name: 'race',
        onChange: this.onChangeRace,
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      race_origin1: {
        id: '',
        name: ''
      },
      race_origin1_sex: {
        id: 'race_origin1_sex',
        name: 'race_origin1_sex',
        optionValue: 'id',
        optionLabel: 'code',
        ariaDescribedby: 'race_origin1_sex',
        label: 'character.sex',
        placeholder: 'form.uknown',
        data: [],
        selectedValue: ''
      },
      race_origin2: {
        id: '',
        name: ''
      },
      race_origin2_sex: {
        id: 'race_origin2_sex',
        name: 'race_origin2_sex',
        optionValue: 'id',
        optionLabel: 'code',
        ariaDescribedby: 'race_origin2_sex',
        label: 'character.sex',
        placeholder: 'form.uknown',
        data: [],
        selectedValue: ''
      },
      race_predominant: {
        ariaDescribedby: 'race_predominant',
        data: [],
        id: 'race_predominant',
        label: 'character.race_predominant',
        name: 'race_predominant',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: 'form.none',
        selectedValue: ''
      },
      physique: {
        ariaDescribedby: 'physique',
        data: [],
        id: 'physique',
        label: 'character.physique',
        name: 'physique',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: 'form.select_race',
        required: 'required',
        selectedValue: ''
      },
      sex: {
        ariaDescribedby: 'sex',
        data: [],
        disabled: 'disabled',
        id: 'sex',
        label: 'character.sex',
        name: 'sex',
        onChange: this.onChangeSex,
        optionLabel: 'code',
        optionValue: 'id',
        placeholder: null,
        required: 'required',
        selectedValue: ''
      },
      skin: ''
    };
  },
  created() {
    Promise.all([this.populateRace(), this.populateSex()]).then(() => {
      this.isContentReady = true;
    });
  },
  methods: {
    // EVENT HANDLERS
    onChangeAge() {
      this.validateAge();
    },
    onChangeRace(id) {
      const raceOption = this.race.data.find(race => race.id === id);
      this.race.isCrossbreed = raceOption ? raceOption.isCrossbreed : '';
      this.sex.disabled = false;
      this.populatePhysique(id);
      this.handleFeatures(raceOption);
      this.handleRaceOrigin(raceOption);
      this.updateRacePredominant(raceOption);
      this.setAge();
    },
    onChangeSex() {
      this.setAge();
    },
    onSubmit() {
      const {
          age,
          birth_day,
          birth_month,
          distinguishing_marks,
          eyes,
          facial_hair,
          hair,
          physique,
          race_origin1_sex,
          race_origin2_sex,
          race_predominant,
          race,
          sex,
          skin
        } = this,
        description = {
          distinguishing_marks,
          eyes,
          facial_hair,
          hair,
          skin
        },
        formData = new FormData();
      formData.append('age', age.data);
      formData.append('birth_day', birth_day.selectedValue);
      formData.append('birth_month', birth_month.selectedValue);
      formData.append('description', JSON.stringify(description));
      formData.append('physique', physique.selectedValue);
      formData.append('race_origin1_sex', race_origin1_sex.selectedValue);
      formData.append('race_origin2_sex', race_origin2_sex.selectedValue);
      formData.append('race_predominant', race_predominant.selectedValue);
      formData.append('race', race.selectedValue);
      formData.append('sex', sex.selectedValue);

      this.form_resetResponse();
      this.prh_dispatch(CHARACTER_CREATION_STEP_2_REQUEST, CHARACTER_REFRESH, formData, '/character/create/step3');
    },

    // FUNCTIONS
    handleFeatures(raceOption) {
      this.feature = raceOption.features;
    },
    handleRaceOrigin(raceOption) {
      if (raceOption.isCrossbreed) {
        this.race_origin1_sex.required = 'required';
        this.race_origin2_sex.required = 'required';

        const origin1 = this.race.data.find(race => race.id === raceOption.origin1);
        this.race_origin1.id = origin1.id;
        this.race_origin1.name = origin1.name;

        const origin2 = this.race.data.find(race => race.id === raceOption.origin2);
        this.race_origin2.id = origin2.id;
        this.race_origin2.name = origin2.name;
      } else {
        this.race_origin1_sex.required = '';
        this.race_origin2_sex.required = '';
      }
    },
    populatePhysique(race) {
      if (!race) {
        return false;
      }

      this.$store
        .dispatch(PHYSIQUE_REQUEST, race)
        .then(data => {
          this.physique.data = data;
          for (let p = 0; p < this.physique.data.length; p++) {
            if (this.physique.data[p]['isDefaultPhysique']) {
              this.physique.selectedValue = this.physique.data[p]['id'];
              break;
            }
          }
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateRace() {
      return this.$store
        .dispatch(RACES_REQUEST)
        .then(data => {
          this.race.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateSex() {
      return this.$store
        .dispatch(SEX_REQUEST)
        .then(data => {
          this.sex.data = data;
          this.race_origin1_sex.data = data;
          this.race_origin2_sex.data = data;
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    setAge() {
      if (!this.race.selectedValue || !this.sex.selectedValue) {
        this.age.data = '';
        this.age.min = '';
        this.age.isDisabled = 'true';
      }

      const raceOption = this.race.data.find(race => race.id === this.race.selectedValue);
      const raceAdolescenceAge = raceOption[this.sex.selectedValue + '_adolescence_age'];

      this.age.data = raceAdolescenceAge;
      this.age.min = raceAdolescenceAge;
      this.age.isDisabled = false;
    },
    updateRacePredominant(raceOption) {
      const racePredominantData = [];

      if (raceOption.isCrossbreed) {
        racePredominantData.push(this.race.data.find(race => race.id === raceOption.origin1));

        racePredominantData.push(this.race.data.find(race => race.id === raceOption.origin2));

        this.race_predominant.data = racePredominantData;
      }
    },
    validateAge() {
      if (this.age.data < this.age.min) {
        this.age.data = this.age.min;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
