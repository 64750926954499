var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group-text-list",
      class: _vm.required === "required" ? "required" : ""
    },
    [
      _c(
        "label",
        { staticClass: "form-group-text-list-label", attrs: { for: "id" } },
        [_vm._v(_vm._s(_vm.$t(_vm.label, _vm.labelParams)))]
      ),
      true
        ? _c("img", {
            staticClass: "icon icon-add icon-add-entry",
            attrs: {
              src:
                _vm.config.mediaPath.application.icons +
                _vm.config.assets.icons.add,
              alt: _vm.$t(_vm.addEntryLabel)
            },
            on: {
              click: function($event) {
                return _vm.addEntry()
              }
            }
          })
        : _vm._e(),
      _vm._l(_vm.list, function(element, index) {
        return _c(
          "div",
          {
            key: _vm.id + "-" + element.id,
            staticClass: "form-group-text-list-row"
          },
          [
            _c("div", { staticClass: "form-group-text-list-key" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: element[_vm.listKeyProperty],
                    expression: "element[listKeyProperty]"
                  }
                ],
                staticClass: "form-control",
                class: {
                  error:
                    _vm.errorFieldsInherited[
                      _vm.id + "-" + _vm.listKeyProperty + "-" + element.id
                    ]
                },
                attrs: {
                  type: "text",
                  id: _vm.id + "-" + _vm.listKeyProperty + "-" + element.id,
                  name: _vm.id + "-" + _vm.listKeyProperty,
                  "aria-describedby":
                    _vm.id + "-" + _vm.listKeyProperty + "-" + element.id
                },
                domProps: { value: element[_vm.listKeyProperty] },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(element, _vm.listKeyProperty, $event.target.value)
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group-text-list-value" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: element[_vm.listValueProperty],
                    expression: "element[listValueProperty]"
                  }
                ],
                staticClass: "form-control",
                class: {
                  error:
                    _vm.errorFieldsInherited[
                      _vm.id + "-" + _vm.listValueProperty + "-" + element.id
                    ]
                },
                attrs: {
                  type: "text",
                  id: _vm.id + "-" + _vm.listValueProperty + "-" + element.id,
                  name: _vm.id + "-" + _vm.listValueProperty,
                  "aria-describedby":
                    _vm.id + "-" + _vm.listValueProperty + "-" + element.id
                },
                domProps: { value: element[_vm.listValueProperty] },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      element,
                      _vm.listValueProperty,
                      $event.target.value
                    )
                  }
                }
              })
            ]),
            _c("div", { staticClass: "form-group-text-list-remove" }, [
              true
                ? _c("img", {
                    staticClass: "icon icon-remove icon-remove-entry",
                    attrs: {
                      src:
                        _vm.config.mediaPath.application.icons +
                        _vm.config.assets.icons.remove,
                      alt: _vm.$t(_vm.addEntryLabel)
                    },
                    on: {
                      click: function($event) {
                        return _vm.confirmRemoveEntry(index)
                      }
                    }
                  })
                : _vm._e()
            ])
          ]
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }