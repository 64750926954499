var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "body-view container-fluid",
      class: _vm.entityNature + "-view",
      attrs: { id: _vm.entityNature + "-view" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "area_title col-12 center" }, [
          _c("h1", [
            _vm._v(" " + _vm._s(_vm.$t("action.action_monitor")) + " "),
            _c("img", {
              staticClass: "icon icon-refresh",
              attrs: {
                src:
                  _vm.config.mediaPath.application.icons +
                  _vm.config.assets.icons.refresh,
                alt: _vm.$t("action.refresh")
              },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.refresh()
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
      ]),
      _c("div", { staticClass: "row relative" }, [
        _c(
          "div",
          { staticClass: "col-6 action-scheduled" },
          [
            _c("ScheduledActionList", { ref: "scheduledActionList" }),
            _c("div", { staticClass: "row relative action-committed" }, [
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("ManeuverModifierList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showModifiers,
                        expression: "showModifiers"
                      }
                    ],
                    attrs: { type: "type" }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-6" },
                [
                  _c("ManeuverModifierList", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showModifiers,
                        expression: "showModifiers"
                      }
                    ],
                    attrs: { type: "skill" }
                  })
                ],
                1
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-6" },
          [_c("CommittedActionList", { ref: "committedActionList" })],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }