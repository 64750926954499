var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "div",
        { staticClass: "master-dataset1" },
        [
          !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
          _c(
            "form",
            {
              staticClass: "master-form left",
              attrs: { enctype: "multipart/form-data" },
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c("div", { staticClass: "form-frame" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group required",
                    class: _vm.errorFields["name"] ? "error" : ""
                  },
                  [
                    _c("label", { attrs: { for: "name" } }, [
                      _vm._v(_vm._s(_vm.$t("master.name")))
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.name,
                          expression: "name"
                        }
                      ],
                      staticClass: "form-control",
                      attrs: {
                        "aria-describedby": "name",
                        id: "name",
                        name: "name",
                        required: "",
                        type: "text",
                        placeholder: _vm.$t("master.name")
                      },
                      domProps: { value: _vm.name },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.name = $event.target.value
                        }
                      }
                    })
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    class: _vm.errorFields["image"] ? "error" : ""
                  },
                  [
                    _c("label", { attrs: { for: "image" } }, [
                      _vm._v(_vm._s(_vm.$t("global.picture")))
                    ]),
                    _c("input", {
                      staticClass: "form-control",
                      attrs: {
                        accept: "image/*",
                        "aria-describedby": "image",
                        id: "image",
                        name: "image",
                        type: "file"
                      },
                      on: {
                        change: function($event) {
                          return _vm.onChangeMasterImageFile($event)
                        }
                      }
                    })
                  ]
                )
              ]),
              _c("div", { staticClass: "center" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }