var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        {
          staticClass: "body-view container",
          class: _vm.entityNature + "-list-view",
          attrs: { id: _vm.entityNature + "-list-view" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
          ]),
          _c(
            "div",
            { staticClass: "row relative" },
            [_c("ComponentItemList", { attrs: { action: "select" } })],
            1
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }