// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "/******************/\n/*** COMPONENTS ***/\n/******************/\n/******************/\n/*** COMPONENTS ***/\n/******************/\n\n/******************/\n/*** COMPONENTS ***/\n/******************/\n/******************/\n/*** COMPONENTS ***/\n/******************/\n", ""]);
// Exports
exports.locals = {
	"role-character-image-src": "\"/application/assets/images/placeholders/character.jpg\"",
	"role-master-image-src": "\"/application/assets/images/placeholders/master.jpg\"",
	"game-image-src": "\"/application/assets/images/placeholders/game.jpg\"",
	"system-image-src": "\"/application/assets/images/placeholders/system.jpg\"",
	"setting-image-src": "\"/application/assets/images/placeholders/setting.jpg\"",
	"campaign-image-src": "\"/application/assets/images/placeholders/campaign.jpg\"",
	"campaign-game-image-src": "\"/application/assets/images/placeholders/campaign_game.png\"",
	"party-image-src": "\"/application/assets/images/placeholders/party.jpg\"",
	"xl-max-break-point": "1599px",
	"xxl-min-break-point": "1600px"
};
module.exports = exports;
