var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container roles",
      class: "roles--" + _vm.type,
      attrs: { id: "roles" }
    },
    [
      _c(
        "div",
        { staticClass: "row relative" },
        [
          !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
          this.isContentReady && _vm.isRoleType
            ? [
                _vm.roles.length > 0
                  ? [
                      _vm._l(_vm.roles, function(role) {
                        return [
                          _c(
                            "div",
                            { key: role.id, staticClass: "role col-4" },
                            [
                              _c("Role", {
                                ref: role.type + "_" + role.id,
                                refInFor: true,
                                attrs: { role: role },
                                on: { "delete-role": _vm.confirmDeleteRole },
                                nativeOn: {
                                  click: function($event) {
                                    _vm.$refs[
                                      role.type + "_" + role.id
                                    ][0].selectRole(
                                      role.id,
                                      role.type,
                                      role.status,
                                      role.creation_step
                                    )
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ]
                  : _vm._e(),
                _vm.canCreateRole
                  ? _c(
                      "div",
                      { staticClass: "role role-new col-4" },
                      [
                        _c("RoleActionSelector", {
                          ref: _vm.type + "Ref",
                          attrs: {
                            title: "role." + _vm.type + ".new_" + _vm.type,
                            alt: "role." + _vm.type + ".new_" + _vm.type,
                            type: _vm.type
                          },
                          nativeOn: {
                            click: function($event) {
                              _vm.$refs[_vm.type + "Ref"].createRole()
                            }
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ]
            : _vm._e(),
          this.isContentReady &&
          !_vm.isRoleType &&
          _vm.getRoleListKeys.length > 0
            ? _vm._l(_vm.getRoleListKeys, function(role) {
                return _c(
                  "div",
                  { key: role, staticClass: "role col-4" },
                  [
                    _c("RoleActionSelector", {
                      ref: role + "Ref",
                      refInFor: true,
                      attrs: {
                        title: "role." + role + ".name",
                        alt: "role." + role + ".name",
                        type: role
                      },
                      nativeOn: {
                        click: function($event) {
                          _vm.$refs[role + "Ref"][0].selectRoleType(role)
                        }
                      }
                    })
                  ],
                  1
                )
              })
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }