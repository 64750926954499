var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isRoleLoaded()
    ? _c("div", { staticClass: "counter", class: _vm.className + "-counter" }, [
        _c("span", { staticClass: "counter-label" }, [
          _vm._v(_vm._s(_vm.$t("counters." + _vm.label)))
        ]),
        _c(
          "span",
          {
            staticClass: "counter-actual",
            class: _vm.currentPoints < 0 ? "negative" : ""
          },
          [_vm._v(_vm._s(_vm.$helpers.formatNumber(_vm.currentPoints)))]
        ),
        _vm.totalPoints != null && _vm.totalPoints !== ""
          ? _c("span", { staticClass: "counter-total" }, [
              _vm._v("/" + _vm._s(_vm.$helpers.formatNumber(_vm.totalPoints)))
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }