export const CHARACTER_CREATION_ERROR = 'CHARACTER_CREATION_ERROR';
export const CHARACTER_CREATION_REQUEST = 'CHARACTER_CREATION_REQUEST';
export const CHARACTER_CREATION_STEP_REQUEST = 'CHARACTER_CREATION_STEP_REQUEST';
export const CHARACTER_CREATION_STEP_1_REQUEST = 'CHARACTER_CREATION_STEP_1_REQUEST';
export const CHARACTER_CREATION_STEP_2_REQUEST = 'CHARACTER_CREATION_STEP_2_REQUEST';
export const CHARACTER_CREATION_STEP_3_REQUEST = 'CHARACTER_CREATION_STEP_3_REQUEST';
export const CHARACTER_CREATION_STEP_4_REQUEST = 'CHARACTER_CREATION_STEP_4_REQUEST';
export const CHARACTER_CREATION_STEP_5_REQUEST = 'CHARACTER_CREATION_STEP_5_REQUEST';
export const CHARACTER_CREATION_STEP_6_REQUEST = 'CHARACTER_CREATION_STEP_6_REQUEST';
export const CHARACTER_CREATION_STEP_7_REQUEST = 'CHARACTER_CREATION_STEP_7_REQUEST';
export const CHARACTER_CREATION_STEP_8_REQUEST = 'CHARACTER_CREATION_STEP_8_REQUEST';
export const CHARACTER_CREATION_SUCCESS = 'CHARACTER_CREATION_SUCCESS';

export const CHARACTER_DELETE = 'CHARACTER_DELETE';
export const CHARACTER_ERROR = 'CHARACTER_ERROR';
export const CHARACTER_OPERATION_SUCCESS = 'CHARACTER_OPERATION_SUCCESS';
export const CHARACTER_REFRESH = 'CHARACTER_REFRESH';
export const CHARACTER_REGENERATION = 'CHARACTER_REGENERATION';
export const CHARACTER_REQUEST = 'CHARACTER_REQUEST';
export const CHARACTER_RESET = 'CHARACTER_RESET';
export const CHARACTER_SKILLS_ERROR = 'CHARACTER_SKILLS_ERROR';
export const CHARACTER_SKILLS_RANK_UP = 'CHARACTER_SKILLS_RANK_UP';
export const CHARACTER_SKILLS_SUCCESS = 'CHARACTER_SKILLS_SUCCESS';
export const CHARACTER_STAT_PROPERTY_SET = 'CHARACTER_STAT_PROPERTY_SET';
export const CHARACTER_SUCCESS = 'CHARACTER_SUCCESS';

export const CHARACTERS_ERROR = 'CHARACTERS_ERROR';
export const CHARACTERS_REQUEST = 'CHARACTERS_REQUEST';
export const CHARACTERS_SUCCESS = 'CHARACTERS_SUCCESS';
