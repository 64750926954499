var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "body-view container role-list-view",
      attrs: { id: "role-list-view" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col 12" }, [_c("Message")], 1)
      ]),
      _c(
        "div",
        { staticClass: "row relative" },
        [_c("RoleList", { attrs: { type: _vm.type } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }