import Router from '../router';
import styles from '@/styles/_variables.scss';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

/********************** */
/* PROTOTYPE EXTENSIONS */
/********************** */
Array.prototype.pushIfNotExist =
  Array.prototype.pushIfNotExist ||
  function(element, key) {
    for (let i = 0; i < this.length; i++) {
      if (this[i][key] === element[key]) {
        return false;
      }
    }
    this.push(element);
  };

String.prototype.capitalize =
  String.prototype.capitalize ||
  function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  };

/********************/
/* INJECTED HELPERS */
/********************/

//*** DATE & TIME ***//
function getCurrentYear() {
  let d = new Date();
  return d.getFullYear();
}

function getCustomFormattedDate(isoDate, language) {
  if (!isoDate) {
    return false;
  }

  const rawDate = isoDate.split(' ')[0];
  if (!rawDate) {
    return false;
  }
  const rawDateArr = rawDate.split('-');
  const day = rawDateArr[2];
  const month = rawDateArr[1];
  const year = rawDateArr[0];
  const isLeapYear = new Date(year, month, day).getMonth() == 1;
  const dummyYear = isLeapYear ? 4 : 1;
  const dummyDate = new Date(dummyYear, month, day);

  if (language) {
    const formatter = new Intl.DateTimeFormat(language, { month: 'long' });
    const localizedMonth = formatter.format(dummyDate);
    return day + ' ' + localizedMonth + ', ' + formatNumber(year);
  }

  return day + '.' + month + '.' + year;
}

function getCustomFormattedDateTime(isoDate) {
  return getCustomFormattedDate(isoDate) + ' ' + getCustomFormattedTime(isoDate);
}

function getCustomFormattedTime(isoDate) {
  if (!isoDate) {
    return false;
  }

  const dateTimeArr = isoDate.split(' ');
  const rawTime = dateTimeArr[1];
  if (!rawTime) {
    return false;
  }
  return rawTime;
}

/**
 * Return an array of day objects with id (the cardinal number)
 * and the name
 */
function getDaysInMonth() {
  const days = [];
  for (let d = 1; d < 32; d++) {
    days.push({ id: d, name: d });
  }
  return days;
}

/**
 * Given the month number and the language returns
 * the locale month name
 */
function getLocalizedMonth(month, language) {
  const dummyDate = new Date(2000, month, 1);

  const formatter = new Intl.DateTimeFormat(language, { month: 'long' });
  return formatter.format(dummyDate);
}

/**
 * Take as input an array of the locale months (from the dictionary) and
 * return an array of month objects with id (the cardinal number)
 * and the name.
 * Why is getLocalizedMonth not used? This function can use custom names.
 */
function getMonthsInYear(monthNames) {
  const months = [];
  for (let m = 1; m < 13; m++) {
    months.push({ id: m, name: monthNames[m] });
  }
  return months;
}

//*** NUMBERS ***//
function getDecimalSeparator() {
  var n = 1.1;
  n = n.toLocaleString().substring(1, 2);
  return n;
}

function formatNumber(number) {
  if (number == null || number === '') {
    return '-';
  }
  return new Intl.NumberFormat('it-IT').format(number);
}

function formatSignedNumber(number, keepEmpty = false) {
  if (keepEmpty && (number == null || number === '')) {
    return '';
  }

  if (!number) {
    return '0';
  }

  if (number > 0) {
    return '+' + number;
  }

  return number;
}

function isPrimitive(test) {
  return test !== Object(test);
}

function roundTo(n, roundPosition) {
  if (roundPosition === undefined) {
    roundPosition = 0;
  }

  const nString = (n * 1).toString();
  let dotPosition = nString.indexOf('.');

  const decimals = nString.slice(dotPosition + 1);

  if (decimals.length > roundPosition) {
    let poweredN =
      nString.slice(0, dotPosition) +
      nString.slice(dotPosition + 1, dotPosition + 1 + roundPosition) +
      '.' +
      nString.slice(dotPosition + 1 + roundPosition, dotPosition + 1 + roundPosition + 1);
    poweredN = Math.round(poweredN * 1);
    return poweredN / Math.pow(10, roundPosition);
  }

  return n;
}

/**
 * Convert the input into an integer (taking advantage of coercion).
 * If the input is not an integer or an integer string it return zero.
 */
function sanitizeInteger(n) {
  return Number.isInteger(n * 1) ? n * 1 : 0;
}

//*** ARRAYS AND OBJECTS ***//
function array_column(array, key, noEmptyValue = true) {
  let arrayColumn = array.map(entry => entry[key]);
  if (noEmptyValue === true) {
    arrayColumn = arrayColumn.filter(function(value) {
      return value !== '' && value != null;
    });
  }
  return arrayColumn;
}

function checkIfDuplicatesInArrayOfObjects(theArray, theKey) {
  let seen = new Set();
  return theArray.some(function(currentObject) {
    return seen.size === seen.add(currentObject[theKey]).size;
  });
}

/*
function removeDuplicatesFromArrayOfObjects(theArray, theKey) {
  const uniqueItemsArray = new Set();

  return theArray.filter(el => {
    const duplicate = uniqueItemsArray.has(el[theKey]);
    uniqueItemsArray.add(el[theKey]);
    return !duplicate;
  });
}
*/

function removeDuplicatesFromArrayOfObjects(theArray, key1, key2 = null) {
  if (!key1) {
    return false;
  }

  return theArray.filter((element, index, self) => {
    if (key2) {
      return index === self.findIndex(e => e[key1] === element[key1] && e[key2] === element[key2]);
    }

    return index === self.findIndex(e => e[key1] === element[key1]);
  });
}

//*** URL ANALYSIS ***//
function checkSegment(segmentToMatch) {
  if (!segmentToMatch) {
    return false;
  }

  // check if one of the URL segments matches the given string
  return Router.currentRoute.path
    .substr(1)
    .split('/')
    .includes(segmentToMatch);
}

function getRoleType() {
  // the first segment of the path is generated directly from the role list
  return Router.currentRoute.path.substr(1).split('/')[0];
}

//*** MISCELLANEOUS ***//
function errorManager(err) {
  // eslint-disable-next-line no-console
  console.error(err.response || err);
}

function getErrorList(message) {
  let errorList = [];
  Object.keys(message).forEach(function(key) {
    if (key !== 'fields') {
      errorList.push(message[key]);
    }
  });
  return errorList;
}

//*** ACCESS AND REDIRECTING ***//
function authCreateCharacter(characterStatus) {
  if (typeof characterStatus !== 'undefined' && characterStatus !== 'incomplete') {
    if (characterStatus === 'active') {
      Router.push('/character/profile');
    } else {
      Router.push('/role/list');
    }
    return false;
  }
  return true;
}

function checkServiceAccess(accessType, characterStatus) {
  return characterStatus === accessType;
}

function goToCreationStep(creationStep, characterStep) {
  if (creationStep * 1 !== characterStep * 1) {
    Router.push('/character/create/step' + characterStep);
  }
}

//*** SKILLS AND CATEGORIES ***//
function findParentCategoryOfCategory(skills, currentCategory) {
  if (currentCategory.isSkill) {
    return false;
  }

  if (!currentCategory.parent) {
    return false;
  }

  return skills.find(function(category) {
    return !category.isSkill && category.id === currentCategory.parent;
  });
}

function findParentCategoryOfSkill(skills, currentSkill) {
  if (!currentSkill.isSkill) {
    return false;
  }

  if (currentSkill.category) {
    return skills.find(function(category) {
      return !category.isSkill && category.id === currentSkill.category;
    });
  }

  const parentSkill = skills.find(function(skill) {
    return (
      skill.isSkill &&
      ((currentSkill.cs_parent && currentSkill.cs_parent === skill.id) ||
        (currentSkill.gs_parent && currentSkill.gs_parent === skill.game_skill) ||
        (currentSkill.parent && currentSkill.parent === skill.skill))
    );
  });

  return findParentCategoryOfSkill(skills, parentSkill);
}

function findRootSkillForType(skills, currentSkill, searchedType) {
  if (!currentSkill.isSkill) {
    return false;
  }

  const parentSkill = skills.find(function(skill) {
    return (
      skill.isSkill &&
      ((currentSkill.cs_parent && currentSkill.cs_parent === skill.id) ||
        (currentSkill.gs_parent && currentSkill.gs_parent === skill.game_skill) ||
        (currentSkill.parent && currentSkill.parent === skill.skill))
    );
  });

  if (!parentSkill) {
    return false;
  }

  if (parentSkill.type === searchedType) {
    return parentSkill;
  }

  return findRootSkillForType(skills, parentSkill, searchedType);
}

function getConsumedCategoryRanksByCategories(skills, categoryId) {
  let consumedCategoryRanks = 0;
  skills.forEach(function(category) {
    if (!category.isSkill && category.parent && category.parent === categoryId && category.rank) {
      consumedCategoryRanks += category.base_rank_from_parent * 1;
    }
  });
  return consumedCategoryRanks;
}

function getConsumedCategoryRanksBySkills(skills, id, key = 'category') {
  /* look for children skills and all the skill descendants, and 
     for only direct children categories.
     If those categories have their own base rank, they must be not counted */
  let consumedCategoryRanks = 0;

  const childrenSkills = skills.filter(function(skill) {
    let match = false;
    if (skill.isSkill && skill[key] === id) {
      match = true;
      if (skill.rank) {
        consumedCategoryRanks += skill.rank * 1;
      }
    }
    return match;
  });

  if (childrenSkills.length > 0) {
    childrenSkills.forEach(function(childrenSkill) {
      consumedCategoryRanks += getConsumedCategoryRanksBySkills(skills, childrenSkill.id, 'parent');
    });
  }

  return consumedCategoryRanks;
}

function getCreateCharacterSkillTableHeaderYOffset() {
  let offset1 = 0;
  const offset2 = document.getElementById('skill-table-header-row').offsetHeight;
  let offset3 = 0;
  const xlMaxWidthBreakPoint = styles['xl-max-break-point'];
  if (window.matchMedia('(max-width: ' + xlMaxWidthBreakPoint + ')').matches) {
    offset1 = document.getElementById('sticky-counters').offsetHeight;
    offset3 = 9;
  }
  return offset3 - (offset1 + offset2);
}

function scrollToElement(elementId, offset = 0) {
  const $element = document.getElementById(elementId);
  if ($element) {
    const top = window.scrollY + $element.getBoundingClientRect().top + offset;
    window.scrollTo(0, top);
  }
}

//*** VUEX STORE ***//
function resetState(state) {
  Object.keys(state).forEach(function(key) {
    if (Array.isArray(state[key])) {
      Vue.set(state, key, []);
    } else if (typeof state[key] === 'object' && state[key] !== null) {
      Vue.set(state, key, {});
    } else {
      Vue.set(state, key, '');
    }
  });
}

export default {
  // DATE & TIME
  getCurrentYear,
  getCustomFormattedDate,
  getCustomFormattedDateTime,
  getCustomFormattedTime,
  getDaysInMonth,
  getLocalizedMonth,
  getMonthsInYear,

  // NUMBERS
  formatNumber,
  formatSignedNumber,
  getDecimalSeparator,
  isPrimitive,
  roundTo,
  sanitizeInteger,

  // ARRAYS AND OBJECTS
  array_column,
  checkIfDuplicatesInArrayOfObjects,
  removeDuplicatesFromArrayOfObjects,

  // URL ANALYSIS
  checkSegment,
  getRoleType,

  // MISCELLANEOUS
  errorManager,
  getErrorList,

  // ACCESS AND REDIRECTING
  authCreateCharacter,
  checkServiceAccess,
  goToCreationStep,

  // SKILLS AND CATEGORIES
  findParentCategoryOfCategory,
  findParentCategoryOfSkill,
  findRootSkillForType,
  getConsumedCategoryRanksByCategories,
  getConsumedCategoryRanksBySkills,
  getCreateCharacterSkillTableHeaderYOffset,
  scrollToElement,

  // VUEX STORE
  resetState
};
