<template>
  <main
    v-if="restrictedAccess_grantMaster"
    :id="entityNature + '-list-view'"
    class="body-view container"
    :class="entityNature + '-list-view'"
  >
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row relative">
      <ComponentItemList />
    </div>
  </main>
</template>

<script>
const entityNature = 'party',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import restrictedAccessMixin from '@/mixins/restrictedAccess';
import ComponentItemList from '@/components/Party/List';
import Message from '@/components/Message';

export default {
  name: entityNatureCapitalized + 'ListView',
  components: {
    ComponentItemList,
    Message
  },
  mixins: [restrictedAccessMixin],
  data() {
    return {
      entityNature,
      entityNatureCapitalized
    };
  }
};
</script>
