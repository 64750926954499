var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group-rich-text",
      class: _vm.required === "required" ? "required" : ""
    },
    [
      _c(
        "label",
        { staticClass: "form-group-rich-text-label", attrs: { for: "id" } },
        [_vm._v(_vm._s(_vm.$t(_vm.label, _vm.labelParams)))]
      ),
      _c("vue-editor", {
        staticClass: "form-group-rich-text form-control",
        attrs: {
          disabled: _vm.disabled,
          placeholder: _vm.placeholder,
          required: _vm.required,
          id: _vm.id,
          name: _vm.name,
          "aria-describedby": _vm.ariaDescribedby
        },
        model: {
          value: _vm.data,
          callback: function($$v) {
            _vm.data = $$v
          },
          expression: "data"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }