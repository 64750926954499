var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modifiers modifiers--general",
      attrs: { id: _vm.entityNature + "List" }
    },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      this.isContentReady
        ? [
            _c(
              "table",
              { staticClass: "table table-modifier table-modifier--general" },
              [
                _c("tr", [
                  _c(
                    "td",
                    {
                      staticClass: "modifiers-maneuver-type",
                      attrs: { colspan: "2" }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "action.maneuver_type." +
                                (this.maneuverType
                                  ? this.maneuverType
                                  : "skill")
                            )
                          ) +
                          " "
                      )
                    ]
                  )
                ]),
                _vm.modifierCategories.length > 0
                  ? [
                      _vm._l(_vm.modifierCategories, function(
                        category,
                        categoryIndex
                      ) {
                        return [
                          category.id
                            ? _c(
                                "tr",
                                { key: "category-modifier-" + categoryIndex },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "category-modifier-title",
                                      attrs: { colspan: "2" }
                                    },
                                    [_vm._v(_vm._s(category.name))]
                                  )
                                ]
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.categorizedModifiers(category.id),
                            function(modifier, modifierIndex) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    key:
                                      "modifier-" +
                                      categoryIndex +
                                      "-" +
                                      modifierIndex,
                                    staticClass: "modifier",
                                    class: _vm.isModifierSelected(
                                      modifier.id,
                                      modifier.isSelected
                                    ),
                                    on: {
                                      click: function($event) {
                                        return _vm.setManeuverModifier(modifier)
                                      }
                                    }
                                  },
                                  [
                                    _c("td", { staticClass: "condition" }, [
                                      _vm._v(_vm._s(modifier.condition))
                                    ]),
                                    _c("td", { staticClass: "value right" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$helpers.formatSignedNumber(
                                            modifier.value
                                          )
                                        )
                                      )
                                    ])
                                  ]
                                )
                              ]
                            }
                          )
                        ]
                      })
                    ]
                  : [
                      _c("tr", [
                        _c(
                          "td",
                          {
                            staticClass: "modifier-none",
                            attrs: { colspan: "2" }
                          },
                          [_vm._v(_vm._s(_vm.$t("global.none")))]
                        )
                      ])
                    ]
              ],
              2
            )
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }