var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "body-view col-12 center" }, [
    _c("h1", { staticClass: "area_title" }, [
      _vm._v(" " + _vm._s(_vm.$t("skill.rankup")) + " ")
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
    ]),
    _c("div", { staticClass: "row main-content" }, [
      _c("div", { staticClass: "dataset col-12 col-xxl-9" }, [
        _c(
          "div",
          { staticClass: "character-skills" },
          [
            _c("StatsBox", {
              ref: "statsBox",
              attrs: { mode: "rankup" },
              on: { "error-fields-set": _vm.form_onErrorFields }
            }),
            _c(
              "form",
              {
                staticClass: "form-skills-rank-up left",
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.saveRankUp($event)
                  }
                }
              },
              [
                _c("SkillList", {
                  ref: "skillList",
                  attrs: {
                    mode: "rankup",
                    errorFieldsInherited: _vm.errorFields
                  },
                  on: { "error-fields-set": _vm.form_onErrorFields }
                }),
                _c("div", { staticClass: "center" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      attrs: {
                        disabled: _vm.somethingHasChanged ? false : "disabled",
                        type: "submit"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("button.save")) + " ")]
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ]),
      _c(
        "div",
        {
          staticClass: "summary col-12 col-xxl-3 sticky",
          attrs: { id: "summary" }
        },
        [
          _c(
            "div",
            {
              staticClass: "sticky sticky-counters",
              attrs: { id: "sticky-counters" }
            },
            [
              _c("PartyCounterExperiencePoints"),
              _c("CharacterCounterExperiencePoints")
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }