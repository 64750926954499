<template>
  <div class="character-creation-summary-frame">
    <ul class="character-creation-summary-text" v-if="isCharacterLoaded">
      <li>
        <span class="character-creation-summary-text-label">{{ $t('character.game') }}</span
        >{{ game.name }}
      </li>
      <li>
        <span class="character-creation-summary-text-label">{{ $t('character.name') }}</span
        >{{ character.name }}
      </li>
      <li v-if="character.race && character.race.name">
        <span class="character-creation-summary-text-label">{{ $t('character.race') }}</span
        >{{ character.race.name }} ({{ character.metrics.sex_id }})
      </li>
      <li v-if="character.class && character.class.name">
        <span class="character-creation-summary-text-label">{{ $t('character.class') }}</span
        >{{ character.class.name }}
      </li>
      <li v-if="character.race && character.race.name">
        <span class="character-creation-summary-text-label">{{ $t('character.realms') }}</span>
        <template v-if="character.realms">
          <template v-for="(realm, index) in character.realms.components">
            <span :key="`realm-${index}`">{{ realm.name }}</span>
            <template v-if="index < character.realms.components.length - 1">/</template>
          </template>
        </template>
      </li>
      <li v-if="character.feature">
        <span class="character-creation-summary-text-label">{{ $t('character.feature') }}</span>
        <ul class="character-creation-summary-text-list">
          <li
            v-for="(factor, index) in character.feature"
            :class="factor.isAdvantage ? 'plus' : 'minus'"
            :key="`factor-${index}`"
          >
            {{ factor.name }}
          </li>
        </ul>
      </li>

      <li v-if="showModifiers && character.scores && character.scores.creation_modifiers">
        <span class="character-creation-summary-text-label">{{ $t('character.formation_creation_modifiers') }}</span>
        <ul class="character-creation-summary-text-list">
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.hit_points') }}</span
            >{{ character.scores.creation_modifiers.hit_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.power_points') }}</span
            >{{ character.scores.creation_modifiers.power_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.exhaustion_points') }}</span
            >{{ character.scores.creation_modifiers.exhaustion_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.reputation_points') }}</span
            >{{ character.scores.creation_modifiers.reputation_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.grace_points') }}</span
            >{{ character.scores.creation_modifiers.grace_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.sanity_points') }}</span
            >{{ character.scores.creation_modifiers.sanity_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.fate_points') }}</span
            >{{ character.scores.creation_modifiers.fate_points }}
          </li>
        </ul>
      </li>

      <li v-if="showModifiers && character.scores && character.scores.permanent_modifiers">
        <span class="character-creation-summary-text-label">{{ $t('character.formation_permanent_modifiers') }}</span>
        <ul class="character-creation-summary-text-list">
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.learning_rate') }}</span
            >{{ character.scores.permanent_modifiers.learning_rate }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.soul_departure_rounds') }}</span
            >{{ character.scores.permanent_modifiers.soul_departure_rounds }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.healing_rate') }}</span
            >{{ character.scores.permanent_modifiers.healing_rate }}
          </li>
        </ul>
      </li>

      <li v-if="character.scores && character.scores.consumable">
        <span class="character-creation-summary-text-label">{{ $t('character.formation_consumable_points') }}</span>
        <ul class="character-creation-summary-text-list">
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.background_points') }}</span
            >{{ character.scores.consumable.background_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.talent_points') }}</span
            >{{ character.scores.consumable.talent_points }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.hobby_ranks') }}</span
            >{{ character.scores.consumable.hobby_ranks }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.free_ranks') }}</span
            >{{ character.scores.consumable.free_ranks }}
          </li>
          <li class="bullet">
            <span class="character-creation-summary-text-label">{{ $t('character.development_points') }}</span
            >{{ character.scores.consumable.development_points }}
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterSummary',
  props: {
    showModifiers: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'game'])
  }
};
</script>

<style lang="scss" scoped>
.character-creation-summary {
  &-frame {
    border: 1px solid $character-creation-summary-border-color;
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 6px;
    text-align: left;

    &-label {
      text-transform: uppercase;
      font-size: 1rem;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }

    &-list {
      list-style-type: none;
      margin: 0 0 0 6px;
      padding: 0 1.2rem;

      li.minus:before,
      li.plus:before,
      li.bullet:before {
        position: absolute;
      }

      li.minus:before,
      li.plus:before {
        left: 2.5rem;
      }

      li.bullet:before {
        left: 3rem;
        margin-top: 0.1rem;
      }

      li.minus:before {
        content: $disadvantage-list-symbol;
      }

      li.plus:before {
        content: $advantage-list-symbol;
      }

      li.bullet:before {
        content: '\2022';
      }
    }
  }
}
</style>
