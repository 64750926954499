var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset6" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-frame" },
            [
              _c("div", { staticClass: "row" }, [
                _vm.spellcasterType
                  ? _c("div", { staticClass: "form-group col-5" }, [
                      _c("label", { attrs: { for: "spellcaster_type" } }, [
                        _vm._v(_vm._s(_vm.$t("character.spellcaster_type")))
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "display_data",
                          attrs: { id: "spellcaster_type" }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.spellcasterType.name) +
                              " (" +
                              _vm._s(_vm.spellcasterType.code) +
                              ")"
                          )
                        ]
                      )
                    ])
                  : _vm._e(),
                _vm.character.scores && _vm.character.scores.permanent_modifiers
                  ? _c("div", { staticClass: "form-group col-5" }, [
                      _c("label", { attrs: { for: "spellcaster_type" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("character.base_spell_list_number"))
                        )
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "display_data",
                          attrs: { id: "spellcaster_type" }
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.character.scores.permanent_modifiers
                                .base_spell_list_number
                            )
                          )
                        ]
                      )
                    ])
                  : _vm._e()
              ]),
              _vm._m(0),
              _vm._l(_vm.spellLists, function(spellList, index) {
                return _c(
                  "div",
                  { key: "spell-list-" + index, staticClass: "row" },
                  [
                    _c("FormGroupSelect", {
                      staticClass: "form-group col-12",
                      class: _vm.errorFields["spellLists"] ? "error" : "",
                      attrs: {
                        ariaDescribedby: "spell_lists_" + index,
                        collectionName: _vm.spellLists[index].collectionName,
                        data: { index: index },
                        disabled: _vm.spellLists[index].disabled,
                        id: "spell_lists_" + index,
                        label: _vm.spellLists[index].label,
                        labelParams: _vm.spellLists[index].labelParams,
                        name: _vm.spellLists[index].name,
                        onChangeCallback: _vm.onChangeSpellList,
                        optionLabel: _vm.spellLists[index].optionLabel,
                        options: _vm.spellLists[index].data,
                        optionValue: _vm.spellLists[index].optionValue,
                        placeholder: _vm.spellLists[index].placeholder,
                        required: _vm.spellLists[index].required,
                        selectValue: _vm.spellLists[index].selectedValue
                      }
                    })
                  ],
                  1
                )
              })
            ],
            2
          ),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "form-group col-12" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }