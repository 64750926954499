<template>
  <main :id="entityNature + '-view'" class="body-view container" :class="entityNature + '-view'">
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row relative">
      <ComponentItem :id="$route.params.id" />
    </div>
  </main>
</template>

<script>
const entityNature = 'party',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import ComponentItem from '@/components/Party/Detail';
import Message from '@/components/Message';

export default {
  name: entityNatureCapitalized + 'View',
  components: {
    ComponentItem,
    Message
  },
  data() {
    return {
      entityNature,
      entityNatureCapitalized
    };
  }
};
</script>
