<template>
  <main v-if="restrictedAccess_grantMaster" id="game-skill-list-view" class="body-view container game-skill-list-view">
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row main-content relative">
      <div class="dataset col-12">
        <div class="game-skills">
          <form class="game-form left" @submit.prevent="">
            <SkillList
              v-if="$store.state.game.id"
              @error-fields-set="form_onErrorFields"
              ref="skillList"
              filter="all"
              mode="play"
              gameId="$store.state.game.id"
              :errorFieldsInherited="errorFields"
            />
          </form>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import formMixin from '@/mixins/form';
import Message from '@/components/Message';
import SkillList from '@/components/Skill/List';
import { EventBus } from '@/utils/eventBus';
import { mapState } from 'vuex';

export default {
  name: 'GameSkillsView',
  components: {
    Message,
    SkillList
  },
  mixins: [restrictedAccessMixin, formMixin],
  data() {
    return {
      errorFields: [],
      formValidationMessage: '',
      nextRoute: '',
      leavePageConfirmed: false
    };
  },
  computed: {
    ...mapState(['game', 'system', 'scores']),
    somethingHasChanged() {
      let totalDevelopmentPointsAllocated = 0;
      if (this.$refs.skillList) {
        this.$refs.skillList.skills.forEach(function(skill) {
          totalDevelopmentPointsAllocated += skill.development_points_allocated
            ? skill.development_points_allocated * 1
            : 0;
        });
      }
      return totalDevelopmentPointsAllocated > 0;
    }
  },
  created() {
    EventBus.$on('modal-leave-confirm', () => {
      this.leavePageConfirmed = true;
      this.$router.push(this.nextRoute);
    });
    EventBus.$on('modal-leave-stay', () => {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.somethingHasChanged && !this.leavePageConfirmed) {
      this.$emit('modal', 'stay-leave', {
        headerText: 'modal.questions.sure_to_leave',
        bodyText: 'message.unsaved_changes'
      });
      this.nextRoute = to.path;
    } else {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
      EventBus.$off('modal-leave-confirm');
      EventBus.$off('modal-leave-stay');
      next();
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.game-form {
  margin-top: auto;
  /deep/ .skill-table.table-fixed thead th {
    top: 0;
  }
}
</style>
