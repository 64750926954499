import { AUTH_LOGOUT } from '../actions/auth';
import { ROLE_SELECT_RESET } from '../actions/role';
import {
  SETTING_ERROR,
  SETTING_REQUEST,
  SETTING_RESET,
  SETTING_SUCCESS,
  SETTINGS_ERROR,
  SETTINGS_REQUEST,
  SETTINGS_SUCCESS
} from '../actions/setting';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  name: '',
  statusVuex: {}
};

const getters = {
  getSettingStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isSettingLoaded: state => !!state.id
};

const actions = {
  [SETTING_REQUEST]: ({ commit, dispatch, rootState }, id = null) => {
    // only for masters, so id is necessary
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(SETTING_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSetting + id, params)
        .then(resp => {
          commit(SETTING_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SETTING_RESET);
          commit(SETTING_ERROR);
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },
  [SETTINGS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(SETTINGS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSettingList, params)
        .then(resp => {
          commit(SETTINGS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SETTINGS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [SETTING_ERROR]: state => {
    Vue.set(state.statusVuex, 'setting', 'error');
  },
  [SETTING_REQUEST]: state => {
    Vue.set(state.statusVuex, 'setting', 'loading');
  },
  [SETTING_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [SETTING_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'setting', 'success');
    state = Object.assign(state, resp);
  },

  [SETTINGS_ERROR]: state => {
    Vue.set(state.statusVuex, 'settings', 'error');
  },
  [SETTINGS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'settings', 'loading');
  },
  [SETTINGS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'settings', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
