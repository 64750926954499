<template>
  <div id="app">
    <AppHeader />
    <Modal
      v-if="modal.show"
      :bodyText="modal.bodyText"
      :buttonCancel="modal.buttonCancel"
      :buttonConfirm="modal.buttonConfirm"
      :footerText="modal.footerText"
      :headerText="modal.headerText"
      :type="modal.type"
      @confirm="modal.show = false"
      @cancel="modal.show = false"
      @close="modal.show = false"
    />
    <Navigation v-if="isAuthenticated" />
    <RouterView v-if="isReadyForRender" :key="$route.path" @modal="modal_handle" />
    <AppFooter />
  </div>
</template>

<script>
import { GAME_LOAD } from '@/store/actions/game';
import { INSTANCE_CHARACTER_REQUEST } from '@/store/actions/instance';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { PREFERENCES_REQUEST } from '@/store/actions/preferences';
import { ROLE_SET, ROLES_REQUEST } from '@/store/actions/role';
import { SYSTEM_CHARACTER_REQUEST } from '@/store/actions/system';
import Navigation from '@/components/Navigation';
import AppHeader from '@/components/Header';
import AppFooter from '@/components/Footer';
import Modal from '@/components/Modal';
import modalMixin from '@/mixins/modal';
import { mapGetters, mapState } from 'vuex';
import { EventBus } from '@/utils/eventBus';

export default {
  name: 'App',
  components: {
    AppFooter,
    AppHeader,
    Modal,
    Navigation
  },
  mixins: [modalMixin],
  data() {
    return {
      isReadyForRender: true,
      modal: {
        bodyText: '',
        buttonCancel: '',
        buttonConfirm: '',
        headerText: '',
        show: false
      }
    };
  },
  computed: {
    ...mapGetters([
      'getRoleListKeys',
      'getStoredRoleId',
      'getStoredRoleType',
      'getStoredGameId',
      'isAuthenticated',
      'isRoleLoaded'
    ]),
    ...mapState(['character'])
  },
  async created() {
    EventBus.$on('modal', this.modal_handle);

    const roleId = this.getStoredRoleId,
      roleType = this.getStoredRoleType,
      gameId = this.getStoredGameId;

    if (roleId && roleType) {
      try {
        // roleId and roleType must be loaded in the store
        this.$store.dispatch(ROLE_SET, { id: roleId, type: roleType }); // synchronous action

        // From this point on the user is logged in
        if (!this.isRoleLoaded()) {
          if (!roleId || !roleType) {
            // It can't load the role, redirect to role list page
            if (this.$router.currentRoute.name !== 'RoleListView') {
              this.$router.push('/role/list');
            }
            this.isReadyForRender = true;
            return;
          }

          // 2) Get possible role types and check if the locally stored type is consistent
          const roleTypes =
            this.getRoleListKeys.length === 0
              ? (await this.$store.dispatch(ROLES_REQUEST)).map(role => role.id)
              : this.getRoleListKeys;

          if (!roleTypes || !roleTypes.includes(roleType)) {
            // either something wrong with the roles from the DB or with the store role type
            if (this.$router.currentRoute.name !== 'RoleListView') {
              this.$router.push('/role/list');
            }
            this.isReadyForRender = true;
            return;
          }
        }

        if (roleType === 'character') {
          // 3) Get System specifications (necessary for the role request)
          await this.$store.dispatch(SYSTEM_CHARACTER_REQUEST, roleId);
        }

        // 4) Role data is not available => load role
        await this.$store.dispatch(roleType.toUpperCase() + '_REQUEST', roleId);

        /*
        5) Get application preferences and role instance.
        It's important to get a fresh version of the instance since it has many 
        dependencies.
        */
        console.log('PREFERENCES_REQUEST: è qui che viene impostata la lingua nel BE');
        await Promise.all([
          this.$store.dispatch(PREFERENCES_REQUEST),
          roleType === 'character' ? this.$store.dispatch(INSTANCE_CHARACTER_REQUEST) : Promise.resolve(),
          roleType === 'master' && gameId ? this.$store.dispatch(GAME_LOAD, gameId) : Promise.resolve()
        ]);

        if (this.$router.currentRoute.name === 'LoginView') {
          this.$router.back();
        }

        this.isReadyForRender = true;
      } catch (err) {
        this.$store.commit(MESSAGE_ERROR_SET, err);
        this.$helpers.errorManager(err);
      }
    }
  },
  beforeDestroy() {
    this.$eventHub.$off('modal', this.modal_handle);
  }
};
</script>
