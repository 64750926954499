import { AUTH_LOGOUT } from '../actions/auth';
import { REALM_RESET, REALMS_ERROR, REALMS_REQUEST, REALMS_SUCCESS } from '../actions/realm';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getRealmStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [REALMS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(REALMS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getMagicRealmList, params)
        .then(resp => {
          commit(REALMS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(REALMS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [REALM_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [REALMS_ERROR]: state => {
    Vue.set(state.statusVuex, 'realms', 'error');
  },
  [REALMS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'realms', 'loading');
  },
  [REALMS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'realms', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
