export const CAMPAIGN_CREATION_ERROR = 'CAMPAIGN_CREATION_ERROR';
export const CAMPAIGN_CREATION_REQUEST = 'CAMPAIGN_CREATION_REQUEST';
export const CAMPAIGN_CREATION_SUCCESS = 'CAMPAIGN_CREATION_SUCCESS';
export const CAMPAIGN_DELETE = 'CAMPAIGN_DELETE';
export const CAMPAIGN_ERROR = 'CAMPAIGN_ERROR';
export const CAMPAIGN_REQUEST = 'CAMPAIGN_REQUEST';
export const CAMPAIGN_RESET = 'CAMPAIGN_RESET';
export const CAMPAIGN_SELECT = 'CAMPAIGN_SELECT';
export const CAMPAIGN_SUCCESS = 'CAMPAIGN_SUCCESS';
export const CAMPAIGN_TEMPLATE_CREATION_REQUEST = 'CAMPAIGN_TEMPLATE_CREATION_REQUEST';
export const CAMPAIGN_TEMPLATES_REQUEST = 'CAMPAIGN_TEMPLATES_REQUEST';

export const CAMPAIGNS_ERROR = 'CAMPAIGNS_ERROR';
export const CAMPAIGNS_REQUEST = 'CAMPAIGNS_REQUEST';
export const CAMPAIGNS_SUCCESS = 'CAMPAIGNS_SUCCESS';
