<template>
  <nav class="navigation container-fluid">
    <!--div>
      <RouterLink class="logo" to="/">
        <img src="../../assets/logo.png" width="40px" /><strong>SYNTROPY</strong>
      </RouterLink>
    </div-->
    <ul class="menu menu--player tabs" v-if="isRoleLoaded() && isCharacter">
      <li>
        <RouterLink to="/character/profile">{{ $t('menu.character.profile') }}</RouterLink>
      </li>
      <template v-if="isCharacterActive">
        <li>
          <RouterLink to="/character/skills">{{ $t('menu.character.skills') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/party">{{ $t('menu.character.party') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/play/action/monitor">{{ $t('menu.character.play.action.monitor') }}</RouterLink>
        </li>
        <!--li>
          <RouterLink to="/character/skills/rankup">{{ $t('menu.character.rankup') }}</RouterLink>
        </li-->
      </template>
    </ul>

    <ul class="menu menu--master tabs" v-if="isRoleLoaded() && isMaster">
      <li>
        <RouterLink to="/master/profile">{{ $t('menu.master.profile') }}</RouterLink>
      </li>
    </ul>

    <template v-if="isMasterLoaded && isMaster">
      <ul class="menu menu--master submenu submenu--all tabs">
        <li>
          <span>{{ $t('menu.master.all') }}</span>
        </li>
        <li>
          <RouterLink to="/system/list">{{ $t('menu.master.systems') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/game/list">{{ $t('menu.master.games') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/setting/list">{{ $t('menu.master.settings') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/campaign/list">{{ $t('menu.master.campaigns') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/party/list">{{ $t('menu.master.parties') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/master/system">{{ $t('menu.master.characters') }}</RouterLink>
        </li>
      </ul>

      <ul class="menu menu--master submenu submenu--selected-system tabs">
        <template v-if="isSystemLoaded">
          <li>
            <span>{{ $t('menu.master.selected_system') }}</span>
          </li>
          <li>
            <RouterLink to="/system/x">{{ $t('menu.master.system') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/skill/list">{{ $t('menu.master.skills') }}</RouterLink>
          </li>
        </template>
      </ul>

      <ul class="menu menu--master submenu submenu--game tabs">
        <li>
          <span>{{ $t('menu.master.selected_game') }}</span>
        </li>
        <template v-if="isGameLoaded">
          <li>
            <RouterLink to="/play/action/monitor">{{ $t('menu.character.play.action.monitor') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/play/action">{{ $t('menu.master.action') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/game/instance">{{ $t('menu.master.game') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/game/skills">{{ $t('menu.master.skills') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/game/campaign">{{ $t('menu.master.campaign') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/game/characters">{{ $t('menu.master.characters') }}</RouterLink>
          </li>
          <li>
            <RouterLink to="/game/party">{{ $t('menu.master.party') }}</RouterLink>
          </li>
        </template>
        <template v-else>
          <li>
            <RouterLink to="/game/select">{{ $t('menu.master.select_game') }}</RouterLink>
          </li>
        </template>
      </ul>
    </template>
  </nav>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Navigation',
  computed: {
    ...mapGetters(['isCharacterLoaded', 'isMasterLoaded', 'isRoleLoaded']),
    isCharacter() {
      return this.$store.getters.isCharacter;
    },
    isCharacterActive() {
      return this.$store.state.character.status === 'active';
    },
    isGameLoaded() {
      return this.$store.getters.isGameLoaded;
    },
    isMaster() {
      return this.$store.getters.isMaster;
    },
    isSystemLoaded() {
      return this.$store.getters.isSystemLoaded;
    }
  }
};
</script>

<style lang="scss" scoped>
.navigation {
  display: inline-block;
  border-bottom: 1px solid $nav-border-color;
  margin-bottom: $nav-margin-bottom;

  .menu {
    &.tabs {
      display: flex;
      justify-content: center;
      margin: 0 0 -1px;

      li {
        display: block;

        a,
        span {
          border-style: solid;
          border-width: 1px 0 1px 1px;
          display: inline-block;
          font-size: 1.2rem;
          font-weight: bold;
          letter-spacing: 1px;
          padding: 10px;
          text-decoration: none;
          text-transform: uppercase;
          transition: color 0.2s;

          &:last-child {
            border-right-width: 1px;
          }

          &:active,
          &:link,
          &:visited {
            text-decoration: none;
          }

          &.RouterLink-active {
            background-color: $page-background-color;
            border-bottom-width: 1px;
            border-bottom-style: solid;
          }
        }

        span {
          border-color: #dcdcdc;
          background-color: #fdfdfd;
          color: #afafaf;

          &::after {
            content: ':';
            display: inline-block;
          }
        }

        .tab-link {
          cursor: pointer;
        }
      }
    }

    &--player {
      &.tabs {
        li {
          a {
            background-color: $nav-tab-inactive-background-color--player;
            border-color: $nav-tab-border-color--player;
            color: $nav-tab-inactive-color--player;

            &:active,
            &:link,
            &:visited {
              color: $nav-tab-inactive-color--player;
            }

            &:hover {
              color: $nav-tab-hover-color--player;
            }

            &.RouterLink-active {
              border-top-color: $nav-tab-active-color--player;
              box-shadow: inset 0 3px 0 $nav-tab-active-color--player;
              color: $nav-tab-active-color--player;
            }
          }

          .tab-link {
            cursor: pointer;
          }
        }
      }
    }

    &--master {
      &.tabs {
        li {
          a {
            background-color: $nav-tab-inactive-background-color--master;
            border-color: $nav-tab-border-color--master;
            color: $nav-tab-inactive-color--master;

            &:active,
            &:link,
            &:visited {
              color: $nav-tab-inactive-color--master;
            }

            &:hover {
              color: $nav-tab-hover-color--master;
            }

            &.RouterLink-active {
              border-top-color: $nav-tab-active-color--master;
              box-shadow: inset 0 3px 0 $nav-tab-active-color--master;
              color: $nav-tab-active-color--master;
            }
          }

          .tab-link {
            cursor: pointer;
          }
        }
      }

      &.submenu {
        &--all {
          &.tabs {
            li {
              a {
                background-color: #e4e4e4;
              }
            }
          }
        }

        &--game {
          &.tabs {
            li {
              a {
                background-color: #f1efff;
              }
            }
          }
        }
      }
    }
  }
}

.logo {
  display: flex;
  align-items: center;
}
</style>
