<template>
  <div
    class="tile tile-selectable tile-with-background role-frame role-frame-new"
    :class="'tile-selectable-new--' + type"
  >
    <img border="0" class="tile-image role-image image-fill" :alt="$t(alt)" :src="imageUrl" />
    <div class="tile-selectable-text role-text">
      {{ $t(title) }}
    </div>
  </div>
</template>

<script>
import { ROLE_SELECT_RESET } from '@/store/actions/role';
import styles from '@/styles/_variables.scss';

export default {
  name: 'RoleActionSelector',
  props: ['alt', 'title', 'type'],
  computed: {
    imageUrl() {
      return styles['role-' + this.type + '-image-src'].replace(/"/g, '');
    }
  },
  methods: {
    async createRole() {
      await this.$store.dispatch(ROLE_SELECT_RESET);
      this.$router.push('/' + this.type + '/create/step1');
      return true;
    },
    selectRoleType(role) {
      this.$router.push('/' + role + '/list');
      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.tile-selectable {
  &-new {
    &--character {
      border-color: $role-character-border-color;

      &:hover {
        border-color: $role-character-border-color-hover;
      }
    }

    &--master {
      border-color: $role-master-border-color;

      &:hover {
        border-color: $role-master-border-color-hover;
      }
    }
  }
}

.role {
  &-frame {
    align-items: center;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    position: relative;
    z-index: $tile-z-index;
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 6px;
  }
}
</style>
