var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "div",
        { staticClass: "body-view col-12 center" },
        [
          _c("h1", { staticClass: "area_title" }, [
            _vm._v(" " + _vm._s(_vm.$t("game.create_game")) + " ")
          ]),
          _c("Message"),
          _c(
            "form",
            {
              staticClass: "form-game-create left",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-frame" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group required",
                      class: _vm.errorFields["name"] ? "error" : ""
                    },
                    [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v(_vm._s(_vm.$t("game.name")))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          required: "",
                          type: "text",
                          id: "name",
                          name: "name",
                          "aria-describedby": "name",
                          placeholder: _vm.$t("game.name")
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ]
                  ),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["system"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.system.selectedValue,
                      required: _vm.system.required,
                      id: _vm.system.id,
                      name: _vm.system.name,
                      optionValue: _vm.system.optionValue,
                      optionLabel: _vm.system.optionLabel,
                      ariaDescribedby: _vm.system.ariaDescribedby,
                      label: _vm.system.label,
                      placeholder: _vm.system.placeholder,
                      options: _vm.system.data
                    }
                  }),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["setting"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.setting.selectedValue,
                      required: _vm.setting.required,
                      id: _vm.setting.id,
                      name: _vm.setting.name,
                      optionValue: _vm.setting.optionValue,
                      optionLabel: _vm.setting.optionLabel,
                      ariaDescribedby: _vm.setting.ariaDescribedby,
                      label: _vm.setting.label,
                      placeholder: _vm.setting.placeholder,
                      options: _vm.setting.data
                    }
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6 required",
                        class: _vm.errorFields["starting_datetime"]
                          ? "error"
                          : ""
                      },
                      [
                        _c("label", { attrs: { for: "starting_datetime" } }, [
                          _vm._v(
                            _vm._s(_vm.$t("game.starting_datetime")) + " ("
                          ),
                          _c("span", { staticClass: "label-data-format" }, [
                            _vm._v(_vm._s(_vm.$t("game.datetime_format")))
                          ]),
                          _vm._v(")")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.starting_datetime,
                              expression: "starting_datetime"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            required: "",
                            type: "text",
                            id: "starting_datetime",
                            name: "starting_datetime",
                            "aria-describedby": "starting_datetime"
                          },
                          domProps: { value: _vm.starting_datetime },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.starting_datetime = $event.target.value
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["ending_datetime"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "ending_datetime" } }, [
                          _vm._v(_vm._s(_vm.$t("game.ending_datetime")) + " ("),
                          _c("span", { staticClass: "label-note" }, [
                            _vm._v(_vm._s(_vm.$t("game.datetime_format")))
                          ]),
                          _vm._v(")")
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.ending_datetime,
                              expression: "ending_datetime"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "ending_datetime",
                            name: "ending_datetime",
                            "aria-describedby": "ending_datetime"
                          },
                          domProps: { value: _vm.ending_datetime },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.ending_datetime = $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["starting_grace_points"]
                          ? "error"
                          : ""
                      },
                      [
                        _c(
                          "label",
                          { attrs: { for: "starting_grace_points" } },
                          [_vm._v(_vm._s(_vm.$t("game.starting_grace_points")))]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.starting_grace_points,
                              expression: "starting_grace_points"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "starting_grace_points",
                            name: "starting_grace_points",
                            "aria-describedby": "starting_grace_points",
                            placeholder: _vm.$t("game.starting_grace_points")
                          },
                          domProps: { value: _vm.starting_grace_points },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.starting_grace_points = $event.target.value
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["starting_reputation_points"]
                          ? "error"
                          : ""
                      },
                      [
                        _c(
                          "label",
                          { attrs: { for: "starting_reputation_points" } },
                          [
                            _vm._v(
                              _vm._s(_vm.$t("game.starting_reputation_points"))
                            )
                          ]
                        ),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.starting_reputation_points,
                              expression: "starting_reputation_points"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "starting_reputation_points",
                            name: "starting_reputation_points",
                            "aria-describedby": "starting_reputation_points",
                            placeholder: _vm.$t(
                              "game.starting_reputation_points"
                            )
                          },
                          domProps: { value: _vm.starting_reputation_points },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.starting_reputation_points =
                                $event.target.value
                            }
                          }
                        })
                      ]
                    )
                  ])
                ],
                1
              ),
              _c("div", { staticClass: "center" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" " + _vm._s(_vm.$t("button.create")) + " ")]
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }