export default {
  methods: {
    formulas_rankBonus(rank_progression, rank, base_rank) {
      return this.formulas_totalRanks(rank, base_rank) * rank_progression;
    },
    formulas_rollDice(faces, n = 1) {
      let score = 0;

      for (let i = 0; i < n; i++) {
        score += Math.ceil(Math.random() * faces);
      }

      return score;
    },
    formulas_rollInitiative() {
      return this.formulas_rollDice(20, 1);
    },
    formulas_rollOpenDice(faces, n = 1) {
      const maxScorable = n * faces;
      const openEndProbability = this.$store.state.system.skill_open_end_probability; // percentage (range from 1 to 50. More than 50% would overlap the thresholds)

      let openEndRange = Math.floor((maxScorable / 100) * openEndProbability); // number range (upper limit). E.g. 4% of 1d100 = 4; 10% of 1d20 = 2.
      openEndRange = openEndRange === 0 ? 1 : openEndRange;

      const openEndThreshHoldDown = openEndRange + 1;
      const openEndThreshHoldUp = openEndRange === 1 ? maxScorable - 1 : maxScorable - openEndRange - 1;
      const scored = [];

      let reroll = false;
      let operation;
      let rolledDice = this.formulas_rollDice(faces, n);
      scored.push(rolledDice);

      if (rolledDice < openEndThreshHoldDown) {
        operation = 'subtract';
        reroll = true;
      } else if (rolledDice > openEndThreshHoldUp) {
        operation = 'add';
        reroll = true;
      }

      while (reroll || rolledDice > openEndThreshHoldUp) {
        reroll = false;
        rolledDice = this.formulas_rollDice(faces, n);
        if (operation === 'subtract') {
          scored.push(-rolledDice);
        } else if (operation === 'add') {
          scored.push(rolledDice);
        }
      }

      return scored;
    },
    formulas_rollSkill() {
      return this.formulas_rollOpenDice(100, 1).join('|');
    },
    formulas_stat3Bonus(skill) {
      if (skill.stat3_code) {
        return skill.stat3_bonus;
      } else if (skill.stat1_code && skill.stat2_code) {
        return Math.floor((skill.stat1_bonus * 1 + skill.stat2_bonus * 1) / 2);
      }
      return '';
    },
    formulas_statsBonus(stat1_bonus, stat2_bonus, stat3_bonus) {
      let statsBonus = '';
      if (stat1_bonus !== '') {
        statsBonus = statsBonus * 1 + stat1_bonus * 1;
      }
      if (stat2_bonus !== '') {
        statsBonus = statsBonus * 1 + stat2_bonus * 1;
      }
      if (stat3_bonus !== '') {
        statsBonus = statsBonus * 1 + stat3_bonus * 1;
      } else if (stat1_bonus !== '' && stat2_bonus !== '') {
        statsBonus = statsBonus * 1 + Math.floor((stat1_bonus * 1 + stat2_bonus * 1) / 2);
      }
      return statsBonus * 1;
    },
    formulas_totalBonus(skill) {
      const totalRanks = this.formulas_totalRanks(skill.rank, skill.base_rank);
      return (
        skill.rank_progression * totalRanks +
        (totalRanks > 0 ? 0 : skill.no_rank_penalty * 1) +
        this.formulas_statsBonus(skill.stat1_bonus, skill.stat2_bonus, skill.stat3_bonus) +
        skill.base_modifier * 1
      );
    },
    formulas_totalRanks(rank, base_rank) {
      return rank * 1 + base_rank * 1;
    }
  }
};
