var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tile tile-selectable tile-with-background item-frame",
      on: {
        click: function($event) {
          return _vm.createCampaign()
        }
      }
    },
    [
      _c("div", { staticClass: "tile-image-frame item-image-frame" }, [
        _c("img", {
          staticClass: "item-image image-fill-frame",
          attrs: {
            border: "0",
            alt: _vm.$t(_vm.entityNature + ".create_" + _vm.entityNature),
            src: _vm.newCampaignImageUrl
          }
        })
      ]),
      _c("div", { staticClass: "tile-selectable-text item-text" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t(_vm.entityNature + ".create_" + _vm.entityNature)) +
            " "
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }