var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message.code
    ? _c(
        "div",
        {
          staticClass: "message alert",
          class: [
            _vm.alignment,
            _vm.operationResultClass(_vm.message.operationResult)
          ],
          attrs: { id: "message" }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("message." + _vm.message.code)) + " "),
          _vm.message.errorList.length > 0
            ? _c(
                "ul",
                { staticClass: "error_list" },
                _vm._l(_vm.message.errorList, function(error, index) {
                  return _c("li", { key: "error-" + index }, [
                    _vm._v(" " + _vm._s(_vm.$t("message." + error)) + " ")
                  ])
                }),
                0
              )
            : _vm._e()
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }