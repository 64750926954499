<template>
  <div class="character-skills">
    <nav class="navigation container-fluid">
      <ul class="menu menu--skills tabs">
        <li>
          <RouterLink to="/character/skills/all">{{ $t('menu.skills.all') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/SM">{{ $t('menu.skills.static_maneuvers') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/MM">{{ $t('menu.skills.movement_maneuvers') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/CM">{{ $t('menu.skills.combat') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/SC">{{ $t('menu.skills.spells') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/OB">{{ $t('menu.skills.observation') }}</RouterLink>
        </li>
        <li>
          <RouterLink to="/character/skills/CO">{{ $t('menu.skills.communication') }}</RouterLink>
        </li>
      </ul>
    </nav>
    <div class="body-view">
      <div class="col-12 center">
        <h1 class="area_title">
          {{ $t(`skills.skills`) + ': ' + (isFiltered ? $t(`skills.${filter}`) : $t(`skills.select_filter`)) }}
        </h1>
        <div class="row">
          <div class="col-12">
            <Message />
          </div>
        </div>
        <div class="row sticky row-summary">
          <div class="summary">
            <div id="committed-actions" class="committed-actions col-5">
              <CommittedActionList
                v-if="$store.state.character.id"
                ref="committedActionList"
                mode="compact"
                :modeCharacter="$store.state.character.id"
              />
            </div>
            <div id="preferred-skills" class="preferred-skills col-6">
              <!--CommittedActionList ref="committedActionList" /-->
            </div>
          </div>
        </div>
        <div class="row main-content">
          <div class="dataset col-12">
            <div class="character-skills">
              <form class="form-skills left" @submit.prevent="">
                <SkillList
                  v-if="$store.state.character.id && isFiltered"
                  @error-fields-set="form_onErrorFields"
                  ref="skillList"
                  mode="play"
                  characterId="$store.state.character.id"
                  :errorFieldsInherited="errorFields"
                />
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import Message from '@/components/Message';
import CommittedActionList from '@/components/Action/CommitmentList';
import SkillList from '@/components/Skill/List';
import { EventBus } from '@/utils/eventBus';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CharacterSkillsView',
  components: {
    CommittedActionList,
    Message,
    SkillList
  },
  mixins: [formMixin],
  data() {
    return {
      errorFields: [],
      filter: this.$route.params.filter,
      formValidationMessage: '',
      nextRoute: '',
      leavePageConfirmed: false
    };
  },
  computed: {
    ...mapState(['character', 'system', 'scores']),
    ...mapGetters(['isCharacterLoaded']),
    isFiltered() {
      return !!this.$route.params.filter;
    },
    somethingHasChanged() {
      let totalDevelopmentPointsAllocated = 0;
      if (this.$refs.skillList) {
        this.$refs.skillList.skills.forEach(function(skill) {
          totalDevelopmentPointsAllocated += skill.development_points_allocated
            ? skill.development_points_allocated * 1
            : 0;
        });
      }
      return totalDevelopmentPointsAllocated > 0;
    }
  },
  created() {
    if (this.isCharacterLoaded) {
      this.validateAccess();
    } else {
      this.unwatchMaster = this.$watch('isCharacterLoaded', () => {
        this.unwatchMaster();
        this.validateAccess();
      });
    }

    EventBus.$on('modal-leave-confirm', () => {
      this.leavePageConfirmed = true;
      this.$router.push(this.nextRoute);
    });
    EventBus.$on('modal-leave-stay', () => {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
    });
    EventBus.$on('refresh-committed-action-list', () => {
      this.refreshCommittedActionList();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.somethingHasChanged && !this.leavePageConfirmed) {
      this.$emit('modal', 'stay-leave', {
        headerText: 'modal.questions.sure_to_leave',
        bodyText: 'message.unsaved_changes'
      });
      this.nextRoute = to.path;
    } else {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
      EventBus.$off('modal-leave-confirm');
      EventBus.$off('modal-leave-stay');
      next();
    }
  },
  methods: {
    refreshCommittedActionList() {
      this.$refs.committedActionList.populateActions(true);
    },
    validateAccess() {
      if (this.isCharacterLoaded && !this.$helpers.checkServiceAccess('active', this.$store.state.character.status)) {
        this.$router.push('/character/profile');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.character-skills {
  @include sticky-counters-on-full-width-page();

  /deep/ .total_ranks {
    cursor: pointer;
  }

  .row-summary {
    background-color: #ffffff;
    z-index: $summary-z-index;
    height: 170px;

    .summary {
      background-color: #ffffff;
      display: flex;
      height: 155px;
      margin-bottom: 0;
      width: 100%;

      .committed-actions,
      .preferred-skills {
        height: 100%;
        overflow: auto;
      }

      .committed-actions {
        padding-right: 0;
      }
    }
  }

  .navigation {
    display: inline-block;
    margin-bottom: $nav-margin-bottom;

    .menu {
      &.tabs {
        display: flex;
        justify-content: center;
        margin: 0 0 -1px;

        li {
          display: block;

          a,
          span {
            border-style: solid;
            border-width: 1px 0 1px 1px;
            display: inline-block;
            font-size: 1.2rem;
            font-weight: bold;
            letter-spacing: 1px;
            padding: 6px;
            text-decoration: none;
            text-transform: uppercase;
            transition: color 0.2s;

            &:last-child {
              border-right-width: 1px;
            }

            &:active,
            &:link,
            &:visited {
              text-decoration: none;
            }

            &.RouterLink-active {
              background-color: $page-background-color;
              border-bottom-width: 1px;
              border-bottom-style: solid;
            }
          }

          span {
            border-color: #dcdcdc;
            background-color: #fdfdfd;
            color: #afafaf;

            &::after {
              content: ':';
              display: inline-block;
            }
          }

          .tab-link {
            cursor: pointer;
          }
        }
      }

      &.tabs {
        li {
          a {
            background-color: $nav-tab-inactive-background-color--skills;
            border-color: $nav-tab-border-color--skills;
            color: $nav-tab-inactive-color--skills;

            &:active,
            &:link,
            &:visited {
              color: $nav-tab-inactive-color--skills;
            }

            &:hover {
              color: $nav-tab-hover-color--skills;
            }

            &.RouterLink-active {
              border-top-color: $nav-tab-active-color--skills;
              box-shadow: inset 0 3px 0 $nav-tab-active-color--skills;
              color: $nav-tab-active-color--skills;
            }
          }

          .tab-link {
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
