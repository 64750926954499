var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "col-4 offset-4 center" },
    [
      _c("Message", {
        attrs: {
          code: _vm.message.code,
          errorList: _vm.message.errorList,
          operationResult: _vm.message.operationResult
        }
      }),
      _c(
        "form",
        {
          staticClass: "form-login left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.login($event)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "form-frame" },
            [
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "email" } }, [
                  _vm._v(_vm._s(_vm.$t("login.email")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.email,
                      expression: "email"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    required: "",
                    type: "text",
                    id: "email",
                    name: "email",
                    "aria-describedby": "email",
                    placeholder: _vm.$t("login.email"),
                    autocomplete: "on"
                  },
                  domProps: { value: _vm.email },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.email = $event.target.value
                    }
                  }
                })
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("label", { attrs: { for: "password" } }, [
                  _vm._v(_vm._s(_vm.$t("login.password")))
                ]),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.password,
                      expression: "password"
                    }
                  ],
                  staticClass: "form-control",
                  attrs: {
                    required: "",
                    type: "password",
                    id: "password",
                    name: "password",
                    "aria-describedby": "password",
                    placeholder: _vm.$t("login.password"),
                    autocomplete: "on"
                  },
                  domProps: { value: _vm.password },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.password = $event.target.value
                    }
                  }
                })
              ]),
              _c("FormGroupSelect", {
                staticClass: "form-group",
                class: _vm.errorFields["language"] ? "error" : "",
                attrs: {
                  selectValue: _vm.language.selectedValue,
                  required: _vm.language.required,
                  id: _vm.language.id,
                  name: _vm.language.name,
                  optionValue: _vm.language.optionValue,
                  optionLabel: _vm.language.optionLabel,
                  ariaDescribedby: _vm.language.ariaDescribedby,
                  label: _vm.language.label,
                  placeholder: _vm.language.placeholder,
                  options: _vm.language.data
                }
              }),
              _c("div", { staticClass: "form-check" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.rememberMe,
                      expression: "rememberMe"
                    }
                  ],
                  staticClass: "form-check-input",
                  attrs: {
                    type: "checkbox",
                    id: "remember_me",
                    name: "remember_me",
                    value: "1",
                    checked: ""
                  },
                  domProps: {
                    checked: Array.isArray(_vm.rememberMe)
                      ? _vm._i(_vm.rememberMe, "1") > -1
                      : _vm.rememberMe
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.rememberMe,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = "1",
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.rememberMe = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.rememberMe = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.rememberMe = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label",
                    attrs: { for: "remember_me" }
                  },
                  [_vm._v(_vm._s(_vm.$t("login.remember_me")))]
                )
              ])
            ],
            1
          ),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("login.login")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }