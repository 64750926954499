var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container item-detail",
      attrs: { id: _vm.entityNature + "-detail" }
    },
    [
      _c(
        "div",
        { staticClass: "row relative" },
        [
          !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
          this.isContentReady && _vm.item
            ? [
                _c(
                  "div",
                  { key: _vm.item.id, staticClass: "col-4" },
                  [
                    _c("Item", {
                      ref: _vm.entityNature + "_" + _vm.item.id,
                      attrs: { item: _vm.item, action: _vm.action }
                    })
                  ],
                  1
                ),
                _c("div", { staticClass: "col-4 item-detail-x" }),
                _c("div", { staticClass: "col-4  item-detail-y" })
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }