var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tile tile-selectable role-frame ",
      class: ["tile-selectable--" + _vm.role.type, _vm.role.status]
    },
    [
      _c("div", { staticClass: "role-image-frame" }, [
        _c("img", {
          staticClass: "role-image image-fill-frame",
          class: _vm.role.picture ? "" : "fade-image",
          attrs: {
            border: "0",
            alt: _vm.role.name,
            src: _vm.role.picture
              ? _vm.imageUrl + _vm.role.id + "/" + _vm.role.picture
              : _vm.missingImage
          }
        })
      ]),
      _c("ul", { staticClass: "role-text" }, [
        _c("li", [
          _c("span", { staticClass: "role-text-label" }, [
            _vm._v(_vm._s(_vm.$t("role.name")))
          ]),
          _vm._v(_vm._s(_vm.role.name) + " ")
        ]),
        _vm.role.party && _vm.role.party.name
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.party")))
              ]),
              _vm._v(_vm._s(_vm.role.party.name) + " ")
            ])
          : _vm._e(),
        _vm.role.game && _vm.role.game.name
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.game")))
              ]),
              _vm._v(_vm._s(_vm.role.game.name) + " ")
            ])
          : _vm._e(),
        _vm.role.setting && _vm.role.setting.name
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.setting")))
              ]),
              _vm._v(_vm._s(_vm.role.setting.name) + " ")
            ])
          : _vm._e(),
        _vm.role.system && _vm.role.system.name
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.system")))
              ]),
              _vm._v(_vm._s(_vm.role.system.name) + " ")
            ])
          : _vm._e(),
        _vm.role.campaign && _vm.role.campaign.name
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.campaign")))
              ]),
              _vm._v(_vm._s(_vm.role.campaign.name) + " ")
            ])
          : _vm._e(),
        _vm.role.status
          ? _c("li", [
              _c("span", { staticClass: "role-text-label" }, [
                _vm._v(_vm._s(_vm.$t("role.status")))
              ]),
              _c("span", { staticClass: "role-status" }, [
                _vm._v(_vm._s(_vm.role.status))
              ]),
              _vm.role.status === "incomplete"
                ? _c("span", [
                    _vm._v(
                      " " +
                        _vm._s(
                          "(" +
                            _vm.$t("role.character.step").toLowerCase() +
                            " " +
                            _vm.role.creation_step +
                            ")"
                        ) +
                        " "
                    )
                  ])
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _c(
        "button",
        {
          staticClass: "btn btn-primary btn-delete-role",
          attrs: { type: "button" },
          on: {
            click: function($event) {
              $event.stopPropagation()
              return _vm.deleteRole(_vm.role.id)
            }
          }
        },
        [_vm._v(" " + _vm._s(_vm.$t("button.delete")) + " ")]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }