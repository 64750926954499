var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.characterCreation_characterStatus
    ? _c("main", { staticClass: "body-view container-fluid center" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "area_title col-12" }, [
            _c("h1", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("role.character.create_character_dataset" + _vm.step)
                  ) +
                  " "
              )
            ])
          ])
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-8" }, [_c("Dataset")], 1),
          _c("div", { staticClass: "col-4" }, [_c("CharacterSummary")], 1)
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }