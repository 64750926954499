<template>
  <div class="tile item-frame">
    <div class="item-image-frame">
      <img
        class="item-image image-fill-frame"
        :class="item.cover ? '' : 'fade-image'"
        border="0"
        :alt="item.name"
        :src="item.cover ? imageUrl + item.id + '/' + item.cover : missingImage"
      />
    </div>
    <ul class="item-text">
      <li>
        <span class="item-text-label">{{ $t(referenceOrigin + '.name') }}</span
        >{{ item.name }}
      </li>

      <li v-if="item.game">
        <span class="item-text-label">{{ $t(referenceOrigin + '.game_name') }}</span
        >{{ item.game.name }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(referenceOrigin + '.starting_datetime') }}</span
        >{{ $helpers.getCustomFormattedDateTime(item.starting_datetime) }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(referenceOrigin + '.ending_datetime') }}</span
        >{{ item.ending_datetime ? $helpers.getCustomFormattedDateTime(item.ending_datetime) : $t('global.on_going') }}
      </li>
      <li>
        <span class="item-text-label">{{ $t('game.current_datetime') }}</span
        >{{ currentDatetime }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(referenceOrigin + '.experience_points') }}</span
        >{{ $helpers.formatNumber(item.experience_points) }}
      </li>
    </ul>
  </div>
</template>

<script>
const entityNature = 'gameCampaign',
  referenceOrigin = 'campaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized,
  props: ['action', 'item'],
  data() {
    return {
      itemToDelete: undefined,
      entityNature,
      referenceOrigin,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters(['get' + entityNatureCapitalized + 'Status', 'isMaster', 'is' + entityNatureCapitalized + 'Loaded']),
    currentDatetime() {
      return this.$helpers.getCustomFormattedDateTime(this.$store.state.game.current_datetime);
    },
    imageUrl() {
      return config.mediaPath[referenceOrigin] + '/';
    },
    missingImage() {
      return config.mediaPath.application.placeholders + 'missing_' + referenceOrigin + '_picture.png';
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  max-width: 100%;
}

.item {
  margin-bottom: 25px;

  &-image-frame {
    position: relative;
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    padding: 6px;
    margin: 0;

    &-label {
      text-transform: uppercase;
      font-size: 1rem;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .btn-close-item {
    right: 0;
    position: absolute;
    top: 0;
  }
}
</style>
