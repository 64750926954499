<template>
  <div class="tile tile-selectable role-frame " :class="['tile-selectable--' + role.type, role.status]">
    <div class="role-image-frame">
      <img
        border="0"
        class="role-image image-fill-frame"
        :alt="role.name"
        :class="role.picture ? '' : 'fade-image'"
        :src="role.picture ? imageUrl + role.id + '/' + role.picture : missingImage"
      />
    </div>
    <ul class="role-text">
      <li>
        <span class="role-text-label">{{ $t('role.name') }}</span
        >{{ role.name }}
      </li>
      <li v-if="role.party && role.party.name">
        <span class="role-text-label">{{ $t('role.party') }}</span
        >{{ role.party.name }}
      </li>
      <li v-if="role.game && role.game.name">
        <span class="role-text-label">{{ $t('role.game') }}</span
        >{{ role.game.name }}
      </li>
      <li v-if="role.setting && role.setting.name">
        <span class="role-text-label">{{ $t('role.setting') }}</span
        >{{ role.setting.name }}
      </li>
      <li v-if="role.system && role.system.name">
        <span class="role-text-label">{{ $t('role.system') }}</span
        >{{ role.system.name }}
      </li>
      <li v-if="role.campaign && role.campaign.name">
        <span class="role-text-label">{{ $t('role.campaign') }}</span
        >{{ role.campaign.name }}
      </li>
      <li v-if="role.status">
        <span class="role-text-label">{{ $t('role.status') }}</span
        ><span class="role-status">{{ role.status }}</span>
        <span v-if="role.status === 'incomplete'">
          {{ `(${$t('role.character.step').toLowerCase()} ${role.creation_step})` }}
        </span>
      </li>
    </ul>
    <button class="btn btn-primary btn-delete-role" type="button" @click.stop="deleteRole(role.id)">
      {{ $t('button.delete') }}
    </button>
  </div>
</template>

<script>
import { ROLE_SELECT } from '@/store/actions/role';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { config } from '@/setup/config';

export default {
  name: 'Role',
  props: ['role'],
  data() {
    return {
      roleToDelete: undefined
    };
  },
  computed: {
    imageUrl() {
      return config.mediaPath[this.role.type].picture + '/';
    },
    missingImage() {
      return `${config.mediaPath.application.placeholders}missing_${this.role.type}_picture.png`;
    }
  },
  methods: {
    deleteRole(id) {
      this.$emit('delete-role', id);
    },
    selectRole(id, type, status = null, creation_step = null) {
      if (!id || !type) {
        return false;
      }

      this.$store
        .dispatch(ROLE_SELECT, { id, type })
        .then(() => {
          if (status === 'incomplete') {
            this.$router.push(`/${type}/create/step${creation_step}`);
          } else {
            this.$router.push(`/${type}/profile`);
          }
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.tile-selectable {
  &--character {
    border-color: $role-character-border-color;

    &:hover {
      border-color: $role-character-border-color-hover;
    }

    &.active {
      border-color: $role-character-active-border-color;
    }

    &.incomplete {
      border-color: $role-character-incomplete-border-color;
    }

    &.inactive {
      border-color: $role-character-inactive-border-color;
    }

    &.trashed {
      border-color: $role-character-trashed-border-color;
    }
  }

  &--master {
    border-color: $role-master-border-color;

    &:hover {
      border-color: $role-master-border-color-hover;
    }
  }
}
.role {
  &-frame {
    .btn-delete-role {
      bottom: 5px;
      font-size: 1rem;
      padding: 0.2rem 0.7rem;
      position: absolute;
      right: 5px;
      z-index: $selectable-tile-inner-button-index;
    }
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    margin: 0;
    padding: 6px;

    &-label {
      font-size: 1rem;
      text-transform: uppercase;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  &-status {
    text-transform: capitalize;
  }
}
</style>
