var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "character-skills" }, [
    _c("nav", { staticClass: "navigation container-fluid" }, [
      _c("ul", { staticClass: "menu menu--skills tabs" }, [
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/all" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.all")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/SM" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.static_maneuvers")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/MM" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.movement_maneuvers")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/CM" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.combat")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/SC" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.spells")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/OB" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.observation")))
            ])
          ],
          1
        ),
        _c(
          "li",
          [
            _c("RouterLink", { attrs: { to: "/character/skills/CO" } }, [
              _vm._v(_vm._s(_vm.$t("menu.skills.communication")))
            ])
          ],
          1
        )
      ])
    ]),
    _c("div", { staticClass: "body-view" }, [
      _c("div", { staticClass: "col-12 center" }, [
        _c("h1", { staticClass: "area_title" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("skills.skills") +
                  ": " +
                  (_vm.isFiltered
                    ? _vm.$t("skills." + _vm.filter)
                    : _vm.$t("skills.select_filter"))
              ) +
              " "
          )
        ]),
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
        ]),
        _c("div", { staticClass: "row sticky row-summary" }, [
          _c("div", { staticClass: "summary" }, [
            _c(
              "div",
              {
                staticClass: "committed-actions col-5",
                attrs: { id: "committed-actions" }
              },
              [
                _vm.$store.state.character.id
                  ? _c("CommittedActionList", {
                      ref: "committedActionList",
                      attrs: {
                        mode: "compact",
                        modeCharacter: _vm.$store.state.character.id
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c("div", {
              staticClass: "preferred-skills col-6",
              attrs: { id: "preferred-skills" }
            })
          ])
        ]),
        _c("div", { staticClass: "row main-content" }, [
          _c("div", { staticClass: "dataset col-12" }, [
            _c("div", { staticClass: "character-skills" }, [
              _c(
                "form",
                {
                  staticClass: "form-skills left",
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                    }
                  }
                },
                [
                  _vm.$store.state.character.id && _vm.isFiltered
                    ? _c("SkillList", {
                        ref: "skillList",
                        attrs: {
                          mode: "play",
                          characterId: "$store.state.character.id",
                          errorFieldsInherited: _vm.errorFields
                        },
                        on: { "error-fields-set": _vm.form_onErrorFields }
                      })
                    : _vm._e()
                ],
                1
              )
            ])
          ])
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }