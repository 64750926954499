<template>
  <div class="tile tile-selectable item-frame" @click="selectItem()">
    <div class="item-image-frame">
      <img
        class="item-image image-fill-frame"
        :class="item.cover ? '' : 'fade-image'"
        border="0"
        :alt="item.name"
        :src="item.cover ? imageUrl + item.id + '/' + item.cover : missingImage"
      />
      <button v-if="showButtons" type="button" class="btn btn-primary btn-close-item" @click.stop="deleteItem()">
        {{ $t('button.' + entityNature + '.delete_' + entityNature) }}
      </button>
    </div>
    <ul class="item-text">
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.name') }}</span
        >{{ item.name }}
      </li>

      <li v-if="item.master">
        <span class="item-text-label">{{ $t(entityNature + '.master') }}</span
        >{{ item.master.name }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.starting_datetime') }}</span
        >{{ $helpers.getCustomFormattedDateTime(item.starting_datetime) }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.ending_datetime') }}</span
        >{{ item.ending_datetime ? $helpers.getCustomFormattedDateTime(item.ending_datetime) : $t('global.on_going') }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.current_datetime') }}</span
        >{{ $helpers.getCustomFormattedDateTime(item.current_datetime) }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.setting') }}</span
        >{{ settingName }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.system') }}</span
        >{{ systemName }}
      </li>
    </ul>
  </div>
</template>

<script>
const entityNature = 'game',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { GAME_SELECT } from '@/store/actions/game';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized,
  props: ['action', 'item'],
  data() {
    return {
      itemToDelete: undefined,
      entityNature,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters(['get' + entityNatureCapitalized + 'Status', 'isMaster', 'is' + entityNatureCapitalized + 'Loaded']),
    imageUrl() {
      return config.mediaPath[entityNature] + '/';
    },
    missingImage() {
      return config.mediaPath.application.placeholders + 'missing_' + entityNature + '_picture.png';
    },
    settingName() {
      return this.$store.state.setting.name;
    },
    systemName() {
      return this.$store.state.system.name;
    },
    showButtons() {
      return this.action !== 'select' && !this.$helpers.checkSegment('instance');
    }
  },
  methods: {
    deleteItem() {
      /* IMPORTANT: TO BE IMPLEMENTED 
        a game can be delete only if it was never played (i.e. no game linked) */
      console.log('delete ' + entityNature, this.item.id);
      this.$emit('delete-' + entityNature, this.item.id);
    },
    selectItem() {
      switch (this.action) {
        case 'select':
          this.selectGame(this.item.id);
          break;
        default:
      }
    },
    selectGame(id) {
      if (!id) {
        return false;
      }

      this.$store
        .dispatch(GAME_SELECT, id)
        .then(() => {
          this.$router.push('/game/instance');
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  max-width: 100%;
}

.item {
  margin-bottom: 25px;

  &-frame {
    border-color: $game-border-color;

    &:hover {
      border-color: $game-border-color-hover;
    }
  }

  &-image-frame {
    position: relative;
  }

  &-text {
    line-height: 1.5rem;
    list-style: none;
    padding: 6px;
    margin: 0;

    &-label {
      text-transform: uppercase;
      font-size: 1rem;

      &::after {
        content: ':';
        display: inline-block;
        margin-right: 5px;
      }
    }
  }

  .btn-close-item {
    right: 0;
    position: absolute;
    top: 0;
  }
}
</style>
