var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        { staticClass: "body-view col-12 center" },
        [
          _c("h1", { staticClass: "area_title" }, [
            _vm._v(" " + _vm._s(_vm.$t("campaign.create_campaign_game")) + " ")
          ]),
          _c("Message"),
          _c(
            "form",
            {
              staticClass: "form-campaign-create left",
              on: {
                submit: function($event) {
                  $event.preventDefault()
                  return _vm.onSubmit($event)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "form-frame" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group required",
                      class: _vm.errorFields["name"] ? "error" : ""
                    },
                    [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v(_vm._s(_vm.$t("campaign.name")))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.name,
                            expression: "name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          required: "",
                          type: "text",
                          id: "name",
                          name: "name",
                          "aria-describedby": "name",
                          placeholder: _vm.$t("campaign.name")
                        },
                        domProps: { value: _vm.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.name = $event.target.value
                          }
                        }
                      })
                    ]
                  ),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["owner"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.owner.selectedValue,
                      required: _vm.owner.required,
                      id: _vm.owner.id,
                      name: _vm.owner.name,
                      optionValue: _vm.owner.optionValue,
                      optionLabel: _vm.owner.optionLabel,
                      ariaDescribedby: _vm.owner.ariaDescribedby,
                      label: _vm.owner.label,
                      placeholder: _vm.owner.placeholder,
                      options: _vm.owner.data
                    }
                  }),
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: _vm.errorFields["image"] ? "error" : ""
                    },
                    [
                      _c("label", { attrs: { for: "image" } }, [
                        _vm._v(_vm._s(_vm.$t("global.cover")))
                      ]),
                      _c("input", {
                        staticClass: "form-control",
                        attrs: {
                          type: "file",
                          id: "image",
                          name: "image",
                          "aria-describedby": "image",
                          accept: "image/*"
                        },
                        on: {
                          change: function($event) {
                            return _vm.onChangeFile($event)
                          }
                        }
                      })
                    ]
                  )
                ],
                1
              ),
              _c("div", { staticClass: "center" }, [
                _c(
                  "button",
                  { staticClass: "btn btn-primary", attrs: { type: "submit" } },
                  [_vm._v(" " + _vm._s(_vm.$t("button.create")) + " ")]
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }