<template>
  <div class="character-dataset2">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" @submit.prevent="saveSkill">
      <div class="form-frame">
        <div class="row">
          <div class="col-4">
            <div class="form-group required" :class="errorFields['name'] ? 'error' : ''">
              <label for="name">{{ $t('skill.name') }}</label>
              <input
                required
                v-model="skill.name"
                type="text"
                class="form-control"
                id="name"
                name="name"
                aria-describedby="name"
              />
            </div>

            <FormGroupSelect
              :selectValue="material_source.selectedValue"
              :required="material_source.required"
              :id="material_source.id"
              :name="material_source.name"
              :optionValue="material_source.optionValue"
              :optionLabel="material_source.optionLabel"
              class="form-group"
              :class="errorFields['material_source'] ? 'error' : ''"
              :ariaDescribedby="material_source.ariaDescribedby"
              :label="material_source.label"
              :placeholder="material_source.placeholder"
              :options="material_source.data"
            />

            <FormGroupSelect
              :nesting="parent.nesting"
              :selectValue="parent.selectedValue"
              :required="parent.required"
              :id="parent.id"
              :name="parent.name"
              :optionValue="parent.optionValue"
              :optionLabel="parent.optionLabel"
              :optionGroup="parent.optionGroup"
              class="form-group"
              :class="errorFields['race'] ? 'error' : ''"
              :ariaDescribedby="parent.ariaDescribedby"
              :label="parent.label"
              :placeholder="parent.placeholder"
              :options="parent.data"
              :onChangeCallback="parent.onChange"
            />

            <div class="row">
              <div class="form-group col-4" :class="errorFields['base_rank'] ? 'error' : ''">
                <label for="base_rank">{{ $t('skill.base_rank') }}</label>
                <input
                  v-model="skill.base_rank"
                  type="number"
                  step="1"
                  min="0"
                  class="form-control"
                  id="base_rank"
                  name="base_rank"
                  aria-describedby="base_rank"
                  @change="onlyPositiveInteger($event, 'base_rank')"
                  @input="onlyPositiveInteger($event, 'base_rank')"
                  @blur="onlyPositiveInteger($event, 'base_rank')"
                />
              </div>

              <div class="form-group col-4" :class="errorFields['max_rank'] ? 'error' : ''">
                <label for="max_rank">{{ $t('skill.max_rank') }}</label>
                <input
                  v-model="skill.max_rank"
                  type="number"
                  step="1"
                  min="0"
                  class="form-control"
                  id="max_rank"
                  name="max_rank"
                  aria-describedby="max_rank"
                  @change="onlyPositiveInteger($event, 'max_rank')"
                  @input="onlyPositiveInteger($event, 'max_rank')"
                  @blur="onlyPositiveInteger($event, 'max_rank')"
                />
              </div>

              <div class="form-group col-4" :class="errorFields['rank_progression'] ? 'error' : ''">
                <label for="rank_progression">{{ $t('skill.rank_progression') }}</label>
                <input
                  v-model="skill.rank_progression"
                  type="number"
                  step="1"
                  min="0"
                  class="form-control"
                  id="rank_progression"
                  name="rank_progression"
                  aria-describedby="rank_progression"
                  @change="onlyPositiveInteger($event, 'rank_progression')"
                  @input="onlyPositiveInteger($event, 'rank_progression')"
                  @blur="onlyPositiveInteger($event, 'rank_progression')"
                />
              </div>
            </div>

            <div class="row">
              <div class="form-group col-6" :class="errorFields['no_rank_penalty'] ? 'error' : ''">
                <label for="no_rank_penalty">{{ $t('skill.no_rank_malus') }}</label>
                <input
                  v-model="skill.no_rank_penalty"
                  type="number"
                  step="1"
                  class="form-control"
                  id="no_rank_penalty"
                  name="no_rank_penalty"
                  aria-describedby="no_rank_penalty"
                />
              </div>

              <div class="form-group col-6" :class="errorFields['base_modifier'] ? 'error' : ''">
                <label for="base_modifier">{{ $t('skill.base_modifier') }}</label>
                <input
                  v-model="skill.base_modifier"
                  type="number"
                  step="1"
                  class="form-control"
                  id="base_modifier"
                  name="base_modifier"
                  aria-describedby="base_modifier"
                />
              </div>
            </div>

            <div class="row">
              <FormGroupSelect
                :selectValue="stat1.selectedValue"
                :required="stat1.required"
                :id="stat1.id"
                :name="stat1.name"
                :optionValue="stat1.optionValue"
                :optionLabel="stat1.optionLabel"
                class="form-group col-4"
                :class="errorFields['stat1'] ? 'error' : ''"
                :ariaDescribedby="stat1.ariaDescribedby"
                :label="stat1.label"
                :labelParams="stat1.labelParams"
                :placeholder="stat1.placeholder"
                :options="stat1.data"
              />

              <FormGroupSelect
                :selectValue="stat2.selectedValue"
                :required="stat2.required"
                :id="stat2.id"
                :name="stat2.name"
                :optionValue="stat2.optionValue"
                :optionLabel="stat2.optionLabel"
                class="form-group col-4"
                :class="errorFields['stat2'] ? 'error' : ''"
                :ariaDescribedby="stat2.ariaDescribedby"
                :label="stat2.label"
                :labelParams="stat2.labelParams"
                :placeholder="stat2.placeholder"
                :options="stat2.data"
              />

              <FormGroupSelect
                :selectValue="stat3.selectedValue"
                :required="stat3.required"
                :id="stat3.id"
                :name="stat3.name"
                :optionValue="stat3.optionValue"
                :optionLabel="stat3.optionLabel"
                class="form-group col-4"
                :class="errorFields['stat3'] ? 'error' : ''"
                :ariaDescribedby="stat3.ariaDescribedby"
                :label="stat3.label"
                :labelParams="stat3.labelParams"
                :placeholder="stat3.placeholder"
                :options="stat3.data"
              />
            </div>

            <FormGroupSelect
              :disabled="category.disabled"
              :selectValue="category.selectedValue"
              :required="category.required"
              :id="category.id"
              :name="category.name"
              :optionValue="category.optionValue"
              :optionLabel="category.optionLabel"
              class="form-group"
              :class="errorFields['category'] ? 'error' : ''"
              :ariaDescribedby="category.ariaDescribedby"
              :label="category.label"
              :placeholder="category.placeholder"
              :options="category.data"
            />

            <div class="row">
              <FormGroupSelect
                :selectValue="type.selectedValue"
                :required="type.required"
                :id="type.id"
                :name="type.name"
                :optionValue="type.optionValue"
                :optionLabel="type.optionLabel"
                class="form-group col-6"
                :class="errorFields['type'] ? 'error' : ''"
                :ariaDescribedby="type.ariaDescribedby"
                :label="type.label"
                :placeholder="type.placeholder"
                :options="type.data"
              />

              <FormGroupSelect
                :selectValue="medium.selectedValue"
                :required="medium.required"
                :id="medium.id"
                :name="medium.name"
                :optionValue="medium.optionValue"
                :optionLabel="medium.optionLabel"
                class="form-group col-6"
                :class="errorFields['medium'] ? 'error' : ''"
                :ariaDescribedby="medium.ariaDescribedby"
                :label="medium.label"
                :placeholder="medium.placeholder"
                :options="medium.data"
              />
            </div>

            <div class="row">
              <FormGroupSelect
                :selectValue="maneuver_type.selectedValue"
                :required="maneuver_type.required"
                :id="maneuver_type.id"
                :name="maneuver_type.name"
                :optionValue="maneuver_type.optionValue"
                :optionLabel="maneuver_type.optionLabel"
                class="form-group col-6"
                :class="errorFields['maneuver_type'] ? 'error' : ''"
                :ariaDescribedby="maneuver_type.ariaDescribedby"
                :label="maneuver_type.label"
                :placeholder="maneuver_type.placeholder"
                :options="maneuver_type.data"
              />

              <FormGroupSelect
                :selectValue="maneuver_subtype.selectedValue"
                :required="maneuver_subtype.required"
                :id="maneuver_subtype.id"
                :name="maneuver_subtype.name"
                :optionValue="maneuver_subtype.optionValue"
                :optionLabel="maneuver_subtype.optionLabel"
                class="form-group col-6"
                :class="errorFields['maneuver_subtype'] ? 'error' : ''"
                :ariaDescribedby="maneuver_subtype.ariaDescribedby"
                :label="maneuver_subtype.label"
                :placeholder="maneuver_subtype.placeholder"
                :options="maneuver_subtype.data"
              />
            </div>

            <FormGroupSelect
              :selectValue="default_class.selectedValue"
              :required="default_class.required"
              :id="default_class.id"
              :name="default_class.name"
              :optionValue="default_class.optionValue"
              :optionLabel="default_class.optionLabel"
              class="form-group"
              :class="errorFields['default_class'] ? 'error' : ''"
              :ariaDescribedby="default_class.ariaDescribedby"
              :label="default_class.label"
              :placeholder="default_class.placeholder"
              :options="default_class.data"
            />

            <div class="row">
              <div class="form-group col-6" :class="errorFields['development_effort'] ? 'error' : ''">
                <label for="development_effort">{{ $t('skill.development_effort') }}</label>
                <input
                  v-model="skill.development_effort"
                  type="number"
                  step="0.001"
                  min="0"
                  class="form-control"
                  id="development_effort"
                  name="development_effort"
                  aria-describedby="development_effort"
                />
              </div>

              <div class="form-group col-6" :class="errorFields['tag'] ? 'error' : ''">
                <label for="tag">{{ $t('skill.tag') }}</label>
                <input
                  v-model="skill.tag"
                  type="text"
                  class="form-control"
                  id="tag"
                  name="tag"
                  aria-describedby="tag"
                />
              </div>
            </div>

            <FormGroupSelect
              :selectValue="status.selectedValue"
              :required="status.required"
              :id="status.id"
              :name="status.name"
              :optionValue="status.optionValue"
              :optionLabel="status.optionLabel"
              class="form-group"
              :class="errorFields['status'] ? 'error' : ''"
              :ariaDescribedby="status.ariaDescribedby"
              :label="status.label"
              :placeholder="status.placeholder"
              :options="status.data"
            />
          </div>

          <div class="col-8">
            <div class="form-group" :class="errorFields['synopsis'] ? 'error' : ''">
              <label for="synopsis">{{ $t('skill.synopsis') }}</label>
              <textarea
                v-model="skill.synopsis"
                class="form-control"
                id="synopsis"
                name="synopsis"
                aria-describedby="synopsis"
              ></textarea>
            </div>

            <FormGroupRichText
              :ref="description.ref"
              :value="description.value"
              :id="description.id"
              :name="description.name"
              class="form-group"
              :class="errorFields['description'] ? 'error' : ''"
              :ariaDescribedby="description.ariaDescribedby"
              :label="description.label"
            />

            <FormGroupRichText
              :ref="difficulty_scale.ref"
              :value="difficulty_scale.value"
              :id="difficulty_scale.id"
              :name="difficulty_scale.name"
              class="form-group"
              :class="errorFields['difficulty_scale'] ? 'error' : ''"
              :ariaDescribedby="difficulty_scale.ariaDescribedby"
              :label="difficulty_scale.label"
            />

            <FormGroupTextList
              :required="maneuver_modifiers.required"
              :disabled="maneuver_modifiers.disabled"
              :id="maneuver_modifiers.id"
              :name="maneuver_modifiers.name"
              class="form-group"
              :class="errorFields['maneuver_modifiers'] ? 'error' : ''"
              :ariaDescribedby="maneuver_modifiers.ariaDescribedby"
              :label="maneuver_modifiers.label"
              :listKeyProperty="maneuver_modifiers.listKeyProperty"
              :listValueProperty="maneuver_modifiers.listValueProperty"
              :list="maneuver_modifiers.data"
              :addEntryLabel="maneuver_modifiers.addEntryLabel"
              :errorFieldsInherited="errorFields"
              v-on="$listeners"
            />
          </div>
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.save') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import { MESSAGE_SUCCESS_SET, MESSAGE_ERROR_SET, MESSAGE_ERROR_LIST_SET } from '@/store/actions/message';
import { CLASSES_REQUEST } from '@/store/actions/class';
import { MATERIAL_SOURCE_REQUEST } from '@/store/actions/materialSource';
import {
  SKILL_REQUEST,
  SKILLS_REQUEST,
  SKILL_SAVE,
  CATEGORIES_REQUEST,
  TYPES_REQUEST,
  MEDIA_REQUEST,
  MANEUVER_TYPES_REQUEST,
  MANEUVER_SUBTYPES_REQUEST,
  STATUS_REQUEST
} from '@/store/actions/skill';
import { STATS_REQUEST } from '@/store/actions/stats';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import FormGroupTextList from '@/components/Form/FormGroupTextList';
import FormGroupRichText from '@/components/Form/FormGroupRichText';
import Vue from 'vue';

export default {
  name: 'CharacterDataset2',
  components: {
    FormGroupSelect,
    FormGroupTextList,
    FormGroupRichText
  },
  mixins: [formMixin],
  props: {
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      skill: {},
      errorFields: [],
      isContentReady: false,
      isMaterialSourceRendered: false,
      isParentRendered: false,
      areStatsRendered: false,
      isCategoryRendered: false,
      isTypeRendered: false,
      isMediumRendered: false,
      isManeuverTypeRendered: false,
      isManeuverSubtypeRendered: false,
      isStatusRendered: false,
      isSkillLoaded: false,
      material_source: {
        id: 'material_source',
        name: 'material_source',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'material_source',
        label: 'skill.material_source',
        data: [],
        selectedValue: ''
      },
      parent: {
        id: 'parent',
        name: 'parent',
        nesting: true,
        optionValue: 'id',
        optionLabel: 'name',
        optionGroup: 'isCategory',
        ariaDescribedby: 'parent',
        label: 'skill.skill_parent',
        placeholder: 'skill.none',
        data: [],
        selectedValue: '',
        onChange: this.onChangeParentSkill
      },
      stat1: {
        required: 'required',
        id: 'stat1',
        name: 'stat1',
        optionValue: 'id',
        optionLabel: 'stat',
        ariaDescribedby: 'stat1',
        label: 'skill.stat',
        labelParams: { number: 1 },
        placeholder: '',
        data: [],
        selectedValue: ''
      },
      stat2: {
        required: 'required',
        id: 'stat2',
        name: 'stat2',
        optionValue: 'id',
        optionLabel: 'stat',
        ariaDescribedby: 'stat2',
        label: 'skill.stat',
        labelParams: { number: 2 },
        placeholder: '',
        data: [],
        selectedValue: ''
      },
      stat3: {
        id: 'stat3',
        name: 'stat3',
        optionValue: 'id',
        optionLabel: 'stat',
        ariaDescribedby: 'stat3',
        label: 'skill.stat',
        labelParams: { number: 3 },
        placeholder: 'skill.*',
        data: [],
        selectedValue: ''
      },
      category: {
        id: 'category',
        name: 'category',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'category',
        label: 'skill.category',
        placeholder: '',
        data: [],
        selectedValue: ''
      },
      type: {
        id: 'type',
        name: 'type',
        required: 'required',
        optionValue: 'skill_type_code',
        optionLabel: 'type',
        ariaDescribedby: 'type',
        label: 'skill.type',
        placeholder: null,
        data: [],
        selectedValue: ''
      },
      medium: {
        id: 'medium',
        name: 'medium',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'medium',
        label: 'skill.medium',
        placeholder: '',
        data: [],
        selectedValue: ''
      },
      maneuver_type: {
        id: 'maneuver_type',
        name: 'maneuver_type',
        required: 'required',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'maneuver_type',
        label: 'skill.maneuver_type',
        placeholder: null,
        data: [],
        selectedValue: ''
      },
      maneuver_subtype: {
        id: 'maneuver_subtype',
        name: 'maneuver_subtype',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'maneuver_subtype',
        label: 'skill.maneuver_subtype',
        placeholder: '',
        data: [],
        selectedValue: ''
      },
      default_class: {
        id: 'default_class',
        name: 'default_class',
        optionValue: 'id',
        optionLabel: 'name',
        ariaDescribedby: 'default_class',
        label: 'skill.default_class',
        placeholder: 'skill.none',
        data: [],
        selectedValue: ''
      },
      status: {
        id: 'status',
        name: 'status',
        required: 'required',
        optionValue: 'id',
        optionLabel: 'status',
        ariaDescribedby: 'status',
        label: 'skill.status',
        placeholder: null,
        data: [],
        selectedValue: ''
      },
      synopsis: '',
      description: {
        ref: 'rich-text-description',
        id: 'description',
        name: 'description',
        required: 'required',
        ariaDescribedby: 'description',
        label: 'skill.description',
        placeholder: 'skill.description'
      },
      difficulty_scale: {
        ref: 'rich-text-difficulty_scale',
        id: 'difficulty_scale',
        name: 'difficulty_scale',
        required: 'required',
        ariaDescribedby: 'difficulty_scale',
        label: 'skill.difficulty_scale',
        placeholder: 'skill.difficulty_scale'
      },
      maneuver_modifiers: {
        required: 'required',
        id: 'maneuver_modifiers',
        name: 'maneuver_modifiers',
        ariaDescribedby: 'maneuver_modifiers',
        label: 'skill.maneuver_modifiers',
        listKeyProperty: 'description',
        listValueProperty: 'modifier',
        placeholder: '',
        data: [],
        addEntryLabel: 'skill.add_maneuver_modifier'
      }
    };
  },
  mounted() {
    this.populateMaterialSource();
    this.populateParentSkill();
    this.populateStats();
    this.populateCategory();
    this.populateType();
    this.populateMedium();
    this.populateManeuverType();
    this.populateManeuverSubtype();
    this.populateDefaultClass();
    this.populateStatus();
    this.getSkill();
  },
  methods: {
    handleReadyContent() {
      if (
        this.isMaterialSourceRendered &&
        this.isParentRendered &&
        this.areStatsRendered &&
        this.isCategoryRendered &&
        this.isTypeRendered &&
        this.isMediumRendered &&
        this.isManeuverTypeRendered &&
        this.isManeuverSubtypeRendered &&
        this.isStatusRendered &&
        this.isSkillLoaded
      ) {
        this.isContentReady = true;
      }
    },
    onChangeParentSkill(id) {
      if (id) {
        this.category.disabled = 'disabled';
      } else {
        this.category.disabled = false;
      }
    },
    onlyPositiveInteger($event, dataModelName) {
      const score = $event.target.value;
      const isInt = /^\d+$/.test(score);
      if (dataModelName && !isInt) {
        this[dataModelName] = '';
        $event.target.value = '';
      }
    },

    getSkill() {
      this.$store
        .dispatch(SKILL_REQUEST, this.id)
        .then(data => {
          this.skill = data;
          this.skill.id = this.skill.id == null ? '' : this.skill.id;
          this.skill.name = this.skill.name == null ? '' : this.skill.name;
          this.material_source.selectedValue = this.skill.material_source;
          this.skill.base_rank = this.skill.base_rank == null ? '' : this.skill.base_rank;
          this.skill.max_rank = this.skill.max_rank == null ? '' : this.skill.max_rank;
          this.skill.rank_progression = this.skill.rank_progression == null ? '' : this.skill.rank_progression;
          this.skill.no_rank_penalty = this.skill.no_rank_penalty == null ? '' : this.skill.no_rank_penalty;
          this.skill.base_modifier = this.skill.base_modifier == null ? '' : this.skill.base_modifier;
          this.parent.selectedValue = this.skill.parent;
          this.stat1.selectedValue = this.skill.stat1;
          this.stat2.selectedValue = this.skill.stat2;
          this.stat3.selectedValue = this.skill.stat3;
          this.category.selectedValue = this.skill.category;
          if (this.parent.selectedValue) {
            this.category.disabled = 'disabled';
          } else {
            this.category.disabled = true;
          }
          this.type.selectedValue = this.skill.type;
          this.medium.selectedValue = this.skill.medium;
          this.maneuver_type.selectedValue = this.skill.maneuver_type;
          this.maneuver_subtype.selectedValue = this.skill.maneuver_subtype;
          this.skill.development_effort = this.skill.development_effort == null ? '' : this.skill.development_effort;
          this.skill.tag = this.skill.tag == null ? '' : this.skill.tag;
          this.status.selectedValue = this.skill.status;

          this.synopsis = this.skill.synopsis;
          this.$refs[this.description.ref].setEditorContent(this.skill.description);
          this.$refs[this.difficulty_scale.ref].setEditorContent(this.skill.difficulty_scale);
          this.maneuver_modifiers.data = this.skill.maneuver_modifiers;

          this.isSkillLoaded = true;
          this.handleReadyContent();
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateDefaultClass() {
      this.$store
        .dispatch(CLASSES_REQUEST)
        .then(data => {
          this.default_class.data = data;
          this.$nextTick(() => {
            this.isDefaultClassRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateCategory() {
      this.$store
        .dispatch(CATEGORIES_REQUEST)
        .then(data => {
          this.category.data = data;
          this.$nextTick(() => {
            this.isCategoryRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateManeuverType() {
      this.$store
        .dispatch(MANEUVER_TYPES_REQUEST)
        .then(data => {
          this.maneuver_type.data = data;
          this.$nextTick(() => {
            this.isManeuverTypeRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateManeuverSubtype() {
      this.$store
        .dispatch(MANEUVER_SUBTYPES_REQUEST)
        .then(data => {
          this.maneuver_subtype.data = data;
          this.$nextTick(() => {
            this.isManeuverSubtypeRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateMaterialSource() {
      this.$store
        .dispatch(MATERIAL_SOURCE_REQUEST)
        .then(data => {
          this.material_source.data = data;
          this.$nextTick(() => {
            this.isMaterialSourceRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateMedium() {
      this.$store
        .dispatch(MEDIA_REQUEST)
        .then(data => {
          this.medium.data = data;
          this.$nextTick(() => {
            this.isMediumRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateParentSkill() {
      this.$store
        .dispatch(SKILLS_REQUEST)
        .then(data => {
          this.parent.data = data;
          this.$nextTick(() => {
            this.isParentRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateStats() {
      this.$store
        .dispatch(STATS_REQUEST)
        .then(data => {
          this.stat1.data = data;
          this.stat2.data = data;
          this.stat3.data = data;
          this.$nextTick(() => {
            this.areStatsRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateStatus() {
      this.$store
        .dispatch(STATUS_REQUEST)
        .then(data => {
          this.status.data = data;
          this.$nextTick(() => {
            this.isStatusRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateType() {
      this.$store
        .dispatch(TYPES_REQUEST)
        .then(data => {
          this.type.data = data;
          this.$nextTick(() => {
            this.isTypeRendered = true;
            this.handleReadyContent();
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    saveSkill() {
      this.form_resetResponse();

      const updatedSkill = {
        id: this.skill.id,
        name: this.skill.name,
        material_source: this.material_source.selectedValue,
        parent: this.parent.selectedValue,
        base_rank: this.skill.base_rank,
        max_rank: this.skill.max_rank,
        rank_progression: this.skill.rank_progression,
        no_rank_penalty: this.skill.no_rank_penalty,
        base_modifier: this.skill.base_modifier,
        stat1: this.stat1.selectedValue,
        stat2: this.stat2.selectedValue,
        stat3: this.stat3.selectedValue,
        category: this.category.selectedValue,
        type: this.type.selectedValue,
        medium: this.medium.selectedValue,
        maneuver_type: this.maneuver_type.selectedValue,
        maneuver_subtype: this.maneuver_subtype.selectedValue,
        default_class: this.default_class.selectedValue,
        development_effort: this.skill.development_effort,
        tag: this.skill.tag,
        synopsis: this.skill.synopsis,
        description: this.$refs[this.description.ref].data,
        difficulty_scale: this.$refs[this.difficulty_scale.ref].data,
        status: this.status.selectedValue,
        maneuver_modifiers: this.skill.maneuver_modifiers
      };

      if (!this.validateSkill(updatedSkill)) {
        this.form_scrollToMessage();
        return false;
      }

      const normalizedManeuverModifiers = JSON.parse(JSON.stringify(this.skill.maneuver_modifiers));
      normalizedManeuverModifiers.forEach(function(maneuverModifier, index, thisArray) {
        if (!maneuverModifier.description) {
          thisArray.splice(index, 1);
        } else if (maneuverModifier.isNew) {
          maneuverModifier.id = '';
          delete maneuverModifier.isNew;
        }
      });
      updatedSkill.maneuver_modifiers = normalizedManeuverModifiers;

      const formData = new FormData();
      formData.append('id', updatedSkill.id);
      formData.append('name', updatedSkill.name);
      formData.append('material_source', updatedSkill.material_source);
      formData.append('parent', updatedSkill.parent);
      formData.append('base_rank', updatedSkill.base_rank);
      formData.append('max_rank', updatedSkill.max_rank);
      formData.append('rank_progression', updatedSkill.rank_progression);
      formData.append('no_rank_penalty', updatedSkill.no_rank_penalty);
      formData.append('base_modifier', updatedSkill.base_modifier);
      formData.append('stat1', updatedSkill.stat1);
      formData.append('stat2', updatedSkill.stat2);
      formData.append('stat3', updatedSkill.stat3);
      formData.append('category', updatedSkill.category);
      formData.append('type', updatedSkill.type);
      formData.append('medium', updatedSkill.medium);
      formData.append('maneuver_type', updatedSkill.maneuver_type);
      formData.append('maneuver_subtype', updatedSkill.maneuver_subtype);
      formData.append('default_class', updatedSkill.default_class);
      formData.append('development_effort', updatedSkill.development_effort);
      formData.append('tag', updatedSkill.tag);
      formData.append('synopsis', updatedSkill.synopsis);
      formData.append('description', updatedSkill.description);
      formData.append('difficulty_scale', updatedSkill.difficulty_scale);
      formData.append('status', updatedSkill.status);
      formData.append('maneuver_modifiers', JSON.stringify(updatedSkill.maneuver_modifiers));

      this.$store
        .dispatch(SKILL_SAVE, formData)
        .then(data => {
          const responseMessage = data.message;

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            this.$store.commit(MESSAGE_ERROR_LIST_SET, responseMessage);
          } else {
            this.$router.push('/skill/list');
            this.$store.commit(MESSAGE_SUCCESS_SET);
          }
          this.form_scrollToMessage();
        })
        .catch(err => {
          let responseMessage = err;
          if (err.response && err.response.data && err.response.data.message) {
            responseMessage = err.response.data.message;
          }

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            const errorList = this.$helpers.getErrorList(responseMessage);
            this.form_setFieldErrors(responseMessage['fields']);
            this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
          } else {
            this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
            this.$helpers.errorManager(responseMessage);
          }
          this.form_scrollToMessage();
        });
    },
    validateManeuverModifiers(maneuverModifiers) {
      for (let i = 0; i < maneuverModifiers.length; i++) {
        // check if both description and modifier are filled
        if (
          (maneuverModifiers[i].description && !maneuverModifiers[i].modifier) ||
          (!maneuverModifiers[i].description && maneuverModifiers[i].modifier)
        ) {
          this.$store.commit(MESSAGE_ERROR_SET, 'skill_maneuver_modifier_missing_field');
          Vue.set(this.errorFields, 'maneuver_modifiers', true);
          Vue.set(
            this.errorFields,
            this.maneuver_modifiers.id + '-' + this.maneuver_modifiers.listKeyProperty + '-' + maneuverModifiers[i].id,
            true
          );
          Vue.set(
            this.errorFields,
            this.maneuver_modifiers.id +
              '-' +
              this.maneuver_modifiers.listValueProperty +
              '-' +
              maneuverModifiers[i].id,
            true
          );
          return false;
        }

        // check if modifiers are numbers
        if (
          maneuverModifiers[i].modifier != null &&
          maneuverModifiers[i].modifier !== '' &&
          !Number.isInteger(maneuverModifiers[i].modifier * 1)
        ) {
          this.$store.commit(MESSAGE_ERROR_SET, 'skill_maneuver_modifier_not_integer');
          Vue.set(this.errorFields, 'maneuver_modifiers', true);
          Vue.set(
            this.errorFields,
            this.maneuver_modifiers.id +
              '-' +
              this.maneuver_modifiers.listValueProperty +
              '-' +
              maneuverModifiers[i].id,
            true
          );
          return false;
        }
      }

      // check if modifiers are unique
      if (this.$helpers.checkIfDuplicatesInArrayOfObjects(maneuverModifiers, 'description')) {
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_maneuver_modifiers_have_duplicates');
        Vue.set(this.errorFields, 'maneuver_modifiers', true);
        return false;
      }

      return true;
    },
    validateSkill(skill) {
      if (skill.name.length === 0) {
        Vue.set(this.errorFields, 'name', true);
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_missing_name');
        return false;
      }

      if (!skill.stat1) {
        Vue.set(this.errorFields, 'stat1', true);
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_missing_stat');
        return false;
      }

      if (!skill.stat2) {
        Vue.set(this.errorFields, 'stat2', true);
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_missing_stat');
        return false;
      }

      if ((skill.parent && skill.category) || (!skill.parent && !skill.category)) {
        Vue.set(this.errorFields, 'parent', true);
        Vue.set(this.errorFields, 'category', true);
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_hierarchy_conflict');
        return false;
      }

      if (
        skill.base_rank != null &&
        skill.base_rank !== '' &&
        (!Number.isInteger(skill.base_rank * 1) || skill.base_rank < 0)
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'not_positive_integer');
        Vue.set(this.errorFields, 'base_rank', true);
        return false;
      }

      if (
        skill.max_rank != null &&
        skill.max_rank !== '' &&
        (!Number.isInteger(skill.max_rank * 1) || skill.max_rank < 0)
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'not_positive_integer');
        Vue.set(this.errorFields, 'max_rank', true);
        return false;
      }

      if (
        skill.rank_progression != null &&
        skill.rank_progression !== '' &&
        (!Number.isInteger(skill.rank_progression * 1) || skill.rank_progression < 0)
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'not_positive_integer');
        Vue.set(this.errorFields, 'rank_progression', true);
        return false;
      }

      if (
        skill.no_rank_penalty != null &&
        skill.no_rank_penalty !== '' &&
        !Number.isInteger(skill.no_rank_penalty * 1)
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'not_integer');
        Vue.set(this.errorFields, 'no_rank_penalty', true);
        return false;
      }

      if (skill.base_modifier != null && skill.base_modifier !== '' && !Number.isInteger(skill.base_modifier * 1)) {
        this.$store.commit(MESSAGE_ERROR_SET, 'not_integer');
        Vue.set(this.errorFields, 'base_modifier', true);
        return false;
      }

      if (
        skill.base_rank != null &&
        skill.base_rank !== '' &&
        skill.max_rank != null &&
        skill.max_rank !== '' &&
        skill.base_rank > skill.max_rank
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'skill_max_rank_conflict');
        Vue.set(this.errorFields, 'max_rank', true);
        return false;
      }

      if (!this.validateManeuverModifiers(skill.maneuver_modifiers)) {
        return false;
      }

      return true;
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
