var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    {
      staticClass: "body-view container",
      class: _vm.entityNature + "-view",
      attrs: { id: _vm.entityNature + "-view" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
      ]),
      _c(
        "div",
        { staticClass: "row relative" },
        [_c("ComponentItem", { attrs: { id: _vm.$route.params.id } })],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }