<template>
  <main v-if="restrictedAccess_grantMaster" class="body-view col-12 center">
    <h1 class="area_title">
      {{ $t(`campaign.create_campaign_game`) }}
    </h1>
    <Message />
    <form class="form-campaign-create left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="form-group required" :class="errorFields['name'] ? 'error' : ''">
          <label for="name">{{ $t('campaign.name') }}</label>
          <input
            required
            v-model="name"
            type="text"
            class="form-control"
            id="name"
            name="name"
            aria-describedby="name"
            :placeholder="$t('campaign.name')"
          />
        </div>
        <FormGroupSelect
          :selectValue="owner.selectedValue"
          :required="owner.required"
          :id="owner.id"
          :name="owner.name"
          :optionValue="owner.optionValue"
          :optionLabel="owner.optionLabel"
          class="form-group"
          :class="errorFields['owner'] ? 'error' : ''"
          :ariaDescribedby="owner.ariaDescribedby"
          :label="owner.label"
          :placeholder="owner.placeholder"
          :options="owner.data"
        />
        <div class="form-group" :class="errorFields['image'] ? 'error' : ''">
          <label for="image">{{ $t('global.cover') }}</label>
          <input
            type="file"
            class="form-control"
            id="image"
            name="image"
            aria-describedby="image"
            accept="image/*"
            @change="onChangeFile($event)"
          />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.create') }}
        </button>
      </div>
    </form>
  </main>
</template>

<script>
import formMixin from '@/mixins/form';
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import { MESSAGE_ERROR_SET, MESSAGE_ERROR_LIST_SET, MESSAGE_SUCCESS_SET } from '@/store/actions/message';
import { CAMPAIGN_TEMPLATE_CREATION_REQUEST } from '@/store/actions/campaign';
import Message from '@/components/Message';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import { mapState } from 'vuex';

export default {
  name: 'CampaignTemplateCreateView',
  components: {
    Message,
    FormGroupSelect
  },
  mixins: [formMixin, restrictedAccessMixin],
  data() {
    return {
      errorFields: [],
      isContentReady: false,
      name: '',
      owner: {
        ariaDescribedby: 'owner',
        data: [],
        id: 'owner',
        label: 'campaign.owner',
        name: 'owner',
        optionLabel: 'name',
        optionValue: 'id',
        placeholder: 'form.public',
        selectedValue: ''
      },
      targetFile: '',
      targetName: ''
    };
  },
  computed: {
    ...mapState(['master'])
  },
  created() {
    this.populateOwners().then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    onChangeFile($event) {
      this.targetName = $event.target.name;
      this.targetFile = $event.target.files[0];
    },
    populateOwners() {
      this.owner.data = [
        {
          id: this.master.id,
          type: 'master',
          name: this.master.name
        }
      ];
    },
    onSubmit() {
      const { name, owner } = this;
      const formData = new FormData();
      formData.append('name', name);
      formData.append('owner', owner.selectedValue);
      formData.append(this.targetName, this.targetFile);

      this.form_resetResponse();

      this.$store
        .dispatch(CAMPAIGN_TEMPLATE_CREATION_REQUEST, formData)
        .then(data => {
          // const id = data.response // not need at the moment
          const responseMessage = data.responseMessage;

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            this.$store.commit(MESSAGE_ERROR_LIST_SET, responseMessage);
          } else {
            this.$router.push('/campaign/template/list');
            this.$store.commit(MESSAGE_SUCCESS_SET);
          }
        })
        .catch(err => {
          let responseMessage = err;
          if (err.response && err.response.data && err.response.data.message) {
            responseMessage = err.response.data.message;
          }

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            const errorList = this.$helpers.getErrorList(responseMessage);
            this.form_setFieldErrors(responseMessage['fields']);
            this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
          } else {
            this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
            this.$helpers.errorManager(responseMessage);
          }
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.form-campaign-create {
  margin-top: auto;
}
</style>
