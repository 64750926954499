var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        { staticClass: "body-view container games", attrs: { id: "games" } },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col 12" }, [_c("Message")], 1)
          ]),
          _c(
            "div",
            { staticClass: "row relative" },
            [
              !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
              _vm.games.length > 0
                ? [
                    _vm._l(_vm.games, function(game) {
                      return [
                        _c("div", { key: game.id, staticClass: "game col-4" }, [
                          _c(
                            "div",
                            {
                              staticClass: "tile tile-selectable game-frame",
                              on: {
                                click: function($event) {
                                  return _vm.selectGame(game.id)
                                }
                              }
                            },
                            [
                              _c("ul", { staticClass: "game-text" }, [
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [_vm._v(_vm._s(_vm.$t("game.name")))]
                                  ),
                                  _vm._v(_vm._s(game.name) + " ")
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [_vm._v(_vm._s(_vm.$t("game.master")))]
                                  ),
                                  _vm._v(_vm._s(game.master_name) + " ")
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("game.starting_datetime"))
                                      )
                                    ]
                                  ),
                                  _vm._v(_vm._s(game.starting_datetime) + " ")
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("game.ending_datetime"))
                                      )
                                    ]
                                  ),
                                  _vm._v(
                                    _vm._s(
                                      game.ending_datetime
                                        ? game.ending_datetime
                                        : _vm.$t("global.on_going")
                                    ) + " "
                                  )
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.$t("game.current_datetime"))
                                      )
                                    ]
                                  ),
                                  _vm._v(_vm._s(game.current_datetime) + " ")
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [_vm._v(_vm._s(_vm.$t("game.setting")))]
                                  ),
                                  _vm._v(_vm._s(game.setting_name) + " ")
                                ]),
                                _c("li", [
                                  _c(
                                    "span",
                                    { staticClass: "game-text-label" },
                                    [_vm._v(_vm._s(_vm.$t("game.system")))]
                                  ),
                                  _vm._v(_vm._s(game.system_name) + " ")
                                ])
                              ]),
                              _c("div", { staticClass: "center" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-primary btn-invite-users",
                                    attrs: { type: "button" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t("button.game.invite_users")
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ])
                            ]
                          )
                        ])
                      ]
                    })
                  ]
                : _vm._e(),
              _c("div", { staticClass: "game game-new col-4" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "tile tile-selectable tile-with-background game-frame",
                    on: {
                      click: function($event) {
                        return _vm.createGame()
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "tile-image-frame game-image-frame " },
                      [
                        _c("img", {
                          staticClass: "game-image",
                          attrs: {
                            border: "0",
                            alt: _vm.$t("game.new_game"),
                            src: _vm.imageUrl
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "tile-selectable-text game-text" },
                      [_vm._v(" " + _vm._s(_vm.$t("game.new_game")) + " ")]
                    )
                  ]
                )
              ])
            ],
            2
          )
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }