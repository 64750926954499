import { CHARACTER_CREATION_STEP_REQUEST } from '@/store/actions/character';

export default {
  computed: {
    characterCreation_characterStatus() {
      return this.$store.state.character.status;
    }
  },
  created() {
    if (this.$router.currentRoute.name !== 'CharacterCreateStep1' && !this.$store.state.role.id) {
      this.$router.push('/role/list');
      return;
    }

    if (this.$store.state.role.id) {
      this.characterCreation_unwatchCharacterStatus = this.$watch('characterCreation_characterStatus', status => {
        if (status) {
          const isCharacterCreationAuth = this.$helpers.authCreateCharacter(this.$store.state.character.status);

          if (isCharacterCreationAuth) {
            this.$store
              .dispatch(CHARACTER_CREATION_STEP_REQUEST) // to refresh data
              .then(characterStep => {
                this.$helpers.goToCreationStep(this.step, characterStep);
              })
              .catch(err => {
                this.$helpers.errorManager(err);
              });
          }
        }
        this.characterCreation_unwatchCharacterStatus();
      });
    }
  }
};
