var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body-view home" },
    [
      _c("img", {
        attrs: { alt: "Vue logo", src: require("../assets/logo.png") }
      }),
      _c("HelloWorld", { attrs: { msg: "Welcome to Your Vue.js App" } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }