var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset2" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.saveSkill($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-frame" }, [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col-4" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group required",
                      class: _vm.errorFields["name"] ? "error" : ""
                    },
                    [
                      _c("label", { attrs: { for: "name" } }, [
                        _vm._v(_vm._s(_vm.$t("skill.name")))
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.skill.name,
                            expression: "skill.name"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          required: "",
                          type: "text",
                          id: "name",
                          name: "name",
                          "aria-describedby": "name"
                        },
                        domProps: { value: _vm.skill.name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.skill, "name", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["material_source"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.material_source.selectedValue,
                      required: _vm.material_source.required,
                      id: _vm.material_source.id,
                      name: _vm.material_source.name,
                      optionValue: _vm.material_source.optionValue,
                      optionLabel: _vm.material_source.optionLabel,
                      ariaDescribedby: _vm.material_source.ariaDescribedby,
                      label: _vm.material_source.label,
                      placeholder: _vm.material_source.placeholder,
                      options: _vm.material_source.data
                    }
                  }),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["race"] ? "error" : "",
                    attrs: {
                      nesting: _vm.parent.nesting,
                      selectValue: _vm.parent.selectedValue,
                      required: _vm.parent.required,
                      id: _vm.parent.id,
                      name: _vm.parent.name,
                      optionValue: _vm.parent.optionValue,
                      optionLabel: _vm.parent.optionLabel,
                      optionGroup: _vm.parent.optionGroup,
                      ariaDescribedby: _vm.parent.ariaDescribedby,
                      label: _vm.parent.label,
                      placeholder: _vm.parent.placeholder,
                      options: _vm.parent.data,
                      onChangeCallback: _vm.parent.onChange
                    }
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["base_rank"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "base_rank" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.base_rank")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.base_rank,
                              expression: "skill.base_rank"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            id: "base_rank",
                            name: "base_rank",
                            "aria-describedby": "base_rank"
                          },
                          domProps: { value: _vm.skill.base_rank },
                          on: {
                            change: function($event) {
                              return _vm.onlyPositiveInteger(
                                $event,
                                "base_rank"
                              )
                            },
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.skill,
                                  "base_rank",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onlyPositiveInteger(
                                  $event,
                                  "base_rank"
                                )
                              }
                            ],
                            blur: function($event) {
                              return _vm.onlyPositiveInteger(
                                $event,
                                "base_rank"
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["max_rank"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "max_rank" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.max_rank")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.max_rank,
                              expression: "skill.max_rank"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            id: "max_rank",
                            name: "max_rank",
                            "aria-describedby": "max_rank"
                          },
                          domProps: { value: _vm.skill.max_rank },
                          on: {
                            change: function($event) {
                              return _vm.onlyPositiveInteger($event, "max_rank")
                            },
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.skill,
                                  "max_rank",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onlyPositiveInteger(
                                  $event,
                                  "max_rank"
                                )
                              }
                            ],
                            blur: function($event) {
                              return _vm.onlyPositiveInteger($event, "max_rank")
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["rank_progression"]
                          ? "error"
                          : ""
                      },
                      [
                        _c("label", { attrs: { for: "rank_progression" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.rank_progression")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.rank_progression,
                              expression: "skill.rank_progression"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "1",
                            min: "0",
                            id: "rank_progression",
                            name: "rank_progression",
                            "aria-describedby": "rank_progression"
                          },
                          domProps: { value: _vm.skill.rank_progression },
                          on: {
                            change: function($event) {
                              return _vm.onlyPositiveInteger(
                                $event,
                                "rank_progression"
                              )
                            },
                            input: [
                              function($event) {
                                if ($event.target.composing) {
                                  return
                                }
                                _vm.$set(
                                  _vm.skill,
                                  "rank_progression",
                                  $event.target.value
                                )
                              },
                              function($event) {
                                return _vm.onlyPositiveInteger(
                                  $event,
                                  "rank_progression"
                                )
                              }
                            ],
                            blur: function($event) {
                              return _vm.onlyPositiveInteger(
                                $event,
                                "rank_progression"
                              )
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["no_rank_penalty"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "no_rank_penalty" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.no_rank_malus")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.no_rank_penalty,
                              expression: "skill.no_rank_penalty"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "1",
                            id: "no_rank_penalty",
                            name: "no_rank_penalty",
                            "aria-describedby": "no_rank_penalty"
                          },
                          domProps: { value: _vm.skill.no_rank_penalty },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.skill,
                                "no_rank_penalty",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["base_modifier"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "base_modifier" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.base_modifier")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.base_modifier,
                              expression: "skill.base_modifier"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "1",
                            id: "base_modifier",
                            name: "base_modifier",
                            "aria-describedby": "base_modifier"
                          },
                          domProps: { value: _vm.skill.base_modifier },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.skill,
                                "base_modifier",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["stat1"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.stat1.selectedValue,
                          required: _vm.stat1.required,
                          id: _vm.stat1.id,
                          name: _vm.stat1.name,
                          optionValue: _vm.stat1.optionValue,
                          optionLabel: _vm.stat1.optionLabel,
                          ariaDescribedby: _vm.stat1.ariaDescribedby,
                          label: _vm.stat1.label,
                          labelParams: _vm.stat1.labelParams,
                          placeholder: _vm.stat1.placeholder,
                          options: _vm.stat1.data
                        }
                      }),
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["stat2"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.stat2.selectedValue,
                          required: _vm.stat2.required,
                          id: _vm.stat2.id,
                          name: _vm.stat2.name,
                          optionValue: _vm.stat2.optionValue,
                          optionLabel: _vm.stat2.optionLabel,
                          ariaDescribedby: _vm.stat2.ariaDescribedby,
                          label: _vm.stat2.label,
                          labelParams: _vm.stat2.labelParams,
                          placeholder: _vm.stat2.placeholder,
                          options: _vm.stat2.data
                        }
                      }),
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-4",
                        class: _vm.errorFields["stat3"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.stat3.selectedValue,
                          required: _vm.stat3.required,
                          id: _vm.stat3.id,
                          name: _vm.stat3.name,
                          optionValue: _vm.stat3.optionValue,
                          optionLabel: _vm.stat3.optionLabel,
                          ariaDescribedby: _vm.stat3.ariaDescribedby,
                          label: _vm.stat3.label,
                          labelParams: _vm.stat3.labelParams,
                          placeholder: _vm.stat3.placeholder,
                          options: _vm.stat3.data
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["category"] ? "error" : "",
                    attrs: {
                      disabled: _vm.category.disabled,
                      selectValue: _vm.category.selectedValue,
                      required: _vm.category.required,
                      id: _vm.category.id,
                      name: _vm.category.name,
                      optionValue: _vm.category.optionValue,
                      optionLabel: _vm.category.optionLabel,
                      ariaDescribedby: _vm.category.ariaDescribedby,
                      label: _vm.category.label,
                      placeholder: _vm.category.placeholder,
                      options: _vm.category.data
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["type"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.type.selectedValue,
                          required: _vm.type.required,
                          id: _vm.type.id,
                          name: _vm.type.name,
                          optionValue: _vm.type.optionValue,
                          optionLabel: _vm.type.optionLabel,
                          ariaDescribedby: _vm.type.ariaDescribedby,
                          label: _vm.type.label,
                          placeholder: _vm.type.placeholder,
                          options: _vm.type.data
                        }
                      }),
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["medium"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.medium.selectedValue,
                          required: _vm.medium.required,
                          id: _vm.medium.id,
                          name: _vm.medium.name,
                          optionValue: _vm.medium.optionValue,
                          optionLabel: _vm.medium.optionLabel,
                          ariaDescribedby: _vm.medium.ariaDescribedby,
                          label: _vm.medium.label,
                          placeholder: _vm.medium.placeholder,
                          options: _vm.medium.data
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["maneuver_type"] ? "error" : "",
                        attrs: {
                          selectValue: _vm.maneuver_type.selectedValue,
                          required: _vm.maneuver_type.required,
                          id: _vm.maneuver_type.id,
                          name: _vm.maneuver_type.name,
                          optionValue: _vm.maneuver_type.optionValue,
                          optionLabel: _vm.maneuver_type.optionLabel,
                          ariaDescribedby: _vm.maneuver_type.ariaDescribedby,
                          label: _vm.maneuver_type.label,
                          placeholder: _vm.maneuver_type.placeholder,
                          options: _vm.maneuver_type.data
                        }
                      }),
                      _c("FormGroupSelect", {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["maneuver_subtype"]
                          ? "error"
                          : "",
                        attrs: {
                          selectValue: _vm.maneuver_subtype.selectedValue,
                          required: _vm.maneuver_subtype.required,
                          id: _vm.maneuver_subtype.id,
                          name: _vm.maneuver_subtype.name,
                          optionValue: _vm.maneuver_subtype.optionValue,
                          optionLabel: _vm.maneuver_subtype.optionLabel,
                          ariaDescribedby: _vm.maneuver_subtype.ariaDescribedby,
                          label: _vm.maneuver_subtype.label,
                          placeholder: _vm.maneuver_subtype.placeholder,
                          options: _vm.maneuver_subtype.data
                        }
                      })
                    ],
                    1
                  ),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["default_class"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.default_class.selectedValue,
                      required: _vm.default_class.required,
                      id: _vm.default_class.id,
                      name: _vm.default_class.name,
                      optionValue: _vm.default_class.optionValue,
                      optionLabel: _vm.default_class.optionLabel,
                      ariaDescribedby: _vm.default_class.ariaDescribedby,
                      label: _vm.default_class.label,
                      placeholder: _vm.default_class.placeholder,
                      options: _vm.default_class.data
                    }
                  }),
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["development_effort"]
                          ? "error"
                          : ""
                      },
                      [
                        _c("label", { attrs: { for: "development_effort" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.development_effort")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.development_effort,
                              expression: "skill.development_effort"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "number",
                            step: "0.001",
                            min: "0",
                            id: "development_effort",
                            name: "development_effort",
                            "aria-describedby": "development_effort"
                          },
                          domProps: { value: _vm.skill.development_effort },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(
                                _vm.skill,
                                "development_effort",
                                $event.target.value
                              )
                            }
                          }
                        })
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "form-group col-6",
                        class: _vm.errorFields["tag"] ? "error" : ""
                      },
                      [
                        _c("label", { attrs: { for: "tag" } }, [
                          _vm._v(_vm._s(_vm.$t("skill.tag")))
                        ]),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.skill.tag,
                              expression: "skill.tag"
                            }
                          ],
                          staticClass: "form-control",
                          attrs: {
                            type: "text",
                            id: "tag",
                            name: "tag",
                            "aria-describedby": "tag"
                          },
                          domProps: { value: _vm.skill.tag },
                          on: {
                            input: function($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.$set(_vm.skill, "tag", $event.target.value)
                            }
                          }
                        })
                      ]
                    )
                  ]),
                  _c("FormGroupSelect", {
                    staticClass: "form-group",
                    class: _vm.errorFields["status"] ? "error" : "",
                    attrs: {
                      selectValue: _vm.status.selectedValue,
                      required: _vm.status.required,
                      id: _vm.status.id,
                      name: _vm.status.name,
                      optionValue: _vm.status.optionValue,
                      optionLabel: _vm.status.optionLabel,
                      ariaDescribedby: _vm.status.ariaDescribedby,
                      label: _vm.status.label,
                      placeholder: _vm.status.placeholder,
                      options: _vm.status.data
                    }
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-8" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "form-group",
                      class: _vm.errorFields["synopsis"] ? "error" : ""
                    },
                    [
                      _c("label", { attrs: { for: "synopsis" } }, [
                        _vm._v(_vm._s(_vm.$t("skill.synopsis")))
                      ]),
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.skill.synopsis,
                            expression: "skill.synopsis"
                          }
                        ],
                        staticClass: "form-control",
                        attrs: {
                          id: "synopsis",
                          name: "synopsis",
                          "aria-describedby": "synopsis"
                        },
                        domProps: { value: _vm.skill.synopsis },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(_vm.skill, "synopsis", $event.target.value)
                          }
                        }
                      })
                    ]
                  ),
                  _c("FormGroupRichText", {
                    ref: _vm.description.ref,
                    staticClass: "form-group",
                    class: _vm.errorFields["description"] ? "error" : "",
                    attrs: {
                      value: _vm.description.value,
                      id: _vm.description.id,
                      name: _vm.description.name,
                      ariaDescribedby: _vm.description.ariaDescribedby,
                      label: _vm.description.label
                    }
                  }),
                  _c("FormGroupRichText", {
                    ref: _vm.difficulty_scale.ref,
                    staticClass: "form-group",
                    class: _vm.errorFields["difficulty_scale"] ? "error" : "",
                    attrs: {
                      value: _vm.difficulty_scale.value,
                      id: _vm.difficulty_scale.id,
                      name: _vm.difficulty_scale.name,
                      ariaDescribedby: _vm.difficulty_scale.ariaDescribedby,
                      label: _vm.difficulty_scale.label
                    }
                  }),
                  _c(
                    "FormGroupTextList",
                    _vm._g(
                      {
                        staticClass: "form-group",
                        class: _vm.errorFields["maneuver_modifiers"]
                          ? "error"
                          : "",
                        attrs: {
                          required: _vm.maneuver_modifiers.required,
                          disabled: _vm.maneuver_modifiers.disabled,
                          id: _vm.maneuver_modifiers.id,
                          name: _vm.maneuver_modifiers.name,
                          ariaDescribedby:
                            _vm.maneuver_modifiers.ariaDescribedby,
                          label: _vm.maneuver_modifiers.label,
                          listKeyProperty:
                            _vm.maneuver_modifiers.listKeyProperty,
                          listValueProperty:
                            _vm.maneuver_modifiers.listValueProperty,
                          list: _vm.maneuver_modifiers.data,
                          addEntryLabel: _vm.maneuver_modifiers.addEntryLabel,
                          errorFieldsInherited: _vm.errorFields
                        }
                      },
                      _vm.$listeners
                    )
                  )
                ],
                1
              )
            ])
          ]),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.save")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }