<template>
  <div class="body-view col-12 center">
    <h1 class="area_title">
      {{ $t(`skill.rankup`) }}
    </h1>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row main-content">
      <div class="dataset col-12 col-xxl-9">
        <div class="character-skills">
          <StatsBox ref="statsBox" @error-fields-set="form_onErrorFields" mode="rankup" />
          <form class="form-skills-rank-up left" @submit.prevent="saveRankUp">
            <SkillList
              @error-fields-set="form_onErrorFields"
              ref="skillList"
              mode="rankup"
              :errorFieldsInherited="errorFields"
            />

            <div class="center">
              <button :disabled="somethingHasChanged ? false : 'disabled'" type="submit" class="btn btn-primary">
                {{ $t('button.save') }}
              </button>
            </div>
          </form>
        </div>
      </div>
      <div id="summary" class="summary col-12 col-xxl-3 sticky">
        <div id="sticky-counters" class="sticky sticky-counters">
          <PartyCounterExperiencePoints />
          <CharacterCounterExperiencePoints />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import {
  MESSAGE_SUCCESS_SET,
  MESSAGE_PARTIAL_SUCCESS_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_ERROR_LIST_SET
} from '@/store/actions/message';
import { CHARACTER_REFRESH, CHARACTER_SKILLS_RANK_UP } from '@/store/actions/character';
import { SCORES_CONSUMED_RESET } from '@/store/actions/scores';
import Message from '@/components/Message';
import PartyCounterExperiencePoints from '@/components/Party/Counters/ExperiencePoints';
import CharacterCounterExperiencePoints from '@/components/Character/Counters/ExperiencePoints';
import SkillList from '@/components/Skill/List';
import StatsBox from '@/components/Stats/StatsBox';
import { EventBus } from '@/utils/eventBus';
import { mapState, mapGetters } from 'vuex';

export default {
  name: 'CharacterSkillsRankUpView',
  components: {
    Message,
    SkillList,
    StatsBox,
    PartyCounterExperiencePoints,
    CharacterCounterExperiencePoints
  },
  mixins: [formMixin],
  data() {
    return {
      errorFields: [],
      formValidationMessage: '',
      nextRoute: '',
      leavePageConfirmed: false
    };
  },
  computed: {
    ...mapState(['character', 'system', 'scores']),
    ...mapGetters(['isCharacterLoaded']),
    somethingHasChanged() {
      let totalDevelopmentPointsAllocated = 0;

      if (this.$refs.skillList && this.$refs.skillList.skills) {
        this.$refs.skillList.skills.forEach(function(skill) {
          totalDevelopmentPointsAllocated += skill.development_points_allocated
            ? skill.development_points_allocated * 1
            : 0;
        });
      }

      if (this.$refs.statsBox && this.$refs.statsBox.stats) {
        this.$refs.statsBox.stats.forEach(function(stat) {
          totalDevelopmentPointsAllocated += stat.development_points_allocated
            ? stat.development_points_allocated * 1
            : 0;
        });
      }

      return this.scores.consumed.experience_points > 0 || totalDevelopmentPointsAllocated > 0;
    }
  },
  created() {
    if (this.isCharacterLoaded) {
      this.validateAccess();
    } else {
      this.unwatchMaster = this.$watch('isCharacterLoaded', () => {
        this.unwatchMaster();
        this.validateAccess();
      });
    }

    EventBus.$on('modal-leave-confirm', () => {
      this.leavePageConfirmed = true;
      this.$router.push(this.nextRoute);
    });
    EventBus.$on('modal-leave-stay', () => {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.somethingHasChanged && !this.leavePageConfirmed) {
      this.$emit('modal', 'stay-leave', {
        headerText: 'modal.questions.sure_to_leave',
        bodyText: 'message.unsaved_changes'
      });
      this.nextRoute = to.path;
    } else {
      this.nextRoute = '';
      this.leavePageConfirmed = false;
      EventBus.$off('modal-leave-confirm');
      EventBus.$off('modal-leave-stay');
      this.$store.commit(SCORES_CONSUMED_RESET);
      next();
    }
  },
  methods: {
    saveRankUp() {
      if (this.somethingHasChanged) {
        const remainingExperiencePoints =
          this.scores.play.experience_points * 1 -
          (this.scores.consumed.experience_points ? this.scores.consumed.experience_points * 1 : 0);
        if (remainingExperiencePoints < 0) {
          this.$store.commit(MESSAGE_ERROR_SET, 'too_many_points_allocated');
        } else {
          const skillsData = [];
          if (this.$refs.skillList && this.$refs.skillList.skills) {
            for (let s = 0; s < this.$refs.skillList.skills.length; s++) {
              const skillObj = {
                id: this.$refs.skillList.skills[s].id,
                template: this.$refs.skillList.skills[s].template,
                isSkill: this.$refs.skillList.skills[s].isSkill,
                max_rank: this.$refs.skillList.skills[s].max_rank,
                rank: this.$refs.skillList.skills[s].rank,
                development_points: this.$refs.skillList.skills[s].development_points
              };
              skillsData.push(skillObj);
            }
          }

          const statsData = [];
          if (this.$refs.statsBox && this.$refs.statsBox.stats) {
            for (let s = 0; s < this.$refs.statsBox.stats.length; s++) {
              const statObj = {
                id: this.$refs.statsBox.stats[s].id,
                temporary: this.$refs.statsBox.stats[s].temporary,
                potential: this.$refs.statsBox.stats[s].potential,
                tag: this.$refs.statsBox.stats[s].tag,
                development_points: this.$refs.statsBox.stats[s].development_points
              };
              statsData.push(statObj);
            }
          }

          const formData = new FormData();
          formData.append('skills', JSON.stringify(skillsData));
          formData.append('stats', JSON.stringify(statsData));
          formData.append('experience_points', remainingExperiencePoints);
          this.form_resetResponse();

          this.$store
            .dispatch(CHARACTER_SKILLS_RANK_UP, formData)
            .then(data => {
              const responseMessage = data.message;

              if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
                this.$store.commit(MESSAGE_ERROR_LIST_SET, responseMessage);
              } else {
                this.$store.commit(SCORES_CONSUMED_RESET);
                this.$store
                  .dispatch(CHARACTER_REFRESH)
                  .then(() => {
                    // no redirect at the moment
                    this.$store.commit(MESSAGE_SUCCESS_SET);
                  })
                  .catch(err => {
                    this.$store.commit(MESSAGE_PARTIAL_SUCCESS_SET, [err.response || err]);
                  })
                  .finally(() => {
                    this.form_scrollToMessage();
                    if (this.$refs.statsBox && this.$refs.statsBox.stats) {
                      this.$refs.statsBox.populateStats();
                    }
                    if (this.$refs.skillList && this.$refs.skillList.skills) {
                      this.$refs.skillList.populateSkills();
                    }
                  });
              }
            })
            .catch(err => {
              let responseMessage = err;
              if (err.response && err.response.data && err.response.data.message) {
                responseMessage = err.response.data.message;
              }

              if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
                const errorList = this.$helpers.getErrorList(responseMessage);
                this.form_setFieldErrors(responseMessage['fields']);
                this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
              } else {
                this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
                this.$helpers.errorManager(responseMessage);
              }
              this.form_scrollToMessage();
            });
        }
      } else {
        console.log('nothing has changed');
      }
    },
    validateAccess() {
      if (this.isCharacterLoaded && !this.$helpers.checkServiceAccess('active', this.$store.state.character.status)) {
        this.$router.push('/character/profile');
      }
    }
  }
};
</script>

<style lang="scss" scoped>
@include sticky-counters-on-full-width-page();
</style>
