import {
  PREFERENCE_GET_ID_FROM_TAG,
  PREFERENCE_UPDATE_ERROR,
  PREFERENCE_UPDATE_REQUEST,
  PREFERENCE_UPDATE_SUCCESS,
  PREFERENCES_ERROR,
  PREFERENCES_REQUEST,
  PREFERENCES_SUCCESS
} from '../actions/preferences';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  arePreferencesLoaded: false,
  options: [],
  statusVuex: {}
};

const getters = {
  arePreferencesLoaded: state => !!state.arePreferencesLoaded,
  getPreferencesStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isExtendedView: state => {
    if (state.options.checkbox) {
      for (let i = 0; i < state.options.checkbox.length; i++) {
        if (state.options.checkbox[i].tag === 'show_extended_view') {
          return !!state.options.checkbox[i].value;
        }
      }
    }
  },
  isMasterView: () => false
};

const actions = {
  [PREFERENCE_GET_ID_FROM_TAG]: ({ commit, dispatch, rootState }, preference) => {
    if (!preference.tag) {
      return false;
    }

    return new Promise(reject => {
      commit(PREFERENCES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);

      axios
        .post(config.sitePath.api.getPreferenceIdFromTag + '/' + preference.tag, params)
        .then(resp => {
          let data = {
            key: resp.data.id,
            value: preference.value
          };
          commit(PREFERENCES_SUCCESS, data);
          return dispatch(PREFERENCE_UPDATE_REQUEST, data);
        })
        .catch(err => {
          commit(PREFERENCES_ERROR, err.response);
          reject(err);
        });
    });
  },
  [PREFERENCE_UPDATE_REQUEST]: ({ commit, dispatch, rootState }, preference) => {
    if (!preference.key && !preference.tag) {
      return false;
    }

    if (!preference.key) {
      return dispatch(PREFERENCE_GET_ID_FROM_TAG, preference);
    }

    return new Promise((resolve, reject) => {
      commit(PREFERENCE_UPDATE_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(
          config.sitePath.api.setPreference +
            '/' +
            encodeURIComponent(preference.key) +
            '/' +
            encodeURIComponent(preference.value),
          params
        )
        .then(resp => {
          let data = {
            key: preference.key,
            value: !!preference.value,
            type: preference.type
          };
          commit(PREFERENCE_UPDATE_SUCCESS, data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PREFERENCE_UPDATE_ERROR, err.response);
          reject(err);
        });
    });
  },
  [PREFERENCES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(PREFERENCES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getPreferences, params)
        .then(resp => {
          commit(PREFERENCES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(PREFERENCES_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [PREFERENCE_UPDATE_ERROR]: state => {
    Vue.set(state.statusVuex, 'preferences', 'error');
  },
  [PREFERENCE_UPDATE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'preferences', 'loading');
  },
  [PREFERENCE_UPDATE_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'preferences', 'success');
    for (let option of state.options[resp.type]) {
      if (option.id === resp.key) {
        option.value = resp.value;
        break;
      }
    }
  },
  [PREFERENCES_ERROR]: state => {
    Vue.set(state.statusVuex, 'preferences', 'error');
  },
  [PREFERENCES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'preferences', 'loading');
  },
  [PREFERENCES_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'preferences', 'success');
    state.arePreferencesLoaded = true;
    state.options = resp;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
