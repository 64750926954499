var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "form-group-select",
      class: _vm.required === "required" ? "required" : ""
    },
    [
      _c(
        "label",
        { staticClass: "form-group-select-label", attrs: { for: "id" } },
        [_vm._v(_vm._s(_vm.$t(_vm.label, _vm.labelParams)))]
      ),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.selectedValue,
              expression: "selectedValue"
            }
          ],
          staticClass: "form-group-select form-control",
          attrs: {
            required: _vm.required,
            disabled: _vm.disabled,
            id: _vm.id,
            "data-select": JSON.stringify(_vm.data),
            name: _vm.name,
            "aria-describedby": _vm.ariaDescribedby
          },
          on: {
            change: [
              function($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function(o) {
                    return o.selected
                  })
                  .map(function(o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.selectedValue = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.onChange
            ]
          }
        },
        [
          !_vm.required || !_vm.selectedValue
            ? _c("option", { attrs: { value: "" } }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        !_vm.placeholder && _vm.placeholder !== ""
                          ? "form.select_placeholder"
                          : _vm.placeholder
                      )
                    ) +
                    " "
                )
              ])
            : _vm._e(),
          _vm._l(_vm.options, function(option) {
            return [
              _vm.optionGroup && option[_vm.optionGroup]
                ? _c("optgroup", {
                    key:
                      "select-" +
                      _vm.id +
                      "-optgroup-" +
                      option[_vm.optionValue],
                    attrs: { label: option[_vm.optionLabel] }
                  })
                : _c(
                    "option",
                    {
                      key:
                        "select-" +
                        _vm.id +
                        "-option-" +
                        option[_vm.optionValue],
                      domProps: { value: option[_vm.optionValue] }
                    },
                    [_vm._v(_vm._s(option[_vm.optionLabel]))]
                  )
            ]
          }),
          _vm._v(" > ")
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }