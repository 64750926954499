import { AUTH_LOGOUT } from '../actions/auth';
import {
  ACTION_COMMIT,
  ACTION_ERROR,
  ACTION_PARTY_EXECUTE,
  ACTION_REQUEST,
  ACTION_RESET,
  ACTION_SCHEDULE,
  ACTION_STATUS_SET,
  ACTION_SUCCESS,
  ACTIONS_REQUEST_ERROR,
  ACTIONS_REQUEST_RESET,
  ACTIONS_REQUEST_SUCCESS,
  ACTIONS_REQUEST,
  COMMITTED_ACTION_DELETE,
  SCHEDULED_ACTION_DELETE,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET,
  SCHEDULED_ACTION_MANEUVER_MODIFIER_SET,
  SCHEDULED_ACTION_PLAY
} from '../actions/action';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  commitmentList: [],
  scheduleList: [],
  statusVuex: {}
};

const getters = {
  getActionStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [ACTION_COMMIT]: ({ commit, rootState }, data) => {
    const {
      characterId,
      characterSkill,
      gameSkill,
      initiativeBonus,
      initiativeRoll,
      skillBonus,
      skillRoll,
      game,
      campaign,
      status
    } = data;

    if (!characterId || !gameSkill) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('characterId', characterId);
      params.append('characterSkill', characterSkill);
      params.append('gameSkill', gameSkill);
      params.append('initiativeBonus', initiativeBonus);
      params.append('initiativeRoll', initiativeRoll);
      params.append('skillBonus', skillBonus);
      if (skillRoll) {
        params.append('skillRoll', skillRoll);
      }
      params.append('game', game);
      params.append('campaign', campaign);
      params.append('status', status);

      axios
        .post(config.sitePath.api.commitAction, params)
        .then(resp => {
          commit(ACTION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(ACTION_RESET);
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },
  [ACTION_PARTY_EXECUTE]: ({ commit, rootState }, data) => {
    const { game, gameSkill, actions, campaign, status } = data;

    if (!game || !gameSkill || !actions || !status) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('game', game);
      params.append('gameSkill', gameSkill);
      params.append('actions', JSON.stringify(actions));
      params.append('campaign', campaign);
      params.append('status', status);

      axios
        .post(config.sitePath.api.executePartyAction, params)
        .then(resp => {
          commit(ACTION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(ACTION_RESET);
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },
  [ACTION_SCHEDULE]: ({ commit, rootState }, data) => {
    const { gameSkill, game, campaign, status } = data;

    if (!game || !gameSkill) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('game', game);
      params.append('gameSkill', gameSkill);
      params.append('campaign', campaign);
      params.append('status', status);

      axios
        .post(config.sitePath.api.scheduleAction, params)
        .then(resp => {
          commit(ACTION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(ACTION_RESET);
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },
  [ACTION_STATUS_SET]: ({ commit, dispatch, rootState }, data) => {
    const { id, status } = data;

    if (!id || !status) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('status', status);
      axios
        .post(config.sitePath.api.setScheduledActionStatus + '/' + id, params)
        .then(resp => {
          /* scheduled actions gets filtered by status in the query, that's why a new database call is necessary (otherwise 
             the status would get updated in the view but the action not filtered out) */
          return dispatch(ACTIONS_REQUEST, data).then(() => {
            commit(ACTION_SUCCESS, resp.data); // VERIFICARE!!!! perchè resp.data
            resolve(resp.data);
          });
        })
        .catch(err => {
          commit(ACTION_RESET);
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },
  [ACTIONS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(ACTIONS_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);

      axios
        .post(config.sitePath.api.getActionList, params)
        .then(resp => {
          commit(ACTIONS_REQUEST_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(ACTIONS_REQUEST_RESET);
          commit(ACTIONS_REQUEST_ERROR);

          reject(err);
        });
    });
  },

  [COMMITTED_ACTION_DELETE]: ({ commit, dispatch, rootState }, data) => {
    const { id, character } = data;

    if (!id) {
      return false;
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      if (character) {
        params.append('character', character);
      }
      return axios
        .post(config.sitePath.api.deleteCommittedAction + '/' + id, params)
        .then(resp => {
          /* this deletion has effects only on committment, but for parallelism with SCHEDULED_ACTION_DELETE a new database call is performed */
          return dispatch(ACTIONS_REQUEST, data).then(() => {
            commit(ACTION_SUCCESS, resp.data); // VERIFICARE!!!! perchè resp.data
            resolve(resp.data);
          });
        })
        .catch(err => {
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },

  [SCHEDULED_ACTION_DELETE]: ({ commit, dispatch, rootState }, data) => {
    const { id } = data;

    if (!id) {
      return false;
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.deleteScheduledAction + '/' + id, params)
        .then(resp => {
          /* this deletion has effects on both schedule and committment. Rather than updating multiple places it's better 
             to make a new database call */
          return dispatch(ACTIONS_REQUEST, data).then(() => {
            commit(ACTION_SUCCESS, resp.data); // VERIFICARE!!!! perchè resp.data
            resolve(resp.data);
          });
        })
        .catch(err => {
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  },

  [SCHEDULED_ACTION_PLAY]: ({ commit, dispatch, rootState }, data) => {
    const { id, game, modifier, committedActions } = data;

    if (!id || !game || modifier == null) {
      return Promise.reject('no_data');
    }

    return new Promise((resolve, reject) => {
      commit(ACTION_REQUEST);

      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('game', game);
      params.append('modifier', modifier);
      params.append('committedActions', JSON.stringify(committedActions));

      axios
        .post(config.sitePath.api.playScheduledAction + '/' + id, params)
        .then(resp => {
          return dispatch(ACTIONS_REQUEST, data).then(() => {
            commit(ACTION_SUCCESS, resp.data); // VERIFICARE!!!! perchè resp.data
            resolve(resp.data);
          });
        })
        .catch(err => {
          commit(ACTION_RESET);
          commit(ACTION_ERROR);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [ACTION_ERROR]: state => {
    Vue.set(state.statusVuex, 'action', 'error');
  },
  [ACTION_REQUEST]: state => {
    Vue.set(state.statusVuex, 'action', 'loading');
  },
  [ACTION_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [ACTION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'action', 'success');
  },

  [SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY]: (state, modifier) => {
    const scheduledAction = state.scheduleList.find(action => action.id === modifier.scheduledActionId);
    Vue.set(scheduledAction, 'customModifier', scheduledAction.customModifier + modifier.variation);
    Vue.set(state.statusVuex, 'action', 'success');
  },
  [SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET]: (state, scheduledActionId) => {
    const scheduledAction = state.scheduleList.find(action => action.id === scheduledActionId);
    Vue.set(scheduledAction, 'customModifier', null);
    Vue.set(scheduledAction, 'modifiers', []);
    Vue.set(state.statusVuex, 'action', 'success');
  },
  [SCHEDULED_ACTION_MANEUVER_MODIFIER_SET]: (state, modifier) => {
    const scheduledAction = state.scheduleList.find(action => action.id === modifier.scheduledActionId);

    if (modifier.value === false) {
      if (modifier.category) {
        scheduledAction.modifiers = scheduledAction.modifiers.filter(
          m => m.category !== modifier.category || (m.category === modifier.category && m.id !== modifier.id)
        );
      } else {
        scheduledAction.modifiers = scheduledAction.modifiers.filter(m => m.id !== modifier.id);
      }
    } else {
      if (modifier.category) {
        scheduledAction.modifiers = scheduledAction.modifiers.filter(m => m.category !== modifier.category);
      }
      scheduledAction.modifiers.push(modifier);
    }

    Vue.set(state.statusVuex, 'action', 'success');
  },

  [ACTIONS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'actions', 'loading');
  },
  [ACTIONS_REQUEST_ERROR]: state => {
    Vue.set(state.statusVuex, 'actions', 'error');
  },
  [ACTIONS_REQUEST_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [ACTIONS_REQUEST_SUCCESS]: (state, resp) => {
    const skills = [];
    let currSkillId = null;

    for (let a = 0; a < resp.length; a++) {
      resp[a].initiative_modifiers = [];
      resp[a].skill_customModifier = null;
      resp[a].skill_modifiers = [];

      if (currSkillId !== resp[a].skill) {
        let actionSkill = resp[a];

        skills.push({
          id: actionSkill.scheduled_action,
          skillId: actionSkill.skill,
          skillParentId: actionSkill.skill_parent,
          gameSkillId: actionSkill.character_skill,
          gameSkillParentId: actionSkill.game_skill_parent,
          characterSkillId: actionSkill.character_skill,
          characterSkillParentId: actionSkill.game_skill_parent,
          name: actionSkill.skill_name,
          maneuverType: actionSkill.maneuver_type,
          maneuverSubtype: actionSkill.maneuver_subtype,
          hier: actionSkill.hier,
          customModifier: null,
          modifier: actionSkill.scheduled_action_modifier,
          modifiers: [],
          game: actionSkill.game,
          campaign: actionSkill.campaign,
          status: actionSkill.scheduled_action_status,
          timestamp: actionSkill.scheduled_action_timestamp
        });

        currSkillId = resp[a].skill;
      }
    }

    state.scheduleList = skills;
    state.commitmentList = resp;
    Vue.set(state.statusVuex, 'actions', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
