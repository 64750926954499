var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _vm.headerText
            ? _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm._t("header", [
                    _c("h3", { staticClass: "modal-body-header" }, [
                      _vm._v(_vm._s(_vm.headerText))
                    ])
                  ])
                ],
                2
              )
            : _vm._e(),
          _vm.bodyText
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._t("body", [
                    _c("span", { staticClass: "modal-body-text" }, [
                      _vm._v(_vm._s(_vm.bodyText))
                    ])
                  ])
                ],
                2
              )
            : _vm._e(),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _vm._t("footer", [
                _vm.type === "ok-cancel"
                  ? [
                      _vm.buttonConfirm
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-button modal-button-confirm",
                              on: { click: _vm.confirmOperation }
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonConfirm) + " ")]
                          )
                        : _vm._e(),
                      _vm.buttonCancel
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-button modal-button-cancel",
                              on: { click: _vm.cancelOperation }
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonCancel) + " ")]
                          )
                        : _vm._e()
                    ]
                  : _vm.type === "stay-leave"
                  ? [
                      _vm.buttonCancel
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-button modal-button-cancel",
                              on: { click: _vm.cancelLeave }
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonCancel) + " ")]
                          )
                        : _vm._e(),
                      _vm.buttonConfirm
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-button modal-button-confirm",
                              on: { click: _vm.confirmLeave }
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonConfirm) + " ")]
                          )
                        : _vm._e()
                    ]
                  : _vm.type === "close"
                  ? [
                      _vm.buttonCancel
                        ? _c(
                            "button",
                            {
                              staticClass: "modal-button modal-button-cancel",
                              on: { click: _vm.close }
                            },
                            [_vm._v(" " + _vm._s(_vm.buttonCancel) + " ")]
                          )
                        : _vm._e()
                    ]
                  : _vm._e()
              ])
            ],
            2
          )
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }