<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div v-if="headerText" class="modal-header">
            <slot name="header">
              <h3 class="modal-body-header">{{ headerText }}</h3>
            </slot>
          </div>

          <div v-if="bodyText" class="modal-body">
            <slot name="body">
              <span class="modal-body-text">{{ bodyText }}</span>
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <template v-if="type === 'ok-cancel'">
                <button v-if="buttonConfirm" class="modal-button modal-button-confirm" @click="confirmOperation">
                  {{ buttonConfirm }}
                </button>
                <button v-if="buttonCancel" class="modal-button modal-button-cancel" @click="cancelOperation">
                  {{ buttonCancel }}
                </button>
              </template>
              <template v-else-if="type === 'stay-leave'">
                <button v-if="buttonCancel" class="modal-button modal-button-cancel" @click="cancelLeave">
                  {{ buttonCancel }}
                </button>
                <button v-if="buttonConfirm" class="modal-button modal-button-confirm" @click="confirmLeave">
                  {{ buttonConfirm }}
                </button>
              </template>
              <template v-else-if="type === 'close'">
                <button v-if="buttonCancel" class="modal-button modal-button-cancel" @click="close">
                  {{ buttonCancel }}
                </button>
              </template>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
.modal {
  &-mask {
    background-color: $modal-mask-background-color;
    display: table;
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    transition: opacity 0.3s ease;
    width: 100%;
    z-index: $component-modal-z-index;
  }

  &-wrapper {
    display: table-cell;
    vertical-align: middle;
  }

  &-container {
    background-color: $modal-container-background-color;
    border-radius: 2px;
    box-shadow: 0 2px 8px $modal-container-box-shadow-color;
    margin: 0px auto;
    max-width: 500px;
    padding: 20px 30px;
    transition: all 0.3s ease;
    width: 80%;
  }

  &-header {
    border: 0;
    padding-bottom: 0;

    h3 {
      color: $modal-header-color;
      margin-top: 0;
      text-align: left;
    }
  }

  &-body {
    margin: 0;
    text-align: left;
  }

  &-footer {
    border: 0;
    justify-content: space-evenly;
  }

  &-button {
    border-radius: 5px;
    border-width: 1px;
    padding: 4px 8px;
    min-width: 60px;
    font-size: 1.4rem;

    &:hover {
      background-color: $modal-button-hover-background-color;
    }
  }

  &-button-cancel {
    color: $modal-button-cancel-color;
  }

  &-button-confirm {
    color: $modal-button-confirm-color;
  }

  /*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

  &-enter {
    opacity: 0;
  }

  &-leave-active {
    opacity: 0;
  }

  &-enter .modal-container,
  &-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
}
</style>

<script>
import { EventBus } from '@/utils/eventBus';

export default {
  name: 'Modal',
  props: {
    type: {
      type: String,
      default: null
    },
    headerText: {
      type: String,
      default: null
    },
    bodyText: {
      type: String,
      default: null
    },
    buttonCancel: {
      type: String,
      default: null
    },
    buttonConfirm: {
      type: String,
      default: null
    }
  },
  data() {
    return {};
  },
  computed: {},
  methods: {
    cancelOperation() {
      this.$emit('cancel');
      EventBus.$emit('modal-cancel');
    },
    confirmOperation() {
      this.$emit('confirm');
      EventBus.$emit('modal-ok');
    },

    cancelLeave() {
      this.$emit('cancel');
      EventBus.$emit('modal-leave-stay');
    },
    confirmLeave() {
      this.$emit('confirm');
      EventBus.$emit('modal-leave-confirm');
    },

    close() {
      this.$emit('close');
      EventBus.$emit('modal-close');
    }
  }
};
</script>
