<template>
  <main id="role-list-view" class="body-view container role-list-view">
    <div class="row">
      <div class="col 12">
        <Message />
      </div>
    </div>
    <div class="row relative">
      <RoleList :type="type" />
    </div>
  </main>
</template>

<script>
import Message from '@/components/Message';
import RoleList from '@/components/Role/List';

export default {
  name: 'RoleListView',
  components: {
    Message,
    RoleList
  },
  data() {
    return {
      type: ''
    };
  },
  created() {
    this.type = this.$route.path.split('/')[1];
  }
};
</script>
