export const ACTION_COMMIT = 'ACTION_COMMIT';
export const ACTION_ERROR = 'ACTION_ERROR';
export const ACTION_PARTY_EXECUTE = 'ACTION_PARTY_EXECUTE';
export const ACTION_REQUEST = 'ACTION_REQUEST';
export const ACTION_RESET = 'ACTION_RESET';
export const ACTION_SCHEDULE = 'ACTION_SCHEDULE';
export const ACTION_STATUS_SET = 'ACTION_STATUS_SET';
export const ACTION_STATUS_SUCCESS = 'ACTION_STATUS_SUCCESS';
export const ACTION_SUCCESS = 'ACTION_SUCCESS';
export const ACTIONS_REQUEST = 'ACTIONS_REQUEST';
export const ACTIONS_REQUEST_ERROR = 'ACTIONS_REQUEST_ERROR';
export const ACTIONS_REQUEST_RESET = 'ACTIONS_REQUEST_RESET';
export const ACTIONS_REQUEST_SUCCESS = 'ACTIONS_REQUEST_SUCCESS';
export const COMMITTED_ACTION_DELETE = 'COMMITTED_ACTION_DELETE';
export const SCHEDULED_ACTION_DELETE = 'SCHEDULED_ACTION_DELETE';
export const SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY = 'SCHEDULED_ACTION_MANEUVER_MODIFIER_MODIFY';
export const SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET = 'SCHEDULED_ACTION_MANEUVER_MODIFIER_RESET';
export const SCHEDULED_ACTION_MANEUVER_MODIFIER_SET = 'SCHEDULED_ACTION_MANEUVER_MODIFIER_SET';
export const SCHEDULED_ACTION_PLAY = 'SCHEDULED_ACTION_PLAY';
