var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        {
          staticClass: "body-view container-fluid skills",
          attrs: { id: "skills" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "area_title col-12 center" }, [
              _c("h1", [_vm._v(" " + _vm._s(_vm.$t("skill.skill_list")) + " ")])
            ])
          ]),
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
          ]),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-12" },
              [_c("SkillList", { ref: "skillList", attrs: { mode: "edit" } })],
              1
            )
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }