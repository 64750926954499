var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("form", { staticClass: "form-preferences" }, [
    _c(
      "ul",
      { staticClass: "preferences" },
      _vm._l(_vm.preferences.checkbox, function(preference) {
        return _c("li", { key: "preference-" + preference.id }, [
          _c("input", {
            staticClass: "form-check-input",
            attrs: {
              type: "checkbox",
              "data-type": "checkbox",
              id: "preferences_" + preference.id,
              name: "preferences_" + preference.tag,
              disabled: preference.disabled
            },
            domProps: { value: preference.id, checked: preference.value },
            on: {
              change: function($event) {
                return _vm.savePreference($event)
              }
            }
          }),
          _c(
            "label",
            {
              staticClass: "form-check-label",
              attrs: { for: "preferences_" + preference.id }
            },
            [_vm._v(_vm._s(_vm.$t("preferences." + preference.tag)))]
          )
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }