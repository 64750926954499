<template>
  <CounterPoints :className="className" :currentPoints="currentPoints" :label="label" :totalPoints="totalPoints" />
</template>

<script>
import CounterPoints from '@/components/Counters/Points';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterCounterHobbyRanks',
  data() {
    return {
      className: 'character-hobby-ranks',
      label: 'hobby_ranks'
    };
  },
  components: {
    CounterPoints
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'scores']),
    currentPoints() {
      return this.totalPoints * 1 - this.$helpers.sanitizeInteger(this.scores.consumed.hobby_ranks);
    },
    totalPoints() {
      return this.isCharacterLoaded ? this.character.scores.consumable.hobby_ranks : '';
    }
  }
};
</script>

<style lang="scss" scoped>
.counter {
  @include rectangular-counter(
    $character-hobby-ranks-counter-bg-color,
    $character-hobby-ranks-counter-border-color,
    $character-hobby-ranks-counter-font-color
  );
}
</style>
