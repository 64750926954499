var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "body-view col-12 center" }, [
    _c("h1", { staticClass: "area_title" }, [
      _vm._v(
        " " +
          _vm._s(_vm.$t("role.character.create_character_dataset" + _vm.step)) +
          " "
      )
    ]),
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
    ]),
    _c("div", { staticClass: "row main-content" }, [
      _c("div", { staticClass: "col-9" }, [
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col-6" },
            [_c("CharacterSummary", { attrs: { showModifiers: false } })],
            1
          ),
          _c("div", { staticClass: "col-6" }, [_c("CharacterSummaryScores")], 1)
        ]),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "dataset col-12" },
            [_c("Dataset", { ref: "dataset" })],
            1
          )
        ])
      ]),
      _c(
        "div",
        { staticClass: "summary col-3 sticky", attrs: { id: "summary" } },
        [
          _c(
            "div",
            {
              staticClass: "sticky sticky-counters",
              attrs: { id: "sticky-counters" }
            },
            [
              _c("CharacterCounterCategoryRanks"),
              _c("CharacterCounterWhiteLabelRanks"),
              _c("CharacterCounterFreeRanks"),
              _c("CharacterCounterHobbyRanks"),
              _c("CharacterCounterDevelopmentPoints")
            ],
            1
          )
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }