<template>
  <div class="form-group-rich-text" :class="required === 'required' ? 'required' : ''">
    <label class="form-group-rich-text-label" for="id">{{ $t(label, labelParams) }}</label>
    <vue-editor
      v-model="data"
      :disabled="disabled"
      :placeholder="placeholder"
      :required="required"
      :id="id"
      :name="name"
      :aria-describedby="ariaDescribedby"
      class="form-group-rich-text form-control"
    />
  </div>
</template>

<style lang="scss" scoped>
/deep/ .ql-toolbar,
/deep/ .ql-container {
  &.ql-snow {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
  }
}

/deep/ .ql-editor {
  font-size: $global-font-size;
}

/deep/ .ql-container {
  &.ql-snow {
    border-bottom-width: 0;
  }
}

.form-control {
  height: auto;
  padding: 0;
}
</style>

<script>
import { VueEditor } from 'vue2-editor';

export default {
  name: 'FormGroupRichText',
  components: {
    VueEditor
  },
  props: {
    required: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: null
    },
    value: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    },
    name: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelParams: {
      type: Object,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    ariaDescribedby: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      data: null
    };
  },
  methods: {
    setEditorContent(content) {
      this.data = content;
    }
  }
};
</script>
