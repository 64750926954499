<template>
  <div class="tile tile-selectable item-frame" @click="selectItem()">
    <div class="item-image-frame">
      <img
        class="item-image image-fill-frame"
        :class="item.cover ? '' : 'fade-image'"
        border="0"
        :alt="item.name"
        :src="item.cover ? imageUrl + item.id + '/' + item.cover : missingImage"
      />
      <button type="button" class="btn btn-primary btn-close-item" @click.stop="deleteItem()">
        {{ $t('button.' + entityNature + '.delete_' + entityNature) }}
      </button>
    </div>
    <ul class="item-text">
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.name') }}</span
        >{{ item.name }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.owner') }}</span
        >{{ item.owner_name ? item.owner_name : $t('global.public') }}
      </li>
    </ul>
  </div>
</template>

<script>
const entityNature = 'campaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

//import { CAMPAIGN_SELECT } from '@/store/actions/campaign';
//import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized,
  props: ['item'],
  data() {
    return {
      itemToDelete: undefined,
      entityNature,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters(['get' + entityNatureCapitalized + 'Status', 'isMaster', 'is' + entityNatureCapitalized + 'Loaded']),
    imageUrl() {
      return config.mediaPath[entityNature] + '/';
    },
    missingImage() {
      return config.mediaPath.application.placeholders + 'missing_' + entityNature + '_picture.png';
    }
  },
  methods: {
    deleteItem() {
      /* IMPORTANT: TO BE IMPLEMENTED 
        a campaign can be delete only if it was never played (i.e. no game linked) */
      console.log('delete ' + entityNature, this.item.id);
      this.$emit('delete-' + entityNature, this.item.id);
    },
    selectItem() {
      console.log('select ' + entityNature + ': ', this.item.id);
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  max-width: 100%;

  .item {
    margin-bottom: 25px;

    &-frame {
      border-color: $campaign-border-color;

      &:hover {
        border-color: $campaign-border-color-hover;
      }
    }

    &-image-frame {
      position: relative;
    }

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .btn-close-item {
      right: 0;
      position: absolute;
      top: 0;
    }
  }
}
</style>
