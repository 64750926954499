var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "stats-form" } }, [
    _c("table", { staticClass: "table table-stats" }, [
      _c("thead", [
        _c("tr", [
          _c("th", { staticClass: "left" }, [
            _vm._v(_vm._s(_vm.$t("stats.stat")))
          ]),
          _c("th", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.$t("stats.temporary")))
          ]),
          _c("th", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.$t("stats.potential")))
          ]),
          _c("th", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.$t("stats.race_bonus")))
          ]),
          _c("th", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.$t("stats.score_calculated")))
          ]),
          _c("th", { staticClass: "center" }, [
            _vm._v(_vm._s(_vm.$t("stats.bonus_calculated")))
          ])
        ])
      ]),
      _c(
        "tbody",
        [
          _vm._l(_vm.statCategories, function(category, catIndex) {
            return [
              _c(
                "tr",
                { key: "category-" + catIndex, staticClass: "stats-category" },
                [
                  _c("td", { attrs: { colspan: "6" } }, [
                    _vm._v(_vm._s(category.name))
                  ])
                ]
              ),
              _vm._l(_vm.groupedStats(category), function(stat, statIndex) {
                return _c(
                  "tr",
                  {
                    key: "stat-" + catIndex + "-" + statIndex,
                    staticClass: "stats-entry",
                    class: {
                      even: statIndex % 2 === 0,
                      odd: statIndex % 2 !== 0,
                      error:
                        stat.statusTypedStat === "error" ||
                        _vm.errorFieldsInherited["temporary_" + stat.id] ||
                        _vm.errorFieldsInherited["potential_" + stat.id]
                    }
                  },
                  [
                    _c("td", { staticClass: "stat left" }, [
                      _vm._v(_vm._s(stat.stat))
                    ]),
                    _c(
                      "td",
                      { staticClass: "center" },
                      [
                        _c("input", {
                          ref: "temporary_" + stat.id,
                          refInFor: true,
                          staticClass: "form-control",
                          attrs: {
                            required: "required",
                            type: _vm.getTemporaryInputType(stat.id),
                            disabled: _vm.disableTemporaryInput(stat.id),
                            id: "temporary_" + stat.id,
                            name: "temporary_" + stat.id,
                            "aria-describedby": "temporary_" + stat.id
                          },
                          domProps: { value: _vm.setTemporaryInputValue(stat) },
                          on: {
                            change: function($event) {
                              return _vm.setStatData(
                                $event,
                                "temporary",
                                stat.id
                              )
                            },
                            input: function($event) {
                              return _vm.setStatData(
                                $event,
                                "temporary",
                                stat.id
                              )
                            },
                            blur: function($event) {
                              return _vm.checkStatData(
                                $event,
                                "temporary",
                                stat.id
                              )
                            }
                          }
                        }),
                        _vm.system.stat_size_id === stat.id
                          ? [_vm._v(_vm._s(_vm.$t("stats.not_applicable")))]
                          : [
                              _vm._v(
                                " / " + _vm._s(_vm.system.stat_max_roll_score)
                              )
                            ]
                      ],
                      2
                    ),
                    _c(
                      "td",
                      { staticClass: "center" },
                      [
                        _c("input", {
                          ref: "potential_" + stat.id,
                          refInFor: true,
                          staticClass: "form-control",
                          attrs: {
                            required: "required",
                            type: _vm.getPotentialInputType(stat.id, stat.tag),
                            disabled: _vm.disablePotentialInput(stat),
                            id: "potential_" + stat.id,
                            name: "potential_" + stat.id,
                            "aria-describedby": "potential_" + stat.id
                          },
                          domProps: { value: _vm.setPotentialInputValue(stat) },
                          on: {
                            change: function($event) {
                              return _vm.setStatData(
                                $event,
                                "potential",
                                stat.id
                              )
                            },
                            input: function($event) {
                              return _vm.setStatData(
                                $event,
                                "potential",
                                stat.id
                              )
                            },
                            blur: function($event) {
                              return _vm.checkStatData(
                                $event,
                                "potential",
                                stat.id
                              )
                            }
                          }
                        }),
                        _vm.getPotentialInputType(stat.id, stat.tag) ===
                        "hidden"
                          ? [
                              _vm._v(
                                " " + _vm._s(_vm.$t("stats.not_applicable"))
                              )
                            ]
                          : [
                              _vm._v(
                                " / " + _vm._s(_vm.system.stat_max_roll_score)
                              )
                            ]
                      ],
                      2
                    ),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$helpers.formatSignedNumber(
                              _vm.getRaceStatModifer(stat.id)
                            )
                          ) +
                          " "
                      )
                    ]),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.calculateTemporaryScore(stat.id)) +
                          " (" +
                          _vm._s(_vm.calculatePotentialScore(stat.id)) +
                          ") "
                      )
                    ]),
                    _c("td", { staticClass: "right" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$helpers.formatSignedNumber(
                              _vm.calculateTemporaryBonus(stat.id)
                            )
                          ) +
                          " "
                      )
                    ])
                  ]
                )
              })
            ]
          })
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }