export const PHYSIQUE_ERROR = 'PHYSIQUE_ERROR';
export const PHYSIQUE_REQUEST = 'PHYSIQUE_REQUEST';
export const PHYSIQUE_SUCCESS = 'PHYSIQUE_SUCCESS';

export const RACE_RESET = 'RACE_RESET';
export const RACES_ERROR = 'RACES_ERROR';
export const RACES_REQUEST = 'RACES_REQUEST';
export const RACES_SUCCESS = 'RACES_SUCCESS';

export const SEX_ERROR = 'SEX_ERROR';
export const SEX_REQUEST = 'SEX_REQUEST';
export const SEX_SUCCESS = 'SEX_SUCCESS';
