/* 
  Promise Response Handler (=> prh)
  Many forms use the same workflow for submitting:
    1) dispatch
    2) handle reponse and messages
    3) second dispatch (if the first was successful)
    4) handle reponse and messages
  The message Handling is particularly articulated, therefore a mixin saves a lot 
  of redundant code
*/
import {
  MESSAGE_ERROR_LIST_SET,
  MESSAGE_ERROR_SET,
  MESSAGE_PARTIAL_SUCCESS_SET,
  MESSAGE_SUCCESS_SET
} from '@/store/actions/message';

export default {
  methods: {
    prh_dispatch(action, thenAction, formData, successUrl, thenPromiseCallback = null) {
      this.isContentReady = false;

      this.$store
        .dispatch(action, formData)
        .then(resp => {
          const responseMessage = resp.responseMessage;
          const data = resp.response || undefined;

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            this.$store.commit(MESSAGE_ERROR_LIST_SET, responseMessage);
          } else {
            (async function() {
              if (thenPromiseCallback) {
                await thenPromiseCallback();
              }

              this.$store
                .dispatch(thenAction, data)
                .then(() => {
                  //this.$router.push(successUrl);
                  this.$store.commit(MESSAGE_SUCCESS_SET);
                })
                .catch(err => {
                  console.log(err);
                  this.$store.commit(MESSAGE_PARTIAL_SUCCESS_SET, [err.response || err]);
                });
            }.bind(this)());
          }
        })
        .catch(err => {
          let responseMessage = err;
          if (err.response && err.response.data && err.response.data.message) {
            responseMessage = err.response.data.message;
          }

          if (Array.isArray(responseMessage) || typeof responseMessage === 'object') {
            const errorList = this.$helpers.getErrorList(responseMessage);
            this.form_setFieldErrors(responseMessage['fields']);
            this.$store.commit(MESSAGE_ERROR_LIST_SET, errorList);
          } else {
            this.$store.commit(MESSAGE_ERROR_SET, responseMessage);
            this.$helpers.errorManager(responseMessage);
          }
        })
        .finally(() => {
          this.isContentReady = true;
          this.form_scrollToMessage();
        });
    }
  }
};
