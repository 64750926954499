import { AUTH_LOGOUT } from '../actions/auth';
import {
  GAME_CREATION_ERROR,
  GAME_CREATION_REQUEST,
  GAME_CREATION_SUCCESS,
  GAME_ERROR,
  GAME_LOAD,
  GAME_LOAD_SUCCESS,
  GAME_REQUEST,
  GAME_RESET,
  GAME_SELECT,
  GAME_SELECT_ERROR,
  GAME_SELECT_REQUEST,
  GAME_SELECT_RESET,
  GAME_SELECT_SUCCESS,
  GAME_SUCCESS,
  GAMES_ERROR,
  GAMES_REQUEST,
  GAMES_SUCCESS,
  GAMES_USER_CHARACTER_REQUEST
} from '../actions/game';
import { ROLE_SELECT_RESET } from '../actions/role';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  id: '',
  current_datetime: '',
  name: '',
  statusVuex: {}
};

const getters = {
  getGameStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  getStoredGameId: () => localStorage.getItem(config.storage.gameId) || undefined,
  isGameLoaded: state => !!state.id
};

const actions = {
  [GAME_CREATION_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData || Object.keys(formData).length === 0) {
      return Promise.reject('no_data');
    }

    return new Promise((resolve, reject) => {
      commit(GAME_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createGame, formData)
        .then(resp => {
          commit(GAME_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAME_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  // This is exclusively for the autologin, since I want to avoid deleting and rewriting the game id into localStorage
  [GAME_LOAD]: ({ commit, dispatch, rootState }, id) => {
    if (!id) {
      return Promise.reject('no_id');
    }

    const data = {
      id,
      type: rootState.role.type
    };

    commit(GAME_RESET);
    commit(GAME_SELECT_REQUEST);

    return dispatch('INSTANCE_REGENERATION', data)
      .then(resp => {
        let data = resp.data || resp;
        commit(GAME_LOAD_SUCCESS, data);
      })
      .catch(err => {
        commit(GAME_ERROR, err.response);
      });
  },
  [GAME_REQUEST]: ({ commit, rootState }, id = null) => {
    return new Promise((resolve, reject) => {
      commit(GAME_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      console.log(config.sitePath.api.getGame + (id ? '/' + id : ''));
      return axios
        .post(config.sitePath.api.getGame + (id ? '/' + id : ''), params)
        .then(resp => {
          let data = resp.data;
          commit(GAME_SUCCESS, data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAME_RESET);
          commit(GAME_ERROR, err.response);
          reject(err);
        });
    });
  },
  [GAME_SELECT]: ({ commit, dispatch, rootState }, id) => {
    if (!id) {
      return Promise.reject('no_id');
    }

    const data = {
      id,
      type: rootState.role.type
    };

    commit(GAME_SELECT_RESET);
    commit(GAME_SELECT_REQUEST);

    return dispatch('INSTANCE_REGENERATION', data)
      .then(resp => {
        let data = resp.data || resp;
        data.rememberMe = rootState.auth.rememberMe;
        commit(GAME_SELECT_SUCCESS, data);
      })
      .catch(err => {
        commit(GAME_SELECT_ERROR, err.response);
      });
  },

  [GAMES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(GAMES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.token);
      return axios
        .post(config.sitePath.api.getGameList, params)
        .then(resp => {
          commit(GAMES_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAMES_ERROR, err.response);
          reject(err);
        });
    });
  },
  [GAMES_USER_CHARACTER_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(GAMES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacterUserGameList, params)
        .then(resp => {
          commit(GAMES_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAMES_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [GAME_CREATION_ERROR]: state => {
    Vue.set(state.statusVuex, 'gameCreation', 'error');
  },
  [GAME_CREATION_REQUEST]: state => {
    Vue.set(state.statusVuex, 'gameCreation', 'loading');
  },
  [GAME_CREATION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'gameCreation', 'success');
  },

  [GAME_ERROR]: state => {
    Vue.set(state.statusVuex, 'game', 'error');
  },
  [GAME_LOAD_SUCCESS]: (state, resp) => {
    state = Object.assign(state, resp);
    Vue.set(state.statusVuex, 'game', 'success');
  },
  [GAME_REQUEST]: state => {
    Vue.set(state.statusVuex, 'game', 'loading');
  },
  [GAME_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [GAME_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'game', 'success');
  },

  [GAME_SELECT_ERROR]: state => {
    localStorage.removeItem(config.storage.gameId);
    Vue.set(state.statusVuex, 'game', 'error');
  },
  [GAME_SELECT_REQUEST]: state => {
    Vue.set(state.statusVuex, 'game', 'loading');
  },
  [GAME_SELECT_RESET]: state => {
    localStorage.removeItem(config.storage.gameId);
    Vue.helpers.resetState(state);
  },
  [GAME_SELECT_SUCCESS]: (state, resp) => {
    const rememberMe = resp.rememberMe;
    delete resp.rememberMe;
    state = Object.assign(state, resp);

    if (rememberMe === true) {
      localStorage.setItem(config.storage.gameId, resp.id);
    }

    Vue.set(state.statusVuex, 'game', 'success');
  },

  [GAMES_ERROR]: state => {
    Vue.set(state.statusVuex, 'games', 'error');
  },
  [GAMES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'games', 'loading');
  },
  [GAMES_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'games', 'success');
  },

  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
