var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "container items",
      attrs: { id: _vm.entityNature + "List" }
    },
    [
      _c(
        "div",
        { staticClass: "row relative" },
        [
          !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
          this.isContentReady
            ? [
                _vm.items.length > 0
                  ? [
                      _vm._l(_vm.items, function(item) {
                        return [
                          _c(
                            "div",
                            {
                              key: item.id,
                              staticClass: "col-4 item item-new"
                            },
                            [
                              _c("Item", {
                                ref: _vm.entityNature + "_" + item.id,
                                refInFor: true,
                                attrs: { item: item },
                                on: { "delete-item": _vm.confirmDeleteItem }
                              })
                            ],
                            1
                          )
                        ]
                      })
                    ]
                  : _vm._e()
              ]
            : _vm._e()
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }