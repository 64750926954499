export const SYSTEM_CHARACTER_REQUEST = 'SYSTEM_CHARACTER_REQUEST';
export const SYSTEM_DELETE = 'SYSTEM_DELETE';
export const SYSTEM_ERROR = 'SYSTEM_ERROR';
export const SYSTEM_REQUEST = 'SYSTEM_REQUEST';
export const SYSTEM_RESET = 'SYSTEM_RESET';
export const SYSTEM_SELECT = 'SYSTEM_SELECT';
export const SYSTEM_SUCCESS = 'SYSTEM_SUCCESS';

export const SYSTEMS_ERROR = 'SYSTEMS_ERROR';
export const SYSTEMS_REQUEST = 'SYSTEMS_REQUEST';
export const SYSTEMS_SUCCESS = 'SYSTEMS_SUCCESS';
