<template>
  <div class="character-dataset4">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div v-for="(trainingPackage, index) in trainingPackages" class="row" :key="`training-package-${index}`">
          <FormGroupSelect
            class="form-group col-12"
            :ariaDescribedby="'training_packages_' + index"
            :class="errorFields['trainingPackages'] ? 'error' : ''"
            :collectionName="trainingPackages[index].collectionName"
            :data="{ index: index }"
            :id="'training_packages_' + index"
            :label="trainingPackages[index].label"
            :labelParams="trainingPackages[index].labelParams"
            :name="trainingPackages[index].name"
            :onChangeCallback="onChangeTrainingPackage"
            :optionLabel="trainingPackages[index].optionLabel"
            :options="trainingPackages[index].data"
            :optionValue="trainingPackages[index].optionValue"
            :placeholder="trainingPackages[index].placeholder"
            :required="trainingPackages[index].required"
            :selectValue="trainingPackages[index].selectedValue"
          />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_4_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SCORES_CONSUMED_SET } from '@/store/actions/scores';
import { TRAINING_PACKAGES_CLASS_REQUEST } from '@/store/actions/trainingPackage';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import { mapState } from 'vuex';

const trainingPackageTemplate = {
  collectionName: 'trainingPackages',
  data: [],
  label: 'character.training_package_with_counter',
  labelParams: { counter: 0 },
  name: 'training_package[]',
  optionLabel: 'extendedName',
  optionValue: 'id',
  placeholder: '',
  selectedValue: ''
};

export default {
  name: 'CharacterDataset4',
  components: {
    FormGroupSelect
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      doNotRemove: false,
      errorFields: [],
      isContentReady: false,
      trainingPackages: [],
      trainingPackagesCounter: 0,
      trainingPackagesSelected: []
    };
  },
  computed: {
    ...mapState(['character', 'scores'])
  },
  created() {
    this.populateTrainingPackage().then(() => {
      this.$nextTick(() => {
        this.isContentReady = true;
      });
    });
  },
  methods: {
    // EVENT HANDLERS
    onChangeTrainingPackage(id, $event) {
      this.form_resetResponse();
      const selectData = JSON.parse($event.target.dataset.select);
      const selectIndex = selectData.index;
      this.updateTrainingPackageSelection(id);

      if (id) {
        if (
          this.trainingPackagesCounter < trainingPackageTemplate.data.length &&
          typeof this.trainingPackages[selectIndex + 1] === 'undefined'
        ) {
          this.addTrainingPackage();
          this.doNotRemove = false;
        } else {
          this.doNotRemove = true;
        }
      } else {
        if (this.doNotRemove) {
          this.doNotRemove = false;
        } else {
          this.removeTrainingPackage(selectIndex);
        }
      }
    },
    onSubmit() {
      this.form_resetResponse();

      if (this.scores.consumed.free_ranks > this.character.scores.consumable.free_ranks) {
        this.$store.commit(MESSAGE_ERROR_SET, 'too_many_allocated_free_ranks');
        this.form_setFieldErrors(['trainingPackages']);
        return false;
      }

      const formData = new FormData();
      formData.append('trainingPackages', JSON.stringify(this.trainingPackagesSelected));

      this.prh_dispatch(
        CHARACTER_CREATION_STEP_4_REQUEST,
        CHARACTER_REFRESH,
        formData,
        '/character/create/step5',
        this.resetConsumedDevelopmentPoints
      );
    },

    // FUNCTIONS
    addTrainingPackage() {
      this.trainingPackagesCounter++;
      const newTrainingPackage = JSON.parse(JSON.stringify(trainingPackageTemplate));
      newTrainingPackage.labelParams.counter = this.trainingPackagesCounter;
      newTrainingPackage.data = this.filterOutSelectedOptions();
      Vue.set(this.trainingPackages, this.trainingPackagesCounter - 1, newTrainingPackage);
    },
    filterOutSelectedOptions(currentSelectedValue = null) {
      return trainingPackageTemplate.data.filter(tp => {
        const foundInTrainingPackagesSelected = this.trainingPackagesSelected.find(
          tpSelected => tp.id === tpSelected.id
        );
        if (currentSelectedValue) {
          return !foundInTrainingPackagesSelected || tp.id === currentSelectedValue;
        }
        return !foundInTrainingPackagesSelected;
      });
    },
    populateTrainingPackage() {
      return this.$store
        .dispatch(TRAINING_PACKAGES_CLASS_REQUEST)
        .then(data => {
          data.forEach(element => {
            element.development_cost = Math.floor(element.base_cost * 1 * element.development_effort * 1);

            element.extendedName = `${element.name} (${element.type_code}) - 
              ${element.development_cost} ${this.$t('character.points').toLowerCase()}
              (${element.n_acquisition_month} ${this.$t('global.datetime.months').toLowerCase()})`;
          });
          trainingPackageTemplate.data = data;
          this.addTrainingPackage();
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    removeTrainingPackage(selectIndex) {
      this.trainingPackagesCounter--;
      this.trainingPackages.splice(selectIndex, 1);
      this.updateTrainingPackageIndexes(selectIndex);
    },
    resetConsumedDevelopmentPoints() {
      const scores = {
        development_points: 0
      };

      return this.$store.commit(SCORES_CONSUMED_SET, scores);
    },
    updateTrainingPackageCost(totalAcquisitionCost) {
      const scores = {
        development_points: totalAcquisitionCost
      };
      this.$store.commit(SCORES_CONSUMED_SET, scores);
    },
    updateTrainingPackageIndexes(selectIndex = 0) {
      for (let i = selectIndex; i < this.trainingPackages.length; i++) {
        this.trainingPackages[i].labelParams.counter = i + 1;
      }
    },
    updateTrainingPackagesOptions() {
      // remove from options already-selected options
      this.trainingPackages.forEach(trainingPackage => {
        trainingPackage.data = this.filterOutSelectedOptions(trainingPackage.selectedValue);
      });
    },
    updateTrainingPackageSelection() {
      let totalTrainingPackageCost = 0;
      this.trainingPackagesSelected = [];
      this.trainingPackages.forEach(trainingPackage => {
        if (trainingPackage.selectedValue) {
          const id = trainingPackage.selectedValue;
          const trainingPackageObject = trainingPackageTemplate.data.find(tp => tp.id === id);

          if (trainingPackage) {
            this.trainingPackagesSelected.push(trainingPackageObject);
            totalTrainingPackageCost += trainingPackageObject.development_cost * 1;
          }
        }
      });
      this.updateTrainingPackageCost(totalTrainingPackageCost);
      this.updateTrainingPackagesOptions();
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
