<template>
  <main v-if="restrictedAccess_grantMaster" class="body-view container-fluid center">
    <div class="row">
      <div class="area_title col-12">
        <h1>
          {{ $t(entityNature + '.create_' + entityNature) }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <CreationForm />
      </div>
    </div>
  </main>
</template>

<script>
const entityNature = 'campaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import restrictedAccessMixin from '@/mixins/restrictedAccess';
import CreationForm from '@/components/Campaign/HandlerForm';
import Message from '@/components/Message';

export default {
  name: entityNatureCapitalized + 'CreateView',
  components: {
    CreationForm,
    Message
  },
  mixins: [restrictedAccessMixin],
  data() {
    return {
      entityNature,
      entityNatureCapitalized
    };
  }
};
</script>
