var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "character-dataset3" },
    [
      !this.isContentReady ? _c("AnimationLoading") : _vm._e(),
      _c(
        "form",
        {
          staticClass: "character-form left",
          on: {
            submit: function($event) {
              $event.preventDefault()
              return _vm.onSubmit($event)
            }
          }
        },
        [
          _c("div", { staticClass: "form-frame" }, [
            _c(
              "div",
              { staticClass: "row" },
              [
                _c("FormGroupSelect", {
                  staticClass: "form-group col-9",
                  class: _vm.errorFields["characterClass"] ? "error" : "",
                  attrs: {
                    ariaDescribedby: _vm.characterClass.ariaDescribedby,
                    id: _vm.characterClass.id,
                    label: _vm.characterClass.label,
                    name: _vm.characterClass.name,
                    onChangeCallback: _vm.characterClass.onChange,
                    optionLabel: _vm.characterClass.optionLabel,
                    options: _vm.characterClass.data,
                    optionValue: _vm.characterClass.optionValue,
                    placeholder: _vm.characterClass.placeholder,
                    required: _vm.characterClass.required,
                    selectValue: _vm.characterClass.selectedValue
                  }
                }),
                _vm.characterClass.selectedValue && !_vm.isClassMagic
                  ? _c("FormGroupSelect", {
                      staticClass: "form-group col-3",
                      class: _vm.errorFields["selectableRealm"] ? "error" : "",
                      attrs: {
                        ariaDescribedby: _vm.selectableRealm.ariaDescribedby,
                        id: _vm.selectableRealm.id,
                        label: _vm.selectableRealm.label,
                        name: _vm.selectableRealm.name,
                        optionLabel: _vm.selectableRealm.optionLabel,
                        options: _vm.selectableRealm.data,
                        optionValue: _vm.selectableRealm.optionValue,
                        placeholder: _vm.selectableRealm.placeholder,
                        required:
                          _vm.characterClass.selectedValue && !_vm.isClassMagic
                            ? "required"
                            : false,
                        selectValue: _vm.selectableRealm.selectedValue
                      }
                    })
                  : _vm._e()
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "form-group required",
                class: _vm.errorFields["stats"] ? "error" : ""
              },
              [
                _c("label", { attrs: { for: "stats" } }, [
                  _vm._v(_vm._s(_vm.$t("character.stats")))
                ]),
                _c("StatsForm", {
                  ref: "statsForm",
                  attrs: { errorFieldsInherited: _vm.errorFields },
                  on: { "error-fields-set": _vm.form_onErrorFields }
                })
              ],
              1
            )
          ]),
          _c("div", { staticClass: "center" }, [
            _c(
              "button",
              { staticClass: "btn btn-primary", attrs: { type: "submit" } },
              [_vm._v(" " + _vm._s(_vm.$t("button.next")) + " ")]
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }