<template>
  <main class="body-view container-fluid center">
    <div class="row">
      <div class="area_title col-12">
        <h1>
          {{ $t(`role.character.create_character_dataset` + step) }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Dataset :type="getRoleType()" />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
.character-form-create {
  margin-top: auto;
}
</style>

<script>
import characterCreationStepCheckMixin from '@/mixins/characterCreationStepCheck';
import Dataset from '@/components/Character/Dataset1';
import Message from '@/components/Message';

const step = '1';

export default {
  name: 'CharacterCreateStep' + step + 'View',
  components: {
    Message,
    Dataset
  },
  mixins: [characterCreationStepCheckMixin],
  data() {
    return {
      step: step
    };
  },
  methods: {
    getRoleType() {
      return this.$helpers.getRoleType();
    }
  }
};
</script>
