<template>
  <div id="skill-list" :class="'skill-list skill-list-mode-' + mode">
    <AnimationLoading v-if="!this.isContentReady" />

    <table class="table table-fixed skill-table">
      <thead>
        <tr id="skill-table-header-row">
          <th scope="col" class="left index"></th>
          <th scope="col" class="left">{{ $t('skill.skill') }}</th>
          <th v-if="isPlayMode" scope="col"></th>
          <th v-if="isPlayMode && isGameScope" scope="col"></th>
          <th scope="col" class="center">{{ $t('skill.type') }}</th>
          <th scope="col" class="center">{{ $t('skill.maneuver_type') }}</th>
          <th scope="col" class="center">{{ $t('skill.stats') }}</th>
          <th v-if="isEditMode || isCreationMode" scope="col" class="center">
            {{ $t('skill.base_rank') }}
          </th>
          <th v-if="isCreationMode" scope="col" class="center">
            {{ $t('skill.add_ranks') }}
          </th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ isCreationMode ? $t('skill.total_ranks') : $t('skill.ranks') }}
          </th>
          <th scope="col" class="center">
            {{ $t('skill.rank_progression') }}
          </th>
          <th scope="col" class="center">{{ $t('skill.no_rank_malus') }}</th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ $t('skill.stats_bonus') }}
          </th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ $t('skill.rank_bonus') }}
          </th>

          <th scope="col" class="center">{{ $t('skill.base_modifier') }}</th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ $t('skill.total_bonus') }}
          </th>
          <th v-if="isCharacterScope" scope="col" class="center">{{ $t('skill.initiative_bonus') }}</th>
          <th scope="col" class="center">
            {{ isEditMode || isCreationMode ? $t('skill.development_effort') : $t('skill.DE') }}
          </th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ isCreationMode ? $t('skill.development_points_to_rank_up') : $t('skill.DPs_to_rank_up') }}
          </th>
          <th v-if="!isEditMode && isPlayMode && isCharacterScope" scope="col" class="center">
            {{ $t('skill.DPs_allocated') }}
          </th>
          <th v-if="!isEditMode && isCharacterScope" scope="col" class="center">
            {{ developmentPointsInputTitle }}
          </th>
        </tr>
      </thead>
      <tbody v-if="this.isContentReady">
        <template v-for="(skill, skillIndex) in skills">
          <tr
            v-if="filterSkills(skill)"
            :key="`skill-${skillIndex}`"
            :class="[
              skillIndex % 2 === 0 ? 'even' : 'odd',
              skill.isSkill ? 'skills-entry ' + skill.type_label.toLowerCase() : 'skills-category',
              'nesting-' + handleNesting(skill),
              skill.statusTypedSkill === 'error' || errorFieldsInherited['skill_' + skill.id] ? 'error' : '',
              skill.open ? 'open' : '',
              skill.collapsed ? 'collapsed' : ''
            ]"
            :id="skill.isSkill ? 'skill-' + skill.id : 'category-' + skill.id"
            :data-id="skill.id"
            :data-skill="skill.skill"
            :data-skill-type="skill.isSkill ? skill.type : ''"
            :data-base-rank-available="skill.base_rank_available"
            :ref="skill.isSkill ? 'skills' : 'categories'"
          >
            <th
              scope="row"
              class="skill-index left"
              :id="(skill.isSkill ? 'skill' : 'category') + '-index-' + skill.id"
            >
              {{ skillIndex + 1 }}
            </th>
            <td class="name left">
              <span
                v-if="isTemporarySkill(skill)"
                class="name-wrapper"
                :class="[skill.type === system.skill_types.whitelabel ? 'skill-white-label' : '']"
                :id="'skill-name-' + skill.id"
              >
                <img
                  v-if="
                    (isEditMode || isCreationMode) &&
                      skill.isSkill &&
                      !skill.skill &&
                      skill.rank * 1 === 0 &&
                      skill.development_points_allocated * 1 === 0
                  "
                  class="icon icon-remove"
                  :src="config.mediaPath.application.icons + config.assets.icons.remove"
                  :alt="$t('skill.delete_skill')"
                  @click="deleteSkill(skillIndex)"
                />
                <a
                  v-if="!isEditMode && isUsableSkill(skill.isSkill, skill.type)"
                  :href="'/skill/' + skill.skill"
                  target="_blank"
                  class="skill-link"
                  >{{ skill.name }}</a
                >
                <RouterLink v-else-if="isEditMode" :to="'/skill/edit/' + skill.id">{{ skill.name }}</RouterLink>
                <template v-else>{{ skill.name }}</template>
              </span>

              <div class="new-skill" v-if="skill.tempIndex !== '' && typeof skill.tempIndex !== 'undefined'">
                <img
                  class="icon icon-remove"
                  :src="config.mediaPath.application.icons + config.assets.icons.remove"
                  :alt="$t('skill.remove_skill')"
                  @click="cancelSkill(skillIndex)"
                />

                <input
                  type="text"
                  class="form-control"
                  :id="'skill-name-' + skill.tempIndex"
                  name="skill-name"
                  :aria-describedby="'rank-' + skill.tempIndex"
                  @input="updateSkillName($event, skill)"
                />

                <img
                  class="icon icon-save"
                  :src="config.mediaPath.application.icons + config.assets.icons.save"
                  :alt="$t('skill.save_skill')"
                  @click="saveSkill(skillIndex, skill)"
                />
              </div>

              {{ skill.isSkill && skill.medium ? '(' + skill.medium + ')' : '' }}

              <img
                v-if="!skill.isSkill"
                class="icon icon-expand"
                :src="
                  config.mediaPath.application.icons +
                    (skill.open ? config.assets.icons.expand : config.assets.icons.collapse)
                "
                :alt="$t('global.expand_collapse')"
                @click="toggleCollapse(skillIndex, skill.nesting)"
              />

              <img
                v-if="skill.type === system.skill_types.whitelabel && (isCreationMode || isRankUpMode)"
                class="icon icon-add icon-add-skill"
                :src="config.mediaPath.application.icons + config.assets.icons.add"
                :alt="$t('skill.add_skill')"
                @click="isCreationMode || isRankUpMode ? addLabelGameSkill(skillIndex, skill) : false"
              />

              <b-tooltip
                v-if="skill.synopsis"
                :target="'skill-index-' + skill.id"
                placement="topright"
                triggers="hover"
                custom-class="skill-tooltip"
              >
                {{ skill.synopsis }}
              </b-tooltip>
            </td>
            <td v-if="isPlayMode" class="commit center">
              <img
                v-if="skill.isSkill"
                class="icon icon-die"
                :src="config.mediaPath.application.icons + config.assets.icons.die6"
                :alt="$t(isGameScope ? 'skill.schedule_action' : 'skill.commit_action')"
                @click="queueAction(skill)"
              />
            </td>
            <td v-if="isPlayMode && isGameScope" class="play-for-all center">
              <img
                v-if="skill.isSkill"
                class="icon icon-party-play"
                :src="config.mediaPath.application.icons + config.assets.icons.play"
                :alt="$t('skill.play_party_action')"
                @click="playPartyAction(skill)"
              />
            </td>
            <td class="type center">
              <span v-if="skill.isSkill" :title="skill.type_label">
                {{ skill.type }}
              </span>
            </td>
            <td class="maneuver_type_subtype center">
              <template v-if="skill.maneuver_type">
                <span
                  class="maneuver_type"
                  :title="
                    $t(`action.maneuver_type.${skill.maneuver_type}`) +
                      (skill.maneuver_subtype ? ' - ' + $t(`action.maneuver_subtype.${skill.maneuver_subtype}`) : '')
                  "
                  >{{ skill.maneuver_type }}</span
                ><template v-if="skill.maneuver_subtype"
                  >/<span class="maneuver_subtype">{{ skill.maneuver_subtype }}</span>
                </template>
              </template>
            </td>
            <td class="stats center">
              <template v-if="skill.stat1_code">
                <span :title="$helpers.formatSignedNumber(skill.stat1_bonus)">{{ skill.stat1_code }}</span
                >/<span :title="$helpers.formatSignedNumber(skill.stat2_bonus)">{{ skill.stat2_code }}</span
                >/<span :title="$helpers.formatSignedNumber(formulas_stat3Bonus(skill))">{{
                  skill.stat3_code ? skill.stat3_code : '*'
                }}</span>
              </template>
            </td>
            <td v-if="isEditMode || isCreationMode" class="base_rank center">
              {{ displayBaseRank(skill) }}
            </td>

            <!-- RANK - start -->
            <td v-if="!isEditMode && isCreationMode && isCharacterScope" class="rank center">
              <template v-if="skill.tag !== 'arcane_resistance_skill'">
                <input
                  v-if="isRankUpableSkillType(skill)"
                  v-model="skill.rank"
                  :type="isRankable(skill.development_effort) ? 'number' : 'hidden'"
                  step="1"
                  min="0"
                  :max="system.stat_max_roll_score - skill.base_rank"
                  :disabled="isTemporarySkill(skill) ? false : 'disabled'"
                  class="form-control"
                  :id="'rank-' + skill.id"
                  name="rank"
                  :aria-describedby="'rank-' + skill.id"
                  @input="updateRanks($event, skill)"
                />
                <div v-if="!skill.isSkill" class="quantity_buttons">
                  <input
                    type="button"
                    value="-"
                    class="minus"
                    @click="transferBaseRanksWithParent($event, skill, '-')"
                  />
                  <input
                    type="button"
                    value="+"
                    class="plus"
                    @click="transferBaseRanksWithParent($event, skill, '+')"
                  />
                </div>
              </template>
            </td>
            <!-- RANK - end -->

            <!-- TOTAL TANKS - start -->
            <template v-if="!isEditMode">
              <td v-if="skill.tag === 'arcane_resistance_skill'" class="total_ranks center">
                <span class="rank_display">
                  {{ getArcaneResistanceSkillRank(skill) }}
                </span>
              </td>

              <td
                v-else-if="isCharacterScope"
                class="total_ranks center"
                @click="isPlayMode ? openEditRankMode(skillIndex) : false"
              >
                <template v-if="skill.editRankMode">
                  <input
                    v-model="skill.rank"
                    type="number"
                    step="1"
                    min="0"
                    :max="system.stat_max_roll_score"
                    class="form-control rank"
                    :id="'rank-' + skill.id"
                    name="rank"
                    :aria-describedby="'rank-' + skill.id"
                    @blur="closeEditRankMode(skill)"
                  />
                </template>
                <span class="rank_display" v-else>
                  {{
                    skill.isSkill && skill.type !== system.skill_types.whitelabel
                      ? formulas_totalRanks(skill.rank, skill.base_rank)
                      : ''
                  }}
                </span>
              </td>
            </template>
            <!-- TOTAL RANKS - end -->

            <td class="rank_progression center">
              {{
                isEditMode || (skill.isSkill && skill.type !== system.skill_types.whitelabel)
                  ? skill.rank_progression
                  : ''
              }}
            </td>
            <td class="no_rank_penalty center">
              {{
                isEditMode || (skill.isSkill && skill.type !== system.skill_types.whitelabel)
                  ? skill.no_rank_penalty
                  : ''
              }}
            </td>
            <td v-if="!isEditMode && isCharacterScope" class="stats_bonus center">
              {{
                skill.isSkill && skill.type !== system.skill_types.whitelabel
                  ? $helpers.formatSignedNumber(
                      formulas_statsBonus(skill.stat1_bonus, skill.stat2_bonus, skill.stat3_bonus)
                    )
                  : ''
              }}
            </td>
            <td v-if="!isEditMode && isCharacterScope" class="rank_bonus center">
              {{
                skill.isSkill && skill.type !== system.skill_types.whitelabel
                  ? $helpers.formatSignedNumber(formulas_rankBonus(skill.rank_progression, skill.rank, skill.base_rank))
                  : ''
              }}
            </td>
            <td class="base_modifier center">
              {{
                isEditMode || (skill.isSkill && skill.type !== system.skill_types.whitelabel)
                  ? $helpers.formatSignedNumber(skill.base_modifier)
                  : ''
              }}
            </td>
            <td v-if="!isEditMode && isCharacterScope" class="total_bonus center">
              {{
                skill.isSkill && skill.type !== system.skill_types.whitelabel
                  ? $helpers.formatSignedNumber(
                      formulas_totalBonus(
                        skill.rank,
                        skill.base_rank,
                        skill.rank_progression,
                        skill.no_rank_penalty,
                        skill.stat1_bonus,
                        skill.stat2_bonus,
                        skill.stat3_bonus,
                        skill.base_modifier
                      )
                    )
                  : ''
              }}
            </td>
            <td v-if="isCharacterScope" class="initiative_bonus center">
              <span :title="skill.stat_initiative_code">{{
                skill.isSkill && skill.type !== system.skill_types.whitelabel
                  ? $helpers.formatSignedNumber(skill.stat_initiative_bonus)
                  : ''
              }}</span>
            </td>
            <td class="development_effort center">
              <span v-if="skill.tag !== 'arcane_resistance_skill'">{{ skill.development_effort }}</span>
            </td>
            <td v-if="!isEditMode && isCharacterScope" class="development_points_to_rank_up center">
              <span
                v-if="isRankUpableSkillType(skill) && skill.tag !== 'arcane_resistance_skill'"
                :class="skill.rank * 1 < system.skill_max_rank_number * 1 ? '' : 'disabled'"
                >{{ developmentPointsToRankUp(skill.rank, skill.base_rank, skill.development_effort) }}
              </span>
            </td>
            <td v-if="!isEditMode && isPlayMode && isCharacterScope" class="development_points_allocated center">
              <span v-if="skill.tag !== 'arcane_resistance_skill'">{{ skill.development_points }}</span>
            </td>
            <td v-if="!isEditMode && isCharacterScope" class="development_points center">
              <span
                v-if="isRankUpableSkillType(skill) && skill.tag !== 'arcane_resistance_skill'"
                class="development_points_to_rank_up_wrapper"
              >
                <img
                  v-show="canRankDown(skill) && (isCreationMode || isRankUpMode)"
                  class="icon icon-rank-down"
                  :src="config.mediaPath.application.icons + config.assets.icons.rank_down"
                  :alt="$t('skill.cancel_rankup')"
                  @click="rankDown(skill)"
                />
                <input
                  :value="isPlayMode ? skill.development_points_allocated : skill.development_points"
                  :type="isRankable(skill.development_effort) ? 'number' : 'hidden'"
                  :class="skill.rank * 1 < system.skill_max_rank_number * 1 ? '' : 'disabled'"
                  step="1"
                  min="0"
                  :disabled="isTemporarySkill(skill) ? false : 'disabled'"
                  class="form-control development_points"
                  :id="'development-points-' + skill.id"
                  name="development-points"
                  :aria-describedby="'development-points-' + skill.id"
                  @input="updateDevelopmentPoints($event, skill)"
                />
                <img
                  v-show="canRankUp(skill) && (isCreationMode || isRankUpMode)"
                  class="icon icon-rank-up"
                  :src="config.mediaPath.application.icons + config.assets.icons.rank_up"
                  :alt="$t('skill.rankup_skill')"
                  @click="rankUp(skill)"
                />
                <img
                  v-show="skill.development_points_allocated > 0 && isPlayMode"
                  class="icon icon-add icon-add-experience-points"
                  :src="config.mediaPath.application.icons + config.assets.icons.add"
                  :alt="$t('skill.add_experience_points')"
                  @click="addExperiencePoints(skill)"
                />
              </span>
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import formulasMixin from '@/mixins/formulas';
import rollResolver from '@/mixins/rollResolver';
import { SCORES_CONSUME } from '@/store/actions/scores';
import {
  CHARACTER_SKILLS_REQUEST,
  GAME_SKILLS_REQUEST,
  SYSTEM_SKILLS_REQUEST,
  CHARACTER_SKILL_EXPERIENCE_ADD,
  CHARACTER_GAME_SKILL_ADD
} from '@/store/actions/skill';
import { EventBus } from '@/utils/eventBus';
import { config } from '@/setup/config';
import { mapGetters, mapState } from 'vuex';
import Vue from 'vue';

let loopCategoryNesting = 1;

export default {
  name: 'SkillList',
  mixins: [formMixin, formulasMixin, rollResolver],
  props: {
    characterId: {
      type: String,
      default: null
    },
    errorFieldsInherited: {
      type: Array,
      default() {
        return [];
      }
    },
    filterProp: {
      type: String,
      default: null
    },
    gameId: {
      type: String,
      default: null
    },
    mode: {
      type: String,
      default: null
    },
    systemId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      areSkillsLoaded: false,
      config,
      filterParam: this.$route.params.filter,
      skills: [],
      raceSkills: [],
      rankUpRanks: 0,
      tempSkillsIndex: 0
    };
  },
  computed: {
    ...mapGetters(['isSystemLoaded']),
    ...mapState(['character', 'system', 'scores']),
    developmentPointsInputTitle() {
      if (this.isCreationMode) {
        return this.$t('skill.development_points_allocated');
      }
      if (this.isRankUpMode) {
        return this.$t('skill.XPs_allocated');
      }
      if (this.isPlayMode) {
        return this.$t('skill.add_XPs');
      }
      return '';
    },
    filter() {
      return this.filterParam || this.filterProp;
    },
    isCharacterScope() {
      return !!this.characterId;
    },
    isContentReady() {
      return this.isSystemLoaded && this.areSkillsLoaded;
    },
    isCreationMode() {
      return this.mode === 'creation';
    },
    isEditMode() {
      return this.mode === 'edit';
    },
    isGameScope() {
      return !!this.gameId;
    },
    isPlayMode() {
      return this.mode === 'play';
    },
    isRankUpMode() {
      return this.mode === 'rankup';
    },
    isSystemScope() {
      return !!this.systemId;
    },
    SKILLS_REQUEST() {
      if (this.characterId) {
        return CHARACTER_SKILLS_REQUEST;
      }

      if (this.gameId) {
        return GAME_SKILLS_REQUEST;
      }

      if (this.systemId) {
        return SYSTEM_SKILLS_REQUEST;
      }

      return '';
    }
  },
  mounted() {
    this.populateSkills();
  },
  methods: {
    // ALL modes - start
    getArcaneResistanceSkillRank(skill) {
      const channelingResistanceSkill = this.skills.find(function(skill) {
        return skill.tag === 'channeling_resistance_skill';
      });
      const essenceResistanceSkill = this.skills.find(function(skill) {
        return skill.tag === 'essence_resistance_skill';
      });
      const mentalismResistanceSkill = this.skills.find(function(skill) {
        return skill.tag === 'mentalism_resistance_skill';
      });

      const arcaneResistanceSkillRank = Math.floor(
        (channelingResistanceSkill.rank * 1 + essenceResistanceSkill.rank * 1 + mentalismResistanceSkill.rank * 1) / 3
      );
      skill.rank = arcaneResistanceSkillRank;
      return arcaneResistanceSkillRank;
    },
    toggleCollapse(index, categoryNesting) {
      // this method relies on the skill presentation order, meaning that will stop working if entries get shuffled or reordered
      const collapse = !this.skills[index + 1].collapsed;
      this.skills[index].open = !this.skills[index].open;
      for (let c = index + 1; c < this.skills.length; c++) {
        if (this.skills[c].nesting <= categoryNesting && !this.skills[c].isSkill) {
          break;
        }

        if (!this.skills[c].isSkill && collapse) {
          this.skills[c].open = true;
        }

        if (this.skills[c].isSkill && collapse) {
          this.skills[c].collapsed = collapse;
        } else {
          this.skills[c].collapsed = !this.skills[c].collapsed;
        }
      }
    },
    updateDevelopmentPoints($event, skill) {
      // NB: development_points input has no model, must be handled manually
      if (this.isCreationMode) {
        this.updateDevelopmentPointsForCreation($event, skill);
      } else if (this.isRankUpMode) {
        this.updateDevelopmentPointsForRankUp($event, skill);
      } else if (this.isPlayMode) {
        skill.development_points_allocated = $event.target.value * 1;
      }
    },
    // ALL modes - end

    // CHARACTER CREATION and RANK UP modes - start
    // Add a specification skill of a White Label skill to the game skill set
    addLabelGameSkill(skillIndex, skill) {
      const newSkill = JSON.parse(JSON.stringify(skill));
      newSkill.id = 'temp-skill-' + this.tempSkillsIndex;
      newSkill.game = this.$store.state.character.id;
      newSkill.gs_parent = skill.game_skill;
      newSkill.base_rank = 0;
      newSkill.base_rank_available = 0;
      newSkill.base_rank_from_parent = 0;
      newSkill.category = '';
      newSkill.name = '';
      newSkill.nesting = skill.nesting * 1 + 1;
      newSkill.rank = 0;
      newSkill.rank_prechange = 0;
      newSkill.type = this.system.skill_types.standard;
      newSkill.type_label = 'standard';
      newSkill.development_effort = skill.development_effort;

      this.skills.splice(skillIndex + 1, 0, newSkill);
      Vue.set(this.skills[skillIndex + 1], 'tempIndex', this.tempSkillsIndex);
      this.tempSkillsIndex++;
    },
    cancelSkill(skillIndex) {
      this.skills.splice(skillIndex, 1);
    },
    canRankDown(skill) {
      return skill.development_points_allocated > 0;
    },
    canRankUp(skill) {
      if (this.isPlayMode) {
        return false;
      }

      if (this.isCreationMode) {
        if (this.formulas_totalRanks(skill.rank, skill.base_rank) < this.system.skill_max_rank_number) {
          const developmentPointsToRankUp = this.developmentPointsToRankUp(
            skill.rank,
            skill.base_rank,
            skill.development_effort
          );
          return developmentPointsToRankUp > 0 && skill.development_points >= developmentPointsToRankUp;
        }
      }

      if (this.isRankUpMode) {
        if (skill.rank * 1 < this.system.skill_max_rank_number * 1) {
          const developmentPointsToRankUp = this.developmentPointsToRankUp(
            skill.rank,
            skill.base_rank,
            skill.development_effort
          );

          return developmentPointsToRankUp > 0 && skill.development_points >= developmentPointsToRankUp;
        }
      }
      return false;
    },
    deleteSkill(skillIndex) {
      console.log(skillIndex, 'to be implemented (need Vuex and prechecks)');
    },
    developmentPointsToNextRank(nextRank, development_effort) {
      if (this.isCreationMode) {
        return Math.ceil(nextRank * development_effort * this.system.skill_development_cost_unit);
      } else {
        const series = [];
        for (let i = 1; i <= nextRank; i++) {
          series[i] = Math.ceil(i * development_effort * this.system.skill_development_cost_unit);
        }
        return series.reduce((a, b) => a + b);
      }
    },
    developmentPointsToRankDown(rank, base_rank, development_effort) {
      const actualRank = this.formulas_totalRanks(rank, base_rank);
      const nextRank = actualRank ? actualRank * 1 : 0;
      return this.developmentPointsToNextRank(nextRank, development_effort);
    },
    developmentPointsToRankUp(rank, base_rank, development_effort) {
      const actualRank = this.formulas_totalRanks(rank, base_rank);
      const nextRank = (actualRank ? actualRank * 1 : 0) + 1;
      return this.developmentPointsToNextRank(nextRank, development_effort);
    },
    rankDown(skill) {
      if (skill.development_points_allocated > 0) {
        let developmentPointsToRankDown =
          this.developmentPointsToRankDown(skill.rank, skill.base_rank, skill.development_effort) * 1;
        const consumeDevelopmentPoints = skill.development_points * 1 + developmentPointsToRankDown;

        skill.development_points = consumeDevelopmentPoints;
        skill.development_points_prechange = skill.development_points;
        skill.development_points_allocated =
          (skill.development_points_allocated ? skill.development_points_allocated * 1 : 0) -
          developmentPointsToRankDown;
        skill.rank = skill.rank * 1 - 1;
        skill.rank_prechange = skill.rank;
        this.rankUpRanks--;
        this.updateSubskillRanks(skill);
      }
      return false;
    },
    rankUp(skill) {
      const max_rank = skill.max_rank || this.system.skill_max_rank_number;

      if (this.formulas_totalRanks(skill.rank, skill.base_rank) < max_rank) {
        let developmentPointsToRankUp =
          this.developmentPointsToRankUp(skill.rank, skill.base_rank, skill.development_effort) * 1;
        const consumeDevelopmentPoints = skill.development_points * 1 - developmentPointsToRankUp;

        if (consumeDevelopmentPoints < 0) {
          EventBus.$emit('error-fields-set', 'skill_' + skill.id, 'error');
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_not_allowed',
            bodyText: 'message.skill_not_enough_development_points'
          });
          return false;
        }

        skill.development_points = consumeDevelopmentPoints;
        skill.development_points_prechange = skill.development_points;
        skill.development_points_allocated =
          (skill.development_points_allocated ? skill.development_points_allocated * 1 : 0) + developmentPointsToRankUp;
        skill.rank = skill.rank * 1 + 1;
        skill.rank_prechange = skill.rank;

        this.updateSubskillRanks(skill);
        this.rankUpRanks++;
      }

      return false;
    },
    saveSkill(skillIndex, skill) {
      if (skill.name === '') {
        Vue.set(this.skills[skillIndex], 'statusTypedSkill', 'error');
        EventBus.$emit('error-fields-set', 'skill_' + skill.id, 'error');
        EventBus.$emit('modal', 'close', {
          headerText: 'message.operation_not_allowed',
          bodyText: 'message.skill_missing_name'
        });
        return false;
      }

      this.$store
        .dispatch(CHARACTER_GAME_SKILL_ADD, skill)
        .then(data => {
          this.skills[skillIndex].id = data.response;
          this.skills[skillIndex].tempIndex = '';
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    },
    updateSkillName($event, skill) {
      skill.name = $event.target.value;
      skill.statusTypedSkill = '';
      EventBus.$emit('error-fields-set', 'skill_' + skill.id, null);
    },
    // CHARACTER CREATION and RANK UP modes - end

    // CHARACTER CREATION mode - start
    transferBaseRanksWithParent($event, category, operation) {
      const parentCategory = this.$helpers.findParentCategoryOfCategory(this.skills, category);
      category.base_rank = category.base_rank ? category.base_rank : 0;
      if (parentCategory) {
        parentCategory.base_rank = parentCategory.base_rank ? parentCategory.base_rank : 0;
      }

      if (parentCategory && operation === '+') {
        if (parentCategory.base_rank_available > 0) {
          parentCategory.base_rank_available = parentCategory.base_rank_available * 1 - 1;
          category.base_rank_available = category.base_rank_available * 1 + 1;
          category.base_rank_from_parent = category.base_rank_from_parent * 1 + 1;
        }
      } else if (parentCategory && operation === '-') {
        if (
          category.base_rank_from_parent > 0 &&
          category.base_rank_available > 0 &&
          parentCategory.base_rank_available < parentCategory.base_rank + parentCategory.base_rank_from_parent
        ) {
          parentCategory.base_rank_available = parentCategory.base_rank_available * 1 + 1;
          category.base_rank_available = category.base_rank_available * 1 - 1;
          category.base_rank_from_parent = category.base_rank_from_parent * 1 - 1;
        }
      }
    },
    updateCategoryRanks(skill, variation) {
      /* 
      consuming total category ranks: no need for checking as it's already not possible to consume more than the available 
      ranks for each category (the check is already performed in every single part)
      */
      const parentCategory = this.$helpers.findParentCategoryOfSkill(this.skills, skill);
      if (parentCategory) {
        parentCategory.base_rank_available = parentCategory.base_rank_available
          ? parentCategory.base_rank_available * 1
          : 0;
      }

      let consumeParentCategoryRanks = 0;
      let consumeSkillRanks = 0;

      if (variation > 0) {
        // skill ranks added
        if (parentCategory) {
          // Skill Rank < Max Rank already satisfied by normalizeSkillFreeRanks
          const categoryRanksDrainage = parentCategory.base_rank_available - variation;
          if (categoryRanksDrainage < 0) {
            consumeParentCategoryRanks = variation + categoryRanksDrainage;
            consumeSkillRanks = -categoryRanksDrainage;
          } else {
            consumeParentCategoryRanks = variation;
          }
          parentCategory.base_rank_available -= consumeParentCategoryRanks;
        } else {
          consumeSkillRanks = -variation;
        }
      } else if (variation < 0) {
        // skill ranks substracted
        if (parentCategory) {
          parentCategory.base_rank_available ? parentCategory.base_rank_available : 0;
          const categoryRanksRefundability =
            parentCategory.base_rank * 1 +
            parentCategory.base_rank_from_parent * 1 -
            parentCategory.base_rank_available * 1;
          if (Math.abs(variation) > categoryRanksRefundability) {
            consumeParentCategoryRanks = -categoryRanksRefundability;
            consumeSkillRanks = categoryRanksRefundability + variation;
          } else {
            consumeParentCategoryRanks = variation;
          }
          parentCategory.base_rank_available -= consumeParentCategoryRanks;
        } else {
          consumeSkillRanks = -variation;
        }
      }

      const scores = {
        category_ranks: consumeParentCategoryRanks
      };
      this.$store.commit(SCORES_CONSUME, scores);

      return consumeSkillRanks;
    },
    updateDevelopmentPointsForCreation($event, skill) {
      // NB: development_points input has no model, must be handled manually
      if (!this.$parent.checkRanksAllocation()) {
        $event.target.value = 0;
        EventBus.$emit('modal', 'close', {
          headerText: 'message.operation_error',
          bodyText: 'message.allocate_all_ranks_before'
        });
        this.form_scrollToMessage();
        return false;
      } else {
        // only if all the ranks are already allocated
        const developmentPointsValue = $event.target.value * 1;
        let consumeDevelopmentPoints = developmentPointsValue - skill.development_points_prechange * 1;

        const scoresConsumedDevelopmentPoints = this.scores.consumed.development_points
          ? this.scores.consumed.development_points * 1
          : 0;

        const remainingDevelopmentPoints =
          this.character.scores.consumable.development_points * 1 - scoresConsumedDevelopmentPoints;

        if (consumeDevelopmentPoints < 0) {
          let totalDevelopmentPointsAllocated = 0;
          this.skills.forEach(function(skill) {
            totalDevelopmentPointsAllocated += skill.development_points_allocated
              ? skill.development_points_allocated * 1
              : 0;
          });

          if (Math.abs(consumeDevelopmentPoints) > scoresConsumedDevelopmentPoints - totalDevelopmentPointsAllocated) {
            skill.development_points =
              developmentPointsValue -
              (consumeDevelopmentPoints + scoresConsumedDevelopmentPoints - totalDevelopmentPointsAllocated);
            consumeDevelopmentPoints = -scoresConsumedDevelopmentPoints + totalDevelopmentPointsAllocated;
            $event.target.value = skill.development_points;
          } else {
            skill.development_points = developmentPointsValue;
          }
        } else {
          if (remainingDevelopmentPoints === 0) {
            $event.target.value = skill.development_points_prechange;
            consumeDevelopmentPoints = 0;
          } else if (consumeDevelopmentPoints > remainingDevelopmentPoints) {
            consumeDevelopmentPoints += remainingDevelopmentPoints - consumeDevelopmentPoints;
            skill.development_points = skill.development_points * 1 + consumeDevelopmentPoints;
            $event.target.value = skill.development_points;
          } else {
            skill.development_points = developmentPointsValue;
          }
        }

        if (consumeDevelopmentPoints !== 0) {
          skill.development_points_prechange = skill.development_points;

          const scores = {
            development_points: consumeDevelopmentPoints
          };

          this.$store.commit(SCORES_CONSUME, scores);
        }
      }
    },
    updateHobbyRanks(consumeHobbyRanks) {
      if (consumeHobbyRanks !== 0) {
        const scores = {
          hobby_ranks: consumeHobbyRanks
        };

        this.$store.commit(SCORES_CONSUME, scores);
      }
    },
    updateRanks($event, skill) {
      if (skill.development_points_allocated > 0) {
        skill.rank = skill.rank_prechange;
      } else {
        const currentRank = $event.target.value * 1;
        this.normalizeSkillFreeRanks(skill, currentRank);

        let variation = skill.rank * 1 - skill.rank_prechange * 1;
        let consumeRanks = 0;

        variation = this.handleParentSkillWhiteLabel(skill, variation);
        consumeRanks = this.updateCategoryRanks(skill, variation);

        this.updateSkillRanks(skill, consumeRanks);
        this.updateSubskillRanks(skill); // only for component-type skills

        // this must stay the last instruction
        skill.rank_prechange = skill.rank;
      }
    },
    updateSkillRanks(skill, consumeSkillRanks) {
      if (consumeSkillRanks !== 0) {
        const consumedFreeRanks = this.scores.consumed.free_ranks ? this.scores.consumed.free_ranks * 1 : 0;
        const totalFreeRanks = this.character.scores.consumable.free_ranks
          ? this.character.scores.consumable.free_ranks * 1
          : 0;
        const consumedHobbyRanks = this.scores.consumed.hobby_ranks ? this.scores.consumed.hobby_ranks * 1 : 0;
        const totalHobbyRanks = this.character.scores.consumable.hobby_ranks
          ? this.character.scores.consumable.hobby_ranks * 1
          : 0;

        let consumeFreeRanks = consumeSkillRanks;
        let consumeHobbyRanks = 0;
        const remainingFreeRanks = totalFreeRanks - consumedFreeRanks;
        const remainingHobbyRanks = totalHobbyRanks - consumedHobbyRanks;

        if (consumeSkillRanks > 0) {
          if (consumeSkillRanks > remainingFreeRanks) {
            const freeRanksOverConsume = consumeSkillRanks - remainingFreeRanks;
            consumeFreeRanks = remainingFreeRanks;
            consumeHobbyRanks = freeRanksOverConsume;

            if (consumeSkillRanks > remainingFreeRanks + remainingHobbyRanks) {
              const hobbyRanksOverConsume = consumeSkillRanks - remainingFreeRanks - remainingHobbyRanks;
              skill.rank = skill.rank * 1 - hobbyRanksOverConsume;
              consumeHobbyRanks = remainingHobbyRanks;
            }
            this.updateHobbyRanks(consumeHobbyRanks);
          }
        } else {
          const hobbyRanksOverFill = totalHobbyRanks - (remainingHobbyRanks - consumeSkillRanks); // eventual overfill
          consumeHobbyRanks = consumeSkillRanks;
          consumeFreeRanks = 0;
          if (hobbyRanksOverFill < 0) {
            consumeHobbyRanks = totalHobbyRanks - remainingHobbyRanks;
            consumeFreeRanks = hobbyRanksOverFill;
          }
          this.updateHobbyRanks(consumeHobbyRanks);
        }

        if (consumeFreeRanks !== 0) {
          const scores = {
            free_ranks: consumeFreeRanks
          };

          this.$store.commit(SCORES_CONSUME, scores);
        }
      }
    },
    updateSubskillRanks(skill) {
      if (skill.isSkill && skill.type === this.system.skill_types.composite) {
        const rank = skill.rank;

        this.skills.forEach(function(subskill) {
          if (
            (subskill.cs_parent && subskill.cs_parent === skill.id) ||
            (subskill.gs_parent && subskill.gs_parent === skill.game_skill) ||
            (subskill.parent && subskill.parent === skill.skill)
          ) {
            subskill.rank = rank;
          }
        });
      }
    },
    // CHARACTER CREATION mode - end

    // RANK UP mode - start
    updateDevelopmentPointsForRankUp($event, skill) {
      // NB: development_points input has no model, must be handled manually
      let developmentPointsValue = $event.target.value * 1;

      // previously allocated XPs can't be removed
      if (skill.development_points_allocated * 1 === 0 && developmentPointsValue < skill.development_points_base) {
        developmentPointsValue = skill.development_points_base;
        skill.development_points = skill.development_points_base;
        $event.target.value = skill.development_points_base;
      }

      let consumeDevelopmentPoints = developmentPointsValue - skill.development_points_prechange * 1;

      const scoresConsumedDevelopmentPoints = this.scores.consumed.experience_points
        ? this.scores.consumed.experience_points * 1
        : 0;

      const remainingDevelopmentPoints = this.scores.play.experience_points * 1 - scoresConsumedDevelopmentPoints;

      if (consumeDevelopmentPoints < 0) {
        // pool points get increased, skill points get reduced
        /*
        let totalDevelopmentPointsAllocated = 0
        this.skills.forEach(function(skill) {
          totalDevelopmentPointsAllocated += skill.development_points_allocated
            ? skill.development_points_allocated * 1
            : 0
        })

        
        if (
          Math.abs(consumeDevelopmentPoints) >
          scoresConsumedDevelopmentPoints - totalDevelopmentPointsAllocated
        ) {
          skill.development_points =
            developmentPointsValue -
            (consumeDevelopmentPoints +
              scoresConsumedDevelopmentPoints -
              totalDevelopmentPointsAllocated)
          consumeDevelopmentPoints =
            -scoresConsumedDevelopmentPoints + totalDevelopmentPointsAllocated
          $event.target.value = skill.development_points
        } else {
          skill.development_points = developmentPointsValue
        }
        */
        skill.development_points = developmentPointsValue;
      } else {
        if (remainingDevelopmentPoints === 0) {
          $event.target.value = skill.development_points_prechange;
          consumeDevelopmentPoints = 0;
        } else if (consumeDevelopmentPoints > remainingDevelopmentPoints) {
          consumeDevelopmentPoints += remainingDevelopmentPoints - consumeDevelopmentPoints;
          skill.development_points = skill.development_points * 1 + consumeDevelopmentPoints;
          $event.target.value = skill.development_points;
        } else {
          skill.development_points = developmentPointsValue;
        }
      }

      if (consumeDevelopmentPoints !== 0) {
        skill.development_points_prechange = skill.development_points;

        const scores = {
          experience_points: consumeDevelopmentPoints
        };

        this.$store.commit(SCORES_CONSUME, scores);
      }
    },
    // RANK UP mode - end

    // PLAY mode - start
    addExperiencePoints(skill) {
      if (skill.development_points_allocated > 0) {
        this.updateSkillExperience(skill);
      }
    },
    closeEditRankMode(skill) {
      skill.editRankMode = false;
      this.updateSkillExperience(skill);
    },
    commitAction(skill) {
      this.rollResolver_commit(this.$store.state.character.id, skill)
        .then(resp => {
          if (!resp || resp.message !== 'success') {
            return;
          }

          //window.open(window.location.origin + '/play/action/monitor', 'action_monitor');
          EventBus.$emit('refresh-committed-action-list');
        })
        .catch(err => {
          let errMessage = '';

          if (err.response && err.response.data && err.response.data.message) {
            errMessage = err.response.data.message;
          } else if (err.response) {
            errMessage = err.response;
          } else {
            errMessage = err;
          }

          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + errMessage
          });
          this.$helpers.errorManager(err);
        });
    },
    openEditRankMode(skillIndex) {
      this.skills[skillIndex].editRankMode = true;
    },
    playPartyAction(skill) {
      const partyCharacterIds = this.$store.state.party.characters.map(character => character.id);
      this.rollResolver_partyExecute(this.$store.state.game.id, skill, partyCharacterIds)
        .then(resp => {
          if (!resp || resp.message !== 'success') {
            EventBus.$emit('modal', 'close', {
              headerText: 'message.operation_error',
              bodyText: 'message.generic_error'
            });
            this.$helpers.errorManager('message.generic_error');
          }
        })
        .catch(err => {
          let errMessage = '';

          if (err.response && err.response.data && err.response.data.message) {
            errMessage = err.response.data.message;
          } else if (err.response) {
            errMessage = err.response;
          } else {
            errMessage = err;
          }

          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + errMessage
          });
          this.$helpers.errorManager(err);
        });
    },
    queueAction(skill) {
      if (this.isCharacterScope) {
        this.commitAction(skill);
      } else if (this.isGameScope) {
        this.scheduleAction(skill);
      }
    },
    scheduleAction(skill) {
      this.rollResolver_schedule(this.$store.state.game.id, skill)
        .then(resp => {
          if (!resp || resp.message !== 'success') {
            return;
          }

          window.open(window.location.origin + '/play/action/monitor', 'action_monitor');
        })
        .catch(err => {
          let errMessage = '';

          if (err.response && err.response.data && err.response.data.message) {
            errMessage = err.response.data.message;
          } else if (err.response) {
            errMessage = err.response;
          } else {
            errMessage = err;
          }

          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + errMessage
          });
          this.$helpers.errorManager(err);
        });
    },

    updateSkillExperience(skill) {
      const skillObj = {
        id: skill.id,
        rank: skill.rank,
        development_points: skill.development_points_allocated
      };
      const formData = new FormData();
      formData.append('skill', JSON.stringify(skillObj));

      this.form_resetResponse();

      this.$store
        .dispatch(CHARACTER_SKILL_EXPERIENCE_ADD, formData)
        .then(() => {
          skill.development_points = skill.development_points * 1 + skill.development_points_allocated * 1;
          skill.development_points_base = skill.development_points * 1;
          skill.development_points_allocated = 0;
          skill.development_points_prechange = skill.development_points_allocated;
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    },
    // PLAY mode - end

    // *** OTHER SHARED FUNCTIONALITIES - start *** //
    displayBaseRank(skill) {
      if (skill.isSkill) {
        if (skill.type === this.system.skill_types.whitelabel) {
          return skill.base_rank_available;
        }
        return skill.base_rank;
      } else {
        if (skill.base_rank === '') {
          return '';
        }
        return skill.base_rank_available;
      }
    },
    filterSkills(skill) {
      if (
        !this.isEditMode &&
        this.character.realms &&
        this.character.realms.spellcaster_code === this.system.non_spellcaster_type_id &&
        !skill.isSkill &&
        skill.tag === 'own_base'
      ) {
        return false;
      }
      return true;
    },
    handleNesting(skill) {
      let nesting = skill.nesting;
      if (skill.isSkill) {
        nesting = nesting * 1 + loopCategoryNesting * 1;
      } else {
        loopCategoryNesting = skill.nesting;
      }
      return nesting;
    },
    handleParentSkillWhiteLabel(skill, variation) {
      const parentSkillWhiteLabel = this.$helpers.findRootSkillForType(
        this.skills,
        skill,
        this.system.skill_types.whitelabel
      );
      let freeRankVariation = variation;

      if (parentSkillWhiteLabel) {
        if (variation > 0) {
          if (parentSkillWhiteLabel.base_rank_available > 0) {
            let parentSkillRanksDrainage = parentSkillWhiteLabel.base_rank_available - variation;

            if (parentSkillRanksDrainage < 0) {
              parentSkillWhiteLabel.base_rank_available = 0;
              variation = variation + parentSkillRanksDrainage;
              freeRankVariation = -parentSkillRanksDrainage;
            } else {
              parentSkillWhiteLabel.base_rank_available = parentSkillRanksDrainage;
              freeRankVariation = 0;
            }
          }
        } else if (variation < 0) {
          if (parentSkillWhiteLabel.base_rank_available < parentSkillWhiteLabel.base_rank) {
            const parentSkillRanksAbsorption =
              parentSkillWhiteLabel.base_rank - parentSkillWhiteLabel.base_rank_available;
            if (Math.abs(parentSkillRanksAbsorption) < Math.abs(variation)) {
              parentSkillWhiteLabel.base_rank_available = parentSkillWhiteLabel.base_rank;
              freeRankVariation = freeRankVariation * 1 + parentSkillRanksAbsorption * 1;
            } else {
              parentSkillWhiteLabel.base_rank_available += Math.abs(freeRankVariation);
              freeRankVariation = 0;
            }
          }
        }

        if (
          this.scores.consumed.white_label_ranks == null ||
          (variation > 0 && this.scores.consumed.white_label_ranks <= parentSkillWhiteLabel.base_rank) ||
          (variation < 0 && this.scores.consumed.white_label_ranks > 0)
        ) {
          const scores = {
            white_label_ranks: variation
          };

          this.$store.commit(SCORES_CONSUME, scores);
        }
      }

      return freeRankVariation;
    },
    isRankable(developmentEffort) {
      return developmentEffort > 0;
    },
    isRankUpableSkillType(skill) {
      return (
        skill.isSkill &&
        skill.type !== this.system.skill_types.component &&
        skill.type !== this.system.skill_types.whitelabel
      );
    },
    isTemporarySkill(skill) {
      return skill.tempIndex === '' || typeof skill.tempIndex === 'undefined';
    },
    isUsableSkill(isSkill, type) {
      return isSkill && type !== this.system.skill_types.whitelabel;
    },
    normalizeSkillFreeRanks(skill, currentRank) {
      const minRanks = this.system.skill_min_rank_number;
      const maxRanks = this.system.skill_max_rank_number * 1 - (skill.base_rank ? skill.base_rank * 1 : 0);

      if (currentRank < minRanks) {
        skill.rank = minRanks;
      } else if (currentRank > maxRanks) {
        skill.rank = maxRanks;
      }
    },
    populateSkills() {
      const options = {
        maneuverType: this.filter === 'all' ? '' : this.filter
      };

      console.log('SKILLS_REQUEST', this.SKILLS_REQUEST);
      //.dispatch(this.isEditMode ? this.SKILLS_REQUEST : CHARACTER_SKILLS_REQUEST, options)
      this.$store
        .dispatch(this.SKILLS_REQUEST, options)
        .then(data => {
          const self = this;
          this.skills = data;

          if (!this.isEditMode) {
            this.skills.forEach(function(skill, index) {
              if (skill.isSkill) {
                Vue.set(
                  skill,
                  'development_points_prechange',
                  self.isPlayMode
                    ? skill.development_points_allocated
                      ? skill.development_points_allocated * 1
                      : 0
                    : skill.development_points * 1
                );
                Vue.set(skill, 'development_points_allocated', 0);
                Vue.set(skill, 'development_points_base', skill.development_points * 1);
              }
              Vue.set(skill, 'base_rank_available', skill['base_rank']);
              Vue.set(skill, 'base_rank_from_parent', 0);
              Vue.set(skill, 'rank_prechange', 0);
              Vue.set(skill, 'editRankMode', false);
              skill.rank_allocated = skill.rank;
              if (!skill.open) {
                skill.open = !skill.open; // why? read from next line
                /* Open is used by the script as a status flag. Here I'm using it improperly to set the initial status 
                  and it messes toggleCollapse up. */
                self.toggleCollapse(index, skill.nesting);
              }
            });
          }
          this.$nextTick(() => {
            this.areSkillsLoaded = true;
          });
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@include skill-table;

.skill-list {
  position: relative;
}

// EDIT MODE - start
.skill-list-mode-edit {
  .skill-table {
    &.table-fixed {
      thead {
        th {
          top: 0;
        }
      }
    }
  }
}
// EDIT MODE - end
</style>
