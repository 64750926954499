var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "body-view container" },
    [
      _vm.isLoading ? _c("AnimationLoading") : _vm._e(),
      _c(
        "main",
        { staticClass: "center" },
        [
          _c("h1", { staticClass: "welcome-message" }, [
            _vm._v(_vm._s(_vm.$t("login.welcome_message")))
          ]),
          _c("LoginBox")
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }