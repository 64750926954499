import { AUTH_LOGOUT } from '../actions/auth';
import {
  CAMPAIGN_CREATION_ERROR,
  CAMPAIGN_CREATION_REQUEST,
  CAMPAIGN_CREATION_SUCCESS,
  CAMPAIGN_ERROR,
  CAMPAIGN_REQUEST,
  CAMPAIGN_RESET,
  CAMPAIGN_SUCCESS,
  CAMPAIGN_TEMPLATE_CREATION_REQUEST,
  CAMPAIGN_TEMPLATES_REQUEST,
  CAMPAIGNS_ERROR,
  CAMPAIGNS_REQUEST,
  CAMPAIGNS_SUCCESS
} from '../actions/campaign';
import { ROLE_SELECT_RESET } from '../actions/role';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  id: '',
  name: '',
  statusVuex: {}
};

const getters = {
  getCampaignStatus: state => statusName => {
    return state.statusVuex[statusName];
  },
  isCampaignLoaded: state => !!state.id
};

const actions = {
  [CAMPAIGN_CREATION_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CAMPAIGN_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCampaign, formData)
        .then(resp => {
          commit(CAMPAIGN_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CAMPAIGN_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CAMPAIGN_REQUEST]: ({ commit, dispatch, rootState }, id = null) => {
    // only for masters, so id is necessary
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      commit(CAMPAIGN_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCampaign + id, params)
        .then(resp => {
          commit(CAMPAIGN_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CAMPAIGN_RESET);
          commit(CAMPAIGN_ERROR, err.response);
          dispatch(AUTH_LOGOUT);
          reject(err);
        });
    });
  },
  [CAMPAIGN_TEMPLATE_CREATION_REQUEST]: ({ commit, rootState }, formData) => {
    if (!formData) {
      return Promise.reject('no_formData');
    }

    return new Promise((resolve, reject) => {
      commit(CAMPAIGN_CREATION_REQUEST);
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.createCampaignTemplate, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then(resp => {
          commit(CAMPAIGN_CREATION_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CAMPAIGN_CREATION_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CAMPAIGN_TEMPLATES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(CAMPAIGNS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCampaignTemplateList, params)
        .then(resp => {
          commit(CAMPAIGNS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CAMPAIGNS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [CAMPAIGNS_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(CAMPAIGNS_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCampaignList, params)
        .then(resp => {
          commit(CAMPAIGNS_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CAMPAIGNS_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [CAMPAIGN_CREATION_ERROR]: state => {
    Vue.set(state.statusVuex, 'campaignCreation', 'error');
  },
  [CAMPAIGN_CREATION_REQUEST]: state => {
    Vue.set(state.statusVuex, 'campaignCreation', 'loading');
  },
  [CAMPAIGN_CREATION_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'campaignCreation', 'success');
  },

  [CAMPAIGN_ERROR]: state => {
    Vue.set(state.statusVuex, 'campaign', 'error');
  },
  [CAMPAIGN_REQUEST]: state => {
    Vue.set(state.statusVuex, 'campaign', 'loading');
  },
  [CAMPAIGN_RESET]: state => {
    Vue.helpers.resetState(state);
  },
  [CAMPAIGN_SUCCESS]: (state, resp) => {
    Vue.set(state.statusVuex, 'campaign', 'success');
    state = Object.assign(state, resp);
  },

  [CAMPAIGNS_ERROR]: state => {
    Vue.set(state.statusVuex, 'campaigns', 'error');
  },
  [CAMPAIGNS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'campaigns', 'loading');
  },
  [CAMPAIGNS_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'campaigns', 'success');
  },

  [ROLE_SELECT_RESET]: state => {
    Vue.helpers.resetState(state);
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
