<template>
  <main v-if="restrictedAccess_grantMaster" id="skills" class="body-view container-fluid skills">
    <div class="row">
      <div class="area_title col-12 center">
        <h1>
          {{ $t('skill.skill_list') }}
        </h1>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <Message />
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <SkillList ref="skillList" mode="edit" />
      </div>
    </div>
  </main>
</template>

<script>
import restrictedAccessMixin from '@/mixins/restrictedAccess';
import SkillList from '@/components/Skill/List';
import Message from '@/components/Message';

export default {
  name: 'SkillListEditorView',
  components: {
    SkillList,
    Message
  },
  mixins: [restrictedAccessMixin],
  data() {
    return {
      skills: [],
      errorFields: []
    };
  }
};
</script>

<style lang="scss" scoped>
@include skill-table;

.skill-table.table-fixed thead th {
  top: 0;
}
</style>
