import { AUTH_REMEMBERME_LOGIN } from './store/actions/auth';

import Vue from 'vue';
import Router from 'vue-router';
import $store from './store/index';
//import { MESSAGE_RESET } from '@/store/actions/message';

/* ACCOUNT */
import Account from './components/Account';

/* CAMPAIGN */
import CampaignCreate from './views/Campaign/Create.vue';
import CampaignList from './views/Campaign/List.vue';
import CampaignTemplateCreate from './views/Campaign/template/Create.vue';
import CampaignTemplateList from './views/Campaign/template/List.vue';
import GameCampaignDetail from './views/Campaign/Game';

/* CHARACTER */
import CharacterCreateStep1 from './views/Character/CreateStep1.vue';
import CharacterCreateStep2 from './views/Character/CreateStep2.vue';
import CharacterCreateStep3 from './views/Character/CreateStep3.vue';
import CharacterCreateStep4 from './views/Character/CreateStep4.vue';
import CharacterCreateStep5 from './views/Character/CreateStep5.vue';
import CharacterCreateStep6 from './views/Character/CreateStep6.vue';
import CharacterCreateStep7 from './views/Character/CreateStep7.vue';
import CharacterCreateStep8 from './views/Character/CreateStep8.vue';
import CharacterList from './views/Role/List.vue';
import CharacterProfile from './views/Character/Profile.vue';
import CharacterSkill from './views/Character/Skill.vue';
import CharacterSkills from './views/Character/Skills.vue';
import CharacterSkillsRankUp from './views/Character/SkillsRankUp.vue';

/* GAME */
import Game from './views/Game';
import GameCreate from './views/Game/Create.vue';
import GameList from './views/Game/List.vue';
import GameSelect from './views/Game/Select.vue';
import GameSkills from './views/Game/Skills.vue';

/* LOGIN */
import Login from './views/Login.vue';

/* MASTER */
import MasterCreate from './views/Master/Create.vue';
import MasterList from './views/Role/List.vue';
import MasterProfile from './views/Master/Profile.vue';

/* PARTY */
import Party from './views/Party';
import PartyList from './views/Party/List.vue';

/* ROLE */
import RoleList from './views/Role/List.vue';

/* SERVER ERROR */
import PageNotFound from './components/ServerError/PageNotFound.vue';

/* SETTING */
import SettingList from './views/Setting/List.vue';

/* SKILL */
import SkillEdit from './views/Skill/Edit.vue';
import SkillList from './views/Skill/List.vue';

/* SYSTEM */
import SystemList from './views/System/List.vue';

/* OTHER */
import Page from './views/Page.vue';

/* ************ */
/***** PLAY *****/
/* ************ */

/* ACTION */
import Action from './views/Action';
import ActionMonitor from './views/Action/Monitor';

Vue.use(Router);

const goToAfterAuth = (to, from, next) => {
  if (to.path === '/' || to.path === '/login') {
    if ($store.getters.isRoleLoaded()) {
      next('/' + $store.state.role.type + '/profile');
      return;
    }

    if ($store.state.role.type) {
      next('/' + $store.state.role.type + '/list');
      return;
    }

    next('/role/list');
    return;
  }

  next();
  return;
};

/*** THE AUTHENITCATION FLOW ***
 * The value of isAuthenticated is decided and stored during the execution of the store module Auth.
 * Everything is good when a user comes from the login, since the login requires no access check (which
 * would result false, however): then authentication is run and auth information is stored.
 * However, when putting in place an autologin system (based on remember-me and token locally
 * stored data, in this case), one must consider that the route guards run before the auth data is
 * available. That's why token information must be checked in the route guards.
 */
const ifAuthenticated = async (to, from, next) => {
  if ($store.getters.isAuthenticated) {
    goToAfterAuth(to, from, next);
    return;
  }

  if ($store.state.auth.rememberMe === true && $store.state.auth.token) {
    await $store.dispatch(AUTH_REMEMBERME_LOGIN);

    if ($store.getters.isAuthenticated) {
      goToAfterAuth(to, from, next);
      return;
    }
  }

  if (to.path !== '/login') {
    next('/login');
  }

  next();
  return;
};

const routes = [
  {
    path: '/',
    name: 'Root',
    component: Login,
    beforeEnter: ifAuthenticated
  },

  /* ACTION */
  {
    path: '/play/action/monitor',
    name: 'ActionMonitor',
    component: ActionMonitor,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/play/action',
    name: 'Action',
    component: Action,
    beforeEnter: ifAuthenticated
  },

  /* CAMPAIGN */
  {
    path: '/campaign/create',
    name: 'CampaignCreate',
    component: CampaignCreate,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/campaign/list',
    name: 'CampaignList',
    component: CampaignList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/campaign/template/list',
    name: 'CampaignTemplateList',
    component: CampaignTemplateList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/campaign/template/create',
    name: 'CampaignTemplateCreate',
    component: CampaignTemplateCreate,
    beforeEnter: ifAuthenticated
  },

  /* CHARACTER */
  {
    path: '/character',
    name: 'CharacterIndex',
    redirect: '/character/list'
  },
  {
    path: '/character/create',
    name: 'CharacterCreate',
    redirect: '/character/create/step1',
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step1',
    name: 'CharacterCreateStep1',
    component: CharacterCreateStep1,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step2',
    name: 'CharacterCreateStep2',
    component: CharacterCreateStep2,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step3',
    name: 'CharacterCreateStep3',
    component: CharacterCreateStep3,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step4',
    name: 'CharacterCreateStep4',
    component: CharacterCreateStep4,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step5',
    name: 'CharacterCreateStep5',
    component: CharacterCreateStep5,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step6',
    name: 'CharacterCreateStep6',
    component: CharacterCreateStep6,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step7',
    name: 'CharacterCreateStep7',
    component: CharacterCreateStep7,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/create/step8',
    name: 'CharacterCreateStep8',
    component: CharacterCreateStep8,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/list',
    name: 'CharacterList',
    component: CharacterList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/profile',
    name: 'CharacterProfile',
    component: CharacterProfile,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/skills/:filter?',
    name: 'Skills',
    component: CharacterSkills,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/skills/rankup',
    name: 'SkillsRankUp',
    component: CharacterSkillsRankUp,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/character/skill/:id',
    name: 'Skill',
    component: CharacterSkill,
    beforeEnter: ifAuthenticated
  },

  /* GAME */
  {
    path: '/game/campaign',
    name: 'GameCampaignDetail',
    component: GameCampaignDetail,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/create',
    name: 'GameCreate',
    component: GameCreate,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/instance',
    name: 'GameInstance',
    component: Game,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/list',
    name: 'GameList',
    component: GameList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/party',
    name: 'GameParty',
    component: Party,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/select',
    name: 'GameSelect',
    component: GameSelect,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/skills',
    name: 'GameSkills',
    component: GameSkills,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/game/:id',
    name: 'GameDetail',
    component: Game,
    beforeEnter: ifAuthenticated
  },

  /* LOGIN */
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: ifAuthenticated
  },

  /* MASTER */
  {
    path: '/master',
    name: 'MasterIndex',
    redirect: '/master/list'
  },
  {
    path: '/master/create',
    name: 'MasterCreate',
    component: MasterCreate,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/master/create/step1',
    name: 'MasterCreateStep1',
    redirect: '/master/create',
    beforeEnter: ifAuthenticated
  },
  {
    path: '/master/list',
    name: 'MasterList',
    component: MasterList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/master/profile',
    name: 'MasterProfile',
    component: MasterProfile,
    beforeEnter: ifAuthenticated
  },

  /* PARTY */
  {
    path: '/party',
    name: 'PartyDetailCharacter',
    component: Party,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/party/list',
    name: 'PartyList',
    component: PartyList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/party/:id',
    name: 'PartyDetailMaster',
    component: Party,
    beforeEnter: ifAuthenticated
  },

  /* ROLE */
  {
    path: '/role/list',
    name: 'RoleList',
    component: RoleList,
    beforeEnter: ifAuthenticated
  },

  /* SETTING */
  {
    path: '/setting/list',
    name: 'SettingList',
    component: SettingList,
    beforeEnter: ifAuthenticated
  },

  /* SKILL */
  {
    path: '/skill/list',
    name: 'SkillList',
    component: SkillList,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/skill/edit/:id',
    name: 'SkillEdit',
    component: SkillEdit,
    props: true,
    beforeEnter: ifAuthenticated
  },

  /* SYSTEM */
  {
    path: '/system/list',
    name: 'SystemList',
    component: SystemList,
    beforeEnter: ifAuthenticated
  },

  /*** OTHER ***/
  {
    path: '/account',
    name: 'Account',
    component: Account,
    beforeEnter: ifAuthenticated
  },
  {
    path: '/page',
    name: 'Page',
    component: Page
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: 'about' */ './views/About.vue')
  },
  {
    path: '*',
    name: 'Page404',
    component: PageNotFound // to develop
  }
];

Vue.mixin({
  beforeRouteLeave(to, from, next) {
    //this.$store.commit(MESSAGE_RESET); // this will prevent messages on forced logout! To rethink
    this.errorFields = [];
    next();
    return;
  }
});

const router = new Router({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: '/',
  routes
});

export default router;
