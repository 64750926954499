var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "character-creation-summary-frame" }, [
    _vm.isCharacterLoaded
      ? _c("ul", { staticClass: "character-creation-summary-text" }, [
          _c("li", [
            _c(
              "span",
              { staticClass: "character-creation-summary-text-label" },
              [_vm._v(_vm._s(_vm.$t("character.game")))]
            ),
            _vm._v(_vm._s(_vm.game.name) + " ")
          ]),
          _c("li", [
            _c(
              "span",
              { staticClass: "character-creation-summary-text-label" },
              [_vm._v(_vm._s(_vm.$t("character.name")))]
            ),
            _vm._v(_vm._s(_vm.character.name) + " ")
          ]),
          _vm.character.race && _vm.character.race.name
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [_vm._v(_vm._s(_vm.$t("character.race")))]
                ),
                _vm._v(
                  _vm._s(_vm.character.race.name) +
                    " (" +
                    _vm._s(_vm.character.metrics.sex_id) +
                    ") "
                )
              ])
            : _vm._e(),
          _vm.character.class && _vm.character.class.name
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [_vm._v(_vm._s(_vm.$t("character.class")))]
                ),
                _vm._v(_vm._s(_vm.character.class.name) + " ")
              ])
            : _vm._e(),
          _vm.character.race && _vm.character.race.name
            ? _c(
                "li",
                [
                  _c(
                    "span",
                    { staticClass: "character-creation-summary-text-label" },
                    [_vm._v(_vm._s(_vm.$t("character.realms")))]
                  ),
                  _vm.character.realms
                    ? [
                        _vm._l(_vm.character.realms.components, function(
                          realm,
                          index
                        ) {
                          return [
                            _c("span", { key: "realm-" + index }, [
                              _vm._v(_vm._s(realm.name))
                            ]),
                            index < _vm.character.realms.components.length - 1
                              ? [_vm._v("/")]
                              : _vm._e()
                          ]
                        })
                      ]
                    : _vm._e()
                ],
                2
              )
            : _vm._e(),
          _vm.character.feature
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [_vm._v(_vm._s(_vm.$t("character.feature")))]
                ),
                _c(
                  "ul",
                  { staticClass: "character-creation-summary-text-list" },
                  _vm._l(_vm.character.feature, function(factor, index) {
                    return _c(
                      "li",
                      {
                        key: "factor-" + index,
                        class: factor.isAdvantage ? "plus" : "minus"
                      },
                      [_vm._v(" " + _vm._s(factor.name) + " ")]
                    )
                  }),
                  0
                )
              ])
            : _vm._e(),
          _vm.showModifiers &&
          _vm.character.scores &&
          _vm.character.scores.creation_modifiers
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("character.formation_creation_modifiers"))
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "character-creation-summary-text-list" },
                  [
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.hit_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers.hit_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.power_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers.power_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.exhaustion_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers
                            .exhaustion_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.reputation_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers
                            .reputation_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.grace_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers.grace_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.sanity_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers.sanity_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.fate_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.creation_modifiers.fate_points
                        ) + " "
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.showModifiers &&
          _vm.character.scores &&
          _vm.character.scores.permanent_modifiers
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("character.formation_permanent_modifiers"))
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "character-creation-summary-text-list" },
                  [
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.learning_rate")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.permanent_modifiers.learning_rate
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [
                          _vm._v(
                            _vm._s(_vm.$t("character.soul_departure_rounds"))
                          )
                        ]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.permanent_modifiers
                            .soul_departure_rounds
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.healing_rate")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.permanent_modifiers.healing_rate
                        ) + " "
                      )
                    ])
                  ]
                )
              ])
            : _vm._e(),
          _vm.character.scores && _vm.character.scores.consumable
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-creation-summary-text-label" },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("character.formation_consumable_points"))
                    )
                  ]
                ),
                _c(
                  "ul",
                  { staticClass: "character-creation-summary-text-list" },
                  [
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.background_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.consumable.background_points
                        ) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.talent_points")))]
                      ),
                      _vm._v(
                        _vm._s(_vm.character.scores.consumable.talent_points) +
                          " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.hobby_ranks")))]
                      ),
                      _vm._v(
                        _vm._s(_vm.character.scores.consumable.hobby_ranks) +
                          " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.free_ranks")))]
                      ),
                      _vm._v(
                        _vm._s(_vm.character.scores.consumable.free_ranks) + " "
                      )
                    ]),
                    _c("li", { staticClass: "bullet" }, [
                      _c(
                        "span",
                        {
                          staticClass: "character-creation-summary-text-label"
                        },
                        [_vm._v(_vm._s(_vm.$t("character.development_points")))]
                      ),
                      _vm._v(
                        _vm._s(
                          _vm.character.scores.consumable.development_points
                        ) + " "
                      )
                    ])
                  ]
                )
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }