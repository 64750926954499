var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.restrictedAccess_grantMaster
    ? _c(
        "main",
        {
          staticClass: "body-view container game-skill-list-view",
          attrs: { id: "game-skill-list-view" }
        },
        [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-12" }, [_c("Message")], 1)
          ]),
          _c("div", { staticClass: "row main-content relative" }, [
            _c("div", { staticClass: "dataset col-12" }, [
              _c("div", { staticClass: "game-skills" }, [
                _c(
                  "form",
                  {
                    staticClass: "game-form left",
                    on: {
                      submit: function($event) {
                        $event.preventDefault()
                      }
                    }
                  },
                  [
                    _vm.$store.state.game.id
                      ? _c("SkillList", {
                          ref: "skillList",
                          attrs: {
                            filter: "all",
                            mode: "play",
                            gameId: "$store.state.game.id",
                            errorFieldsInherited: _vm.errorFields
                          },
                          on: { "error-fields-set": _vm.form_onErrorFields }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            ])
          ])
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }