var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tile tile-selectable item-frame",
      on: {
        click: function($event) {
          return _vm.selectItem()
        }
      }
    },
    [
      _c("div", { staticClass: "item-image-frame" }, [
        _c("img", {
          staticClass: "item-image image-fill-frame",
          class: _vm.item.cover ? "" : "fade-image",
          attrs: {
            border: "0",
            alt: _vm.item.name,
            src: _vm.item.cover
              ? _vm.imageUrl + _vm.item.id + "/" + _vm.item.cover
              : _vm.missingImage
          }
        }),
        _vm.showButtons
          ? _c(
              "button",
              {
                staticClass: "btn btn-primary btn-close-item",
                attrs: { type: "button" },
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.deleteItem()
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "button." +
                          _vm.entityNature +
                          ".delete_" +
                          _vm.entityNature
                      )
                    ) +
                    " "
                )
              ]
            )
          : _vm._e()
      ]),
      _c("ul", { staticClass: "item-text" }, [
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".name")))
          ]),
          _vm._v(_vm._s(_vm.item.name) + " ")
        ]),
        _vm.item.master
          ? _c("li", [
              _c("span", { staticClass: "item-text-label" }, [
                _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".master")))
              ]),
              _vm._v(_vm._s(_vm.item.master.name) + " ")
            ])
          : _vm._e(),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".starting_datetime")))
          ]),
          _vm._v(
            _vm._s(
              _vm.$helpers.getCustomFormattedDateTime(
                _vm.item.starting_datetime
              )
            ) + " "
          )
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".ending_datetime")))
          ]),
          _vm._v(
            _vm._s(
              _vm.item.ending_datetime
                ? _vm.$helpers.getCustomFormattedDateTime(
                    _vm.item.ending_datetime
                  )
                : _vm.$t("global.on_going")
            ) + " "
          )
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".current_datetime")))
          ]),
          _vm._v(
            _vm._s(
              _vm.$helpers.getCustomFormattedDateTime(_vm.item.current_datetime)
            ) + " "
          )
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".setting")))
          ]),
          _vm._v(_vm._s(_vm.settingName) + " ")
        ]),
        _c("li", [
          _c("span", { staticClass: "item-text-label" }, [
            _vm._v(_vm._s(_vm.$t(_vm.entityNature + ".system")))
          ]),
          _vm._v(_vm._s(_vm.systemName) + " ")
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }