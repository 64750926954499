<template>
  <div class="character-dataset6">
    <AnimationLoading v-if="!this.isContentReady" />
    <form class="character-form left" @submit.prevent="onSubmit">
      <div class="form-frame">
        <div class="row">
          <div class="form-group col-5" v-if="spellcasterType">
            <label for="spellcaster_type">{{ $t('character.spellcaster_type') }}</label>
            <span class="display_data" id="spellcaster_type"
              >{{ spellcasterType.name }} ({{ spellcasterType.code }})</span
            >
          </div>
          <div class="form-group col-5" v-if="character.scores && character.scores.permanent_modifiers">
            <label for="spellcaster_type">{{ $t('character.base_spell_list_number') }}</label>
            <span class="display_data" id="spellcaster_type">{{
              character.scores.permanent_modifiers.base_spell_list_number
            }}</span>
          </div>
        </div>
        <div class="row">
          <div class="form-group col-12">
            <!-- SpellListDevelopmentCost /-->
          </div>
        </div>
        <div class="row" v-for="(spellList, index) in spellLists" :key="`spell-list-${index}`">
          <FormGroupSelect
            class="form-group col-12"
            :ariaDescribedby="'spell_lists_' + index"
            :class="errorFields['spellLists'] ? 'error' : ''"
            :collectionName="spellLists[index].collectionName"
            :data="{ index: index }"
            :disabled="spellLists[index].disabled"
            :id="'spell_lists_' + index"
            :label="spellLists[index].label"
            :labelParams="spellLists[index].labelParams"
            :name="spellLists[index].name"
            :onChangeCallback="onChangeSpellList"
            :optionLabel="spellLists[index].optionLabel"
            :options="spellLists[index].data"
            :optionValue="spellLists[index].optionValue"
            :placeholder="spellLists[index].placeholder"
            :required="spellLists[index].required"
            :selectValue="spellLists[index].selectedValue"
          />
        </div>
      </div>
      <div class="center">
        <button type="submit" class="btn btn-primary">
          {{ $t('button.next') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Vue from 'vue';
import formMixin from '@/mixins/form';
import prhMixin from '@/mixins/prh';
import { CHARACTER_CREATION_STEP_6_REQUEST, CHARACTER_REFRESH } from '@/store/actions/character';
import { SPELLCASTER_TYPE_REQUEST } from '@/store/actions/class';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { SCORES_CONSUMED_SET } from '@/store/actions/scores';
import { SPELL_LISTS_REQUEST } from '@/store/actions/skill';
import FormGroupSelect from '@/components/Form/FormGroupSelect';
import { mapState } from 'vuex';

const spellListTemplate = {
  collectionName: 'spellLists',
  data: [],
  label: 'character.base_spell_list_with_counter',
  labelParams: { counter: 0 },
  name: 'spell_list[]',
  optionLabel: 'extendedName',
  optionValue: 'id',
  placeholder: '',
  selectedValue: ''
};

export default {
  name: 'CharacterDataset6',
  components: {
    FormGroupSelect
  },
  mixins: [formMixin, prhMixin],
  data() {
    return {
      classStandardBaseLists: [],
      doNotRemove: false,
      errorFields: [],
      isContentReady: false,
      isSpellListPopulated: false,
      magicRealmIds: [],
      spellcasterType: {},
      spellLists: [],
      spellListsCounter: 0,
      spellListsSelected: []
    };
  },
  computed: {
    ...mapState(['character', 'scores'])
  },
  created() {
    this.$store
      .dispatch(SPELLCASTER_TYPE_REQUEST)
      .then(data => {
        this.spellcasterType = data;
      })
      .catch(err => {
        this.$store.commit(MESSAGE_ERROR_SET, err);
        this.$helpers.errorManager(err);
      });

    if (this.$store.getters.isCharacterLoaded) {
      this.populateSpellList();
    } else {
      /* this part is useful only when reloading the page, otherwise the necessary character is already available */
      this.unsubscribe = this.$store.subscribe(mutation => {
        if (mutation.type === 'CHARACTER_SUCCESS') {
          this.populateSpellList();
          this.unsubscribe();
        }
      });
    }
  },
  methods: {
    // EVENT HANDLERS
    onChangeSpellList(id, $event = null, selectIndex = null) {
      this.form_resetResponse();

      if ($event) {
        const selectData = JSON.parse($event.target.dataset.select);
        selectIndex = selectData.index;
      }

      this.updateSpellListSelection(id);

      if (id) {
        if (
          this.spellListsCounter < spellListTemplate.data.length &&
          this.spellLists.length < this.character.scores.permanent_modifiers.base_spell_list_number &&
          typeof this.spellLists[selectIndex + 1] === 'undefined'
        ) {
          this.addSpellList();
          this.doNotRemove = false;
        } else {
          this.doNotRemove = true;
        }
      } else {
        if (this.doNotRemove) {
          this.doNotRemove = false;
        } else {
          this.removeSpellList(selectIndex);
        }
      }
    },
    onSubmit() {
      this.form_resetResponse();

      if (
        this.scores.consumed.base_spell_list_selected > this.character.scores.permanent_modifiers.base_spell_list_number
      ) {
        this.$store.commit(MESSAGE_ERROR_SET, 'too_many_allocated_base_spell_lists');
        this.form_setFieldErrors(['spellLists']);
        return false;
      }
      // it's not necessary to allocate all the base spell lists
      /*
      else if (
        this.scores.consumed.base_spell_list_selected <
        this.character.scores.permanent_modifiers.base_spell_list_number
      ) {
        this.$store.commit(
          MESSAGE_ERROR_SET,
          'too_few_allocated_base_spell_lists'
        )
        this.form_setFieldErrors(['spellLists'])
        this.form_scrollToMessage()
        return false
      }
      */

      const formData = new FormData();
      formData.append('baseSpellLists', JSON.stringify(this.spellListsSelected));
      this.prh_dispatch(CHARACTER_CREATION_STEP_6_REQUEST, CHARACTER_REFRESH, formData, '/character/create/step7');
    },

    // FUNCTIONS
    addSpellList() {
      this.spellListsCounter++;
      const newSpellList = JSON.parse(JSON.stringify(spellListTemplate));
      newSpellList.labelParams.counter = this.spellListsCounter;
      newSpellList.data = this.filterOutSelectedOptions();
      Vue.set(this.spellLists, this.spellListsCounter - 1, newSpellList);
    },
    filterOutSelectedOptions(currentSelectedValue = null) {
      return spellListTemplate.data.filter(tp => {
        const foundInSpellListsSelected = this.spellListsSelected.find(tpSelected => tp.id === tpSelected.id);
        if (currentSelectedValue) {
          return !foundInSpellListsSelected || tp.id === currentSelectedValue;
        }
        return !foundInSpellListsSelected;
      });
    },
    getMagicRealmIds() {
      if (this.character.realms && this.character.realms.components_magic.length > 0) {
        const magicRealms = this.character.realms.components_magic;
        return this.$helpers.array_column(magicRealms, 'id');
      }
      return [];
    },
    populateSpellList() {
      this.magicRealmIds = this.getMagicRealmIds();
      if (this.magicRealmIds.length > 0 && !this.isSpellListPopulated) {
        this.isSpellListPopulated = true;

        this.$store
          .dispatch(SPELL_LISTS_REQUEST, this.magicRealmIds)
          .then(data => {
            data.forEach(element => {
              if (element.class_id === this.character.class.id) {
                this.classStandardBaseLists.push(element);
              }

              const realm = element.realm1_name + (element.realm2 ? '/' + element.realm2_name : '');

              element.extendedName = `[${realm} - ${element.spell_list_type} 
                ${element.class_name ? ': ' + element.class_name : ''}] ${element.name}`;
            });

            if (this.character.scores.permanent_modifiers.base_spell_list_number < this.classStandardBaseLists.length) {
              spellListTemplate.data = this.classStandardBaseLists;
            } else {
              spellListTemplate.data = data;
            }

            if (this.character.scores.permanent_modifiers.base_spell_list_number > 0) {
              this.addSpellList();
            }
            this.presetClassBaseLists();
            this.$nextTick(() => {
              this.isContentReady = true;
            });
          })
          .catch(err => {
            this.$store.commit(MESSAGE_ERROR_SET, err);
            this.$helpers.errorManager(err);
          });
      }
    },
    presetClassBaseLists() {
      for (
        let i = 0;
        i < this.classStandardBaseLists.length && i < this.character.scores.permanent_modifiers.base_spell_list_number;
        i++
      ) {
        this.spellLists[i].selectedValue = this.classStandardBaseLists[i].id;
        if (this.character.scores.permanent_modifiers.base_spell_list_number >= this.classStandardBaseLists.length) {
          this.spellLists[i].disabled = 'disabled';
        }
        this.onChangeSpellList(this.classStandardBaseLists[i].id, null, i);
      }
    },
    removeSpellList(selectIndex) {
      this.spellListsCounter--;
      this.spellLists.splice(selectIndex, 1);
      this.updateSpellListIndexes(selectIndex);
    },
    updateBaseSpellListCost(totalBaseSpellListCost) {
      const scores = {
        base_spell_list_selected: totalBaseSpellListCost
      };
      this.$store.commit(SCORES_CONSUMED_SET, scores);
    },
    updateSpellListIndexes(selectIndex = 0) {
      for (let i = selectIndex; i < this.spellLists.length; i++) {
        this.spellLists[i].labelParams.counter = i + 1;
      }
    },
    updateSpellListsOptions() {
      // remove from options already-selected options
      this.spellLists.forEach(spellList => {
        spellList.data = this.filterOutSelectedOptions(spellList.selectedValue);
      });
    },
    updateSpellListSelection() {
      let totalBaseSpellListCost = 0;
      this.spellListsSelected = [];
      this.spellLists.forEach(spellList => {
        if (spellList.selectedValue) {
          const id = spellList.selectedValue;
          const spellListObject = spellListTemplate.data.find(tp => tp.id === id);

          if (spellList) {
            this.spellListsSelected.push(spellListObject);
            totalBaseSpellListCost++;
          }
        }
      });

      this.updateBaseSpellListCost(totalBaseSpellListCost);
      this.updateSpellListsOptions();
    }
  }
};
</script>

<style lang="scss" scoped>
.character-form {
  margin-top: auto;
}
</style>
