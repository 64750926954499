import { AUTH_LOGOUT } from '../actions/auth';
import {
  CLASS_RESET,
  CLASSES_ERROR,
  CLASSES_REQUEST,
  CLASSES_SUCCESS,
  SPELLCASTER_TYPE_ERROR,
  SPELLCASTER_TYPE_REQUEST,
  SPELLCASTER_TYPE_SUCCESS
} from '../actions/class';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getClassStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [CLASSES_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(CLASSES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getClassList, params)
        .then(resp => {
          commit(CLASSES_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CLASSES_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SPELLCASTER_TYPE_REQUEST]: ({ commit, rootState }) => {
    return new Promise((resolve, reject) => {
      commit(CLASSES_REQUEST);
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSpellcasterType, params)
        .then(resp => {
          commit(SPELLCASTER_TYPE_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SPELLCASTER_TYPE_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [CLASS_RESET]: state => {
    Vue.helpers.resetState(state);
  },

  [CLASSES_ERROR]: state => {
    Vue.set(state.statusVuex, 'classes', 'error');
  },
  [CLASSES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'classes', 'loading');
  },
  [CLASSES_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'classes', 'success');
  },

  [SPELLCASTER_TYPE_ERROR]: state => {
    Vue.set(state.statusVuex, 'spellCasterType', 'error');
  },
  [SPELLCASTER_TYPE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'spellCasterType', 'loading');
  },
  [SPELLCASTER_TYPE_SUCCESS]: state => {
    Vue.set(state.statusVuex, 'spellCasterType', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
