<template>
  <div id="skill-list" :class="'skill-list skill-list-mode-' + mode">
    <AnimationLoading v-if="!this.isContentReady" />

    <table class="table table-fixed skill-table">
      <thead>
        <tr id="skill-table-header-row">
          <th scope="col" class="left index"></th>
          <th scope="col" class="left">{{ $t('play.skills') }}</th>
          <th scope="col" class="center">{{ $t('play.roll_for_party') }}</th>
        </tr>
      </thead>
      <tbody v-if="this.isContentReady">
        <template v-for="(skill, skillIndex) in skills">
          <tr
            v-if="filterSkills(skill)"
            :key="`skill-${skillIndex}`"
            :class="[
              skillIndex % 2 === 0 ? 'even' : 'odd',
              skill.isSkill ? 'skills-entry ' + skill.type_label.toLowerCase() : 'skills-category',
              'nesting-' + handleNesting(skill),
              skill.statusTypedSkill === 'error' || errorFieldsInherited['skill_' + skill.id] ? 'error' : '',
              skill.open ? 'open' : '',
              skill.collapsed ? 'collapsed' : ''
            ]"
            :id="skill.isSkill ? 'skill-' + skill.id : 'category-' + skill.id"
            :data-id="skill.id"
            :data-template="skill.template"
            :data-skill-type="skill.isSkill ? skill.type : ''"
            :data-base-rank-available="skill.base_rank_available"
            :ref="skill.isSkill ? 'skills' : 'categories'"
          >
            <th
              scope="row"
              class="skill-index left"
              :id="(skill.isSkill ? 'skill' : 'category') + '-index-' + skill.id"
            >
              {{ skillIndex + 1 }}
            </th>
            <td class="name left">
              <span
                class="name-wrapper"
                :class="[skill.type === system.skill_types.whitelabel ? 'skill-white-label' : '']"
                :id="'skill-name-' + skill.id"
              >
                <a
                  v-if="isUsableSkill(skill.isSkill, skill.type)"
                  :href="'/skill/' + skill.id"
                  target="_blank"
                  class="skill-link"
                  >{{ skill.name }}</a
                >
                <template v-else>{{ skill.name }}</template>
              </span>

              {{ skill.isSkill && skill.medium ? '(' + skill.medium + ')' : '' }}

              <img
                v-if="!skill.isSkill"
                class="icon icon-expand"
                :src="
                  config.mediaPath.application.icons +
                    (skill.open ? config.assets.icons.expand : config.assets.icons.collapse)
                "
                :alt="$t('global.expand_collapse')"
                @click="toggleCollapse(skillIndex, skill.nesting)"
              />

              <b-tooltip
                v-if="skill.synopsis"
                :target="'skill-index-' + skill.id"
                placement="topright"
                triggers="hover"
                custom-class="skill-tooltip"
              >
                {{ skill.synopsis }}
              </b-tooltip>
            </td>
            <td class="commit center">
              <img
                v-if="skill.isSkill"
                class="icon icon-die"
                :src="config.mediaPath.application.icons + config.assets.icons.die6"
                :alt="$t('skill.commit_action')"
                @click="commitAction(skill)"
              />
            </td>
          </tr>
        </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import formMixin from '@/mixins/form';
import formulasMixin from '@/mixins/formulas';
import rollResolver from '@/mixins/rollResolver';
import { SKILLS_REQUEST } from '@/store/actions/skill';
import { EventBus } from '@/utils/eventBus';
import { config } from '@/setup/config';
import { mapGetters, mapState } from 'vuex';

let loopCategoryNesting = 1;

export default {
  name: 'ActionSkillList',
  mixins: [formMixin, formulasMixin, rollResolver],
  props: {
    mode: {
      type: String,
      default: null
    },
    errorFieldsInherited: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      areSkillsLoaded: false,
      config,
      filter: this.$route.params.filter,
      skills: [],
      raceSkills: [],
      rankUpRanks: 0,
      tempSkillsIndex: 0
    };
  },
  computed: {
    ...mapGetters(['isSystemLoaded']),
    ...mapState(['character', 'system', 'scores']),
    isContentReady() {
      return this.isSystemLoaded && this.areSkillsLoaded;
    }
  },
  created() {
    this.populateSkills();
  },
  methods: {
    // ALL modes - start
    toggleCollapse(index, categoryNesting) {
      // this method relies on the skill presentation order, meaning that will stop working if entries get shuffled or reordered
      const collapse = !this.skills[index + 1].collapsed;
      this.skills[index].open = !this.skills[index].open;
      for (let c = index + 1; c < this.skills.length; c++) {
        if (this.skills[c].nesting <= categoryNesting && !this.skills[c].isSkill) {
          break;
        }

        if (!this.skills[c].isSkill && collapse) {
          this.skills[c].open = true;
        }

        if (this.skills[c].isSkill && collapse) {
          this.skills[c].collapsed = collapse;
        } else {
          this.skills[c].collapsed = !this.skills[c].collapsed;
        }
      }
    },
    // ALL modes - end

    // PLAY mode - start
    commitAction(skill) {
      const skillBonus = this.formulas_totalBonus(skill);

      this.rollResolver_execute(
        this.$store.state.character.id,
        skill.id,
        skill.maneuver_type,
        skill.maneuver_subtype,
        skillBonus
      )
        .then(() => {
          //console.log('commitAction', resp);
          console.log('action committed');
          window.open('https://www.syntropy-rpg.com/play/action/monitor', 'action_monitor');
          /*
          EventBus.$emit('modal', 'close', {
            headerText: 'all goood',
            bodyText: 'message.' + resp
          });
          */
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    },
    // PLAY mode - end

    // *** OTHER SHARED FUNCTIONALITIES - start *** //
    filterSkills() {
      return true;
    },
    handleNesting(skill) {
      let nesting = skill.nesting;
      if (skill.isSkill) {
        nesting = nesting * 1 + loopCategoryNesting * 1;
      } else {
        loopCategoryNesting = skill.nesting;
      }
      return nesting;
    },
    isUsableSkill(isSkill, type) {
      return isSkill && type !== this.system.skill_types.whitelabel;
    },
    populateSkills() {
      this.$store
        .dispatch(SKILLS_REQUEST)
        .then(data => {
          this.skills = data;

          this.$nextTick(() => {
            this.areSkillsLoaded = true;
          });
        })
        .catch(err => {
          EventBus.$emit('modal', 'close', {
            headerText: 'message.operation_error',
            bodyText: 'message.' + err
          });
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
@include skill-table;

.skill-list {
  position: relative;
}

// EDIT MODE - start
.skill-list-mode-edit {
  .skill-table {
    &.table-fixed {
      thead {
        th {
          top: 0;
        }
      }
    }
  }
}
// EDIT MODE - end
</style>
