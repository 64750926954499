<template>
  <div class="body-view profile container-fluid">
    <div class="row">
      <div class="col-12 points-counters">
        <CharacterCounterHitPoints />
        <CharacterCounterExhaustionPoints />
        <CharacterCounterPowerPoints />
        <CharacterCounterFatePoints />
        <CharacterCounterSanityPoints />
        <CharacterCounterReputationPoints />
        <CharacterCounterGracePoints />
      </div>
    </div>
    <div class="row relative">
      <!-- col 1 -->
      <div class="col-5">
        <StatsBox ref="statsBox" mode="play" />
      </div>
      <!-- col 2 -->
      <div class="col-4">
        <table class="profile-table">
          <tbody v-if="isCharacterLoaded">
            <tr>
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.user_name') }}</span>
              </td>
              <td class="profile-table-data">{{ user.name }}</td>
            </tr>
            <tr>
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.character_name') }}</span>
              </td>
              <td class="profile-table-data">{{ character.name }}</td>
            </tr>
            <tr>
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.race') }}</span>
              </td>
              <td class="profile-table-data">{{ race }} ({{ character.metrics.sex }})</td>
            </tr>
            <tr v-if="character.race && character.race.origin1_sex">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.race_' + character.race.origin1_sex) }}</span>
              </td>
              <td class="profile-table-data">{{ raceOrigin(1) }}</td>
            </tr>
            <tr v-if="character.race && character.race.origin2_sex">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.race_' + character.race.origin2_sex) }}</span>
              </td>
              <td class="profile-table-data">{{ raceOrigin(2) }}</td>
            </tr>
            <tr v-if="character.race && character.race.isCrossbreed">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.predominant_race') }}</span>
              </td>
              <td class="profile-table-data" v-if="character.race && character.race.predominant">
                {{ predominantRace(character.race.predominant) }}
              </td>
              <td class="profile-table-data" v-else>
                {{ $t('profile.no_predominant_race') }}
              </td>
            </tr>
            <tr v-if="character.metrics">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.age') }}</span>
              </td>
              <td class="profile-table-data">
                {{ character.metrics.age }}
                ({{
                  getBirthdate(
                    character.metrics.birth_day,
                    character.metrics.birth_month,
                    character.metrics.birth_year
                  )
                }})
              </td>
            </tr>
            <tr v-if="character.metrics">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.physique') }}</span>
              </td>
              <td class="profile-table-data">
                {{ character.metrics.physique }}
              </td>
            </tr>
            <tr v-if="character.metrics">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.height') }}</span>
              </td>
              <td class="profile-table-data">
                {{ character.metrics.height }}
                {{ character.metrics.height_unit }}
              </td>
            </tr>
            <tr v-if="character.metrics">
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.weight') }}</span>
              </td>
              <td class="profile-table-data">
                {{ character.metrics.weight }}
                {{ character.metrics.weight_unit }}
                <template v-if="character.metrics.isOverweight">
                  ({{ $t('profile.overweight') }}:
                  {{ character.metrics.overweight }}
                  {{ character.metrics.weight_unit }})
                </template>
              </td>
            </tr>
            <tr>
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.class') }}</span>
              </td>
              <td class="profile-table-data">{{ character.class.name }}</td>
            </tr>
            <tr>
              <td class="profile-table-label">
                <span class="text">{{ $t('profile.realm(s)') }}</span>
              </td>
              <td class="profile-table-data" v-if="character.realms">
                {{ statsText(character.realms.components) }} ({{ statsText(character.realms.components_magic) }})
              </td>
            </tr>
            <tr v-for="(category, catIndex) in factorCategories" :key="catIndex">
              <td class="profile-table-label">
                <span class="text">{{ category.name }}</span>
              </td>
              <td class="profile-table-data">
                <ul class="feature">
                  <li
                    v-for="(factor, factorIndex) in groupedFactors(category)"
                    :key="`stat-${catIndex}-${factorIndex}`"
                    :class="{
                      advantage: category.isAdvantage,
                      disadvantage: !category.isAdvantage
                    }"
                  >
                    {{ factor.name }}
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import StatsBox from '@/components/Stats/StatsBox.vue';
import CharacterCounterHitPoints from '@/components/Character/Counters/HitPoints';
import CharacterCounterExhaustionPoints from '@/components/Character/Counters/ExhaustionPoints';
import CharacterCounterPowerPoints from '@/components/Character/Counters/PowerPoints';
import CharacterCounterFatePoints from '@/components/Character/Counters/FatePoints';
import CharacterCounterSanityPoints from '@/components/Character/Counters/SanityPoints';
import CharacterCounterReputationPoints from '@/components/Character/Counters/ReputationPoints';
import CharacterCounterGracePoints from '@/components/Character/Counters/GracePoints';
import { mapGetters, mapState } from 'vuex';

export default {
  name: 'CharacterProfileView',
  components: {
    StatsBox,
    CharacterCounterHitPoints,
    CharacterCounterExhaustionPoints,
    CharacterCounterPowerPoints,
    CharacterCounterFatePoints,
    CharacterCounterSanityPoints,
    CharacterCounterReputationPoints,
    CharacterCounterGracePoints
  },
  computed: {
    ...mapGetters(['isCharacterLoaded']),
    ...mapState(['character', 'user']),
    factorCategories() {
      let categories = [];
      let currCatId = null;

      if (this.character.feature) {
        for (let s = 0; s < this.character.feature.length; s++) {
          if (currCatId !== this.character.feature[s].type_code) {
            categories.push({
              type_code: this.character.feature[s].type_code,
              name: this.character.feature[s].type,
              isAdvantage: this.character.feature[s].isAdvantage
            });
            currCatId = this.character.feature[s].type_code;
          }
        }
      }
      return categories;
    },
    race() {
      /*
      let race = ''
      if (this.character.race && this.character.race.stack) {
        for (let r = this.character.race.stack.length - 1; r >= 0; r--) {
          race += this.character.race.stack[r].name + ' '
        }
        race = race.substring(0, race.length - 1)
      }
      return race
      */
      let race = '';
      if (this.character.race && this.character.race.stack) {
        race = this.character.race.stack[0].name;
      }
      return race;
    }
  },
  methods: {
    getBirthdate(day, month, year) {
      const localizedMonth = this.$helpers.getLocalizedMonth(month, this.user.language);
      return day + ' ' + localizedMonth + ', ' + this.$helpers.formatNumber(year);
    },
    groupedFactors(currCat) {
      if (!this.character.feature) {
        return undefined;
      }
      return this.character.feature.filter(function(factor) {
        return factor.type_code === currCat.type_code;
      });
    },
    predominantRace(parentRaceId) {
      const race1OriginStack = this.character.race['origin1'];
      const race2OriginStack = this.character.race['origin2'];
      const raceOriginStack = race1OriginStack.concat(race2OriginStack);
      const raceOrigin = raceOriginStack.find(function(race) {
        return race.id === parentRaceId;
      });
      return raceOrigin.name;
    },
    raceOrigin(parent) {
      const raceOriginStack = this.character.race['origin' + parent];
      return raceOriginStack[0].name;
    },
    statsText(stats) {
      let statsString = '';
      for (let i = 0; i < stats.length; i++) {
        statsString += stats[i]['name'] + '/';
      }
      return statsString.substring(0, statsString.length - 1);
    }
  }
};
</script>

<style lang="scss" scoped>
.profile {
  &-table {
    &-label {
      vertical-align: top;

      .text::after {
        content: ':';
        display: inline-block;
      }
    }

    &-data {
      padding-left: 10px;
      vertical-align: top;
    }
  }

  ul.feature {
    list-style: none;
    margin-left: 0;
    padding-left: 1em;
    text-indent: -1em;

    li {
      &.advantage,
      &.disadvantage {
        &::before {
          display: inline-block;
          margin-left: 15px;
          margin-right: 3px;
          text-align: center;
          width: 0;
        }
      }

      &.advantage {
        &::before {
          content: $advantage-list-symbol--alternative;
        }
      }

      &.disadvantage {
        &::before {
          content: $disadvantage-list-symbol--alternative;
          letter-spacing: 2px;
        }
      }
    }
  }

  .points-counters {
    display: flex;
    flex-direction: row;

    .counter {
      margin-right: 25px;
    }
  }
}
</style>
