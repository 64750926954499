<template>
  <div class="tile tile-selectable item-frame" @click="selectItem()">
    <div class="item-image-frame">
      <img
        class="item-image image-fill-frame"
        :class="item.image ? '' : 'fade-image'"
        border="0"
        :alt="item.name"
        :src="item.image ? imageUrl + item.id + '/' + item.image : missingImage"
      />
      <button v-if="showButtons" type="button" class="btn btn-primary btn-close-item" @click.stop="deleteItem()">
        {{ $t('button.' + entityNature + '.delete_' + entityNature) }}
      </button>
    </div>
    <ul class="item-text">
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.name') }}</span
        >{{ item.name }}
      </li>
      <li>
        <span class="item-text-label">{{ $t(entityNature + '.owner') }}</span
        >{{ item.owner && item.owner.name ? item.owner.name : $t('global.public') }}
      </li>
    </ul>
  </div>
</template>

<script>
const entityNature = 'system',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { SYSTEM_SELECT } from '@/store/actions/system';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import { config } from '@/setup/config';
import { mapGetters } from 'vuex';

export default {
  name: entityNatureCapitalized,
  props: ['action', 'item'],
  data() {
    return {
      itemToDelete: undefined,
      entityNature,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters(['get' + entityNatureCapitalized + 'Status', 'isMaster', 'is' + entityNatureCapitalized + 'Loaded']),
    imageUrl() {
      return config.mediaPath[entityNature] + '/';
    },
    missingImage() {
      return config.mediaPath.application.placeholders + 'missing_' + entityNature + '_picture.png';
    },
    showButtons() {
      return this.action !== 'select';
    }
  },
  methods: {
    deleteItem() {
      /* IMPORTANT: TO BE IMPLEMENTED 
        a system can be delete only if it was never played (i.e. no system linked) */
      console.log('delete ' + entityNature, this.item.id);
      this.$emit('delete-' + entityNature, this.item.id);
    },
    selectItem() {
      switch (this.action) {
        case 'select':
          this.selectSystem(this.item.id);
          break;
        default:
      }
    },
    selectSystem(id) {
      if (!id) {
        return false;
      }

      this.$store
        .dispatch(SYSTEM_SELECT, id)
        .then(() => {
          this.$router.push('/game/select');
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  max-width: 100%;

  .item {
    margin-bottom: 25px;

    &-image-frame {
      position: relative;
    }

    &-text {
      line-height: 1.5rem;
      list-style: none;
      padding: 6px;
      margin: 0;

      &-label {
        text-transform: uppercase;
        font-size: 1rem;

        &::after {
          content: ':';
          display: inline-block;
          margin-right: 5px;
        }
      }
    }

    .btn-close-item {
      right: 0;
      position: absolute;
      top: 0;
    }
  }
}
</style>
