import { AUTH_LOGOUT } from '../actions/auth';
import { ROLE_SELECT_RESET } from '../actions/role';
import {
  CATEGORIES_ERROR,
  CATEGORIES_REQUEST,
  CATEGORIES_SUCCESS,
  CHARACTER_GAME_SKILL_ADD_ERROR,
  CHARACTER_GAME_SKILL_ADD_SUCCESS,
  CHARACTER_GAME_SKILL_ADD,
  CHARACTER_GAME_SKILL_ERROR,
  CHARACTER_GAME_SKILL_REQUEST,
  CHARACTER_GAME_SKILL_SUCCESS,
  CHARACTER_SKILL_ERROR,
  CHARACTER_SKILL_EXPERIENCE_ADD_ERROR,
  CHARACTER_SKILL_EXPERIENCE_ADD_SUCCESS,
  CHARACTER_SKILL_EXPERIENCE_ADD,
  CHARACTER_SKILL_REQUEST,
  CHARACTER_SKILL_SUCCESS,
  CHARACTER_SKILLS_ERROR,
  CHARACTER_SKILLS_REQUEST,
  CHARACTER_SKILLS_SUCCESS,
  CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_ERROR,
  CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST,
  CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_SUCCESS,
  GAME_SKILL_ERROR,
  GAME_SKILL_REQUEST,
  GAME_SKILL_SUCCESS,
  GAME_SKILLS_ERROR,
  GAME_SKILLS_REQUEST,
  GAME_SKILLS_SUCCESS,
  MANEUVER_SUBTYPES_REQUEST,
  MANEUVER_TYPES_ERROR,
  MANEUVER_TYPES_REQUEST,
  MANEUVER_TYPES_SUCCESS,
  MEDIA_ERROR,
  MEDIA_REQUEST,
  MEDIA_SUCCESS,
  SKILL_ERROR,
  SKILL_REQUEST,
  SKILL_SAVE,
  SKILL_SUCCESS,
  SKILLS_ERROR,
  SKILLS_REQUEST,
  SKILLS_SUCCESS,
  SPELL_LISTS_ERROR,
  SPELL_LISTS_REQUEST,
  SPELL_LISTS_SUCCESS,
  STATUS_ERROR,
  STATUS_REQUEST,
  STATUS_SUCCESS,
  TYPES_ERROR,
  TYPES_REQUEST,
  TYPES_SUCCESS
} from '../actions/skill';
import { config } from '@/setup/config';
import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

const state = {
  statusVuex: {}
};

const getters = {
  getSkillStatus: state => statusName => {
    return state.statusVuex[statusName];
  }
};

const actions = {
  [CATEGORIES_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCategories, params)
        .then(resp => {
          commit(CATEGORIES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CATEGORIES_ERROR, err.response);
          reject(err);
        });
    });
  },

  [CHARACTER_GAME_SKILL_ADD]({ commit, rootState }, skill) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('skill', JSON.stringify(skill));
      return axios
        .post(config.sitePath.api.addGameSkill, params)
        .then(resp => {
          commit(CHARACTER_GAME_SKILL_ADD_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_GAME_SKILL_ADD_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_GAME_SKILL_REQUEST]({ commit, rootState }, data) {
    const { character, gameSkill } = data;

    if (!character || !gameSkill) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('gameSkill', gameSkill);
      return axios
        .post(config.sitePath.api.getCharacterGameSkill + '/' + character, params)
        .then(resp => {
          commit(CHARACTER_GAME_SKILL_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_GAME_SKILL_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_SKILL_EXPERIENCE_ADD]({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.addCharacterSkillExperience, formData)
        .then(resp => {
          commit(CHARACTER_SKILL_EXPERIENCE_ADD_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_SKILL_EXPERIENCE_ADD_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_SKILL_REQUEST]({ commit, rootState }, id) {
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacterSkill + '/' + id, params)
        .then(resp => {
          commit(CHARACTER_SKILL_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_SKILL_ERROR, err.response);
          reject(err);
        });
    });
  },
  [CHARACTER_SKILLS_REQUEST]({ commit, rootState }, filters = []) {
    const paramFilters = filters.length === 0 ? null : JSON.stringify(filters);

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('filters', paramFilters);
      return axios
        .post(config.sitePath.api.getCharacterSkills, params)
        .then(resp => {
          commit(CHARACTER_SKILLS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CHARACTER_SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getCharacterSkillClassCategoriesToPriotize, params)
        .then(resp => {
          commit(CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_ERROR, err.response);
          reject(err);
        });
    });
  },

  [GAME_SKILL_REQUEST]({ commit, rootState }, id) {
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getGameSkill + '/' + id, params)
        .then(resp => {
          commit(GAME_SKILL_SUCCESS);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAME_SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },
  [GAME_SKILLS_REQUEST]({ commit, rootState }, filters = []) {
    const paramFilters = filters.length === 0 ? null : JSON.stringify(filters);

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('filters', paramFilters);
      return axios
        .post(config.sitePath.api.getGameSkills, params)
        .then(resp => {
          commit(GAME_SKILLS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(GAME_SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [MANEUVER_SUBTYPES_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkillManeuverSubtypes, params)
        .then(resp => {
          commit(MANEUVER_TYPES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MANEUVER_TYPES_ERROR, err.response);
          reject(err);
        });
    });
  },
  [MANEUVER_TYPES_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkillManeuverTypes, params)
        .then(resp => {
          commit(MANEUVER_TYPES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MANEUVER_TYPES_ERROR, err.response);
          reject(err);
        });
    });
  },

  [MEDIA_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkillMedia, params)
        .then(resp => {
          commit(MEDIA_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(MEDIA_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SKILL_REQUEST]({ commit, rootState }, id) {
    if (!id) {
      return Promise.reject('no_id');
    }

    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkill + '/' + id, params)
        .then(resp => {
          commit(SKILLS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SKILL_SAVE]({ commit, rootState }, formData) {
    return new Promise((resolve, reject) => {
      formData.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.saveSkill, formData)
        .then(resp => {
          commit(SKILL_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SKILL_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SKILLS_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkills, params)
        .then(resp => {
          commit(SKILLS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SKILLS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [SPELL_LISTS_REQUEST]({ commit, rootState }, magicRealmIds) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      params.append('realms', JSON.stringify(magicRealmIds));
      params.append('token', magicRealmIds);
      return axios
        .post(config.sitePath.api.getSpellLists, params)
        .then(resp => {
          commit(SPELL_LISTS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(SPELL_LISTS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [STATUS_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkillStatus, params)
        .then(resp => {
          commit(STATUS_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(STATUS_ERROR, err.response);
          reject(err);
        });
    });
  },

  [TYPES_REQUEST]({ commit, rootState }) {
    return new Promise((resolve, reject) => {
      let params = new URLSearchParams();
      params.append('token', rootState.auth.token);
      return axios
        .post(config.sitePath.api.getSkillTypes, params)
        .then(resp => {
          commit(TYPES_SUCCESS, resp.data);
          resolve(resp.data);
        })
        .catch(err => {
          commit(TYPES_ERROR, err.response);
          reject(err);
        });
    });
  }
};

const mutations = {
  [AUTH_LOGOUT]: () => {
    Vue.helpers.resetState(state);
  },

  [CATEGORIES_ERROR]: state => {
    Vue.set(state.statusVuex, 'categories', 'error');
  },
  [CATEGORIES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'categories', 'loading');
  },
  [CATEGORIES_SUCCESS](state) {
    Vue.set(state.statusVuex, 'categories', 'success');
  },

  [CHARACTER_GAME_SKILL_ADD]: state => {
    Vue.set(state.statusVuex, 'gameAddSkill', 'loading');
  },
  [CHARACTER_GAME_SKILL_ADD_ERROR]: state => {
    Vue.set(state.statusVuex, 'gameAddSkill', 'error');
  },
  [CHARACTER_GAME_SKILL_ADD_SUCCESS](state) {
    Vue.set(state.statusVuex, 'gameAddSkill', 'success');
  },
  [CHARACTER_GAME_SKILL_ERROR]: state => {
    Vue.set(state.statusVuex, 'characterGameSkill', 'error');
  },
  [CHARACTER_GAME_SKILL_REQUEST]: state => {
    Vue.set(state.statusVuex, 'characterGameSkill', 'loading');
  },
  [CHARACTER_GAME_SKILL_SUCCESS](state) {
    Vue.set(state.statusVuex, 'characterGameSkill', 'success');
  },
  [CHARACTER_SKILL_EXPERIENCE_ADD]: state => {
    Vue.set(state.statusVuex, 'characterUpdateSkill', 'loading');
  },
  [CHARACTER_SKILL_EXPERIENCE_ADD_ERROR]: state => {
    Vue.set(state.statusVuex, 'characterUpdateSkill', 'error');
  },
  [CHARACTER_SKILL_EXPERIENCE_ADD_SUCCESS](state) {
    Vue.set(state.statusVuex, 'characterUpdateSkill', 'success');
  },
  [CHARACTER_SKILL_ERROR]: state => {
    Vue.set(state.statusVuex, 'characterSkill', 'error');
  },
  [CHARACTER_SKILL_REQUEST]: state => {
    Vue.set(state.statusVuex, 'characterSkill', 'loading');
  },
  [CHARACTER_SKILL_SUCCESS](state) {
    Vue.set(state.statusVuex, 'characterSkill', 'success');
  },
  [CHARACTER_SKILLS_ERROR]: state => {
    Vue.set(state.statusVuex, 'characterSkills', 'error');
  },
  [CHARACTER_SKILLS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'characterSkills', 'loading');
  },
  [CHARACTER_SKILLS_SUCCESS](state) {
    Vue.set(state.statusVuex, 'characterSkills', 'success');
  },

  [CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_ERROR]: state => {
    Vue.set(state.statusVuex, 'classSkillCategoriesToPrioritize', 'error');
  },
  [CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_REQUEST]: state => {
    Vue.set(state.statusVuex, 'classSkillCategoriesToPrioritize', 'loading');
  },
  [CLASS_SKILL_CATEGORIES_TO_PRIORITIZE_SUCCESS](state) {
    Vue.set(state.statusVuex, 'classSkillCategoriesToPrioritize', 'success');
  },

  [GAME_SKILL_ERROR]: state => {
    Vue.set(state.statusVuex, 'gameSkill', 'error');
  },
  [GAME_SKILL_REQUEST]: state => {
    Vue.set(state.statusVuex, 'gameSkill', 'loading');
  },
  [GAME_SKILL_SUCCESS](state) {
    Vue.set(state.statusVuex, 'gameSkill', 'success');
  },
  [GAME_SKILLS_ERROR]: state => {
    Vue.set(state.statusVuex, 'gameSkills', 'error');
  },
  [GAME_SKILLS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'gameSkills', 'loading');
  },
  [GAME_SKILLS_SUCCESS](state) {
    Vue.set(state.statusVuex, 'gameSkills', 'success');
  },

  [MANEUVER_SUBTYPES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'ManeuverTypes', 'loading');
  },
  [MANEUVER_TYPES_ERROR]: state => {
    Vue.set(state.statusVuex, 'ManeuverTypes', 'error');
  },
  [MANEUVER_TYPES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'ManeuverTypes', 'loading');
  },
  [MANEUVER_TYPES_SUCCESS](state) {
    Vue.set(state.statusVuex, 'ManeuverTypes', 'success');
  },

  [MEDIA_ERROR]: state => {
    Vue.set(state.statusVuex, 'media', 'error');
  },
  [MEDIA_REQUEST]: state => {
    Vue.set(state.statusVuex, 'media', 'loading');
  },
  [MEDIA_SUCCESS](state) {
    Vue.set(state.statusVuex, 'media', 'success');
  },

  [ROLE_SELECT_RESET]: () => {
    Vue.helpers.resetState(state);
  },

  [SKILL_ERROR]: state => {
    Vue.set(state.statusVuex, 'skill', 'error');
  },
  [SKILL_REQUEST]: state => {
    Vue.set(state.statusVuex, 'skill', 'loading');
  },
  [SKILL_SUCCESS](state) {
    Vue.set(state.statusVuex, 'skill', 'success');
  },

  [SKILLS_ERROR]: state => {
    Vue.set(state.statusVuex, 'skills', 'error');
  },
  [SKILLS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'skills', 'loading');
  },
  [SKILLS_SUCCESS](state) {
    Vue.set(state.statusVuex, 'skills', 'success');
  },

  [SPELL_LISTS_ERROR]: state => {
    Vue.set(state.statusVuex, 'spellLists', 'error');
  },
  [SPELL_LISTS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'spellLists', 'loading');
  },
  [SPELL_LISTS_SUCCESS](state) {
    Vue.set(state.statusVuex, 'spellLists', 'success');
  },

  [STATUS_ERROR]: state => {
    Vue.set(state.statusVuex, 'status', 'error');
  },
  [STATUS_REQUEST]: state => {
    Vue.set(state.statusVuex, 'status', 'loading');
  },
  [STATUS_SUCCESS](state) {
    Vue.set(state.statusVuex, 'status', 'success');
  },

  [TYPES_ERROR]: state => {
    Vue.set(state.statusVuex, 'types', 'error');
  },
  [TYPES_REQUEST]: state => {
    Vue.set(state.statusVuex, 'types', 'loading');
  },
  [TYPES_SUCCESS](state) {
    Vue.set(state.statusVuex, 'types', 'success');
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
