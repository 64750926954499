var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAuthenticated
    ? _c("header", { staticClass: "container-fluid header-menu" }, [
        _c(
          "ul",
          { staticClass: "header-menu-left col-3" },
          [
            _vm.isUserLoaded && _vm.date
              ? _c("li", [
                  _vm._v(
                    _vm._s(_vm.$t("global.date")) + ": " + _vm._s(_vm.date)
                  )
                ])
              : _vm._e(),
            _vm.isUserLoaded
              ? _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/account" } }, [
                      _vm._v(
                        _vm._s(_vm.$t("global.user")) +
                          ": " +
                          _vm._s(_vm.userName)
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _vm.isRoleLoaded
              ? [
                  _vm.isCharacterLoaded && _vm.isCharacter
                    ? [
                        _c(
                          "li",
                          [
                            _c("RouterLink", { attrs: { to: "/account" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("global.character")) +
                                  ": " +
                                  _vm._s(_vm.characterName)
                              )
                            ])
                          ],
                          1
                        ),
                        _c(
                          "li",
                          [
                            _c("RouterLink", { attrs: { to: "/account" } }, [
                              _vm._v(_vm._s(_vm.$t("global.party")) + ": "),
                              _c("span", { staticClass: "campaign" }, [
                                _vm._v(_vm._s(_vm.partyName))
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    : _vm.isMasterLoaded && _vm.isMaster
                    ? [
                        _c(
                          "li",
                          [
                            _c("RouterLink", { attrs: { to: "/account" } }, [
                              _vm._v(
                                _vm._s(_vm.$t("global.master")) +
                                  ": " +
                                  _vm._s(_vm.masterName)
                              )
                            ])
                          ],
                          1
                        )
                      ]
                    : _vm._e(),
                  _vm.isSettingLoaded
                    ? _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/account" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("global.world")) +
                                ": " +
                                _vm._s(_vm.settingName)
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isGameLoaded
                    ? _c(
                        "li",
                        [
                          _c("RouterLink", { attrs: { to: "/account" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("global.game")) +
                                ": " +
                                _vm._s(_vm.gameName)
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "li",
                    [
                      _c("RouterLink", { attrs: { to: "/account" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("global.current_campaign")) + ": "
                        ),
                        _c("span", { staticClass: "campaign" }, [
                          _vm._v(_vm._s(_vm.campaignName))
                        ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "li",
                    [
                      _c("RouterLink", { attrs: { to: "/account" } }, [
                        _vm._v(
                          _vm._s(_vm.$t("global.system")) +
                            ": " +
                            _vm._s(_vm.systemName)
                        )
                      ])
                    ],
                    1
                  )
                ]
              : _vm._e()
          ],
          2
        ),
        _vm.isAuthenticated
          ? _c(
              "ul",
              { staticClass: "header-menu-right is-authenticated col-9" },
              [
                _c(
                  "li",
                  [
                    _c(
                      "transition",
                      { attrs: { name: "expand" } },
                      [
                        _vm.arePreferencesLoaded
                          ? _c("Preferences", {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.showPreferences,
                                  expression: "showPreferences"
                                }
                              ]
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/account" } }, [
                      _vm._v(_vm._s(_vm.$t("global.account")))
                    ])
                  ],
                  1
                ),
                _c(
                  "li",
                  [
                    _c(
                      "b-dropdown",
                      {
                        staticClass: "header-submenu",
                        attrs: {
                          id: "role-select-menu",
                          type: "light",
                          variant: "light",
                          text: _vm.$t("role.select")
                        }
                      },
                      [
                        _c(
                          "b-dropdown-item",
                          { staticClass: "header-submenu-option" },
                          [
                            _c(
                              "RouterLink",
                              { attrs: { to: "/character/list" } },
                              [_vm._v("Character")]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-dropdown-item",
                          { staticClass: "header-submenu-option" },
                          [
                            _c(
                              "RouterLink",
                              { attrs: { to: "/master/list" } },
                              [_vm._v("Master")]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c("li", { on: { click: _vm.logout } }, [
                  _c("span", { staticClass: "tab-link" }, [
                    _vm._v(_vm._s(_vm.$t("global.logout")))
                  ])
                ]),
                _c(
                  "li",
                  {
                    staticClass: "preferences",
                    on: { click: _vm.togglePreferences }
                  },
                  [
                    _c("img", {
                      staticClass: "icon-config",
                      attrs: {
                        src:
                          _vm.config.mediaPath.application.icons +
                          _vm.config.assets.icons.config,
                        alt: _vm.$t("global.settings")
                      }
                    })
                  ]
                )
              ]
            )
          : _vm._e(),
        !_vm.isAuthenticated
          ? _c(
              "ul",
              { staticClass: "header-menu-right is-not-authenticated col-10" },
              [
                _c(
                  "li",
                  [
                    _c("RouterLink", { attrs: { to: "/" } }, [
                      _vm._v(_vm._s(_vm.$t("global.login")))
                    ])
                  ],
                  1
                )
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            staticClass: "counters",
            staticStyle: { display: "none" },
            attrs: { id: "counters" }
          },
          [
            _c("PartyCounterExperiencePoints"),
            _c("CharacterCounterExperiencePoints")
          ],
          1
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }