var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "character-score-summary-frame" }, [
    _vm.isCharacterLoaded
      ? _c("ul", { staticClass: "character-score-summary-text" }, [
          _vm.character.scores && _vm.character.scores.game
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-score-summary-text-label" },
                  [_vm._v(_vm._s(_vm.$t("character.game_scores")))]
                ),
                _c("ul", { staticClass: "character-score-summary-text-list" }, [
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.hit_points")))]
                    ),
                    _vm._v(_vm._s(_vm.character.scores.game.hit_points) + " ")
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.power_points")))]
                    ),
                    _vm._v(_vm._s(_vm.character.scores.game.power_points) + " ")
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.exhaustion_points")))]
                    ),
                    _vm._v(
                      _vm._s(_vm.character.scores.game.exhaustion_points) + " "
                    )
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.sanity_points")))]
                    ),
                    _vm._v(
                      _vm._s(_vm.character.scores.game.sanity_points) + " "
                    )
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.fate_points")))]
                    ),
                    _vm._v(_vm._s(_vm.character.scores.game.fate_points) + " ")
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.reputation_points")))]
                    ),
                    _vm._v(_vm._s(_vm.scores.play.reputation_points) + " ")
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.grace_points")))]
                    ),
                    _vm._v(_vm._s(_vm.scores.play.grace_points) + " ")
                  ])
                ])
              ])
            : _vm._e(),
          _vm.character.scores && _vm.character.scores.permanent_modifiers
            ? _c("li", [
                _c(
                  "span",
                  { staticClass: "character-score-summary-text-label" },
                  [_vm._v(_vm._s(_vm.$t("character.game_rates")))]
                ),
                _c("ul", { staticClass: "character-score-summary-text-list" }, [
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.learning_rate")))]
                    ),
                    _vm._v(
                      _vm._s(
                        _vm.character.scores.permanent_modifiers.learning_rate
                      ) + " "
                    )
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [
                        _vm._v(
                          _vm._s(_vm.$t("character.soul_departure_rounds"))
                        )
                      ]
                    ),
                    _vm._v(
                      _vm._s(
                        _vm.character.scores.permanent_modifiers
                          .soul_departure_rounds
                      ) + " "
                    )
                  ]),
                  _c("li", { staticClass: "bullet" }, [
                    _c(
                      "span",
                      { staticClass: "character-score-summary-text-label" },
                      [_vm._v(_vm._s(_vm.$t("character.healing_rate")))]
                    ),
                    _vm._v(
                      _vm._s(
                        _vm.character.scores.permanent_modifiers.healing_rate
                      ) + " "
                    )
                  ])
                ])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }