<template>
  <div class="container items" :id="entityNature + 'List'">
    <div class="row relative">
      <AnimationLoading v-if="!this.isContentReady" />

      <!-- ITEM LIST -->
      <template v-if="this.isContentReady">
        <template v-if="items.length > 0">
          <template v-for="item in items">
            <div class="col-4 item" :key="item.id">
              <Item :ref="entityNature + '_' + item.id" :item="item" @delete-item="confirmDeleteItem" />
            </div>
          </template>
        </template>

        <div class="col-4 item-new" :class="entityNature + ' ' + entityNature + '-new'">
          <ItemCreation />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
const entityNature = 'campaign',
  entityNatureCapitalized = entityNature.charAt(0).toUpperCase() + entityNature.slice(1);

import { CAMPAIGN_DELETE, CAMPAIGNS_REQUEST } from '@/store/actions/campaign';
import { MESSAGE_ERROR_SET } from '@/store/actions/message';
import Item from '@/components/Campaign';
import ItemCreation from '@/components/Campaign/Creation';
import { mapGetters } from 'vuex';
import { EventBus } from '@/utils/eventBus';

export default {
  name: entityNatureCapitalized + 'List',
  components: {
    Item,
    ItemCreation
  },
  data() {
    return {
      items: [],
      isContentReady: false,
      entityNature,
      entityNatureCapitalized
    };
  },
  computed: {
    ...mapGetters(['get' + entityNatureCapitalized + 'Status', 'isMaster', 'is' + entityNatureCapitalized + 'Loaded']),
    isGameLoaded() {
      return this.$store.getters.isGameLoaded;
    }
  },
  created() {
    EventBus.$on('modal-ok', () => {
      this.deleteItem(this.itemToDelete);
      this.itemToDelete = undefined;
    });
    EventBus.$on('modal-cancel', () => {
      this.itemToDelete = undefined;
    });

    this.populateItems();
  },
  beforeDestroy() {
    EventBus.$off('modal-ok');
    EventBus.$off('modal-cancel');
  },
  methods: {
    confirmDeleteItem(id) {
      this.itemToDelete = id;
      EventBus.$emit('modal', 'ok-cancel', {
        headerText: 'modal.questions.sure_to_delete_' + entityNature,
        bodyText: 'message.all_data_will_be_lost_' + entityNature
      });
    },
    deleteItem(id) {
      if (!id) {
        return false;
      }

      this.$store
        .dispatch(CAMPAIGN_DELETE, id)
        .then(() => {
          this.itemToDelete = undefined;
          //this.$router.push(`/${entityNature}/list`);
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    },
    populateItems() {
      this.$store
        .dispatch(CAMPAIGNS_REQUEST)
        .then(data => {
          this.items = data;
          this.$nextTick(() => {
            this.isContentReady = true;
          });
        })
        .catch(err => {
          this.$store.commit(MESSAGE_ERROR_SET, err);
          this.$helpers.errorManager(err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.items {
  max-width: 100%;

  .item,
  .item-new {
    margin-bottom: 25px;
  }
}
</style>
